import { CaretDown } from '@phosphor-icons/react'
import React, { useState, useEffect } from 'react'
import { creationEventStore } from 'state/event/eventCreationStore'
import {
  ActivitiesScheduleType,
  EventResp,
  SuppliesType,
} from 'types/event/events'
import {
  AdditionalFeeResp,
  EstimatedActivity,
  PriceRange,
} from 'types/event/eventStep3'

interface EstimatedActivitySummaryProps {
  data: EventResp
}

const EstimatedActivitySummary: React.FC<EstimatedActivitySummaryProps> = ({
  data,
}) => {
  const [openActivity, setOpenActivity] = useState<number>(0)
  const [estimatedActivityPrice, setEstimatedActivityPrice] = useState<
    EstimatedActivity[]
  >([])
  const eventData = creationEventStore(store => store.eventData)
  const step3Payload = creationEventStore(store => store.step3Payload)
  const setStep3Payload = creationEventStore(store => store.setStep3Payload)

  const calculateActivityPrice = (
    activity: ActivitiesScheduleType,
  ): EstimatedActivity => {
    let activityPrice = 0
    let activityMinPrice = 0
    let activityMaxPrice = 0

    activity.supplies.forEach((supply: SuppliesType) => {
      const { minGuestsRequired = 1, maxGuestsAllowed = 1 } = data

      if (supply.price) {
        if (supply.type === 'reusable') {
          activityPrice += supply.price
        } else if (supply.type === 'consumable') {
          activityMinPrice += supply.price * (minGuestsRequired || 1)
          activityMaxPrice += supply.price * (maxGuestsAllowed || 1)
        }
      } else {
        const { min, max } = supply.priceRange
        if (supply.type === 'reusable') {
          activityMinPrice += min + activityPrice
          activityMaxPrice += max + activityPrice
        } else if (supply.type === 'consumable') {
          activityMinPrice += min * (minGuestsRequired || 1) + activityPrice
          activityMaxPrice += max * (maxGuestsAllowed || 1) + activityPrice
        }
      }
    })

    return {
      name: activity.name,
      price:
        activityMinPrice === 0
          ? activityPrice
          : { min: activityMinPrice, max: activityMaxPrice },
      supplies: activity.supplies || [],
    }
  }

  const estimatedTotalPrice = (): PriceRange => {
    let min = 0
    let max = 0

    min =
      step3Payload.totalStaffSalary.min +
      (typeof step3Payload.venuePrice === 'number'
        ? step3Payload.venuePrice
        : step3Payload.venuePrice.min)
    max =
      step3Payload.totalStaffSalary.max +
      (typeof step3Payload.venuePrice === 'number'
        ? step3Payload.venuePrice
        : step3Payload.venuePrice.max)

    estimatedActivityPrice.forEach(activity => {
      if (typeof activity.price === 'number') {
        min += activity.price
        max += activity.price
      } else {
        min += activity.price.min
        max += activity.price.max
      }
    })

    if (eventData?.additionalFee.length > 0) {
      eventData?.additionalFee.forEach((fee: AdditionalFeeResp) => {
        if (fee.price) {
          min += fee.price
          max += fee.price
        } else if (fee.priceRange) {
          min += fee.priceRange.min
          max += fee.priceRange.max
        }
      })
    }

    return {
      min,
      max,
    }
  }

  useEffect(() => {
    if (data?.activitiesSchedule.length > 0) {
      const updatedEstimatedActivityPrice = data.activitiesSchedule.map(
        calculateActivityPrice,
      )
      setEstimatedActivityPrice(updatedEstimatedActivityPrice)
    }
  }, [data])

  useEffect(() => {
    setStep3Payload({
      estimatedTotalPrice: estimatedTotalPrice(),
    })
  }, [
    estimatedActivityPrice,
    step3Payload.totalStaffSalary,
    eventData?.additionalFee,
  ])

  return (
    <div>
      {estimatedActivityPrice.length > 0 &&
        estimatedActivityPrice.map((activity, index) => {
          return (
            <div key={index}>
              <div className="flex align-center justify-between gap-20px mt-10px">
                <div className="flex align-center gap-5px">
                  <p>{activity.name}</p>
                  {activity.supplies.length > 0 && (
                    <CaretDown
                      size={14}
                      color="#000"
                      className="pointer"
                      onClick={() =>
                        setOpenActivity(prev => (prev === index ? 0 : index))
                      }
                    />
                  )}
                </div>
                <p>{`$${
                  typeof activity.price === 'number'
                    ? activity.price.toFixed(2)
                    : `${activity.price.min.toFixed(
                        2,
                      )} ~ $${activity.price.max.toFixed(2)}`
                }`}</p>
              </div>

              {activity.supplies.length > 0 &&
                activity.supplies.map((supply: SuppliesType) => {
                  return (
                    openActivity === index && (
                      <div className="flex justify-between align-center gap-5px pl-15px">
                        <p className="text-gray">{supply.name}</p>
                        <p className="text-gray">{`${
                          typeof supply.price === 'number'
                            ? `$${supply.price?.toFixed(2)}`
                            : `$${supply.priceRange.min.toFixed(
                                2,
                              )} ~ $${supply.priceRange.max.toFixed(2)}`
                        }`}</p>
                      </div>
                    )
                  )
                })}
            </div>
          )
        })}
    </div>
  )
}

export default EstimatedActivitySummary
