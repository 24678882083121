import { CheckCircle, PencilSimpleLine, XCircle } from '@phosphor-icons/react'
import { Table } from 'antd'
import { useGetColors } from 'api/Listings/Color'
import moment from 'moment'
import React, { useMemo } from 'react'
import { colorStore } from 'state/listing/colorListing'
import { ColorListing } from 'types/listing/listings'

interface ColorTableProps {
  id: string
}

const ColorTable: React.FC<ColorTableProps> = ({ id }) => {
  const { setAddColorModal, setcolorData } = colorStore(store => ({
    setAddColorModal: store.setAddColorModal,
    setcolorData: store.setColorListingData,
  }))
  const { data, isLoading, isFetching, refetch } = useGetColors()

  const handleEditClick = (data: ColorListing) => {
    setcolorData(data)
    setAddColorModal(true)
  }

  const columns = [
    {
      title: 'Color Code',
      dataIndex: 'colorCode',
      key: 'colorCode',
      render: (text: string) => <p className="text-14 w-400">{text}</p>,
    },
    {
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
      render: (text: string) => (
        <div
          className="w-30px h-30px radius-rounded"
          style={{ backgroundColor: `#${text}` }}
        />
      ),
    },
    {
      title: 'Default',
      dataIndex: 'default',
      key: 'default',
      render: (text: boolean) =>
        text ? (
          <CheckCircle size={32} weight="thin" color="#8cc73f" />
        ) : (
          <XCircle size={32} weight="thin" color="#ff6961" />
        ),
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text: string) => (
        <p className="text-14 w-400">{moment(text).format('DD MMM, YYYY')}</p>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      render: (data: ColorListing) => (
        <div className="flex align-center gap-20px">
          <PencilSimpleLine
            size={20}
            color="#000"
            className="pointer"
            onClick={() => handleEditClick(data)}
          />
        </div>
      ),
    },
  ]

  // Fetch data whenever `id` changes
  React.useEffect(() => {
    refetch()
  }, [id])

  const tableData = useMemo(() => {
    return (
      data?.map((color: ColorListing) => ({
        key: color._id,
        colorCode: color.code,
        color: color.code,
        default: color.isDefault,
        updatedAt: color.updatedAt,
        actions: color,
      })) || []
    )
  }, [data])

  return (
    <div>
      {isLoading || isFetching ? (
        <div className="w-full h-70vh flex align-center justify-center">
          <div className="loader"></div>
        </div>
      ) : (
        <Table
          className="colored_header_table"
          columns={columns}
          dataSource={tableData}
          pagination={false}
        />
      )}
    </div>
  )
}

export default ColorTable
