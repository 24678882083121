import React from 'react'
import useFormSubmit from 'helper/Hooks/formSubmit'
import { activityCategoryStore } from 'state/listing/activityCategoryListing'
import CustomModal from 'components/global/CustomModal'
import CustomModalFooter from 'components/global/CustomModalFooter'
import ActivityCategoryForm from './ModalContent/ActivityCategoryForm'

const ActivityCategoryModal: React.FC = () => {
  const { form, handleSubmit } = useFormSubmit()

  const {
    activityCategoryData,
    addActivityCategoryModal,
    setAddActivityCategoryModal,
  } = activityCategoryStore(store => ({
    activityCategoryData: store.activityCategoryListingData,
    addActivityCategoryModal: store.addActivityCategoryModal,
    setAddActivityCategoryModal: store.setAddActivityCategoryModal,
  }))

  return (
    <>
      <CustomModal
        openModal={addActivityCategoryModal}
        closeModal={setAddActivityCategoryModal}
        title={
          activityCategoryData
            ? 'Edit Event Category'
            : 'Add new Event Category'
        }
        footer={
          <CustomModalFooter
            multibutton={false}
            btnAlignment="justify-end"
            btnText={activityCategoryData ? 'Update' : 'Create'}
            btnType="primary"
            onSubmit={handleSubmit}
          />
        }
      >
        <ActivityCategoryForm form={form} />
      </CustomModal>
    </>
  )
}

export default ActivityCategoryModal
