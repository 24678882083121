import React, { useState } from 'react'
import { Button, Col, Form, FormInstance, Input, Row, Switch } from 'antd'
import { Sketch } from '@uiw/react-color'
import { useCreateColor, useUpdateColor } from 'api/Listings/Color'
import { myStore } from 'state/store'
import { colorStore } from 'state/listing/colorListing'

interface ColorFormProps {
  form: FormInstance
}

const ColorForm: React.FC<ColorFormProps> = ({ form }) => {
  const { setAddColorModal, colorData } = colorStore(store => ({
    setAddColorModal: store.setAddColorModal,
    colorData: store.colorListingData,
  }))
  const [hex, setHex] = useState(colorData ? `#${colorData.code}` : '#fff')
  const [colorPicker, setColorPicker] = useState(false)
  const [isDefault, setIsDefault] = useState(
    colorData ? colorData.isDefault : false,
  )
  const { mutate: createColor } = useCreateColor()
  const { mutate: updateColor } = useUpdateColor()
  const setModalBtnLoading = myStore(store => store.setModalBtnLoader)

  const formSchema = {
    color: colorData ? colorData.code.replace('#', '') : undefined,
    isDefault: colorData ? colorData.isDefault : false,
  }
  const onFinish = (values: { color: string; isDefault: boolean }) => {
    const payload = {
      code: values.color.replace('#', ''),
      isDefault: values.isDefault,
    }
    setModalBtnLoading(true)
    if (colorData) {
      updateColor(
        { colorId: colorData._id, payload },
        {
          onSuccess: () => {
            setModalBtnLoading(false)
            setAddColorModal(false)
          },
          onError: () => {
            setModalBtnLoading(false)
          },
        },
      )
    } else {
      createColor(payload, {
        onSuccess: () => {
          setModalBtnLoading(false)
          setAddColorModal(false)
        },
        onError: () => {
          setModalBtnLoading(false)
        },
      })
    }
  }
  return (
    <div>
      <Form
        name="color-listing-form"
        form={form}
        initialValues={formSchema}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
      >
        <Row className="mt-10px">
          <Col span={24}>
            <Form.Item name="isDefault" valuePropName="checked">
              <div className="flex align-center justify-between gap-10px">
                <label className="input-label required-input">
                  Set default
                </label>
                <div className="switch-btn">
                  <Switch
                    checked={isDefault}
                    onChange={checked => {
                      setIsDefault(checked)
                      form.setFieldsValue({ isDefault: checked })
                    }}
                  />
                </div>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row
          className="mt-10px"
          style={{ height: `${colorPicker ? '400px' : 'auto'}` }}
        >
          <Col span={24}>
            <label className="input-label required-input">Select color</label>
            <Form.Item
              name="color"
              rules={[{ required: true, message: 'Color is required' }]}
            >
              <div className="relative">
                <Input
                  value={hex}
                  readOnly
                  onClick={() => setColorPicker(prev => !prev)}
                  style={{
                    backgroundColor: hex,
                    cursor: 'pointer',
                    color: '#fff',
                    border: '1px solid #ccc',
                  }}
                />
                {colorPicker && (
                  <div className="absolute z-10 mt-2">
                    <Sketch
                      color={hex}
                      onChange={color => {
                        setHex(color.hex)
                        form.setFieldsValue({ color: color.hex })
                      }}
                    />
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => setColorPicker(false)}
                      style={{ marginTop: 10, width: '100%' }}
                    >
                      OK
                    </Button>
                  </div>
                )}
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default ColorForm
