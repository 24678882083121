import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { axiosDelete, axiosGet, axiosPatch, axiosPost } from 'Redux/axiosHelper'
import {
  CreateTeamPayload,
  GetAllTeamRespType,
  GetAllTeamsParams,
  TeamType,
} from 'types/team/team'

// get all teams of all organizations
const getAllTeams = async (params: GetAllTeamsParams) => {
  const response = await axiosGet(`v1/admins/teams`, params)
  return response.data
}
export const useGetAllTeams = (params: GetAllTeamsParams) => {
  const allTeams = useQuery<any>(
    ['get-all-teams-list'],
    () => getAllTeams(params),
    {
      enabled: false,
    },
  )
  return allTeams
}

// get all teams of one organization
const getAllTeamsOfOneOrganization = async (organizationId: string) => {
  const response = await axiosGet(`v1/organization-team/${organizationId}`)
  return response.data
}
export const useGetAllTeamsOfOneOrganization = (organizationId: string) => {
  const teamsOfOneOrg = useQuery<TeamType[]>(
    ['all-teams-by-organization-id'],
    () =>
      organizationId ? getAllTeamsOfOneOrganization(organizationId) : null,
    {
      enabled: false,
    },
  )
  return teamsOfOneOrg
}

// create team
const createTeamForOrganization = async ({
  organizationId,
  payload,
}: {
  organizationId: string
  payload: CreateTeamPayload
}) => {
  const response = await axiosPost(
    payload,
    `v1/organization-team/${organizationId}`,
  )
  return response.data
}

export const useCreateTeamForOrganization = () => {
  const queryClient = useQueryClient()
  return useMutation(createTeamForOrganization, {
    onSuccess: newTeam => {
      queryClient.setQueryData(
        ['get-all-teams-list'],
        (oldData: GetAllTeamRespType | undefined) => {
          if (!oldData) return
          if (!oldData.count)
            return {
              ...oldData,
              count: oldData.count + 1,
              records: [newTeam],
            }
          return {
            ...oldData,
            count: oldData.count + 1,
            records: [newTeam, ...oldData.records],
          }
        },
      )
    },
  })
}

// update team
const updateTeamInOrganization = async ({
  teamId,
  payload,
}: {
  teamId: string
  payload: CreateTeamPayload
}) => {
  const response = await axiosPatch(payload, `v1/organization-team/${teamId}`)
  return response.data
}

export const useUpdateTeamInOrganization = () => {
  const queryClient = useQueryClient()
  return useMutation(updateTeamInOrganization, {
    onSuccess: updatedTeam => {
      queryClient.setQueryData(
        ['get-all-teams-list'],
        (oldData: GetAllTeamRespType | undefined) => {
          if (!oldData) return undefined
          if (!oldData.count)
            return {
              ...oldData,
              records: [updatedTeam],
            }
          return {
            ...oldData,
            records: oldData.records.map(team =>
              team._id === updatedTeam._id ? updatedTeam : team,
            ),
          }
        },
      )
    },
  })
}

// delete team
const deleteTeamInOrganization = async (teamId: string) => {
  const response = await axiosDelete(`v1/organization-team/${teamId}`)
  return response.data
}
export const useDeleteTeamInOrganization = () => {
  const queryClient = useQueryClient()
  return useMutation(deleteTeamInOrganization, {
    onSuccess: deletedTeam => {
      queryClient.setQueryData(
        ['get-all-teams-list'],
        (oldData: GetAllTeamRespType | undefined) => {
          if (!oldData) return
          return {
            ...oldData,
            records: oldData.records.map(team =>
              team._id === deletedTeam._id ? deletedTeam : team,
            ),
          }
        },
      )
    },
  })
}
