import React, { useState } from 'react'
import { Row, Col, Button, Tooltip, Space, Dropdown, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import ExportModal from './ExportModal' // Adjust the path as necessary
import printerImg from '../../../assets/Images/Printer.svg'
import downloadImg from '../../../assets/Images/Download.svg'
import statusimg from '../../../assets/Images/Activity.svg'
import deleteImg from '../../../assets/Images/delete_white.svg'
import ImportOrganizer from './ImportOrganizer'
import FilePlus from 'assets/Images/FilePlus.svg'
import { organizerStore } from 'state/organizer/organizer'
const PageNameHeader = ({ CSdata, selectedRows, setAlert, deleteMulti }) => {
  const {
    setOrganizerModalVisible,
    setIsUpdating,
    setOrganizerCurrentStep,
    setOrganizerData,
  } = organizerStore(store => ({
    setOrganizerModalVisible: store.setOrganizerModalVisible,
    setIsUpdating: store.setIsUpdating,
    setOrganizerCurrentStep: store.setOrganizerCurrentStep,
    setOrganizerData: store.setOrganizerData,
  }))
  const [isExportModalVisible, setExportModalVisible] = useState(false)
  const [isImportModalVisible, setIsImportModalVisible] = useState(false)
  const handleExportClick = () => {
    setExportModalVisible(true)
  }

  const handleModalClose = () => {
    setExportModalVisible(false)
  }
  const handleImportModalClose = () => {
    setIsImportModalVisible(false)
  }
  const handleImportClick = () => {
    setIsImportModalVisible(true)
  }
  const menu = (
    <Menu>
      <Menu.Item>
        <Space onClick={handleExportClick}>
          <img alt="img" src={downloadImg} /> Export
        </Space>
        <Space className="mt-10" onClick={handleImportClick}>
          <img alt="importIcon" src={FilePlus} /> Import
        </Space>
      </Menu.Item>
    </Menu>
  )

  const handleAddOrganizer = () => {
    setIsUpdating(false)
    setOrganizerCurrentStep(0)
    setOrganizerData(null)
    setOrganizerModalVisible(true)
  }

  return (
    <Row className="page-header" justify="space-between">
      <Col lg={{ span: 6 }} xs={{ span: 24 }}>
        <h1 className="table-head mb-0">Organizer</h1>
      </Col>

      <Col lg={{ span: 18 }} offset={6} xs={{ span: 24, offset: 0 }}>
        {selectedRows?.length > 0 ? (
          <Space
            className="organizations-table-onselect-btn"
            size={10}
            align="end"
          >
            <Tooltip placement="bottom" title="Status" color="blue">
              <Button
                className={'bg-grey br-8'}
                icon={
                  <img
                    alt="img"
                    src={statusimg}
                    style={{
                      padding: '1%',
                      height: '20px',
                      marginRight: '10px',
                    }}
                  ></img>
                }
                onClick={() => {
                  setAlert(true)
                }}
              >
                Status
              </Button>
            </Tooltip>
            <Tooltip placement="bottom" title="Print" color="blue">
              <Button
                className={'bg-grey br-8'}
                onClick={() => {
                  window.print()
                }}
                icon={
                  <img
                    alt="img"
                    src={printerImg}
                    style={{
                      padding: '1%',
                      height: '20px',
                      marginRight: '10px',
                    }}
                  ></img>
                }
              >
                Print
              </Button>
            </Tooltip>
            <Tooltip placement="bottom" title="Export" color="blue">
              <Button
                className={'bg-grey br-8'}
                icon={<img alt="img" src={downloadImg}></img>}
                onClick={handleExportClick}
              >
                Export
              </Button>
            </Tooltip>
            <Tooltip placement="bottom" title="Delete" color="blue">
              <Button
                className={'bg-grey br-8'}
                onClick={() => {
                  deleteMulti()
                }}
                icon={
                  <img
                    alt="img"
                    src={deleteImg}
                    style={{
                      padding: '1%',
                      height: '20px',
                      marginRight: '10px',
                    }}
                  ></img>
                }
              >
                Delete
              </Button>
            </Tooltip>
          </Space>
        ) : (
          <div className="right-search-block">
            <div
              className="table-action-btn new-organizations-export-btn"
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {localStorage.getItem('role') === 'admin' && (
                <Dropdown
                  overlay={menu}
                  placement="bottom"
                  className="bg-grey mx-20px"
                >
                  <button className=" prim-sm-btn" style={{ color: 'black' }}>
                    More <DownOutlined />
                  </button>
                </Dropdown>
              )}
              <Tooltip placement="bottom" title="Create">
                <Button
                  className={'btn-new primary br-8'}
                  // onClick={() => {
                  //   addOrg(true)
                  // }}
                  onClick={handleAddOrganizer}
                >
                  New organizer
                </Button>
              </Tooltip>
            </div>
          </div>
        )}
      </Col>
      <ExportModal
        visible={isExportModalVisible}
        onClose={handleModalClose}
        data={CSdata}
      />
      {isImportModalVisible && (
        <ImportOrganizer
          visible={isImportModalVisible}
          onClose={handleImportModalClose}
        />
      )}
    </Row>
  )
}

export default PageNameHeader
