import React, { useEffect, useState } from 'react'
import { Checkbox, Col, Form, FormInstance, Radio, Row, TimePicker } from 'antd'
import VenueInput from '../../Team/ModalContent/VenueInput'
import { Address, WeekDays } from 'types/globalTypes'
import { leagueEventStore } from 'state/event/leagueEvent'
import {
  convertGivenDayTypeToOther,
  convertMinutesToLocalTime,
  convertToLocal,
  formValidationOnRequiredFields,
  getDayNamesBetweenDates,
} from 'helper/utility'
import DivisionInput from './DivisionInput'
import { DivisionConstraintPayload } from 'types/league/divisionConstraints'
import moment from 'moment'
import {
  useCreateDivisionConstraint,
  useUpdateDivisionConstraint,
} from 'api/LeagueCreation/leagueDivisionConstraints'
import { myStore } from 'state/store'
import { infoMessage } from 'api/global'

interface AddDivisionConstraintFormProps {
  form: FormInstance
}

const AddDivisionConstraintForm: React.FC<AddDivisionConstraintFormProps> = ({
  form,
}) => {
  const [mapData, setMapModalData] = useState<Address | null>(null)
  const [checkedDays, setCheckedDays] = useState<WeekDays[]>([])

  const {
    leagueData,
    setAddDivisionConstraintModal,
    divisionConstraintData,
    setDivisionConstraintData,
  } = leagueEventStore(store => ({
    leagueData: store.leagueData,
    setAddDivisionConstraintModal: store.setAddDivisionConstraintModal,
    divisionConstraintData: store.divisionConstraintData,
    setDivisionConstraintData: store.setDivisionConstraintData,
  }))
  const setModalBtnLoader = myStore(store => store.setModalBtnLoader)

  const {
    mutate: divisionConstraintCreate,
    isLoading: divisionConstraintLoading,
  } = useCreateDivisionConstraint()
  const { mutate: divisionConstraintUpdate, isLoading: isUpdating } =
    useUpdateDivisionConstraint()

  const handleCheckboxChange = (day: WeekDays, isChecked: boolean) => {
    if (isChecked) {
      setCheckedDays(prev => [...prev, day])
    } else {
      setCheckedDays(prev => prev.filter(item => item !== day))
    }
  }

  const formSchema = {
    startTime: divisionConstraintData
      ? moment(
          convertMinutesToLocalTime(
            divisionConstraintData?.timings.start,
            'HH:mm',
          ),
          'HH:mm',
        )
      : undefined,
    endTime: divisionConstraintData
      ? moment(
          convertMinutesToLocalTime(
            divisionConstraintData?.timings.end,
            'HH:mm',
          ),
          'HH:mm',
        )
      : undefined,
    constraintsOnSelected: divisionConstraintData
      ? divisionConstraintData.constraintsOnSelected
      : undefined,
  }
  const onFinish = (values: {
    divisionId: string
    constraintsOnSelected: boolean
    startTime: string
    endTime: string
  }) => {
    if (
      !formValidationOnRequiredFields(values, [
        'divisionId',
        'startTime',
        'endTime',
        'constraintsOnSelected',
      ])
    )
      return
    if (!mapData) return infoMessage('Venue is required')
    if (!checkedDays.length) return infoMessage('Days are required')

    const payload: DivisionConstraintPayload = {
      ...values,
      venueId: mapData._id,
      organizationId: leagueData.organizationId,
      days: convertGivenDayTypeToOther(checkedDays, 'tiny'),
      timings: {
        start:
          parseInt(moment(values.startTime).utc().format('HH:mm'), 10) * 60,
        end: parseInt(moment(values.endTime).utc().format('HH:mm'), 10) * 60,
      },
    }

    if (divisionConstraintData) {
      divisionConstraintUpdate(
        {
          constraintId: divisionConstraintData._id,
          payload,
        },
        {
          onSuccess: () => {
            setModalBtnLoader(false)
            setDivisionConstraintData(null)
            setAddDivisionConstraintModal(false)
          },
        },
      )
    } else {
      divisionConstraintCreate(
        { leagueId: leagueData._id, payload },
        {
          onSuccess: () => {
            setModalBtnLoader(false)
            setAddDivisionConstraintModal(false)
          },
        },
      )
    }
  }

  useEffect(() => {
    if (divisionConstraintData) {
      setMapModalData(divisionConstraintData.venue)
      setCheckedDays(
        convertGivenDayTypeToOther(divisionConstraintData.days, 'full'),
      )
    }
  }, [])

  useEffect(() => {
    setModalBtnLoader(isUpdating || divisionConstraintLoading)
  }, [isUpdating || divisionConstraintLoading])

  return (
    <>
      <div>
        <Form
          form={form}
          layout="vertical"
          name="addDivisionConstraintForm"
          initialValues={formSchema}
          onFinish={onFinish}
        >
          <Row className="mt-10px">
            <Col span={24}>
              <label className="input-label">Divisions</label>
              <Form.Item name="divisionId">
                <DivisionInput
                  form={form}
                  data={
                    divisionConstraintData && divisionConstraintData.divisionId
                      ? divisionConstraintData.divisionId
                      : undefined
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="mt-10px">
            <Col>
              <label className="required-input  input-label">Venue</label>
            </Col>
            <Col>
              <VenueInput
                mapData={mapData}
                setMapData={setMapModalData}
                leagueData={leagueData}
                form={form}
              />
            </Col>
          </Row>

          <Row className="mt-20px">
            <Col>
              <label className="input-label">Select days</label>
            </Col>
            <Col>
              <div>
                {getDayNamesBetweenDates(
                  convertToLocal(leagueData.startDate),
                  convertToLocal(leagueData.endDate),
                ).map((day: WeekDays, index: number) => {
                  return (
                    <div className="py-15px border-btm " key={index}>
                      <div className="flex align-center justify-between gap-20px">
                        <div className="flex align-center gap-10px">
                          <Checkbox
                            checked={checkedDays.includes(day)}
                            onChange={e =>
                              handleCheckboxChange(day, e.target.checked)
                            }
                          />
                          <h4 className="text-14 w-500">{day}</h4>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </Col>
          </Row>

          <Row gutter={20} className="py-15px border-btm">
            <Col span={12}>
              <label className="text-14 input-label required-input mb-10px">
                Only Schedule From:
              </label>
              <Form.Item className="form-control mb-0" name="startTime">
                <TimePicker
                  className="h-50px"
                  name="time"
                  format={'hh:mm A'}
                ></TimePicker>
              </Form.Item>
            </Col>
            <Col span={12}>
              <label className="text-14 input-label required-input mb-10px">
                Untill:
              </label>
              <Form.Item className="form-control mb-0" name="endTime">
                <TimePicker
                  className="h-50px"
                  name="time"
                  format={'hh:mm A'}
                ></TimePicker>
              </Form.Item>
            </Col>
          </Row>

          <Row className="mt-20px">
            <Col>
              <Form.Item name="constraintsOnSelected">
                <Radio.Group className="flex flex-col gap-16px">
                  <Radio className="radio" value={true}>
                    Apply constraint only on selected weekdays (other days are
                    unconstrained)
                  </Radio>

                  <Radio className="radio" value={false}>
                    Only schedule on selected weekdays (other days are
                    disallowed)
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
}

export default AddDivisionConstraintForm
