import moment from 'moment'
import { useQuery } from '@tanstack/react-query'
import { getAllergyList } from '../../../../../api/Lists'
import { Avatar } from 'antd'
import { kgToPounds, metersToInches } from 'helper/utility'
import icon from 'assets/Images/otherCategory.svg'
import sadIcon from 'assets/Images/sad.svg'
import happyIcon from 'assets/Images/happy.svg'
import smileIcon from 'assets/Images/smile.svg'
import { kidStore } from 'state/family/kid'

const Detail = ({ title, value }: any) => (
  <div className="mt-15px flex justify-between">
    <h2 className="title-14">{title}</h2>
    <h4 className="text-14">{value}</h4>
  </div>
)

// main component
const KidGeneralDetails = ({ GeneralData }: any) => {
  const { data: AllergyData } = useQuery(['getAllergyList'], () =>
    getAllergyList(),
  )

  const getAllergywithIcon = (data: any) => {
    const allergy = AllergyData?.find(
      (allergy: any) => allergy._id === data?._id,
    )

    if (allergy) {
      return (
        <div className="flex justify-center align-center mr-20px chips">
          <Avatar src={allergy.icon} size={20} />
          <h2 className="text-16 pl-10">{allergy.name}</h2>
        </div>
      )
    } else {
      return (
        <div className="flex justify-center align-center mr-20px chips">
          <Avatar src={icon} size={20} />
          <h2 className="text-16 pl-10">{data?.name}</h2>
        </div>
      )
    }
  }
  function getMoodIcon(kidMood) {
    if (kidMood === 'sad') {
      return sadIcon
    } else if (kidMood === 'happy') {
      return happyIcon
    } else if (kidMood === 'natural') {
      return smileIcon
    } else {
      // Default icon or handle other cases
      return null
    }
  }

  const { setKidCreationModal, setKidCounter, setKidData } = kidStore(
    store => ({
      setKidCreationModal: store.setKidCreationModal,
      setKidCounter: store.setKidCounter,
      setKidData: store.setKidData,
    }),
  )

  const hanldeEditGeneralDetails = () => {
    setKidCounter(0)
    setKidData(GeneralData)
    setKidCreationModal(true)
  }

  const hanldeEditpersonalities = () => {
    setKidCounter(1)
    setKidData(GeneralData)
    setKidCreationModal(true)
  }

  return (
    <div className=" h-full overflow-auto bg-white p-30px h-80vh">
      <div className="flex justify-between ">
        <h1>General Details</h1>
        <button className="borderd-btn" onClick={hanldeEditGeneralDetails}>
          Edit
        </button>
      </div>
      <div>
        {GeneralData?.dob && (
          <div className="flex justify-between mt-20px">
            <h2 className="title-14">Birthday</h2>
            <h4 className="text-14">
              {moment(GeneralData?.dob).format('MMMM DD, YYYY')}
            </h4>
          </div>
        )}
        {GeneralData?.gender && (
          <div className="flex justify-between mt-15px">
            <h2 className="title-14">Gender</h2>
            <h4 className="text-14 text-capitalize">{GeneralData?.gender}</h4>
          </div>
        )}
        {GeneralData?.school && (
          <div className="flex justify-between mt-15px">
            <h2 className="title-14">School</h2>
            <h4 className="text-14">{GeneralData?.school}</h4>
          </div>
        )}
        {GeneralData?.grade && (
          <div className="flex justify-between mt-15px">
            <h2 className="title-14">Grade</h2>
            <h4 className="text-14">{GeneralData?.gradeInfo?.name}</h4>
          </div>
        )}
        {GeneralData?.height !== 0 && GeneralData?.height !== undefined && (
          <div className="flex justify-between mt-15px">
            <h2 className="title-14">Height</h2>
            <h4 className="text-14">
              {metersToInches(GeneralData?.height)}
              inches
            </h4>
          </div>
        )}
        {GeneralData?.weight !== 0 && GeneralData?.weight !== undefined && (
          <div className="flex justify-between mt-15px">
            <h2 className="title-14">Weight</h2>
            <h4 className="text-14">{kgToPounds(GeneralData?.weight)} lb</h4>
          </div>
        )}

        {GeneralData?.nickName && (
          <div className="flex justify-between mt-15px">
            <h2 className="title-14">Preffered name</h2>
            <h4 className="text-14 text-capitalize">{GeneralData?.nickName}</h4>
          </div>
        )}
        {GeneralData?.specialNeeds && (
          <div className="mt-15px">
            <h2 className="title-14">Special needs</h2>
            <h4 className="text-14">{GeneralData?.specialNeeds}</h4>
          </div>
        )}
        {GeneralData?.medicalNotes && (
          <div className="mt-15px">
            <h2 className="title-14">Medical notes</h2>
            <h4 className="text-14">{GeneralData?.medicalNotes}</h4>
          </div>
        )}

        {GeneralData?.allergies?.length > 0 && (
          <div className="mt-15px">
            <h2 className="title-14">Allergies</h2>
            <div className="flex  flex-wrap w-full">
              {GeneralData?.allergies?.map((data: any) => {
                return (
                  <div key={data?._id} className="mt-10px">
                    {getAllergywithIcon(data)}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-between mt-40px">
        <h1>Personality</h1>
        <button className="borderd-btn" onClick={hanldeEditpersonalities}>
          Edit
        </button>
      </div>
      <div>
        {GeneralData?.personalities?.favoriteColor && (
          <Detail
            title="Favorite color"
            value={GeneralData?.personalities?.favoriteColor}
          />
        )}
        {GeneralData?.personalities?.favoriteSubject && (
          <Detail
            title="Favorite school subject"
            value={GeneralData?.personalities?.favoriteSubject}
          />
        )}
        {GeneralData?.personalities?.leastFavoriteSubject && (
          <Detail
            title="Least favorite school subjects"
            value={GeneralData?.personalities?.leastFavoriteSubject}
          />
        )}
        {GeneralData?.abilities?.readingLevel && (
          <Detail
            title="Reading level"
            value={GeneralData?.abilities?.readingLevel}
          />
        )}
        {GeneralData?.abilities?.areaOfImprovements && (
          <Detail
            title="Areas of improvement"
            value={GeneralData?.abilities?.areaOfImprovements}
          />
        )}
        {GeneralData?.personalities?.sensitiveSubject && (
          <div className="mt-15px">
            <h2 className="title-14">Sensitive Topics</h2>
            <h4 className="text-14">
              {GeneralData?.personalities?.sensitiveSubject}
            </h4>
          </div>
        )}
        {GeneralData?.personalities?.personality && (
          <div className="mt-15px">
            <h2 className="title-14">Personality Traits</h2>
            <h4 className="text-14">
              {GeneralData?.personalities?.personality}
            </h4>
          </div>
        )}

        {/* <div className="flex justify-between mt-40px">
          <h1>Abilities</h1>
          <button className="borderd-btn">Edit</button>
        </div> */}
        {GeneralData?.abilities?.strength && (
          <div className="mt-15px">
            <h2 className="title-14 ">Strengths</h2>
            <h4 className="text-14 mt-10px">
              {GeneralData?.abilities?.strength}
            </h4>
          </div>
          // <Detail title="Strengths" value={GeneralData?.abilities?.strength} />
        )}

        {/* {GeneralData?.abilities?.areaOfImprovements && (
          <Detail
            title="Areas of improvement"
            value={GeneralData?.abilities?.areaOfImprovements}
          />
        )} */}
        {GeneralData?.abilities?.howToComfort && (
          <div className="mt-15px">
            <h2 className="title-14 ">
              Comfort methods if hurt, scared, worried
            </h2>
            <h4 className="text-14 mt-10px">
              {GeneralData?.abilities?.howToComfort}
            </h4>
          </div>
          // <Detail
          //   title="Comfort methods if hurt, scared, worried"
          //   value={GeneralData?.abilities?.howToComfort}
          // />
        )}
        {/* {GeneralData?.abilities?.readingLevel && (
          <Detail
            title="Reading level"
            value={GeneralData?.abilities?.readingLevel}
          />
        )} */}
        {GeneralData?.abilities?.kidMood && (
          <div className="mt-15px">
            <h2 className="title-14 ">Average mood on an average day</h2>
            <div className="flex text-center align-center">
              {getMoodIcon(GeneralData?.abilities?.kidMood) && (
                <img
                  src={getMoodIcon(GeneralData?.abilities?.kidMood)}
                  alt="Mood Icon"
                />
              )}
              <h4
                style={{ marginLeft: '5px' }}
                className="text-14 mt-10px text-capitalize"
              >
                {GeneralData?.abilities?.kidMood}
              </h4>
            </div>
          </div>
          // <Detail
          //   title="Average mood on an average day"
          //   value={GeneralData?.abilities?.kidMood}
          // />
        )}
      </div>
    </div>
  )
}
export default KidGeneralDetails
