import { PencilSimpleLine, Trash } from '@phosphor-icons/react'
import { Table } from 'antd'
import { useGetRelations } from 'api/Listings/Relation'
import Spinner from 'components/Spinner'
import { capitalizeFirstLetter } from 'helper/utility'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { relationStore } from 'state/listing/relationListing'
import { BasicListingType } from 'types/listing/listings'

interface RelationsTableProps {
  id: string
}

const RelationsTable: React.FC<RelationsTableProps> = ({ id }) => {
  const [tableData, setTableData] = useState([])
  const { setAddRelationModal, setRelationData } = relationStore(store => ({
    setAddRelationModal: store.setAddRelationModal,
    setRelationData: store.setRelationData,
  }))
  const { data, isLoading, isFetching, refetch } = useGetRelations()

  const handleEditClick = (data: BasicListingType) => {
    setRelationData(data)
    setAddRelationModal(true)
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => (
        <p className="text-14 w-400">{capitalizeFirstLetter(text)}</p>
      ),
    },
    {
      title: 'Last Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text: string) => (
        <p className="text-14 w-400">{moment(text).format('DD MMM, YYYY')}</p>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      render: (data: BasicListingType) => (
        <div className="flex align-center gap-20px">
          <PencilSimpleLine
            size={20}
            color="#000"
            className="pointer"
            onClick={() => handleEditClick(data)}
          />
        </div>
      ),
    },
  ]

  useEffect(() => {
    refetch()
  }, [id])

  useEffect(() => {
    if (data) {
      const enrichedTableData = data.map((grade: BasicListingType) => {
        return {
          Key: grade._id,
          name: grade.name,
          updatedAt: grade.updatedAt,
          actions: grade,
        }
      })
      setTableData(enrichedTableData)
    }
  }, [data])

  return (
    <>
      <div>
        {isLoading || isFetching ? (
          <div className="w-full h-70vh flex align-center justify-center">
            <Spinner />
          </div>
        ) : (
          <Table
            className="colored_header_table"
            columns={columns}
            dataSource={tableData}
            pagination={false}
          />
        )}
      </div>
    </>
  )
}

export default RelationsTable
