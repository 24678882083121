import IconsTextNavigation from 'components/global/IconsTextNavigation'
import React from 'react'

const ScheduleEmail: React.FC = () => {
  return (
    <>
      <div>
        <IconsTextNavigation
          iconName="EnvelopeSimple"
          heading="Scheduled emails"
        />
      </div>
    </>
  )
}

export default ScheduleEmail
