import moment from 'moment'
import { convertMinutesToLocalTime } from 'helper/utility'
import { CommonEventResp, EventResp } from 'types/event/events'

interface EventTime {
  date: string
  startTime: string
  endTime: string
}

export const getEventTimeArray = (
  eventDetail: EventResp | CommonEventResp,
): EventTime[] => {
  let timeArr = []
  eventDetail?.datesOfEvent.map(eachDay => {
    let updateTimeArr

    if (eventDetail?.timings.specialSchedules.length > 0) {
      const specialSchedule = eventDetail?.timings.specialSchedules.find(
        item =>
          moment(item.day).format('YYYY-MM-DD') ===
          moment(eachDay).format('YYYY-MM-DD'),
      )
      if (specialSchedule) {
        updateTimeArr = [
          ...timeArr,
          {
            date: moment(eachDay).format('MMM DD YYYY'),
            startTime: convertMinutesToLocalTime(specialSchedule?.startTime),
            endTime: convertMinutesToLocalTime(specialSchedule?.endTime),
          },
        ]
      } else {
        updateTimeArr = [
          ...timeArr,
          {
            date: moment(eachDay).format('MMM DD, YYYY'),
            startTime: convertMinutesToLocalTime(
              eventDetail?.timings?.startTime,
            ),
            endTime: convertMinutesToLocalTime(eventDetail?.timings?.endTime),
          },
        ]
      }
    } else {
      updateTimeArr = [
        ...timeArr,
        {
          date: moment(eachDay).format('MMM DD, YYYY'),
          startTime: convertMinutesToLocalTime(eventDetail?.timings?.startTime),
          endTime: convertMinutesToLocalTime(eventDetail?.timings?.endTime),
        },
      ]
    }

    timeArr = updateTimeArr
  })
  return timeArr
}
