import { ArrowDown, CaretDown } from '@phosphor-icons/react'
import ChatMainInfo from 'components/global/chat/ChatMainInfo'
import { capitalizeFirstLetter } from 'helper/utility'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { ChatRoomsType } from 'types/chat'
import { Person } from 'types/globalTypes'
import clock from 'assets/Images/clockimg.svg'
import { useGetScheduledMessages } from 'api/Contact/Contact'

interface AllMessagesProps {
  chatRoomIds: ChatRoomsType[]
  participants: Person[]
  activeChatRoom: string
  setActiveChatRoom: React.Dispatch<React.SetStateAction<string>>
}

const AllMessages: React.FC<AllMessagesProps> = ({
  chatRoomIds,
  participants,
  activeChatRoom,
  setActiveChatRoom,
}) => {
  const { data: scheduledMessages, refetch: scheduledMessagesRefetch } =
    useGetScheduledMessages(localStorage.getItem('adminId'))

  useEffect(() => {
    scheduledMessagesRefetch()
  }, [])

  return (
    <div
      className="scroller overflow-auto-y py-20px"
      style={{ height: '84vh' }}
    >
      <div className="flex align-center gap-20px px-15px">
        <button className="flex align-center justify-center border-1px gap-10px px-10px py-7px radius-full bg-transparent">
          <ArrowDown size={20} color="#c5c7c9" />
          <span className="text-14 w-500">More</span>
          <CaretDown size={20} color="#000" />
        </button>
        <button className="flex align-center justify-center border-1px gap-10px px-10px py-7px radius-full bg-transparent">
          <span className="text-14 w-500">All messages</span>
          <CaretDown size={20} color="#000" />
        </button>
      </div>

      <div className="mt-20px">
        {scheduledMessages?.length ? (
          <>
            <div className="flex align-center gap-10px px-20px text-16 w-600 px-20px">
              <img src={clock} alt="clock" />
              <h3>Scheduled messages ({scheduledMessages?.length})</h3>
            </div>

            <div className="mt-20px mb-20px">
              {scheduledMessages?.map((message: any) => {
                return (
                  <ChatMainInfo
                    key={message._id}
                    id={message._id}
                    avatar={message.admin?.photo?.post}
                    name={capitalizeFirstLetter(
                      message.admin.firstName + message.admin.lastName,
                    )}
                    createdBy="Admin"
                    lastMessage={message.smsText}
                    time={moment(message.scheduledAt).format('hh:mm A')}
                    scheduled={true}
                  />
                )
              })}
            </div>
          </>
        ) : undefined}
        <h3 className="text-16 w-600 px-20px">All messages</h3>
        {!chatRoomIds?.length ? (
          <p className="px-20px text-16 w-400 text-gray">No messages</p>
        ) : (
          <div className="mt-20px">
            {chatRoomIds.map((item: ChatRoomsType) => {
              const member = participants.find(
                participant =>
                  participant._id === item.data.lastMessage.senderId,
              )
              const updatedAt = item.data.updatedAt?.toDate()
              return (
                <ChatMainInfo
                  key={item.id}
                  id={item.id}
                  avatar={member?.photo?.post}
                  name={capitalizeFirstLetter(
                    member.firstName + member.lastName,
                  )}
                  createdBy="Admin"
                  lastMessage={item.data.lastMessage.messageText}
                  time={moment(updatedAt).format('hh:mm A')}
                  active={activeChatRoom === item.id}
                  setActiveChatRoom={setActiveChatRoom}
                />
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default AllMessages
