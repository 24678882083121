import { Button, Col, Form, Input, Row } from 'antd'
import OrganizationInput from 'components/global/inputs/OrganizationInput/OrganizationInput'
import React, { useEffect, useState } from 'react'
import { Address, OrganizationResp, Phone, Photo } from 'types/globalTypes'
import Location from 'pages/Events/Views/CreateCommunity/Screens/Step1/InputComps/Location'
import { formValidationOnRequiredFields, validatePhone } from 'helper/utility'
import PhoneInput from 'react-phone-input-2'
import UploadDocument from 'components/global/UploadDocument'
import { Link } from 'react-router-dom'
import { infoMessage } from 'api/global'
import { useCreateExternal, useUpdateExternal } from 'api/External/External'
import { externalStore } from 'state/event/external'
import ButtonSpinner from 'components/global/ButtonSpinner'
import { ExternalEventResp } from 'types/external/externalEvent'

interface ExternalStep1Props {
  handleNext: () => void
}

const ExternalStep1: React.FC<ExternalStep1Props> = ({ handleNext }) => {
  const [form] = Form.useForm()
  const {
    externalData,
    setExternalData,
  }: {
    externalData: ExternalEventResp
    setExternalData: React.Dispatch<React.SetStateAction<ExternalEventResp>>
  } = externalStore(store => ({
    externalData: store.externalData,
    setExternalData: store.setExternalData,
  }))
  const [selectedOrganization, setSelectedOrganization] = useState<
    OrganizationResp | undefined
  >()
  const [documentIds, setDocumentIds] = useState<string[]>([])
  const [photos, setPhotos] = useState<Photo[]>([])
  const [mapData, setMapModalData] = useState<Address | undefined>()
  const [phone, setPhone] = useState<Phone>({
    dialCode: '',
    isoCode: '',
    number: '',
  })
  const { mutate: createExternal, isLoading: createExternalLoading } =
    useCreateExternal()
  const { mutate: updateExternal, isLoading: updateExternalLoading } =
    useUpdateExternal()

  const handleSubmit = (values: {
    name: string
    websiteUrl: string
    customUrl: string
  }) => {
    if (!selectedOrganization) return infoMessage('Organization is required')
    if (
      !formValidationOnRequiredFields(values, [
        'name',
        'websiteUrl',
        'customUrl',
      ])
    )
      return
    if (!mapData) return infoMessage('Location is required')

    const payload = {
      ...values,
      locationId: mapData._id,
      coverPhotosIds: documentIds.length > 0 ? documentIds : undefined,
      organizationId: selectedOrganization._id,
      phone: phone,
      venueType: 'physical',
    }

    if (externalData) {
      updateExternal(
        { payload, externalId: externalData._id },
        {
          onSuccess: data => {
            setExternalData({ ...externalData, ...data })
            handleNext()
          },
        },
      )
    } else {
      createExternal(payload, {
        onSuccess: data => {
          setExternalData(data)
          handleNext()
        },
      })
    }
  }

  useEffect(() => {
    if (externalData) {
      setSelectedOrganization(externalData.organization)
      setPhone(externalData.phone)
      setMapModalData(externalData.location)
      setDocumentIds(externalData.coverPhotosIds || [])
      setPhotos(externalData.coverPhotos || [])
      form.setFieldsValue({
        name: externalData.name,
        websiteUrl: externalData.websiteUrl,
        customUrl: externalData.customUrl,
        phone: externalData.phone?.number,
      })
    }
  }, [externalData])

  return (
    <div className="create-event-main-content-box" style={{ margin: '0 auto' }}>
      <h2>Step 1. External’s settings</h2>
      <Form
        form={form}
        initialValues={{}}
        autoComplete="off"
        layout="vertical"
        onFinish={handleSubmit}
      >
        <OrganizationInput
          selectedOrganization={selectedOrganization}
          setSelectedOrganization={setSelectedOrganization}
        />
        <Row>
          <Col>
            <label className="input-label required-input">External name</label>
          </Col>
          <Col>
            <Form.Item className="form-control" name="name">
              <Input
                placeholder="External name"
                className="form-control-field"
              />
            </Form.Item>
          </Col>
        </Row>
        {selectedOrganization && (
          <Row className="-mt-10px">
            <Col span={24}>
              <Location
                selectedOrganization={selectedOrganization}
                mapData={mapData}
                setMapModalData={setMapModalData}
                form={form}
              />
            </Col>
          </Row>
        )}
        <Row className="mt-10px">
          <Col span={24}>
            <label className="input-label required-input">Phone number</label>
            <Form.Item
              name="phone"
              className="phone-number-form"
              rules={[{ validator: validatePhone }]}
            >
              <PhoneInput
                country={phone?.isoCode.toLowerCase() || 'us'}
                onChange={(value: string, data: any) => {
                  setPhone({
                    dialCode: '+' + data?.dialCode,
                    number: '+' + value,
                    isoCode: data.countryCode.toUpperCase(),
                  })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label htmlFor="" className="input-label required-input">
              Website
            </label>
            <Form.Item colon={false} name="websiteUrl" className="form-control">
              <Input
                placeholder="Enter Website URL"
                name="websiteUrl"
                className="form-control-field"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label htmlFor="" className="input-label">
              Custom URL
            </label>
            <Form.Item colon={false} name="customUrl" className="form-control">
              <Input
                placeholder="Custom Url"
                name="customUrl"
                className="form-control-field"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mt-10px">
          <Col span={18}>
            <label className="input-label">Image</label>
            <UploadDocument
              documentIds={documentIds}
              setDocumentIds={setDocumentIds}
              multiple={true}
              docType="image/*"
              files={photos}
              uploadMultipleAtOnce={true}
            />
          </Col>
        </Row>

        <Row justify="space-between" className="mb-26 mt-40px">
          <Link to="/events">
            <Button className="cancel-button">Back to listing</Button>
          </Link>
          <Button
            className="stepper-button"
            disabled={createExternalLoading || updateExternalLoading}
            htmlType="submit"
          >
            {createExternalLoading || updateExternalLoading ? (
              <ButtonSpinner size={20} color="#000" />
            ) : (
              'Next'
            )}
          </Button>
        </Row>
      </Form>
    </div>
  )
}

export default ExternalStep1
