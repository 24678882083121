import { Avatar, Col, Row, Space, Dropdown, Menu } from 'antd'
import { useEffect, useState } from 'react'
import phone from '../../../../../assets/Images/Phone.svg'
import envelopSimple from '../../../../../assets/Images/EnvelopeSimple.svg'
import location from '../../../../../assets/Images/MapPin.png'
import warning from '../../../../../assets/Images/Warning.png'
import Person from '../../FamilyProfile/FamilyMembers/Person'
import UserMisus from '../../../../../assets/Images/UserMinus.png'
import { adultStore } from 'state/family/adult'
import { FamilyMemberAdult } from 'types/family/adult'
import AlertModal from 'helper/AlertModal'
import { useParams } from 'react-router-dom'
import {
  useDeleteKidAdultAssociation,
  useGetKidsByAdultId,
} from 'api/family/adultRole'
import Spinner from 'components/Spinner'
interface adultInfo {
  data: FamilyMemberAdult
  refetchData: () => any
}
const AdultInfo = ({ data, refetchData }: adultInfo) => {
  const { id }: { id: string } = useParams()
  const { setAdultCreationModal, setAdultData, currentStep, setCurrentStep } =
    adultStore(store => ({
      adultCreationModal: store.adultCreationModal,
      setAdultCreationModal: store.setAdultCreationModal,
      setAdultData: store.setAdultData,
      currentStep: store.adultCurrentStep,
      setCurrentStep: store.setAdultCurrentStep,
    }))
  const {
    data: adultKids,
    isLoading: adultKidsLoading,
    isFetching: adultKidsFetching,
    refetch: adultKidsRefetch,
  } = useGetKidsByAdultId(id)
  const [openAlertModal, setOpenAlertModal] = useState<boolean>(false)
  const [kidId, setKidId] = useState<string | undefined>()
  const { mutate: removeAdultMutation, isLoading: removeAdultMutationLoading } =
    useDeleteKidAdultAssociation()
  const [modal, setModal] = useState<Number | null | false>(null)
  const [mapModalVisible, setMapModalVisible] = useState(false)
  const onYesClick = () => {
    removeAdultMutation(
      { kidId, adultId: id },
      {
        onSuccess: () => {
          setOpenAlertModal(false)
        },
      },
    )
  }

  const menu = (
    <Menu>
      <Menu.Item
        key="email"
        onClick={() => {
          setModal(1)
        }}
      >
        <Space>
          <img alt="img" src={envelopSimple}></img> Change email
        </Space>
      </Menu.Item>
      <Menu.Item
        key="address"
        onClick={() => {
          setMapModalVisible(true)
        }}
      >
        <Space>
          <img alt="img" src={location}></img> Edit address
        </Space>
      </Menu.Item>
      <Menu.Item
        key="phone"
        onClick={() => {
          setModal(3)
        }}
      >
        <Space>
          <img alt="img" src={phone}></img> Edit phone number
        </Space>
      </Menu.Item>
      <Menu.Item key="block">
        <Space className="text-color-red">
          <img alt="img" src={warning}></img> Block email
        </Space>
      </Menu.Item>
    </Menu>
  )
  const kidmenu = (
    <Menu>
      <Menu.Item
        key="email"
        onClick={() => {
          setModal(1)
        }}
      >
        <Space>Kid</Space>
      </Menu.Item>
    </Menu>
  )

  const handleAddKid = () => {
    setCurrentStep(1)
    setAdultData(data)
    setAdultCreationModal(true)
  }

  const handleUnauthorizeAdult = (kid: string) => {
    setKidId(kid)
    setOpenAlertModal(true)
  }

  useEffect(() => {
    adultKidsRefetch()
  }, [id])

  return (
    <Col span={12}>
      <div className="profile_container">
        <div className="flex justify-between align-center">
          <h2 className="b1 h6">Contact information</h2>
          <Dropdown overlay={menu} className="status_dropdown">
            <button className="bordered-prim-btn">Manage</button>
          </Dropdown>
        </div>

        <Row className="pt-40px">
          <Col>
            <div className="contact-information">
              <Row gutter={[5, 30]}>
                <Col>
                  <Row gutter={[12, 20]}>
                    <Col>
                      <Space size={12}>
                        {data?.phone && (
                          <>
                            <img alt="alt" src={phone}></img>
                            <h4 className="b3">{data?.phone?.number}</h4>
                          </>
                        )}
                      </Space>
                    </Col>
                    <Col>
                      <Space size={12}>
                        {data?.email && (
                          <>
                            <img alt="alt" src={envelopSimple}></img>
                            <h4 className="b3"> {data?.email}</h4>
                          </>
                        )}
                      </Space>
                    </Col>
                    <Col>
                      <Space size={12}>
                        {data?.address && (
                          <>
                            <Avatar src={location} size={28} />
                            <h4 className="b3">
                              {data.address.apartment} {data.address.street}{' '}
                              {data.address.state}
                            </h4>
                          </>
                        )}
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <div className="pt-40px flex justify-between align-center">
          <h2 className="b1 h6">Kids</h2>

          <button className="bordered-prim-btn" onClick={handleAddKid}>
            Add Kid
          </button>
        </div>
        <Row className="pt-40px">
          <Col>
            {adultKidsLoading || adultKidsFetching ? (
              <div className="py-20px flex align-center justify-center">
                <Spinner />
              </div>
            ) : (
              <div>
                {adultKids.map((data: any) => {
                  return (
                    <>
                      <div
                        className="flex justify-between align-center"
                        key={data._id}
                      >
                        <Person
                          id={data?._id}
                          photo={data?.photo?.post}
                          name={data?.firstName + ' ' + data?.lastName}
                          relation={data?.relation?.name}
                          to="parent-profile"
                          bcase={'guardian'}
                        />
                        <Row
                          className="pointer"
                          onClick={() => handleUnauthorizeAdult(data._id)}
                        >
                          <img src={UserMisus} alt="" />
                          <h4 style={{ color: '#FF6961', marginLeft: '10px' }}>
                            Unauthorize
                          </h4>
                        </Row>
                      </div>
                    </>
                  )
                })}
              </div>
            )}
          </Col>
        </Row>
      </div>
      {openAlertModal && (
        <AlertModal
          isModalVisible={openAlertModal}
          setIsModalVisible={setOpenAlertModal}
          data={{
            name: 'Delete Adult from kid',
            heading: 'Are you sure to remove adult from kid',
          }}
          onYesClick={onYesClick}
          loading={removeAdultMutationLoading}
        />
      )}
    </Col>
  )
}
export default AdultInfo
