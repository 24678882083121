import React from 'react'
import { Col, Form, FormInstance, Input, Row } from 'antd'
import { leagueEventStore } from 'state/event/leagueEvent'

interface RegionProps {
  form: FormInstance
}

const Region: React.FC<RegionProps> = ({ form }) => {
  const { regions, setAddRegionModal, setRegions } = leagueEventStore(
    store => ({
      regions: store.regions,
      setAddRegionModal: store.setAddRegionModal,
      setRegions: store.setRegions,
    }),
  )
  const onFinish = (values: { name: string }) => {
    setRegions([...regions, values.name])
    setAddRegionModal(false)
  }

  return (
    <>
      <Form
        name="highlighted-elem-form"
        form={form}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
      >
        <Row className="mt-10px">
          <Col span={24}>
            <label className="input-label required-input">Name</label>
            <Form.Item
              colon={false}
              name="name"
              rules={[{ required: true, message: 'Name required' }]}
            >
              <Input placeholder="Enter Name" required name="name" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default Region
