import React, { useEffect } from 'react'
import AppLayout from 'components/layout/layout'
import { useParams } from 'react-router-dom'
import { communityStore } from 'state/event/community'
import Step1Community from './Screens/Step1/step1'
import CommunityStep2 from './Screens/Step2/Step2'
import CommunityStep3 from './Screens/Step3/Step3'
import { useGetCommunityById } from 'api/Community/CommunityCreation'
import Spinner from 'components/Spinner'
import { forceRefetch } from 'helper/utility'
import { useQueryClient } from '@tanstack/react-query'

const CreateCommunity: React.FC = () => {
  const queryClient = useQueryClient()
  const { id } = useParams<{ id: string }>()
  const { stepCounter, setStepCounter, setCommunityData } = communityStore(
    store => ({
      stepCounter: store.communityStepCounter,
      setStepCounter: store.setCommunityStepCounter,
      setCommunityData: store.setCommunityData,
    }),
  )

  const { isLoading, isFetching, refetch, isError, data } =
    useGetCommunityById(id)

  const handleNext = () => {
    setStepCounter(stepCounter + 1)
  }

  const handleBack = () => {
    setStepCounter(stepCounter - 1)
  }

  useEffect(() => {
    if (id) {
      refetch()
    }
  }, [id])

  useEffect(() => {
    if (id) {
      setCommunityData(data)
    } else {
      forceRefetch('get-community-by-id', queryClient)
    }
  }, [id, data])

  if (isError) {
    return (
      <AppLayout id={id}>
        <div className="w-100 h-100 flex align-center justify-center">
          <h1>Something went wrong</h1>
        </div>
      </AppLayout>
    )
  }

  if (id && (isLoading || isFetching)) {
    return (
      <AppLayout id={id}>
        <div className="flex w-100 h-100 justify-center align-center">
          <Spinner />
        </div>
      </AppLayout>
    )
  }

  return (
    <AppLayout id={id}>
      {stepCounter === 0 && (
        <div
          className="create-event-main-content-wapper w-100"
          style={{ padding: '0px 40px 0px 40px' }}
        >
          <Step1Community handleNext={handleNext} />
        </div>
      )}
      {stepCounter === 1 && (
        <div
          className="create-event-main-content-wapper w-100"
          style={{ padding: '0px 40px 0px 40px' }}
        >
          <CommunityStep2 handleBack={handleBack} handleNext={handleNext} />
        </div>
      )}
      {stepCounter === 2 && (
        <div
          className="create-event-main-content-wapper w-100"
          style={{ padding: '0px 40px 0px 40px' }}
        >
          <CommunityStep3 handleBack={handleBack} />
        </div>
      )}
    </AppLayout>
  )
}

export default CreateCommunity
