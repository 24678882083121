import React, { useEffect, useMemo } from 'react'
import { PencilSimpleLine } from '@phosphor-icons/react'
import { Table } from 'antd'
import { useGetGoals } from 'api/Listings/Goal'
import { capitalizeFirstLetter } from 'helper/utility'
import moment from 'moment'
import { BasicListingType } from 'types/listing/listings'
import Spinner from 'components/Spinner'
import { goalStore } from 'state/listing/GoalListing'

interface GoalTableProps {
  id: string
}

const GoalTable: React.FC<GoalTableProps> = ({ id }) => {
  const { setAddGoalModal, setGoalData } = goalStore(store => ({
    setAddGoalModal: store.setAddGoalModal,
    setGoalData: store.setGoalData,
  }))
  const { data, isLoading, isFetching, refetch } = useGetGoals()

  const handleEditClick = (data: BasicListingType) => {
    setGoalData(data)
    setAddGoalModal(true)
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => (
        <p className="text-14 w-400">{capitalizeFirstLetter(text)}</p>
      ),
    },
    {
      title: 'Last Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text: string) => (
        <p className="text-14 w-400">{moment(text).format('DD MMM, YYYY')}</p>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      render: (data: BasicListingType) => (
        <div className="flex align-center gap-20px">
          <PencilSimpleLine
            size={20}
            color="#000"
            className="pointer"
            onClick={() => handleEditClick(data)}
          />
        </div>
      ),
    },
  ]

  const tableData = useMemo(() => {
    return (
      data?.map(goal => ({
        Key: goal._id,
        name: goal.name,
        updatedAt: goal.updatedAt,
        actions: goal,
      })) || []
    )
  }, [data])

  useEffect(() => {
    refetch()
  }, [id])

  return (
    <>
      <div>
        {isLoading || isFetching ? (
          <div className="w-full h-70vh flex align-center justify-center">
            <Spinner />
          </div>
        ) : (
          <Table
            className="colored_header_table"
            columns={columns}
            dataSource={tableData}
            pagination={false}
          />
        )}
      </div>
    </>
  )
}

export default GoalTable
