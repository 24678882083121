import React from 'react'
import useFormSubmit from 'helper/Hooks/formSubmit'
import { leagueEventStore } from 'state/event/leagueEvent'
import CustomModal from 'components/global/CustomModal'
import CustomModalFooter from 'components/global/CustomModalFooter'
import AddDivisionConstraintForm from './ModalContent/AddDivisionConstraintForm'

const AddDivisionConstraints: React.FC = () => {
  const { form, handleSubmit } = useFormSubmit()

  const { addDivisionConstraintModal, setAddDivisionConstraintModal } =
    leagueEventStore(store => ({
      addDivisionConstraintModal: store.addDivisionConstraintModal,
      setAddDivisionConstraintModal: store.setAddDivisionConstraintModal,
    }))

  return (
    <CustomModal
      openModal={addDivisionConstraintModal}
      closeModal={setAddDivisionConstraintModal}
      title="Add team constraints"
      footer={
        <CustomModalFooter
          multibutton={false}
          btnAlignment="justify-end"
          btnText="Save"
          btnType="primary"
          onSubmit={handleSubmit}
        />
      }
    >
      <AddDivisionConstraintForm form={form} />
    </CustomModal>
  )
}

export default AddDivisionConstraints
