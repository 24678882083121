import CustomModal from 'components/global/CustomModal'
import React from 'react'
import { categoryStore } from 'state/listing/eventCategoryListing'
import EventCategoryForm from './ModalContent/EventCategoryForm'
import CustomModalFooter from 'components/global/CustomModalFooter'
import useFormSubmit from 'helper/Hooks/formSubmit'

const EventCategoryModal: React.FC = () => {
  const { form, handleSubmit } = useFormSubmit()
  const { categoryData, addEventCategoryModal, setAddEventCategoryModal } =
    categoryStore(store => ({
      categoryData: store.eventCategoryData,
      addEventCategoryModal: store.addEventCategoryModal,
      setAddEventCategoryModal: store.setAddEventCategoryModal,
    }))

  return (
    <>
      <CustomModal
        openModal={addEventCategoryModal}
        closeModal={setAddEventCategoryModal}
        title={categoryData ? 'Edit Event Category' : 'Add new Event Category'}
        footer={
          <CustomModalFooter
            multibutton={false}
            btnAlignment="justify-end"
            btnText={categoryData ? 'Update' : 'Create'}
            btnType="primary"
            onSubmit={handleSubmit}
          />
        }
      >
        <EventCategoryForm form={form} />
      </CustomModal>
    </>
  )
}

export default EventCategoryModal
