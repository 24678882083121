import { create } from 'zustand'

const relationListingStore = (set: any) => ({
  addRelationModal: false,
  setAddRelationModal: (state: boolean) =>
    set(() => ({ addRelationModal: state })),
  relationData: null,
  setRelationData: (state: any) => set(() => ({ relationData: state })),
})

export const relationStore = create(relationListingStore)
