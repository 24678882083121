import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { axiosDelete, axiosGet, axiosPatch, axiosPost } from 'Redux/axiosHelper'
import {
  DivisionConstraintPayload,
  DivisonConstraintResp,
} from 'types/league/divisionConstraints'
import { ConstaintStepStatusesResp } from 'types/league/league'

// get all division constraints
const getDivisionConstraints = async (leagueId: string) => {
  const response = await axiosGet(
    `v1/league-division-constraint/step-6/${leagueId}`,
  )
  return response.data
}
export const useGetDivisionConstraints = (leagueId: string) => {
  const divisionConstraints = useQuery<DivisonConstraintResp[]>(
    ['get-league-division-constraints'],
    () => (leagueId ? getDivisionConstraints(leagueId) : null),
    {
      enabled: false,
    },
  )
  return divisionConstraints
}

// create new division constraint
const createDivisionConstraint = async ({
  leagueId,
  payload,
}: {
  leagueId: string
  payload: DivisionConstraintPayload
}) => {
  const response = await axiosPost(
    payload,
    `v1/league-division-constraint/step-6/${leagueId}`,
  )
  return response.data
}
export const useCreateDivisionConstraint = () => {
  const queryClient = useQueryClient()
  return useMutation(createDivisionConstraint, {
    onSuccess: newConstraint => {
      queryClient.setQueryData(
        ['get-league-division-constraints'],
        (oldData: DivisonConstraintResp[] | undefined) => {
          if (!oldData) return []
          return [newConstraint, ...oldData]
        },
      )
      queryClient.setQueryData(
        ['league-onstraints-status'],
        (oldData: ConstaintStepStatusesResp | undefined) => {
          if (!oldData)
            return {
              divisionConstraints: true,
            }
          return {
            ...oldData,
            divisionConstraints: true,
          }
        },
      )
    },
  })
}

// update division constrait
const updateDivisionConstraint = async ({
  constraintId,
  payload,
}: {
  constraintId: string
  payload: DivisionConstraintPayload
}) => {
  const response = await axiosPatch(
    payload,
    `v1/league-division-constraint/step-6/${constraintId}`,
  )
  return response.data
}
export const useUpdateDivisionConstraint = () => {
  const queryClient = useQueryClient()
  return useMutation(updateDivisionConstraint, {
    onSuccess: updatedConstraint => {
      queryClient.setQueryData(
        ['get-league-division-constraints'],
        (oldData: DivisonConstraintResp[] | undefined) => {
          if (!oldData) return []
          return oldData.map(constraint =>
            constraint._id === updatedConstraint._id
              ? updatedConstraint
              : constraint,
          )
        },
      )
    },
  })
}

// delete division constraint
const deleteDivisionConstraint = async (constraintId: string) => {
  const response = await axiosDelete(
    `v1/league-division-constraint/step-6/delete-by-id/${constraintId}`,
  )
  return response.data
}
export const useDeleteDivisionConstraint = () => {
  const queryClient = useQueryClient()
  return useMutation(deleteDivisionConstraint, {
    onSuccess: deletedConstraint => {
      queryClient.setQueryData(
        ['get-league-division-constraints'],
        (oldData: DivisonConstraintResp[] | undefined) => {
          if (!oldData) return []
          return oldData.filter(
            constraint => constraint._id !== deletedConstraint._id,
          )
        },
      )
      queryClient.setQueryData(
        ['league-onstraints-status'],
        (oldData: ConstaintStepStatusesResp | undefined) => {
          if (!oldData)
            return {
              divisionConstraints: false,
            }
          const constraintsQuery = queryClient
            .getQueryCache()
            .find(['get-league-division-constraints'])

          if (
            Array.isArray(constraintsQuery?.state?.data) &&
            !constraintsQuery.state.data.length
          ) {
            return {
              ...oldData,
              divisionConstraints: false,
            }
          }
        },
      )
    },
  })
}
