import React from 'react'
import IconsTextNavigation from 'components/global/IconsTextNavigation'
import { leagueEventStore } from 'state/event/leagueEvent'
import TeamConstraintModal from '../Modals/Team/TeamConstraintModal'
import AddTeamConstraint from '../Modals/Team/AddTeamConstraint'
import { ConstaintStepStatusesResp } from 'types/league/league'

interface TeamConstraintsProps {
  status: ConstaintStepStatusesResp
}

const TeamConstraints: React.FC<TeamConstraintsProps> = ({ status }) => {
  const {
    teamConstraintModal,
    setTeamConstraintModal,
    addTeamConstraintModal,
    leagueData,
  } = leagueEventStore(store => ({
    teamConstraintModal: store.teamConstraintModal,
    setTeamConstraintModal: store.setTeamConstraintModal,
    addTeamConstraintModal: store.addTeamConstraintModal,
    leagueData: store.leagueData,
  }))

  return (
    <>
      <div onClick={() => setTeamConstraintModal(true)}>
        <IconsTextNavigation
          iconName="Users"
          heading="Team Constraints"
          isCompleted={status.teamsConstraints}
        />
      </div>
      {teamConstraintModal && <TeamConstraintModal leagueId={leagueData._id} />}
      {addTeamConstraintModal && <AddTeamConstraint />}
    </>
  )
}

export default TeamConstraints
