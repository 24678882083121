export const organizationTypes = [
  {
    key: 'profit',
    name: 'Profit',
  },
  {
    key: 'non-profit',
    name: 'Non-profit',
  },
]
