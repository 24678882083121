import { EventCategoryListing } from 'types/listing/listings'
import { create } from 'zustand'

const eventCategoryStore = (set: any) => ({
  addEventCategoryModal: false,
  setAddEventCategoryModal: (state: boolean) =>
    set(() => ({ addEventCategoryModal: state })),
  eventCategoryData: null,
  setEventCategoryData: (state: EventCategoryListing) =>
    set(() => ({ eventCategoryData: state })),
})

export const categoryStore = create(eventCategoryStore)
