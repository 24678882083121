export const listsName = [
  {
    name: 'Skills',
    route: 'skill',
  },
  {
    name: 'Energy level',
    route: 'energyLevel',
  },
  {
    name: 'Grades',
    route: 'grade',
  },
  {
    name: 'Relations',
    route: 'relation',
  },
  {
    name: 'Event Category',
    route: 'event-category',
  },
  {
    name: 'Activity Category',
    route: 'activity-category',
  },
  {
    name: 'Colors',
    route: 'colors',
  },
  {
    name: 'Goal',
    route: 'goal',
  },
  {
    name: "Highlighted Element's",
    route: 'highlighted-element',
  },
  {
    name: 'Age group',
    route: 'age-group',
  },
]
