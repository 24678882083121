import React, { useState } from 'react'
import {
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Switch,
  TimePicker,
} from 'antd'
import RecepientInput from './RecepientInput'
import { EventResp } from 'types/event/events'
import { sendMessage, createChatRoom } from 'helper/Chat/ChatHelpers'
import { eventShatStore } from 'state/event/eventChat'
import { myStore } from 'state/store'
import { ChatMessageType, SendEmailPayload, SendSmsPayload } from 'types/chat'
import { useParams } from 'react-router-dom'
import { useSendMessageNotification } from 'api/Notification'
import {
  useSendEmailToAttendees,
  useSendSmsToAttendees,
} from 'api/Contact/Contact'
import { failureMessage } from 'api/global'
import { serverTimestamp, Timestamp } from 'firebase/firestore'
import moment from 'moment'

interface ChatContentProps {
  form: FormInstance
  eventData: EventResp
}

const ChatContent: React.FC<ChatContentProps> = ({ form, eventData }) => {
  const { id }: { id: string } = useParams()
  const senderId = localStorage.getItem('adminId')
  const [isScheduled, setIsScheduled] = useState<boolean>(false)
  const {
    selectedParticipants,
    setChatModalVisible,
    setSelectedChatRoomId,
    selectedParticipantsData,
    selectedParticipantsEmails,
  } = eventShatStore(store => ({
    setChatModalVisible: store.setChatModalVisible,
    selectedParticipants: store.selectedParticipants,
    setSelectedChatRoomId: store.setSelectedChatRoomId,
    selectedParticipantsData: store.selectedParticipantsData,
    selectedParticipantsEmails: store.selectedParticipantsEmails,
  }))
  const setModalBtnLoader = myStore(store => store.setModalBtnLoader)
  const [contactType, setContactType] = useState<'chat' | 'email' | 'sms'>(
    'chat',
  )
  const { mutate: messageNotificationMutation } = useSendMessageNotification()
  const { mutate: sendEmailMutation } = useSendEmailToAttendees()
  const { mutate: sendSmsMutation } = useSendSmsToAttendees()

  const formSchema = {
    contactVia: 'chat',
  }

  // create chat room
  async function createRoom(message: ChatMessageType) {
    setModalBtnLoader(true)
    try {
      const roomId = await createChatRoom(
        id,
        'EVENTCHATS',
        'Group Chat',
        [...selectedParticipants, localStorage.getItem('adminId')],
        message,
        selectedParticipantsData,
      )
      setModalBtnLoader(false)
      setChatModalVisible(false)
      return roomId
    } catch (error) {
      setModalBtnLoader(false)
      return null
    }
  }
  const onFinish = async values => {
    const message: ChatMessageType = {
      fileUrl: '',
      messageText: values.message,
      messageType: 'Text',
      recieverId: null,
      senderId,
      thumbnailUrl: '',
      readByUsers: [senderId],
      time: serverTimestamp() as Timestamp,
    }

    if (contactType === 'chat') {
      const roomId = await createRoom(message)
      if (!roomId)
        return failureMessage('Something went wrong when creating chat room')
      await sendMessage(
        roomId,
        senderId,
        null,
        'Text',
        values.message,
        '',
        '',
        [senderId],
      )
      setSelectedChatRoomId(roomId)
      messageNotificationMutation({
        chatRoomId: roomId,
        members: selectedParticipantsData.map(participant => ({
          id: participant._id,
          role: participant.role,
          name: participant.firstName,
          familyId: participant.familyId,
          organizationId: participant.organizationId,
        })),
        lastMessage: values.message,
        lastMessageType: 'Text',
        senderId: senderId,
        title: 'Chat',
      })
      return
    }
    if (contactType === 'email') {
      const payload: SendEmailPayload = {
        attendeesIds: selectedParticipants,
        ccRecipients: selectedParticipantsEmails,
        bccRecipients: selectedParticipantsEmails,
        subject: values.subject,
        text: values.message,
      }

      setModalBtnLoader(true)
      sendEmailMutation(payload, {
        onSuccess: () => {
          setModalBtnLoader(false)
          setChatModalVisible(false)
        },
        onError: () => {
          setModalBtnLoader(false)
        },
      })
      return
    }
    if (contactType === 'sms') {
      const payload: SendSmsPayload = {
        attendeesIds: selectedParticipants,
        text: values.message,
        scheduledAt: isScheduled
          ? moment
              .utc(
                `${moment(values.scheduleDate).format('YYYY-MM-DD')} ${moment
                  .utc(values.scheduleTime)
                  .format('HH:mm')}`,
              )
              .format()
          : undefined,
      }
      setModalBtnLoader(true)
      sendSmsMutation(
        { payload, isScheduled },
        {
          onSuccess: () => {
            setModalBtnLoader(false)
            setChatModalVisible(false)
          },
          onError: () => {
            setModalBtnLoader(false)
          },
        },
      )
    }
  }

  const handleChange = (e: RadioChangeEvent) => {
    setContactType(e.target.value)
  }

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        initialValues={formSchema}
        onFinish={onFinish}
      >
        <Row gutter={10}>
          <Col className="mt-10">
            <label className="input-label">Contact via</label>
            <Form.Item className="form-control mb-0" name="contactVia">
              <Radio.Group defaultValue={contactType} onChange={handleChange}>
                <Radio value={'email'} className="mr-40 radio">
                  Email
                </Radio>
                <Radio value={'sms'} className="mr-40 radio">
                  SMS
                </Radio>
                <Radio value={'chat'} className="mr-40 radio">
                  Chat
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <RecepientInput eventData={eventData} contactType={contactType} />
        <Row className="mt-10px">
          <Col>
            {contactType === 'email' && (
              <>
                <label className="input-label">Subject</label>
                <Form.Item className="form-control mb-0" name="subject">
                  <Input
                    placeholder="Email subject"
                    name="subject"
                    className="radius-12px"
                  />
                </Form.Item>
              </>
            )}
            <Form.Item className="form-control mb-0 mt-15px" name="message">
              <Input.TextArea
                className="note-field"
                placeholder="Your message..."
                autoSize={{ minRows: 5, maxRows: 10 }}
              />
            </Form.Item>
          </Col>
        </Row>
        {contactType === 'sms' && (
          <Row className="mt-20px">
            <Col span={24} className="flex align-center justify-between ">
              <label className="input-label">Scheduled At:</label>
              <Switch onChange={() => setIsScheduled(prev => !prev)} />
            </Col>
            {isScheduled && (
              <Col span={24}>
                <Row gutter={24} className="mt-15px">
                  <Col span={12}>
                    <Form.Item
                      name="scheduleDate"
                      rules={[{ required: true, message: 'Date is required' }]}
                    >
                      <DatePicker
                        className="w-100 form-control-field"
                        placeholder="Select date"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="expiryTime"
                      rules={[{ required: true, message: 'Time is required' }]}
                    >
                      <TimePicker
                        className="w-100 form-control-field"
                        placeholder="Start Time"
                        format="h:mm a"
                        showNow={false}
                        clearIcon={false}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        )}
      </Form>
    </div>
  )
}

export default ChatContent
