import { Button, Col, Form, Input, Row } from 'antd'
import OrganizationInput from 'components/global/inputs/OrganizationInput/OrganizationInput'
import UploadDocument from 'components/global/UploadDocument'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Address } from 'types/globalTypes'
import Location from './InputComps/Location'
import { formValidationOnRequiredFields } from 'helper/utility'
import { infoMessage } from 'api/global'
import {
  useCreateCommunity,
  useUpdateCommunity,
} from 'api/Community/CommunityCreation'
import ButtonSpinner from 'components/global/ButtonSpinner'
import { communityStore } from 'state/event/community'

interface Step1Props {
  handleNext: () => void
}

const Step1Community: React.FC<Step1Props> = ({ handleNext }) => {
  const { id } = useParams<{ id: string }>()
  const { communityData, setCommunityData } = communityStore(store => ({
    communityData: store.communityData,
    setCommunityData: store.setCommunityData,
  }))
  const [selectedOrganization, setSelectedOrganization] = useState<
    any | undefined
  >(communityData ? communityData.organization : undefined) // todo change type when changed from backend
  const [mapData, setMapModalData] = useState<any | undefined>(
    communityData ? communityData.location : undefined,
  )
  const [documentIds, setDocumentIds] = useState<string[]>(
    communityData?.coverPhotosIds ? communityData.coverPhotosIds : [],
  )
  const [photos, setPhotos] = useState<any[]>(
    communityData?.coverPhotos ? communityData.coverPhotos : [],
  ) // todo change type when changed from backend
  const [form] = Form.useForm()
  const { mutate: createCommunity, isLoading: createCommunityLoading } =
    useCreateCommunity()
  const { mutate: updateCommunity, isLoading: updateCommunityLoading } =
    useUpdateCommunity()

  const formSchema = {
    name: communityData ? communityData.name : undefined,
  }

  const onFinish = (values: { name: string }) => {
    if (!selectedOrganization) return infoMessage('Organization is required')
    if (!formValidationOnRequiredFields(values, ['name'])) return
    if (!mapData) return infoMessage('Location is required')

    const payload = {
      ...values,
      organizationId: selectedOrganization._id,
      locationId: mapData._id,
      coverPhotosIds: documentIds.length ? documentIds : undefined,
    }

    if (communityData) {
      updateCommunity(
        { payload, communityId: communityData._id },
        {
          onSuccess: data => {
            setCommunityData(data)
            handleNext()
          },
        },
      )
    } else {
      createCommunity(payload, {
        onSuccess: data => {
          setCommunityData(data)
          handleNext()
        },
      })
    }
  }

  useEffect(() => {
    if (communityData && id) {
      form.setFieldsValue({
        name: communityData.name,
      })
      setSelectedOrganization(communityData.organization)
      setMapModalData(communityData.location)
      setDocumentIds(
        communityData.coverPhotosIds ? communityData.coverPhotosIds : [],
      )
      setPhotos(communityData.coverPhotos ? communityData.coverPhotos : [])
    }
  }, [communityData])

  return (
    <div className="create-event-main-content-box" style={{ margin: '0 auto' }}>
      <Form
        form={form}
        layout="horizontal"
        initialValues={formSchema}
        onFinish={onFinish}
      >
        <h2>Step 1. General Details</h2>
        <OrganizationInput
          selectedOrganization={selectedOrganization}
          setSelectedOrganization={setSelectedOrganization}
          isDisabled={communityData ? true : false}
        />
        <Row>
          <Col>
            <label className="input-label required-input">Community name</label>
          </Col>
          <Col>
            <Form.Item className="form-control" name="name">
              <Input
                placeholder="Community name"
                className="form-control-field"
              />
            </Form.Item>
          </Col>
        </Row>

        {selectedOrganization && (
          <Row className="-mt-10px">
            <Col span={24}>
              <Location
                selectedOrganization={selectedOrganization}
                mapData={mapData}
                setMapModalData={setMapModalData}
                form={form}
              />
            </Col>
          </Row>
        )}

        <Row className="mt-10px">
          <Col span={18}>
            <label className="input-label">Image</label>
            <UploadDocument
              documentIds={documentIds}
              setDocumentIds={setDocumentIds}
              multiple={true}
              docType="image/*"
              files={photos}
              uploadMultipleAtOnce={true}
            />
          </Col>
        </Row>

        <Row justify="space-between" className="mb-26 mt-40px">
          <Link to="/events">
            <Button className="cancel-button">Back to listing</Button>
          </Link>
          <Button
            className="stepper-button"
            disabled={createCommunityLoading || updateCommunityLoading}
            htmlType="submit"
          >
            {createCommunityLoading || updateCommunityLoading ? (
              <ButtonSpinner color="#000" size={20} />
            ) : (
              'Next'
            )}
          </Button>
        </Row>
      </Form>
    </div>
  )
}

export default Step1Community
