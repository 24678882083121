import { Button } from 'antd'
import React from 'react'
import { CaretLeft } from '@phosphor-icons/react'
import { EventResp } from 'types/event/events'
import { creationEventStore } from 'state/event/eventCreationStore'
import './suppliesandcoststyles.scss'
import Calculation from './essential-comoponents/Calculation'
import Total from './essential-comoponents/Total'
import { useEventStep3 } from 'api/EventCreation/creationStep3'
import ButtonSpinner from 'components/global/ButtonSpinner'

interface SuppliesAndCostProps {
  handleNext: (data: EventResp) => void
  handleBack: () => void
}

const SuppliesAndCost: React.FC<SuppliesAndCostProps> = ({
  handleNext,
  handleBack,
}) => {
  const eventData = creationEventStore(store => store.eventData)
  const setEventData = creationEventStore(store => store.setEventData)
  const step3Payload = creationEventStore(store => store.step3Payload)

  const { mutate: eventStep3, isLoading: eventStep3Loading } = useEventStep3()

  const handleStep3Next = () => {
    eventStep3(
      { payload: step3Payload, eventId: eventData.id },
      {
        onSuccess: data => {
          setEventData(data)
          handleNext(data)
        },
      },
    )
  }

  return (
    <div className="step3-main-container">
      <div className="content scroller overflow-auto-y">
        <h3 className="text-24 w-600"> Step 3. Supplies & Cost</h3>
        <p className="mt-20px">
          Based on your event details we recommend the number of stuff
        </p>
        <div className="mt-15px flex justify-between gap-60px">
          <div className="w-50p">
            <Calculation />
          </div>
          <div className="w-50p">
            <Total />
          </div>
        </div>
      </div>
      <div className="mt-15px event-button-cont">
        <h5
          className="flex gap-10px align-center pointer"
          onClick={() => handleBack()}
        >
          <CaretLeft size={20} color="#000" />
          <span className="text-15 w-500 ">Back</span>
        </h5>
        <Button className="stepper-button" onClick={handleStep3Next}>
          {eventStep3Loading ? (
            <ButtonSpinner size={20} color="#000" />
          ) : (
            'Next'
          )}
        </Button>
      </div>
    </div>
  )
}

export default SuppliesAndCost
