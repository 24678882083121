import { Col, Form, FormInstance, Input, Row } from 'antd'
import React from 'react'
import { AddFaqPayload, Faqs } from 'types/event/eventStep5'
import { eventCommonStore } from 'state/event/eventCommon'
import { myStore } from 'state/store'
import { useAddFaq, useUpdateFaq } from 'api/Faq/Faq'

interface AddQuestionContentProps {
  form: FormInstance
  reference: 'event' | 'organization' | 'league'
  referenceId: string
  data: Faqs
}

const AddQuestionContent: React.FC<AddQuestionContentProps> = ({
  form,
  reference,
  referenceId,
  data,
}) => {
  const { setAddQuestionModal } = eventCommonStore(store => ({
    setAddQuestionModal: store.setAddQuestionModal,
  }))
  const setModalBtnLoader = myStore(store => store.setModalBtnLoader)
  const { mutate: createEventFaq } = useAddFaq()
  const { mutate: updateEventFaq } = useUpdateFaq()

  const formSchema = {
    question: data ? data.question : undefined,
    answer: data ? data.answer : undefined,
  }

  const onFinish = (values: AddFaqPayload) => {
    const payload = {
      ...values,
      [`${reference}Id`]: referenceId,
    }
    setModalBtnLoader(true)
    if (data) {
      updateEventFaq(
        { questionId: data._id, payload, reference },
        {
          onSuccess: () => {
            setModalBtnLoader(false)
            setAddQuestionModal(false)
          },
          onError: () => {
            setModalBtnLoader(false)
          },
        },
      )
    } else {
      createEventFaq(
        { payload, reference },
        {
          onSuccess: () => {
            setModalBtnLoader(false)
            setAddQuestionModal(false)
          },
          onError: () => {
            setModalBtnLoader(false)
          },
        },
      )
    }
  }

  return (
    <div className="mt-20px">
      <Form
        form={form}
        layout="vertical"
        initialValues={formSchema}
        onFinish={onFinish}
      >
        <Row gutter={10}>
          <Col>
            <label className="input-label required-input">Question</label>
            <Form.Item
              className="form-control"
              name="question"
              rules={[{ required: true, message: 'Question is required' }]}
            >
              <Input.TextArea
                className="question-field"
                placeholder="Enter a note here"
                autoSize={{ minRows: 5, maxRows: 8 }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col>
            <label className="input-label required-input">Answer</label>
            <Form.Item
              className="form-control"
              name="answer"
              rules={[{ required: true, message: 'Answer is required' }]}
            >
              <Input.TextArea
                className="answer-field"
                placeholder="Enter a note here"
                autoSize={{ minRows: 5, maxRows: 8 }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default AddQuestionContent
