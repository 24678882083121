import React from 'react'
import CustomModal from './CustomModal'
import { myStore } from 'state/store'
import CustomModalFooter from './CustomModalFooter'

interface WarningModalProps {
  yesClose: () => void
}

const WarningModal: React.FC<WarningModalProps> = ({ yesClose }) => {
  const { openWarningModal, setWarningModal } = myStore(store => ({
    openWarningModal: store.openWarningModal,
    setWarningModal: store.setOpenWarningModal,
  }))

  return (
    <>
      <CustomModal
        openModal={openWarningModal}
        closeModal={setWarningModal}
        title="Warning"
        footer={
          <CustomModalFooter
            multibutton
            btnAlignment="justify-between"
            btnText="Cancel, Yes Close"
            closeModal={setWarningModal}
            isDanger
            onSubmit={yesClose}
          />
        }
      >
        <div className="flex align-center justify-center flex-col">
          <h3 className="danger-txt text-16 w-600 mt-15px">Warning!</h3>
          <p className="mt-15px">
            All progress will be lost if you close this modal
          </p>
          <p>Are you sure you want to close?</p>
        </div>
      </CustomModal>
    </>
  )
}

export default WarningModal
