import { Avatar } from 'antd'
import { capitalizeFirstLetter, getNameSignature } from 'helper/utility'
import React from 'react'

interface RecepientBoxProps {
  avatar: string
  name: string
  role: 'mentor' | 'admin' | 'guardian' | 'adult' | 'kid' | 'organizer'
}

const RecepientBox: React.FC<RecepientBoxProps> = ({ avatar, name, role }) => {
  return (
    <div className="flex gap-16px py-12px px-20px">
      <Avatar size={45} src={avatar}>
        {getNameSignature(name)}
      </Avatar>
      <div>
        <h2 className="text-16 w-500">{name}</h2>
        <p className="text-gray">{capitalizeFirstLetter(role)}</p>
      </div>
    </div>
  )
}

export default RecepientBox
