import React from 'react'
import IconsTextNavigation from 'components/global/IconsTextNavigation'
import { leagueEventStore } from 'state/event/leagueEvent'
import DatesConstraint from '../Modals/Dates/DatesConstraint'
import { ConstaintStepStatusesResp } from 'types/league/league'

interface DatesConstraintsProps {
  status: ConstaintStepStatusesResp
}

const DatesConstraints: React.FC<DatesConstraintsProps> = ({ status }) => {
  const { datesConstraints, setDatesConstraints } = leagueEventStore(store => ({
    datesConstraints: store.datesConstraints,
    setDatesConstraints: store.setDatesConstraints,
  }))
  return (
    <>
      <div onClick={() => setDatesConstraints(true)}>
        <IconsTextNavigation
          iconName="CalendarX"
          heading="Dates"
          isCompleted={status.constraintDates}
        />
      </div>
      {datesConstraints && <DatesConstraint />}
    </>
  )
}

export default DatesConstraints
