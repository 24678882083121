import { useQuery } from '@tanstack/react-query'
import { axiosGet } from '../Redux/axiosHelper'
import { Person } from 'types/globalTypes'

export const getOverviewCounts = async () => {
  const response = await axiosGet('v1/admins/overview/count')
  return response.data
}

const getAdminDetails = async () => {
  const response = await axiosGet('v1/admins/me')
  return response.data
}

export const useGetAdminDetails = () => {
  const adminDetails = useQuery<Person>(
    ['get-admin-details'],
    getAdminDetails,
    {
      enabled: false,
    },
  )
  return adminDetails
}
