import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { externalStore } from 'state/event/external'
import AppLayout from 'components/layout/layout'

import ExternalStep1 from './screens/Step1/Step1'
import ExternalStep2 from './screens/Step2/Step2'
import { useGetExternalEvent } from 'api/External/External'
import Spinner from 'components/Spinner'

const CreateExternal: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { stepCounter, setStepCounter, setExternalData } = externalStore(
    store => ({
      stepCounter: store.externalStepCounter,
      setStepCounter: store.setExternalStepCounter,
      setExternalData: store.setExternalData,
    }),
  )
  const {
    data: externalData,
    isLoading,
    isFetching,
    refetch,
    isError,
  } = useGetExternalEvent(id)

  const handleNext = () => {
    setStepCounter(stepCounter + 1)
  }

  const handleBack = () => {
    setStepCounter(stepCounter - 1)
  }

  useEffect(() => {
    if (id) {
      refetch()
    }
  }, [id])
  useEffect(() => {
    if (externalData) {
      setExternalData(externalData)
    }
  }, [externalData])

  if (id && (isLoading || isFetching)) {
    return (
      <AppLayout id={id}>
        <div className="flex w-100 h-100vh justify-center align-center">
          <Spinner />
        </div>
      </AppLayout>
    )
  }

  if (isError) {
    return (
      <AppLayout id={id}>
        <div className="w-100 h-100vh flex align-center justify-center">
          <h1>Something went wrong</h1>
        </div>
      </AppLayout>
    )
  }

  return (
    <AppLayout id={id}>
      {stepCounter === 0 && (
        <div
          className="create-event-main-content-wapper w-100"
          style={{ padding: '0px 40px 0px 40px' }}
        >
          <ExternalStep1 handleNext={handleNext} />
        </div>
      )}
      {stepCounter === 1 && (
        <div
          className="create-event-main-content-wapper w-100"
          style={{ padding: '0px 40px 0px 40px' }}
        >
          <ExternalStep2 handleNext={handleNext} handleBack={handleBack} />
        </div>
      )}
    </AppLayout>
  )
}

export default CreateExternal
