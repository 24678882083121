import React, { useEffect, useState } from 'react'
import { getChatRoom, getMessages } from '../Chat/ChatHelpers'
import { ChatRoomsType } from 'types/chat'

function useMessages(roomId: string) {
  const [messages, setMessages] = useState([])
  useEffect(() => {
    if (roomId) {
      const unsubscribe = getMessages(roomId, setMessages)
      return unsubscribe
    }
  }, [roomId])
  return messages
}

function useFindOrCreateChatRoom(roomId: string) {
  const [chatroomID, setChatroomID] = useState<ChatRoomsType[] | null>(null)

  useEffect(() => {
    if (!roomId) return

    const unsubscribe = getChatRoom(roomId, rooms => {
      setChatroomID(rooms)
    })

    return () => unsubscribe()
  }, [roomId])
  return chatroomID
}

export { useMessages, useFindOrCreateChatRoom }
