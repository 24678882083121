import React from 'react'
import { Col, Form, FormInstance, Input, Row } from 'antd'
import { myStore } from 'state/store'
import { ageGroupStore } from 'state/listing/ageGroupListing'
import { useCreateAgeGroup, useUpdateAgeGroup } from 'api/Listings/AgeGroup'

interface AgeFormProps {
  form: FormInstance
}

const AgeForm: React.FC<AgeFormProps> = ({ form }) => {
  const setModalBtnLoading = myStore(store => store.setModalBtnLoader)
  const { setAddAgeGroupModal, ageGroupData } = ageGroupStore(store => ({
    setAddAgeGroupModal: store.setAddAgeGroupModal,
    ageGroupData: store.ageGroupData,
  }))

  const { mutate: createAgeGroup } = useCreateAgeGroup()
  const { mutate: updateAgeGroup } = useUpdateAgeGroup()

  const formSchema = {
    name: ageGroupData ? ageGroupData.name : undefined,
  }

  const onFinish = (values: { name: string }) => {
    setModalBtnLoading(true)

    if (ageGroupData) {
      updateAgeGroup(
        { ageGroupId: ageGroupData._id, payload: values },
        {
          onSuccess: () => {
            setModalBtnLoading(false)
            setAddAgeGroupModal(false)
          },
          onError: () => {
            setModalBtnLoading(false)
          },
        },
      )
    } else {
      createAgeGroup(values, {
        onSuccess: () => {
          setModalBtnLoading(false)
          setAddAgeGroupModal(false)
        },
        onError: () => {
          setModalBtnLoading(false)
        },
      })
    }
  }

  return (
    <div>
      <Form
        name="grade-form"
        initialValues={formSchema}
        form={form}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
      >
        <Row className="mt-10px">
          <Col span={24}>
            <label className="input-label required-input">Name</label>
            <Form.Item
              colon={false}
              name="name"
              rules={[{ required: true, message: 'Name required' }]}
            >
              <Input placeholder="Enter Name" required name="name" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default AgeForm
