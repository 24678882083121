import React, { useState, useEffect } from 'react'
import { Modal } from 'antd'
import closeIcon from 'assets/Images/close.svg'
import Map from 'helper/Map/Map'
import StorageLocationForm from './StorageLocationForm'
import { Address } from 'types/globalTypes'
import { inventoryStorageLocType } from 'types/event/events'

interface StorageMapModalProps {
  openModal: boolean
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
  prevData: Address | inventoryStorageLocType | undefined
}

const StorageMapModal: React.FC<StorageMapModalProps> = ({
  openModal,
  closeModal,
  prevData,
}) => {
  const [current, setCurrent] = useState<number>(1)
  const [mapData, setMapData] = useState<
    null | Address | inventoryStorageLocType
  >(null)

  const handleCancel = () => {
    closeModal(false)
  }

  const handleNext = (childData?: Address) => {
    if (current === 1) {
      setMapData({
        ...mapData,
        ...childData,
      })
      setCurrent(prev => prev + 1)
    } else {
      handleCancel()
    }
  }

  const handleBack = () => {
    if (current === 1) {
      closeModal(false)
    } else {
      setCurrent(prev => prev - 1)
    }
  }

  useEffect(() => {
    if (prevData) {
      setMapData(prevData)
    }
  }, [prevData])

  return (
    <Modal
      open={openModal}
      onCancel={handleCancel}
      //   className="add-supplyform-modal"
      width={600}
      footer={false}
    >
      <div className="flex-center pt-20px justify-between ">
        <h2 className="w-600 text-18">
          {current === 1 ? 'Select location' : 'Fill the form'}
        </h2>
        <img
          src={closeIcon}
          alt="close-button"
          onClick={handleCancel}
          className="close button pointer"
        />
      </div>

      <div className="h-33rem overflow-auto-y overflow-hidden-x scroller">
        {current === 1 ? (
          <div className="h-33rem" style={{ marginTop: '50px' }}>
            <Map
              next={handleNext}
              //   google={props.google}
              center={{
                lat:
                  mapData !== null ? mapData.coordinates.latitude : 37.2428698,
                lng:
                  mapData !== null
                    ? mapData.coordinates.longitude
                    : -121.9231999,
              }}
              mapData={mapData}
              height="300px"
              zoom={15}
            />
          </div>
        ) : (
          <div>
            <StorageLocationForm
              handleNext={handleNext}
              handleBack={handleBack}
              mapData={mapData}
            />
          </div>
        )}
      </div>
    </Modal>
  )
}

export default StorageMapModal
