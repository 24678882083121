import React from 'react'
import CustomModal from 'components/global/CustomModal'
import { eventCommonStore } from 'state/event/eventCommon'
import useFormSubmit from 'helper/Hooks/formSubmit'
import CustomModalFooter from 'components/global/CustomModalFooter'
import AddQuestionContent from '../ModalCotent/AddQuestion'

interface AddQuestionProps {
  referenceModalId: any
  reference: 'event' | 'organization' | 'league'
}

const AddQuestion: React.FC<AddQuestionProps> = ({
  referenceModalId,
  reference,
}) => {
  const { form, handleSubmit } = useFormSubmit()
  const { addQuestionModal, setAddQuestionModal, faqData } = eventCommonStore(
    store => ({
      addQuestionModal: store.addQuestionModal,
      setAddQuestionModal: store.setAddQuestionModal,
      faqData: store.faqData,
    }),
  )

  return (
    <>
      <CustomModal
        title="Add Question"
        openModal={addQuestionModal}
        closeModal={setAddQuestionModal}
        footer={
          <CustomModalFooter
            multibutton={false}
            btnAlignment="justify-end"
            btnType="primary"
            btnText="Save"
            onSubmit={handleSubmit}
          />
        }
      >
        <AddQuestionContent
          form={form}
          reference={reference}
          referenceId={referenceModalId}
          data={faqData}
        />
      </CustomModal>
    </>
  )
}

export default AddQuestion
