import { useState, useEffect } from 'react'
import { Modal, Input, Button, notification } from 'antd'
import { UploadSimple } from '@phosphor-icons/react'
import { storage } from '../../../Firebase'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import '../styles/styles.scss'
import ButtonSpinner from '../ButtonSpinner'
import { turncateText } from 'helper/utility'

function Uploadfile({
  isModalOpen,
  setIsOpenModal,
  setImgLink,
  setType,
  setThumbnailUrl,
  setImageName,
}) {
  const [imageUpload, setImageUpload] = useState(null)
  const [, setImageUrls] = useState<any>()
  const [base64img, setBase64img] = useState<any>()
  const [isLoading, setIsLoadong] = useState<boolean>(false)

  const uploadFile = async () => {
    try {
      setIsLoadong(true)
      if (imageUpload == null) return
      const imageRef = ref(storage, `Chats/Files/${imageUpload.name}`)
      const url = await getDownloadURL(imageRef)

      setImageUrls(url)
      setImgLink(url)
      setIsOpenModal(false)
      setImageName(imageUpload.name)
    } catch (error) {
      notification.error({ message: `Please try again: ${error}` })
    } finally {
      setIsLoadong(false)
    }
  }

  const uploadImage = async event => {
    const file = event.target.files[0]
    if (file.type.includes('image')) {
      setImageUpload(event.target.files[0])
    } else {
      setImageUpload(event.target.files[0])
    }

    // code for uploaidng video thumbnail
    const fileReader = new FileReader()
    if (file.type.includes('video')) {
      fileReader.onload = function () {
        const blob = new Blob([fileReader.result], { type: file.type })
        const url = URL.createObjectURL(blob)
        const video = document.createElement('video')
        const timeupdate = function () {
          if (snapImage()) {
            video.removeEventListener('timeupdate', timeupdate)
            video.pause()
          }
        }

        video.addEventListener('loadeddata', function () {
          if (snapImage()) {
            video.removeEventListener('timeupdate', timeupdate)
          }
        })

        const snapImage = function () {
          const canvas = document.createElement('canvas')
          canvas.width = video.videoWidth
          canvas.height = video.videoHeight
          canvas
            .getContext('2d')
            .drawImage(video, 0, 0, canvas.width, canvas.height)
          const image = canvas.toDataURL()
          const success = image.length > 100000
          setBase64img(image)
          return success
        }

        video.addEventListener('timeupdate', timeupdate)
        video.preload = 'metadata'
        video.src = url

        // Load video in Safari / IE11
        video.muted = true
        video.playsInline = true
        video.play()
      }
      fileReader.readAsArrayBuffer(file)
    }
    if (file.type.includes('image')) {
      setType('Image')
    } else if (file.type.includes('video')) {
      setType('Video')
    } else if (file.type.includes('application')) {
      setType('file')
    } else if (file.type.includes('audio')) {
      setType('VoiceNote')
    }
  }

  useEffect(() => {
    if (base64img) {
      thumbnailGenerator()
    }
  }, [base64img])

  const handleCancel = () => {
    setIsOpenModal(false)
  }

  const handleConfirm = () => {
    uploadFile()
  }

  const thumbnailGenerator = async () => {
    const base64ToBlob = (base64Data, contentType = 'image/png') => {
      const base64String = base64Data.replace(/^data:[^;]+;base64,/, '')

      const sliceSize = 512
      const byteCharacters = atob(base64String)
      const byteArrays = []

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)
        const byteNumbers = new Array(slice.length)

        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
      }

      return new Blob(byteArrays, { type: contentType })
    }

    const thumbnailBlob = base64ToBlob(base64img, 'image/png')
    const thumbnailRef = ref(
      storage,
      `Chats/VideoThumbnails/${imageUpload.name}`,
    )
    const snapshot = await uploadBytes(thumbnailRef, thumbnailBlob)
    const thumbnailurl = await getDownloadURL(thumbnailRef)
    setThumbnailUrl(thumbnailurl)
  }

  // code for generating the thumbnail

  return (
    <div>
      <Modal open={isModalOpen} onCancel={handleCancel} footer={false}>
        <div
          className="flex justify-center align-center w-100"
          style={{ height: '400px' }}
        >
          <label
            className={`custom-file-upload flex align-center justify-center flex-col`}
          >
            <UploadSimple size={72} height={72} alt="upload" />
            <div>
              <Input
                type="file"
                placeholder="Upload Image"
                className={`input-box input-box-file `}
                onChange={e => uploadImage(e)}
                style={{ display: 'none' }}
              />
            </div>
            <div>
              <h3 className="b2 font16 mt-10 text-space-prewrap">
                {imageUpload
                  ? turncateText(imageUpload.name, 50)
                  : 'Upload file'}
              </h3>
            </div>
          </label>
        </div>

        <div className="btns flex gap-20 justify-center">
          <Button className="btn-prim" onClick={handleCancel}>
            Close
          </Button>
          <Button className="btn-prim" onClick={handleConfirm}>
            {isLoading ? <ButtonSpinner size={20} color="#000" /> : 'Confirm'}
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default Uploadfile
