import React, { useState } from 'react'
import IconsTextNavigation from 'components/global/IconsTextNavigation'
import SecurityModal from '../../Modals/SecurityModal'
import { creationEventStore } from 'state/event/eventCreationStore'
import { EventStep5Payload } from 'types/event/eventStep5'

const Security: React.FC = () => {
  const [openSecurityModal, setOpenSecurityModal] = useState<boolean>(false)
  const step5Payload: EventStep5Payload = creationEventStore(
    store => store.step5Payload,
  )

  return (
    <>
      <div onClick={() => setOpenSecurityModal(true)}>
        <IconsTextNavigation
          iconName="ShieldCheck"
          heading="Security"
          details={
            step5Payload?.riskLevel ? step5Payload?.riskLevel : 'Not added'
          }
        />
      </div>
      {openSecurityModal && (
        <SecurityModal
          openModal={openSecurityModal}
          closeModal={setOpenSecurityModal}
        />
      )}
    </>
  )
}

export default Security
