import { Copy, Plus } from '@phosphor-icons/react'
import { Checkbox } from 'antd'
import { capitalizeFirstLetter } from 'helper/utility'
import { weekDays } from 'pages/Events/Views/CreateEvent/utility'
import React, { useEffect, useState } from 'react'
import { communityStore } from 'state/event/community'
import { WeekDays } from 'types/globalTypes'
import TimeModal from './Modals/TimeModal'
import TimeCopyModal from './Modals/TimeCopyModal'
import TimeField from './TimeField'

const WorkingHours = () => {
  const {
    timeSlots,
    setTimeSlots,
    addTimeModal,
    setAddTimeModal,
    copyTimeModal,
    setCopyTimeModal,
  } = communityStore(store => ({
    timeSlots: store.timeSlots,
    setTimeSlots: store.setTimeSlots,
    addTimeModal: store.addTimeModal,
    setAddTimeModal: store.setAddTimeModal,
    copyTimeModal: store.copyTimeModal,
    setCopyTimeModal: store.setCopyTimeModal,
  }))
  const [checkedDays, setCheckedDays] = useState<WeekDays[]>([])
  const [dayToAddTime, setDayToAddTime] = useState<WeekDays>()

  const handleCheckboxChange = (day: WeekDays, isChecked: boolean) => {
    if (isChecked) {
      setCheckedDays(prev => [...prev, day])
      const daykey = day.toLowerCase()
      const updatedTimeSlots = {
        ...timeSlots,
        [daykey]: [],
      }
      setTimeSlots(updatedTimeSlots)
    } else {
      setCheckedDays(prev => prev.filter(item => item !== day))
      const dayKey = day.toLowerCase()
      const updatedTimeSlots = { ...timeSlots }
      delete updatedTimeSlots[dayKey]
      setTimeSlots(updatedTimeSlots)
    }
  }

  const handleAddTimeClick = (day: WeekDays) => {
    setDayToAddTime(day)
    setAddTimeModal(true)
  }
  const handleCopyTimeClick = (day: WeekDays) => {
    setDayToAddTime(day)
    setCopyTimeModal(true)
  }

  useEffect(() => {
    if (timeSlots) {
      const activeDays = Object.keys(timeSlots).map(dayKey =>
        capitalizeFirstLetter(dayKey),
      ) as WeekDays[]
      setCheckedDays(activeDays)
    }
  }, [timeSlots])

  return (
    <>
      <div className="mt-15px">
        {weekDays.map((day: WeekDays, index: number) => {
          return (
            <div className="py-15px border-btm " key={index}>
              <div className="flex align-center justify-between gap-20px">
                <div className="flex align-center gap-10px">
                  <Checkbox
                    checked={checkedDays.includes(day)}
                    onChange={e => handleCheckboxChange(day, e.target.checked)}
                  />
                  <h4 className="text-14 w-500">{day}</h4>
                </div>

                {checkedDays.includes(day) && (
                  <div className="flex align-center gap-16px">
                    <Plus
                      color="#000"
                      size={20}
                      className="pointer"
                      weight="thin"
                      onClick={() => handleAddTimeClick(day)}
                    />
                    <Copy
                      color="#000"
                      size={20}
                      className="pointer"
                      weight="thin"
                      onClick={() => handleCopyTimeClick(day)}
                    />
                  </div>
                )}
              </div>

              {timeSlots
                ? timeSlots[day.toLocaleLowerCase()] && (
                    <div>
                      {timeSlots[day.toLocaleLowerCase()].map(
                        (timeSlots: any, index: number) => {
                          return (
                            <div className="mt-15px" key={index}>
                              <TimeField
                                startTime={timeSlots.start}
                                endTime={timeSlots.end}
                                day={day}
                                index={index}
                              />
                            </div>
                          )
                        },
                      )}
                    </div>
                  )
                : null}
            </div>
          )
        })}
      </div>
      {addTimeModal && <TimeModal day={dayToAddTime} />}
      {copyTimeModal && <TimeCopyModal day={dayToAddTime} />}
    </>
  )
}

export default WorkingHours
