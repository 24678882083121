import React, { useEffect, useState } from 'react'
import CustomModal from 'components/global/CustomModal'
import CustomModalFooter from 'components/global/CustomModalFooter'
import SelectDatesCalendar from 'components/global/SelectDatesCalendar'
import { leagueEventStore } from 'state/event/leagueEvent'
import { convertToLocal, convertToUtc } from 'helper/utility'
import { useLeagueStep9 } from 'api/LeagueCreation/leagueCreation'
import { ConstraintDates } from 'types/league/league'
import { myStore } from 'state/store'

const DatesConstraint: React.FC = () => {
  const { leagueData, datesConstraints, setDatesConstraints, setLeagueData } =
    leagueEventStore(store => ({
      leagueData: store.leagueData,
      setLeagueData: store.setLeagueData,
      datesConstraints: store.datesConstraints,
      setDatesConstraints: store.setDatesConstraints,
    }))
  const setModalBtnLoader = myStore(store => store.setModalBtnLoader)
  const { mutate: leagueStep9Mutation, isLoading: isLeagueStep9Loading } =
    useLeagueStep9()

  const [selectedDates, setSelectedDates] = useState<string[]>(
    leagueData.constraintDates ? leagueData.constraintDates : [],
  )

  const hanldeSubmit = () => {
    const payload: ConstraintDates = {
      constraintDates: selectedDates.map((date: string) =>
        convertToUtc(date, 'YYYY-MM-DDTHH:mm:ss[Z]'),
      ),
    }
    leagueStep9Mutation(
      { leagueId: leagueData._id, payload },
      {
        onSuccess: data => {
          setLeagueData({
            ...leagueData,
            constraintDates: data.constraintDates,
            statuses: data.statuses,
          })
          setDatesConstraints(false)
        },
      },
    )
  }

  useEffect(() => {
    if (leagueData.constraintDates) {
      const formatedDates = leagueData.constraintDates.map((date: string) => {
        return convertToLocal(date, 'YYYY-MM-DD')
      })
      setSelectedDates(formatedDates)
    }
  }, [])

  useEffect(() => {
    setModalBtnLoader(isLeagueStep9Loading)
  }, [isLeagueStep9Loading])

  return (
    <>
      <CustomModal
        openModal={datesConstraints}
        closeModal={setDatesConstraints}
        width={500}
        title="Dates"
        footer={
          <CustomModalFooter
            multibutton={false}
            btnAlignment="justify-end"
            btnType="primary"
            btnText="Save"
            onSubmit={hanldeSubmit}
          />
        }
      >
        <SelectDatesCalendar
          isLimited
          isRange
          startDate={leagueData.startDate}
          endDate={leagueData.endDate}
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
        />
      </CustomModal>
    </>
  )
}

export default DatesConstraint
