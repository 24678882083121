import React, { useState, useEffect } from 'react'
import AppLayout from '../../../../components/layout/layout'
import GeneralDetail from './Screens/GeneralDetails/GeneralDetail'
import ScheduleAndActivities from './Screens/Step2/ScheduleAndActivities'
import ManageTickets from './Screens/Step4/ManageTickets'
import SuppliesAndCost from './Screens/Step3/SuppliesAndCost'
import { creationEventStore } from 'state/event/eventCreationStore'
import AdditionalEventSettings from './Screens/Step5/AdditionalEventSettings'
import { useLocation } from 'react-router-dom'
import { useGetEventById } from 'api/EventCreation/EventCreation'
import { forceRefetch } from 'helper/utility'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { EventResp } from 'types/event/events'

export type CreateEventProps = {
  currentEvent: EventResp
  currentData?: any | null
}

const CreateEvent: React.FC<CreateEventProps> = ({ currentData }) => {
  const queryClient = useQueryClient()
  const location = useLocation<{ id: string }>()
  const stepCounter = creationEventStore(store => store.stepCounter)
  const setStepCounter = creationEventStore(store => store.setStepCounter)
  const setEventData = creationEventStore(store => store.setEventData)
  const [data, setData] = useState(currentData || {})
  // const id = location.state?.id
  const { id } = useParams<{ id: string }>()

  const {
    data: getEventData,
    isLoading: eventDataLoading,
    isFetching: eventDataFetching,
    refetch: eventDataRefetch,
  } = useGetEventById(id)

  useEffect(() => {
    if (id) {
      eventDataRefetch()
    }
  }, [])

  useEffect(() => {
    if (id) {
      setEventData(getEventData)
    } else {
      forceRefetch('event-detail-by-id', queryClient)
    }
  }, [getEventData, id])

  const next = (childData: any) => {
    setData(childData)
    setStepCounter(stepCounter + 1)
    // if (stepCounter === 1) {
    //   setStepCounter(3)
    // } else {
    //   setStepCounter(stepCounter + 1)
    // }
  }

  const prev = () => {
    // setStepCounter(stepCounter - 1)
    if (stepCounter === 3) {
      setStepCounter(1)
    } else {
      setStepCounter(stepCounter - 1)
    }
  }

  return (
    <AppLayout id={id}>
      {stepCounter === 0 && (
        <div
          className="create-event-main-content-wapper w-100"
          style={{ padding: '0px 40px 0px 40px' }}
        >
          <GeneralDetail handleNext={next} />{' '}
        </div>
      )}
      {stepCounter === 1 && (
        <div className="w-100 create-event-main-wapper">
          <ScheduleAndActivities handleNext={next} handleBack={prev} />
        </div>
      )}
      {stepCounter === 2 && (
        <div className="create-event-main-content-wapper flex-1">
          <SuppliesAndCost handleNext={next} handleBack={prev} />{' '}
        </div>
      )}
      {stepCounter === 3 && (
        <div
          className="create-event-main-content-wapper flex-1"
          style={{ padding: '0px 40px 0px 40px' }}
        >
          <ManageTickets handleNext={next} handleBack={prev} />
        </div>
      )}
      {stepCounter === 4 && (
        <div className="create-event-main-content-wapper flex-1">
          <AdditionalEventSettings handleNext={next} handleBack={prev} />
          {''}
        </div>
      )}
    </AppLayout>
  )
}
export default CreateEvent
