import React, { useEffect, useMemo } from 'react'
import { useGetAgeGroup } from 'api/Listings/AgeGroup'
import Spinner from 'components/Spinner'
import { Table } from 'antd'
import moment from 'moment'
import { BasicListingType } from 'types/listing/listings'
import { PencilSimpleLine } from '@phosphor-icons/react'
import { capitalizeFirstLetter } from 'helper/utility'
import { ageGroupStore } from 'state/listing/ageGroupListing'

interface AgegroupTableProps {
  id: string
}

const AgegroupTable: React.FC<AgegroupTableProps> = ({ id }) => {
  const { setAddGroupModal, setAgeGroupData } = ageGroupStore(store => ({
    setAddGroupModal: store.setAddAgeGroupModal,
    setAgeGroupData: store.setAgeGroupData,
  }))
  const { data, isLoading, isFetching, refetch } = useGetAgeGroup()

  const handleEditClick = (data: BasicListingType) => {
    setAgeGroupData(data)
    setAddGroupModal(true)
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => (
        <p className="text-14 w-400">{capitalizeFirstLetter(text)}</p>
      ),
    },
    {
      title: 'Last Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text: string) => (
        <p className="text-14 w-400">{moment(text).format('DD MMM, YYYY')}</p>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      render: (data: BasicListingType) => (
        <div className="flex align-center gap-20px">
          <PencilSimpleLine
            size={20}
            color="#000"
            className="pointer"
            onClick={() => handleEditClick(data)}
          />
        </div>
      ),
    },
  ]

  const tableData = useMemo(() => {
    return (
      data?.map(ageGroup => ({
        Key: ageGroup._id,
        name: ageGroup.name,
        updatedAt: ageGroup.updatedAt,
        actions: ageGroup,
      })) || []
    )
  }, [data])

  useEffect(() => {
    refetch()
  }, [id])

  return (
    <>
      <div>
        {isLoading || isFetching ? (
          <div className="w-full h-70vh flex align-center justify-center">
            <Spinner />
          </div>
        ) : (
          <Table
            className="colored_header_table"
            columns={columns}
            dataSource={tableData}
            pagination={false}
          />
        )}
      </div>
    </>
  )
}

export default AgegroupTable
