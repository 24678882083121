import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { axiosDelete, axiosGet, axiosPatch, axiosPost } from 'Redux/axiosHelper'
import { AddFaqPayload, Faqs } from 'types/event/eventStep5'

const getFaqs = async (
  id: string,
  reference: 'event' | 'organization' | 'league',
) => {
  const response = await axiosGet(`v1/event-questions/${reference}/${id}`)
  return response.data
}

export const useGetFaqs = (
  id: string,
  reference: 'event' | 'organization' | 'league',
) => {
  const faqs = useQuery<Faqs[]>(
    [`${reference}-faqs`],
    () => getFaqs(id, reference),
    {
      enabled: false,
    },
  )
  return faqs
}

// add faq
const addFaq = async ({
  payload,
  reference,
}: {
  payload: AddFaqPayload
  reference: string
}) => {
  const response = await axiosPost(payload, `v1/event-questions/create`)
  return response.data
}
export const useAddFaq = () => {
  const queryClient = useQueryClient()
  return useMutation(addFaq, {
    onSuccess: (newQuestion, variables) => {
      queryClient.setQueryData(
        [`${variables.reference}-faqs`],
        (oldData: Faqs[] | undefined) => {
          return oldData ? [...oldData, newQuestion] : [newQuestion]
        },
      )
    },
  })
}

// update faq
const updateFaq = async ({
  questionId,
  payload,
  reference,
}: {
  questionId: string
  payload: AddFaqPayload
  reference: string
}) => {
  const response = await axiosPatch(
    payload,
    `v1/event-questions/update/${questionId}`,
  )
  return response.data
}

export const useUpdateFaq = () => {
  const queryClient = useQueryClient()
  return useMutation(updateFaq, {
    onSuccess: (updatedFaq, variables) => {
      queryClient.setQueryData(
        [`${variables.reference}-faqs`],
        (oldData: Faqs[] | undefined) => {
          if (!oldData) return [updatedFaq]
          return oldData.map(data =>
            data._id === updatedFaq._id ? updatedFaq : data,
          )
        },
      )
    },
  })
}

// delete faq
const removeQuestion = async ({
  questionId,
  reference,
}: {
  questionId: string
  reference: string
}) => {
  const response = await axiosDelete(`v1/event-questions/delete/${questionId}`)
  return response.data
}

export const useDeleteFaq = () => {
  const queryClient = useQueryClient()
  return useMutation(removeQuestion, {
    onSuccess: (deletedQuestion: Faqs, variables) => {
      queryClient.setQueryData(
        [`${variables.reference}-faqs`],
        (oldData: Faqs[] | undefined) => {
          if (!oldData) return []

          return oldData.filter(data => data._id !== deletedQuestion._id)
        },
      )
    },
  })
}
