import CustomModal from 'components/global/CustomModal'
import React, { useState } from 'react'
import { organizerStore } from 'state/organizer/organizer'
import CreateOrganizerContent from './ModalContent/CreateOrganizer/CreateOrganizerContent'
import useFormSubmit from 'helper/Hooks/formSubmit'
import CustomModalFooter from 'components/global/CustomModalFooter'

const CreateOrganizer: React.FC = () => {
  const { form, handleSubmit } = useFormSubmit()
  const {
    organizerModalVisible,
    setOrganizerModalVisible,
    organizerCurrentStep,
    setOrganizerCurrentStep,
    setOrganizerData,
    isUpdating,
    setIsUpdating,
    setOrganizerId,
    setAssignedOrganizations,
  } = organizerStore(store => ({
    organizerModalVisible: store.organizerModalVisible,
    setOrganizerModalVisible: store.setOrganizerModalVisible,
    organizerCurrentStep: store.organizerCurrentStep,
    setOrganizerCurrentStep: store.setOrganizerCurrentStep,
    setOrganizerData: store.setOrganizerData,
    isUpdating: store.isUpdating,
    setIsUpdating: store.setIsUpdating,
    setOrganizerId: store.setOrganizerId,
    setAssignedOrganizations: store.setAssignedOrganizations,
  }))

  const handleCrossClick = () => {
    setOrganizerCurrentStep(0)
    setOrganizerData(null)
    setIsUpdating(false)
    setOrganizerId(null)
    setAssignedOrganizations([])
    setOrganizerModalVisible(false)
  }

  const handleBack = () => {
    if (organizerCurrentStep === 0) {
      setOrganizerModalVisible(false)
    } else {
      setOrganizerCurrentStep(organizerCurrentStep - 1)
    }
  }

  return (
    <>
      <CustomModal
        openModal={organizerModalVisible}
        isCloseModalCustom
        closeModal={handleCrossClick}
        title={isUpdating ? 'Update Organizer' : 'New Organizer'}
        footer={
          <CustomModalFooter
            multibutton={organizerCurrentStep === 1 ? true : false}
            btnAlignment={`${
              organizerCurrentStep === 1 ? 'justify-between' : 'justify-end'
            }`}
            btnType="primary"
            closeModal={handleBack}
            btnText={
              isUpdating
                ? 'Update'
                : organizerCurrentStep === 0
                ? 'Next'
                : organizerCurrentStep === 1
                ? 'Back,Next'
                : 'Create Profile'
            }
            onSubmit={
              organizerCurrentStep === 2 ? handleCrossClick : handleSubmit
            }
          />
        }
      >
        <CreateOrganizerContent form={form} />
      </CustomModal>
    </>
  )
}

export default CreateOrganizer
