import React from 'react'
import { Col, Form, Row, TimePicker } from 'antd'
import CustomModal from 'components/global/CustomModal'
import useFormSubmit from 'helper/Hooks/formSubmit'
import { WeekDays } from 'types/globalTypes'
import CustomModalFooter from 'components/global/CustomModalFooter'
import moment from 'moment'
import { infoMessage } from 'api/global'
import { communityStore } from 'state/event/community'

interface InputTimeProps {
  day: WeekDays
}

const TimeModal: React.FC<InputTimeProps> = ({ day }) => {
  const { form, handleSubmit } = useFormSubmit()
  const { addTimeModal, setAddTimeModal, timeSlots, setTimeSlots } =
    communityStore(store => ({
      addTimeModal: store.addTimeModal,
      setAddTimeModal: store.setAddTimeModal,
      timeSlots: store.timeSlots,
      setTimeSlots: store.setTimeSlots,
    }))

  const formSchema = {}

  const onFinish = (values: any) => {
    const start =
      parseInt(moment(values.startTime).utc().format('HH:mm'), 10) * 60
    const end = parseInt(moment(values.endTime).utc().format('HH:mm'), 10) * 60
    const dayKey = day.toLowerCase()

    if (timeSlots && timeSlots[dayKey]) {
      const isDuplicate = timeSlots[dayKey].some(
        slot => slot.start === start && slot.end === end,
      )
      if (isDuplicate) return infoMessage(`Time slot already exists`)
      setTimeSlots({
        ...timeSlots,
        [dayKey]: [...timeSlots[dayKey], { start, end }],
      })
    } else {
      setTimeSlots({
        ...timeSlots,
        [dayKey]: [{ start, end }],
      })
    }
    setAddTimeModal(false)
  }

  return (
    <>
      <CustomModal
        title="Enter time"
        openModal={addTimeModal}
        closeModal={setAddTimeModal}
        footer={
          <CustomModalFooter
            btnAlignment="justify-end"
            btnText="Save"
            multibutton={false}
            btnType="primary"
            onSubmit={handleSubmit}
          />
        }
      >
        <Form
          form={form}
          initialValues={formSchema}
          layout="vertical"
          onFinish={onFinish}
        >
          <Row gutter={20} className="mt-10px">
            <Col span={12}>
              <label className="text-14 input-label required-input mb-10px">
                Start time
              </label>
              <Form.Item className="form-control mb-0" name="startTime">
                <TimePicker
                  className="h-50px"
                  name="time"
                  format={'HH:mm A'}
                ></TimePicker>
              </Form.Item>
            </Col>
            <Col span={12}>
              <label className="text-14 input-label required-input mb-10px">
                End time
              </label>
              <Form.Item className="form-control mb-0" name="endTime">
                <TimePicker
                  className="h-50px"
                  name="time"
                  format={'HH:mm A'}
                ></TimePicker>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </CustomModal>
    </>
  )
}

export default TimeModal
