import React from 'react'
import CustomModal from 'components/global/CustomModal'
import { leagueEventStore } from 'state/event/leagueEvent'
import AddTeamConstraintForm from './ModalContent/AddTeamConstraintForm'
import useFormSubmit from 'helper/Hooks/formSubmit'
import CustomModalFooter from 'components/global/CustomModalFooter'

const AddTeamConstraint: React.FC = () => {
  const { form, handleSubmit } = useFormSubmit()
  const { addTeamConstraintModal, setAddTeamConstraintModal } =
    leagueEventStore(store => ({
      addTeamConstraintModal: store.addTeamConstraintModal,
      setAddTeamConstraintModal: store.setAddTeamConstraintModal,
    }))

  return (
    <>
      <CustomModal
        openModal={addTeamConstraintModal}
        closeModal={setAddTeamConstraintModal}
        title="Add team constraints"
        footer={
          <CustomModalFooter
            multibutton={false}
            btnAlignment="justify-end"
            btnText="Save"
            btnType="primary"
            onSubmit={handleSubmit}
          />
        }
      >
        <AddTeamConstraintForm form={form} />
      </CustomModal>
    </>
  )
}

export default AddTeamConstraint
