import CustomModal from 'components/global/CustomModal'
import React from 'react'
import { eventCommonStore } from 'state/event/eventCommon'
import FaqModalContent from '../ModalCotent/FaqModalContent'
import CustomModalFooter from 'components/global/CustomModalFooter'

const FaqModal: React.FC = () => {
  const { faqModalOpen, setFaqModalOpen, setAddQuestionModal } =
    eventCommonStore(store => ({
      faqModalOpen: store.faqModalOpen,
      setFaqModalOpen: store.setFaqModalOpen,
      setAddQuestionModal: store.setAddQuestionModal,
    }))

  const handleSubmit = () => {
    setFaqModalOpen(false)
  }

  const handleClose = () => {
    setAddQuestionModal(true)
  }

  return (
    <CustomModal
      openModal={faqModalOpen}
      isCloseModalCustom={true}
      closeModal={setFaqModalOpen}
      title="FAQs"
      footer={
        <CustomModalFooter
          multibutton={true}
          btnAlignment="justify-between"
          btnText="Add Question,Submit"
          btnType="primary"
          closeModal={handleClose}
          onSubmit={handleSubmit}
        />
      }
    >
      <FaqModalContent
        organizationId="6731deb098a25aa016ad5ca9"
        referenceModalId="67ca8696225e9a76e7b27870"
        reference="league"
      />
    </CustomModal>
  )
}

export default FaqModal
