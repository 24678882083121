import React, { useEffect, useRef, useState } from 'react'
import { Avatar, Checkbox, Dropdown, Empty, Input } from 'antd'
import { organizerStore } from 'state/organizer/organizer'
import { useGetOrganizationsToAddOrganizer } from 'api/Organizations'
import {
  AccessLevelTypes,
  OrganizerNotAddedOrganizationType,
} from 'types/organization'
import Spinner from 'components/Spinner'
import { OrganizationResp } from 'types/globalTypes'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useAssignToOrganization } from 'api/Organizers'
import ButtonSpinner from 'components/global/ButtonSpinner'
import OrgRole from './OrgRole'
import { infoMessage } from 'api/global'

const Step3: React.FC = () => {
  const { organizerId, assignedOrganizations, setAssignedOrganizations } =
    organizerStore(store => ({
      organizerId: store.organizerId,
      assignedOrganizations: store.assignedOrganizations,
      setAssignedOrganizations: store.setAssignedOrganizations,
    }))
  const [params, setParams] = useState<OrganizerNotAddedOrganizationType>({
    page: 1,
    limit: 5,
    searchString: '',
  })
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [role, setRole] = useState<AccessLevelTypes>('view-only')
  const [selectedOrg, setSelectedOrg] = useState<OrganizationResp>()
  const dropdownRef = useRef<HTMLDivElement>(null)

  const {
    data: organizationsToAdd,
    isLoading: organizationsToAddLoading,
    isFetching: organizationsToAddFetching,
    refetch: organizationsToAddRefetch,
  } = useGetOrganizationsToAddOrganizer(organizerId, params)

  const {
    mutate: assignToOrganizationMutation,
    isLoading: assignToOrganizatinLoading,
  } = useAssignToOrganization()

  const handleSearch = () => {
    setDropdownOpen(true)
    organizationsToAddRefetch()
  }

  const checkeIfAlreadyAssigned = (item: OrganizationResp) => {
    return assignedOrganizations.some(
      (assignedOrg: any) => assignedOrg.organizationId === item._id,
    )
  }

  const handleItemClick = (
    selectedItem: OrganizationResp,
    e: React.MouseEvent,
  ) => {
    if (checkeIfAlreadyAssigned(selectedItem))
      return infoMessage('Already assigned')
    setSelectedOrg(selectedItem)
    setDropdownOpen(false)
  }

  const items =
    organizationsToAddLoading || organizationsToAddFetching
      ? [
          {
            key: 'load',
            label: (
              <div
                className="w-full justify-center align-center flex"
                style={{ height: '200px' }}
              >
                <Spinner />
              </div>
            ),
          },
        ]
      : !organizationsToAdd?.records.length
      ? [
          {
            key: 'no-data',
            label: (
              <div
                className="w-full justify-center align-center flex flex-col"
                style={{ height: '200px' }}
              >
                <Empty />
                <p>No organization exists with this search term</p>
              </div>
            ),
          },
        ]
      : organizationsToAdd?.records.map((item: OrganizationResp) => ({
          key: item._id,
          label: (
            <div ref={dropdownRef}>
              <div
                className="flex align-center justify-between"
                onClick={e => handleItemClick(item, e)}
              >
                <div className="flex align-center gap-10px">
                  <Avatar src={item.photo?.post} size={34} />
                  <span>{item.name}</span>
                </div>
                <Checkbox
                  name={item.name}
                  checked={selectedOrg?._id === item._id}
                  className="custom-category-checkbox"
                ></Checkbox>
              </div>
            </div>
          ),
        }))

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(false)
    }
  }

  const handleAddToOrganization = () => {
    const payload = {
      organizations: [
        {
          organizationId: selectedOrg?._id,
          designation: role,
          accessLevel: role,
        },
      ],
    }
    assignToOrganizationMutation(
      {
        organizerId,
        payload,
      },
      {
        onSuccess: assignedData => {
          setSelectedOrg(undefined)
          setAssignedOrganizations([...assignedData, ...assignedOrganizations])
        },
      },
    )
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleDropdownClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  return (
    <div className="mt-20px" style={{ minHeight: '300px' }}>
      {!selectedOrg ? (
        <>
          <label className="input-label">Search for organization</label>
          <div
            className="flex align-center justify-between gap-20px"
            ref={dropdownRef}
            onClick={handleDropdownClick}
          >
            <Dropdown
              menu={{
                items,
              }}
              placement="bottomLeft"
              open={dropdownOpen}
              trigger={[]}
              className="category-input-block"
              overlayStyle={{
                minWidth: '400px',
                maxWidth: '400px',
                maxHeight: '200px',
                overflowY: 'auto',
                background: '#fff',
                borderRadius: '8px',
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
              }}
            >
              <Input
                placeholder="Search by email"
                type="email"
                name="email"
                onChange={e =>
                  setParams({ ...params, searchString: e.target.value })
                }
              />
            </Dropdown>
            <button
              disabled={!params.searchString}
              className={`${
                !params.searchString ? 'btn-disabled' : 'borderd-btn'
              }`}
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
        </>
      ) : (
        <div className="flex align-center gap-20px">
          <div className="mt-20px w-full align-center flex gap-20px">
            <div className="h-60px w-full border-1px radius-12 d-flex align-center justify-between px-20px">
              <OrgRole
                accessLevel={role}
                organization={selectedOrg}
                setRole={setRole}
              />
            </div>

            <button
              className={`prim-btn pointer`}
              style={{ height: '60px' }}
              onClick={handleAddToOrganization}
            >
              {assignToOrganizatinLoading ? (
                <ButtonSpinner size={20} color="#000" />
              ) : (
                'Assign'
              )}
            </button>
          </div>
        </div>
      )}

      {assignedOrganizations.length ? (
        <div>
          <h2 className="text-14 w-500 mt-20px">Assigned to</h2>
          {assignedOrganizations.map((organization: any) => {
            return (
              <div
                key={organization._id}
                className="mt-20px h-60px w-full d-flex align-center justify-between px-20px border-btm"
              >
                <OrgRole
                  accessLevel={organization.accessLevel}
                  organization={organization.organization}
                  setRole={setRole}
                  isUpdate
                />
              </div>
            )
          })}
        </div>
      ) : null}
    </div>
  )
}

export default Step3
