import { CaretLeft, CaretRight } from '@phosphor-icons/react'
import { Button, Col, Row } from 'antd'
import { convertMinutesToLocalTime } from 'helper/utility'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { SpecialSchedule } from 'types/globalTypes'
import SelectDateModal from './Modals/SelectDateModal'
import SpecialScheduleModal from './Modals/SpecialScheduleModal'
import { useCreateExternalStep2 } from 'api/External/External'
import { externalStore } from 'state/event/external'
import ButtonSpinner from 'components/global/ButtonSpinner'
import StatusModal from './Modals/StatusModal'
import { ExternalEventResp } from 'types/external/externalEvent'

interface ExternalStep2Props {
  handleNext: () => void
  handleBack: () => void
}
interface ExternalStep2States {
  externalData: ExternalEventResp
  setExternalData: React.Dispatch<React.SetStateAction<ExternalEventResp>>
  statusModalVisible: boolean
  setStatusModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const ExternalStep2: React.FC<ExternalStep2Props> = ({
  handleBack,
  handleNext,
}) => {
  const {
    externalData,
    setExternalData,
    statusModalVisible,
    setStatusModalVisible,
  }: ExternalStep2States = externalStore(store => ({
    externalData: store.externalData,
    statusModalVisible: store.statusModalVisible,
    setExternalData: store.setExternalData,
    setStatusModalVisible: store.setStatusModalVisible,
  }))
  const [selectedDates, setSelectedDates] = useState<string[]>([])
  const [specialScheduleData, setSpecialScheduleData] = useState([])
  const [eventTime, setEventTime] = useState({
    startTime: undefined,
    endTime: undefined,
  })
  const [arrangedSelectedDates, setArrangedSelectedDates] = useState<string[]>()
  const [selectDaysModalVisible, setSelectDaysModalVisible] =
    useState<boolean>(false)
  const [addSpecialScheduleModalVisible, setAddSpecialScheduleModalVisible] =
    useState<boolean>(false)
  const { mutate: createExternalStep2, isLoading: createExternalStep2Loading } =
    useCreateExternalStep2()

  const handleDaysModal = () => {
    setSelectDaysModalVisible(true)
  }

  const handleFinish = () => {
    const payload = {
      datesOfEvent: selectedDates.map((item: string) =>
        moment(
          new Date(moment(item).format('YYYY-MM-DDTHH:mm:ss[Z]')).setHours(
            parseInt(moment(eventTime.startTime).utc().format('HH:mm'), 10),
            0,
            0,
            0,
          ),
        ).format('YYYY-MM-DDTHH:mm:ss[Z]'),
      ),
      timings: {
        startTime:
          parseInt(moment(eventTime.startTime).utc().format('HH:mm'), 10) * 60,
        endTime:
          parseInt(moment(eventTime.endTime).utc().format('HH:mm'), 10) * 60,
        specialSchedules: specialScheduleData.map((data: SpecialSchedule) => ({
          ...data,
          day: moment(data.day, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss[Z]'),
        })),
      },
    }
    createExternalStep2(
      { payload, externalId: externalData._id },
      {
        onSuccess: data => {
          setExternalData(data)
          setStatusModalVisible(true)
        },
      },
    )
  }

  useEffect(() => {
    if (selectedDates?.length > 0) {
      const sortedDates = [...selectedDates].sort(
        (a, b) => new Date(a).getTime() - new Date(b).getTime(),
      )
      setArrangedSelectedDates(sortedDates)
    }
  }, [selectedDates])

  useEffect(() => {
    if (externalData.timings?.startTime && externalData.timings?.endTime) {
      setEventTime({
        startTime: moment(
          convertMinutesToLocalTime(externalData?.timings.startTime, 'HH:mm'),
          'HH:mm',
        ),
        endTime: moment(
          convertMinutesToLocalTime(externalData?.timings.endTime, 'HH:mm'),
          'HH:mm',
        ),
      })
    }
    setSelectedDates(
      externalData?.datesOfEvent?.map((date: string) =>
        moment(date).format('YYYY-MM-DD'),
      ),
    )
    setSpecialScheduleData(externalData.timings?.specialSchedules)
  }, [])

  return (
    <>
      <div
        className="create-event-main-content-box"
        style={{ margin: '0 auto' }}
      >
        <h2>Working days & time</h2>
        <Row className="mt-10px">
          <Col>
            <label htmlFor="" className="input-label required-input">
              Date and Time
            </label>
            <Col style={{ height: 50 }}>
              <div
                onClick={handleDaysModal}
                className="w-full border-1px border-top flex align-center px-12px pointer h-full"
              >
                <div className=" w-full flex justify-between align-center">
                  <p className="text-gray">Dates</p>
                  <div className="d-flex align-center gap-10px">
                    {arrangedSelectedDates?.length > 0 ? (
                      <p className="text-14px w-500">{`${moment(
                        arrangedSelectedDates[0],
                      ).format('MMM DD')} - ${moment(
                        arrangedSelectedDates[arrangedSelectedDates.length - 1],
                      ).format('MMM DD')}, ${moment(
                        arrangedSelectedDates[0],
                      ).format('yyyy')}`}</p>
                    ) : (
                      <p className="text-gray">Select</p>
                    )}
                    <CaretRight size={16} color="#000" />
                  </div>
                </div>
              </div>
            </Col>

            <Col style={{ marginTop: 0, height: 50 }}>
              <div
                className={`w-full border-1px flex align-center px-12px pointer h-full ${
                  specialScheduleData?.length === 0 && 'border-bottom'
                }`}
                onClick={() => setAddSpecialScheduleModalVisible(true)}
              >
                <div className=" w-full flex justify-between align-center">
                  <p className="text-gray">Time</p>
                  <div className="d-flex align-center gap-10px">
                    {selectedDates?.length > 0 ? (
                      <p className="text-14px w-500">{`${moment(
                        eventTime.startTime,
                      ).format('hh:mm A')} - ${moment(eventTime.endTime).format(
                        'hh:mm A',
                      )}`}</p>
                    ) : (
                      <p className="text-gray">Select</p>
                    )}
                    <CaretRight size={16} color="#000" />
                  </div>
                </div>
              </div>
            </Col>
          </Col>

          {specialScheduleData && specialScheduleData.length > 0 && (
            <div className="w-full">
              <Row style={{ marginTop: 0 }} className="w-full">
                <div className="w-full border-1px flex align-center px-12px pointer h-full py-5px border-bottom">
                  {specialScheduleData && specialScheduleData.length > 0 && (
                    <div className=" w-full flex justify-between">
                      <p className="text-gray mt-10px">Special Schedule</p>
                      <div className="flex gap-10px">
                        <div className="flex flex-col gap-10px">
                          {specialScheduleData.map(
                            (item: SpecialSchedule, index: number) => {
                              return (
                                <div className="" key={index}>
                                  <p className="text-14px w-500 mt-5px">{`${moment(
                                    item.day,
                                  ).format(
                                    'MMM DD,YYYY',
                                  )}, ${convertMinutesToLocalTime(
                                    item?.startTime,
                                  )} - ${convertMinutesToLocalTime(
                                    item?.endTime,
                                  )}`}</p>
                                </div>
                              )
                            },
                          )}
                        </div>
                        <CaretRight
                          size={16}
                          color="#000"
                          className="mt-10px"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </Row>
            </div>
          )}
        </Row>

        {/* date and time input  */}

        <div className="mt-15px event-button-cont">
          <h5
            className="flex gap-10px align-center pointer"
            onClick={() => handleBack()}
          >
            <CaretLeft size={20} color="#000" />
            <span className="text-15 w-500">Back</span>
          </h5>
          <Button
            className="stepper-button"
            disabled={createExternalStep2Loading}
            onClick={handleFinish}
          >
            {createExternalStep2Loading ? (
              <ButtonSpinner size={20} color="#000" />
            ) : (
              'Create external'
            )}
          </Button>
        </div>
      </div>
      {selectDaysModalVisible && (
        <SelectDateModal
          openModal={selectDaysModalVisible}
          closeModal={setSelectDaysModalVisible}
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
          specialScheduleData={specialScheduleData}
          setSpecialScheduleData={setSpecialScheduleData}
          setAddSpecialScheduleModalVisible={setAddSpecialScheduleModalVisible}
          setEventTime={setEventTime}
          externalData={externalData}
        />
      )}
      {addSpecialScheduleModalVisible && (
        <SpecialScheduleModal
          addSpecialScheduleModalVisible={addSpecialScheduleModalVisible}
          setAddSpecialScheduleModalVisible={setAddSpecialScheduleModalVisible}
          selectedDates={selectedDates}
          setSpecialScheduleData={setSpecialScheduleData}
          specialScheduleData={specialScheduleData}
          setEventTime={setEventTime}
        />
      )}
      {statusModalVisible && <StatusModal />}
    </>
  )
}

export default ExternalStep2
