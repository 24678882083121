import { PencilSimpleLine } from '@phosphor-icons/react'
import { Table } from 'antd'
import { useGetActivityCategories } from 'api/Listings/ActivityCategories'
import Spinner from 'components/Spinner'
import moment from 'moment'
import React, { useEffect, useMemo } from 'react'
import { activityCategoryStore } from 'state/listing/activityCategoryListing'
import { ActivityCategoryListing } from 'types/listing/listings'

interface ActivityCategoriesTableProps {
  id: string
}

const ActivityCategoriesTable: React.FC<ActivityCategoriesTableProps> = ({
  id,
}) => {
  const { setAddEventCategoryModal, setEventCategoryData } =
    activityCategoryStore(store => ({
      setAddEventCategoryModal: store.setAddActivityCategoryModal,
      setEventCategoryData: store.setActivityCategoryListingData,
    }))
  const { data, isLoading, isFetching, refetch } = useGetActivityCategories()

  const handleEditClick = (data: ActivityCategoryListing) => {
    setEventCategoryData(data)
    setAddEventCategoryModal(true)
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => <p className="text-14 w-400">{text}</p>,
    },
    {
      title: 'Icon',
      dataIndex: 'icon',
      key: 'icon',
      render: (text: string) => (
        <img src={text} alt="icon" className="w-30px h-30px radius-rounded" />
      ),
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text: string) => (
        <p className="text-14 w-400">{moment(text).format('DD MMM, YYYY')}</p>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      render: (data: ActivityCategoryListing) => (
        <div className="flex align-center gap-20px">
          <PencilSimpleLine
            size={20}
            color="#000"
            className="pointer"
            onClick={() => handleEditClick(data)}
          />
        </div>
      ),
    },
  ]

  const tableData = useMemo(() => {
    return (
      data?.map(category => ({
        key: category._id,
        name: category.name,
        icon: category.icon.post,
        updatedAt: category.updatedAt,
        actions: category,
      })) || []
    )
  }, [data])

  useEffect(() => {
    refetch()
  }, [id])

  return (
    <div>
      {isLoading || isFetching ? (
        <div className="w-full h-70vh flex align-center justify-center">
          <Spinner />
        </div>
      ) : (
        <Table
          className="colored_header_table"
          columns={columns}
          dataSource={tableData}
          pagination={false}
        />
      )}
    </div>
  )
}

export default ActivityCategoriesTable
