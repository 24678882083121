import React, { useState, useEffect } from 'react'
import { useGetEventDefaultActivities } from 'api/EventCreation/CreationStep2'
import { GetActivitiesTypes, defaultActivitiesResp } from 'types/event/events'
import Spinner from 'components/Spinner'
import { FileMagnifyingGlass } from '@phosphor-icons/react'
import ViewActivity from './ViewActivity'

interface SpecificActivtyTabProps {
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
  params: GetActivitiesTypes
  selected: defaultActivitiesResp
  setSelected: React.Dispatch<React.SetStateAction<defaultActivitiesResp>>
}

const SpecificActivtyTab: React.FC<SpecificActivtyTabProps> = ({
  closeModal,
  params,
  selected,
  setSelected,
}) => {
  const [activityId, setActivityId] = useState<string>()
  const [activityModal, setActivityModal] = useState<boolean>(false)
  const {
    data: eventActivitiesData,
    isLoading: eventActivitiesLoading,
    isFetching: eventActivitiesFetching,
    refetch: eventActivitiesRefetch,
  } = useGetEventDefaultActivities(params)

  const handleView = (activityId: string) => {
    setActivityId(activityId)
    setActivityModal(true)
  }

  useEffect(() => {
    eventActivitiesRefetch()
  }, [params])

  return (
    <>
      {eventActivitiesLoading || eventActivitiesFetching ? (
        <div className="mt-30px mx-auto flex justify-center">
          <Spinner />
        </div>
      ) : (
        <div>
          {eventActivitiesData.length === 0 ? (
            <div className="mt-30px">
              <FileMagnifyingGlass size={100} color="#D5D5D5" />
              <p className="mt-15px">No activites available</p>
            </div>
          ) : (
            <div className="default-activity h-20 overflow-auto-y scroller">
              {eventActivitiesData.map((activity: defaultActivitiesResp) => (
                <div
                  className="flex align-center justify-between mt-20px"
                  key={activity._id}
                >
                  <h3 className="w-500 text-16">{activity.name}</h3>
                  <div className="flex align-center gap-10px">
                    <button
                      className={`btn`}
                      onClick={() => handleView(activity._id)}
                    >
                      View
                    </button>
                    <button
                      className={`btn ${
                        selected?._id === activity._id && 'selected-activity'
                      }`}
                      onClick={() => setSelected(activity)}
                    >
                      {selected?._id === activity._id ? 'Selected' : 'Select'}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      {activityModal && (
        <ViewActivity
          modalOpen={activityModal}
          modalClose={setActivityModal}
          type={'specific'}
          activityId={activityId}
        />
      )}
    </>
  )
}

export default SpecificActivtyTab
