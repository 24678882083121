import React, { useState } from 'react'
import { Col, List, Row } from 'antd'
import AppLayout from '../../components/layout/layout'
import Setting from '../../assets/Images/Setting.svg'
import UserCircleGear from '../../assets/Images/UserCircleGear.svg'
import CreditCard from '../../assets/Images/CreditCard.svg'
import Permission from './Permission'
import GeneralSetting from './GeneralSetting'
import Notification from './Notification'
import Payment from './PaymentMethod/Payment'
import Rewards from './Rewards'
import AppInformation from './AppInformation'
import BellSimple from '../../assets/Images/BellSimple.svg'
import Medal from '../../assets/Images/Medal.svg'
import { ListPlus } from '@phosphor-icons/react'
import AllListings from './Listings/AllListings'
const Settings = ({ match }: any) => {
  const [settingOption, setSettingOption] = useState('/GeneralSetting')
  const data = [
    {
      icon: <img alt="" src={Setting} />,
      title: <b className="b1 setting-optn-tab">General Settings</b>,
      Description: 'General Settings',
      path: '/GeneralSetting',
    },
    {
      icon: <img alt="" src={UserCircleGear} />,
      title: <b className="b1 setting-optn-tab">Permissions</b>,
      Description: 'Manage roles permissions',
      path: '/Permission',
    },
    {
      icon: <img alt="" src={BellSimple} />,
      title: <b className="b1 setting-optn-tab">Notifications</b>,
      Description: 'Push notifications settings',
      path: '/Notification',
    },
    {
      icon: <img alt="" src={CreditCard} />,
      title: <b className="b1 setting-optn-tab">Payment method</b>,
      Description: 'Manage roles permissions',
      path: '/Payment',
    },
    {
      icon: <img alt="" src={Medal} />,
      title: <b className="b1 setting-optn-tab">Rewards</b>,
      Description: 'Add kids rewards',
      path: '/Rewards',
    },
    {
      icon: <img alt="" src={CreditCard} />,
      title: <b className="b1 setting-optn-tab">App information </b>,
      Description: 'Manage general app details',
      path: '/AppInformation',
    },
    {
      icon: <ListPlus size={20} color="#000" />,
      title: <b className="b1 setting-optn-tab">Listings</b>,
      Description: 'Manage all listings',
      path: '/listings',
      arrow: true,
    },
  ]

  const SettingHandler = (path: any | null | undefined) => {
    switch (path) {
      case '/GeneralSetting':
        return <GeneralSetting />
      case '/Permission':
        return <Permission />
      case '/Notification':
        return <Notification />
      case '/Payment':
        return <Payment />
      case '/Rewards':
        return <Rewards />
      case '/AppInformation':
        return <AppInformation />
      case '/listings':
        return <AllListings />
    }
  }
  return (
    <AppLayout>
      <div className=" page-header main-height setting-main-page">
        <Row gutter={{ sm: 8, md: 16, lg: 24 }} style={{ width: '100%' }}>
          <Col xl={{ span: 16 }} xs={{ span: 14 }}>
            {SettingHandler(settingOption)}
          </Col>
          <Col xl={{ span: 8 }} xs={{ span: 10 }}>
            <List
              className="setting-list"
              itemLayout="horizontal"
              dataSource={data}
              renderItem={item => (
                <List.Item
                  className={
                    item.path === settingOption
                      ? 'setings_active_menu pl-20 pt-20 pb-20 pr-20'
                      : 'cursor_pointer pl-20 pt-20 pb-20 pr-20'
                  }
                  onClick={() => setSettingOption(item.path)}
                >
                  <List.Item.Meta
                    avatar={item.icon}
                    title={<b className="b2">{item.title}</b>}
                    description={item.Description}
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </div>
    </AppLayout>
  )
}
export default Settings
