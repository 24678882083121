import { FormInstance, Select } from 'antd'
import { useGetColors } from 'api/Listings/Color'
import Spinner from 'components/Spinner'
import React, { useEffect, useMemo, useState } from 'react'
import { ColorListing } from 'types/listing/listings'

interface ColorsInputProps {
  form: FormInstance
  data: string | undefined
}

const ColorsInput: React.FC<ColorsInputProps> = ({ form, data }) => {
  const { data: colorsData, isLoading, isFetching, refetch } = useGetColors()

  useEffect(() => {
    refetch()
  }, [])

  const setColors = () => {
    if (colorsData) {
      return colorsData.map((color: ColorListing) => {
        return {
          key: color._id,
          name: color.code,
          color: color.code,
        }
      })
    }
  }

  const colors = useMemo(() => setColors(), [colorsData])

  return (
    <div>
      <Select
        className="h-50px select-field"
        placeholder={'Select color'}
        defaultValue={data}
        onChange={(v, item: { value: string; key: string }) => {
          form.setFieldsValue({
            color: item.value,
          })
        }}
      >
        {isLoading || isFetching ? (
          <div className="py-40px">
            <Spinner />
          </div>
        ) : (
          colors.map((color: { key: string; name: string }) => (
            <Select.Option key={color.key} value={color.name}>
              <div className="flex align-center gap-10px">
                <div
                  className="w-20px h-20px radius-rounded"
                  style={{ backgroundColor: `#${color.name}` }}
                />
                <p style={{ color: `#${color.name}` }}>#{color.name}</p>
              </div>
            </Select.Option>
          ))
        )}
      </Select>
    </div>
  )
}

export default ColorsInput
