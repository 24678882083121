import React, { useState } from 'react'
import { Avatar, Space, Table } from 'antd'
import StatusDropdown from 'helper/StatusDropdown'
import { useHistory } from 'react-router-dom'
import editblackPencil from 'assets/Images/editblackPencil.svg'
import deleteBlackIcon from 'assets/Images/deleteBlackIcon.svg'
import { getNameSignature } from 'helper/utility'
import usePagination from 'helper/usePagination'
import {
  GetAllTeamRespType,
  GetAllTeamsParams,
  TableTypes,
  TeamType,
} from 'types/team/team'
import { teamStore } from 'state/team/team'
import AlertModal from 'helper/AlertModal'
import { useDeleteTeamInOrganization } from 'api/Team/Team'

interface TableViewProps {
  data: GetAllTeamRespType
  params: GetAllTeamsParams
  setParams: React.Dispatch<React.SetStateAction<GetAllTeamsParams>>
}

const TableView: React.FC<TableViewProps> = ({ data, params, setParams }) => {
  const history = useHistory()
  const { setTeamModalVisible, setTeamData, setIsUpdatingTeam } = teamStore(
    store => ({
      setTeamModalVisible: store.setTeamModalVisible,
      setTeamData: store.setTeamData,
      setIsUpdatingTeam: store.setIsUpdatingTeam,
    }),
  )
  const [alertModalVisible, setAlertModalVisible] = useState<boolean>(false)
  const [selectedTeam, setSelectedTeam] = useState<TeamType | undefined>()
  const [paginationInfo, onTableChange] = usePagination({
    totalCount: data?.count || 0,
    currentPage: params.page,
    setParams: setParams,
  })

  const { mutate: deleteTeamMutation, isLoading: deleteTeamMutationLoading } =
    useDeleteTeamInOrganization()

  const handleEditClick = (team: TeamType) => {
    setTeamData(team)
    setIsUpdatingTeam(true)
    setTeamModalVisible(true)
  }

  const handleDeleteClick = (team: TeamType) => {
    setSelectedTeam(team)
    setAlertModalVisible(true)
  }

  const onYesClick = () => {
    deleteTeamMutation(selectedTeam._id, {
      onSuccess: () => {
        setAlertModalVisible(false)
        setSelectedTeam(undefined)
      },
    })
  }

  const tableData = data.records.map((record: TeamType) => ({
    key: record._id,
    name: record.name,
    organizationId: record.organization._id,
    organizationName: record.organization.name,
    organizationAvatar: record.organization.photo?.post,
    status: record.status,
    action: [
      <Space className="orgn-action-btn" key={'edit'}>
        <img
          src={editblackPencil}
          alt="edit"
          width={20}
          onClick={() => handleEditClick(record)}
          className="cursor_pointer"
        />
        <img
          src={deleteBlackIcon}
          alt="delete"
          onClick={() => handleDeleteClick(record)}
          className="cursor_pointer"
        />
      </Space>,
    ],
  }))

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '40%',
      render: (text: string) => <p className="text-14 w-500">{text}</p>,
    },
    {
      title: 'Organization',
      dataIndex: 'organization',
      key: 'organization',
      render: (_, record: TableTypes) =>
        record?.organizationName ? (
          <>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                history.push({
                  pathname: `/organization-profile/${record?.organizationId}`,
                })
              }}
            >
              <Space size={8}>
                <Avatar size={24} src={record.organizationAvatar}>
                  {getNameSignature(record.organizationName)}
                </Avatar>
                {record.organizationName}
              </Space>
            </div>
          </>
        ) : (
          <h3 className="text-12 w-400">N/A</h3>
        ),
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '350px',
      render: (text: 'active' | 'archived', record: TableTypes) => (
        <StatusDropdown id={record.key} name="team-status" status={text} />
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'actions',
      width: 120,
    },
  ]

  return (
    <>
      <Table
        className="colored_header_table"
        columns={columns}
        dataSource={tableData}
        pagination={
          data.hasNextPage || data.hasPrevPage
            ? { ...paginationInfo, pageSize: params.limit }
            : false
        }
        onChange={onTableChange}
      />
      {alertModalVisible && (
        <AlertModal
          isModalVisible={alertModalVisible}
          setIsModalVisible={setAlertModalVisible}
          data={{
            name: 'Team',
            heading: `Delete the Team ${selectedTeam.name}`,
            message1: `Are you sure you want to delete?`,
          }}
          onYesClick={onYesClick}
          loading={deleteTeamMutationLoading}
        />
      )}
    </>
  )
}

export default TableView
