import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  axiosDelete,
  axiosGet,
  axiosPatch,
  axiosPost,
  axiosPut,
} from 'Redux/axiosHelper'
import { FamilyMemberAdult } from 'types/family/adult'
import { FamilyFullResp } from 'types/family/family'
import {
  CreateKidPayload,
  FamilyMemberKid,
  KidAdultsResp,
  UpdateKidPayload,
} from 'types/family/kid'
import { PaginatedParams } from 'types/globalTypes'

const getMedia = async (kidId: string, params: PaginatedParams) => {
  const response = await axiosGet(`v1/kids/${kidId}/media`, params)
  return response.data
}

export const useGetMedia = (kidId: string, params: PaginatedParams) => {
  const media = useQuery<any>(
    ['kid-media-listing'],
    () => getMedia(kidId, params),
    { enabled: false },
  )
  return media
}

// create kid
const createKid = async ({
  familyId,
  payload,
}: {
  familyId: string
  payload: CreateKidPayload
}) => {
  const response = await axiosPost(payload, `v1/kids/${familyId}/create-kid`)
  return response.data
}

export const useCreateKid = () => {
  const queryClient = useQueryClient()
  return useMutation(createKid, {
    onSuccess: (newKid: FamilyMemberKid) => {
      queryClient.setQueryData(
        ['getFamilyData'],
        (oldData: FamilyFullResp | undefined) => {
          return oldData.kids?.length > 0
            ? {
                ...oldData,
                kids: [...oldData.kids, newKid],
              }
            : {
                ...oldData,
                kids: [newKid],
              }
        },
      )
    },
  })
}

// update kid profile
const updateKid = async ({
  kidId,
  payload,
}: {
  kidId: string
  payload: UpdateKidPayload
}) => {
  const response = await axiosPatch(payload, `v1/kids/${kidId}`)
  return response.data
}

export const useUpdateKid = () => {
  const queryClient = useQueryClient()
  return useMutation(updateKid, {
    onSuccess: (updatedKid: FamilyMemberKid) => {
      queryClient.setQueryData(
        ['getFamilyData'],
        (oldData: FamilyFullResp | undefined) => {
          if (!oldData) return
          if (oldData.kids.length === 0)
            return { ...oldData, kids: [updatedKid] }

          return {
            ...oldData,
            kids: oldData.kids.map((kid: FamilyMemberKid) =>
              kid._id === updatedKid._id ? updatedKid : kid,
            ),
          }
        },
      )
      queryClient.setQueryData(['kid-by-id'], () => {
        return { ...updatedKid }
      })
    },
  })
}

// delete kid
const deleteKid = async (kidId: string) => {
  const response = await axiosDelete(`v1/kids/${kidId}`)
  return response.data
}

export const useDeleteKid = () => {
  const queryClient = useQueryClient()
  return useMutation(deleteKid, {
    onSuccess: (deletedKid: FamilyMemberKid) => {
      queryClient.setQueryData(
        ['getFamilyData'],
        (oldData: FamilyFullResp | undefined) => {
          if (!oldData) return
          if (oldData.kids.length === 0) return { ...oldData, kids: [] }
          return {
            ...oldData,
            kids: oldData.kids.filter(
              (kid: FamilyMemberKid) => kid._id !== deletedKid._id,
            ),
          }
        },
      )
    },
  })
}

// get kid by id
const getKidById = async (kidId: string) => {
  const response = await axiosGet(`v1/kids/${kidId}`)
  return response.data
}

export const useGetKidById = (kidId: string) => {
  const kidData = useQuery<any>(
    ['kid-by-id'],
    () => (kidId ? getKidById(kidId) : null),
    { enabled: false },
  )
  return kidData
}

// todo remove it later
// remove kid from adult
const removeKidFromAdult = async ({
  payload,
  adultId,
}: {
  payload: { kidId: string }
  adultId: string
}) => {
  const response = await axiosPut(
    payload,
    `v1/authorized-adults/${adultId}/remove-kid`,
  )
  return response.data
}
