import CustomModal from 'components/global/CustomModal'
import CustomModalFooter from 'components/global/CustomModalFooter'
import useFormSubmit from 'helper/Hooks/formSubmit'
import React from 'react'
import { highlightedElementStore } from 'state/listing/highlightedElementListing'
import HighlightedElemForm from './ModalContent/HighlightedElemForm'

const HighlightedElemModal: React.FC = () => {
  const { form, handleSubmit } = useFormSubmit()
  const {
    addHighlightedElementModal,
    setAddHighlightedElementModal,
    highlightedElementData,
  } = highlightedElementStore(store => ({
    addHighlightedElementModal: store.addHighlightedElementModal,
    setAddHighlightedElementModal: store.setAddHighlightedElementModal,
    highlightedElementData: store.highlightedElementData,
  }))
  return (
    <>
      <CustomModal
        openModal={addHighlightedElementModal}
        closeModal={setAddHighlightedElementModal}
        title={
          highlightedElementData
            ? 'Edit Highlighted Element'
            : 'Add new Element'
        }
        footer={
          <CustomModalFooter
            multibutton={false}
            btnAlignment="justify-end"
            btnText={highlightedElementData ? 'Update' : 'Create'}
            btnType="primary"
            onSubmit={handleSubmit}
          />
        }
      >
        <HighlightedElemForm form={form} />
      </CustomModal>
    </>
  )
}

export default HighlightedElemModal
