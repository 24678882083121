import React, { useEffect, useState } from 'react'
import { Avatar, Col, DatePicker, Form, FormInstance, Input, Row } from 'antd'
import FileUploader from 'helper/FileUploader'
import PhoneInput from 'react-phone-input-2'
import { validateAlphabets, validatePhone } from 'helper/utility'
import { Address, Phone } from 'types/globalTypes'
import GenderSelect from 'helper/GenderSelect'
import AllergyList from 'helper/AllergyList'
import { organizerStore } from 'state/organizer/organizer'
import MapModal from 'helper/Map/MapModal'
import OrganizerDataType, { OrganizerCreatePayload } from 'types/organizer'
import moment from 'moment'
import { useUpdateOrganizer } from 'api/Organizers'
import { myStore } from 'state/store'

interface Step1Props {
  form: FormInstance
}

interface OrganizerStatesProps {
  setOrganerModalVisible: (state: boolean) => void
  setOrganizerCurrentStep: (state: number) => void
  organizerData: OrganizerDataType
  setOrganizerData: (state: OrganizerDataType) => void
  isUpdating: boolean
  setIsUpdating: (state: boolean) => void
}

const Step1: React.FC<Step1Props> = ({ form }) => {
  const {
    setOrganerModalVisible,
    setOrganizerCurrentStep,
    organizerData,
    setOrganizerData,
    isUpdating,
    setIsUpdating,
  }: OrganizerStatesProps = organizerStore(store => ({
    setOrganerModalVisible: store.setOrganizerModalVisible,
    setOrganizerCurrentStep: store.setOrganizerCurrentStep,
    organizerData: store.organizerData,
    setOrganizerData: store.setOrganizerData,
    isUpdating: store.isUpdating,
    setIsUpdating: store.setIsUpdating,
  }))
  const setModalBtnLoader = myStore(store => store.setModalBtnLoader)
  const [avatar, setAvatar] = useState<string>('')
  const [mapModalVisible, setMapModalVisible] = useState(false)
  const [mapModalData, setMapModalData] = useState<Address>(
    organizerData ? organizerData.address : undefined,
  )
  const [phone, setPhone] = useState<Phone>({
    dialCode: organizerData ? organizerData.phone?.dialCode : '',
    isoCode: organizerData ? organizerData.phone?.isoCode : '',
    number: organizerData ? organizerData.phone?.number : '',
  })
  const [allergies, setAllergies] = useState([])

  const { mutate: updateOrganizerMutation, isLoading: updateOrganizerLoading } =
    useUpdateOrganizer()

  const formSchema = {
    photoId: organizerData ? organizerData?.photoId : undefined,
    firstName: organizerData ? organizerData?.firstName : undefined,
    middleName: organizerData ? organizerData?.middleName : undefined,
    nickName: organizerData ? organizerData?.nickName : undefined,
    lastName: organizerData ? organizerData.lastName : undefined,
    dob:
      organizerData && organizerData?.dob
        ? moment(organizerData.dob)
        : undefined,
    phone:
      organizerData && organizerData.phone?.number
        ? organizerData.phone.number
        : undefined,
    gender: organizerData ? organizerData?.gender : undefined,
    email: organizerData ? organizerData.email : undefined,
    allergies: organizerData ? organizerData.allergies : [],
    medicalNotes: organizerData ? organizerData?.medicalNotes : undefined,
  }

  const onFinish = (values: OrganizerCreatePayload) => {
    const payload = { ...values }
    payload.address = mapModalData ? mapModalData : undefined
    payload.phone = phone.number && phone.number.length > 4 ? phone : undefined
    payload.dob = values.dob
      ? moment(values.dob).format('YYYY-MM-DD')
      : undefined

    if (!isUpdating) {
      setOrganizerData(payload as any)
      form.resetFields()
      setOrganizerCurrentStep(1)
    } else {
      updateOrganizerMutation(
        { organizerId: organizerData._id, payload },
        {
          onSuccess: () => {
            setOrganizerCurrentStep(0)
            setOrganizerData(null)
            setIsUpdating(false)
            setOrganerModalVisible(false)
          },
        },
      )
    }
  }

  useEffect(() => {
    setModalBtnLoader(updateOrganizerLoading)
  }, [updateOrganizerLoading])

  return (
    <div className="mt-20px">
      <Form
        name="organizer-creation-form"
        initialValues={formSchema}
        form={form}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
      >
        <Row gutter={24} align="middle">
          <Col span={4} className="mb-10px">
            <Avatar size={80} src={avatar ? avatar : ''}></Avatar>
          </Col>
          <Col span={18} className="">
            <Form.Item name="photoId">
              <FileUploader
                setAvatar={setAvatar}
                name="adult"
                form={form}
                fileId={
                  organizerData && organizerData?.photoId
                    ? organizerData.photoId
                    : undefined
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label className="input-label required-input">Email</label>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: 'Email is required' },
                { type: 'email', message: 'Email format is not right' },
              ]}
            >
              <Input
                placeholder="example@gmail.com"
                type="email"
                name="email"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label className="input-label required-input">Phone number</label>
            <Form.Item
              name="phone"
              className="phone-number-form"
              rules={[
                { required: true, message: 'Phone number is required' },
                { validator: validatePhone },
              ]}
            >
              <PhoneInput
                country={phone?.isoCode || 'us'}
                onChange={(value: string, data: any) => {
                  setPhone({
                    dialCode: '+' + data?.dialCode,
                    number: '+' + value,
                    isoCode: data.countryCode.toUpperCase(),
                  })
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} className="mt-10px">
          <Col span={12}>
            <label className="input-label required-input">FirstName</label>
            <Form.Item
              colon={false}
              name="firstName"
              rules={[
                { required: true, message: 'First Name required' },
                { validator: validateAlphabets },
              ]}
            >
              <Input placeholder="Enter First Name" required name="firstname" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label className="input-label required-input">Last Name</label>
            <Form.Item
              colon={false}
              name="lastName"
              rules={[
                { required: true, message: 'Last Name required' },
                { validator: validateAlphabets },
              ]}
            >
              <Input placeholder="Enter Last Name" required name="lastName" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <label className="input-label">Middle Name</label>
            <Form.Item colon={false} name="middleName">
              <Input placeholder="Enter Middle Name" name="middleName" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label className="input-label">Preferred Name</label>
            <Form.Item colon={false} name="nickName">
              <Input placeholder="Enter Preferred Name" name="nickName" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24} className="mt-10px">
            <label className="input-label">Gender</label>
            <Form.Item name="gender">
              <GenderSelect
                form={form}
                data={
                  organizerData && organizerData.gender
                    ? organizerData.gender
                    : undefined
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label className="input-label">Date of Birth</label>
            <Form.Item name="dob" required>
              <DatePicker name="dob" className="selector w-full" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label className="input-label">Address</label>
            <Form.Item>
              <Input
                placeholder="Location"
                onClick={() => {
                  setMapModalVisible(true)
                }}
                value={
                  mapModalData
                    ? mapModalData.city + ',' + mapModalData.state
                    : ''
                }
              />
              {mapModalVisible && (
                <MapModal
                  setMapModalVisible={setMapModalVisible}
                  setMapModalData={setMapModalData}
                  mapData={mapModalData}
                />
              )}{' '}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label className="input-label ">Allergies</label>
            <Form.Item
              name="allergies"
              className="form-control allergies-field "
            >
              <AllergyList
                form={form}
                setAllergies={setAllergies}
                data={
                  organizerData && organizerData.allergies
                    ? organizerData.allergies
                    : []
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <label className="input-label">Medical Notes</label>
            <Form.Item name="medicalNotes">
              <Input
                placeholder="Ex.: Asthma, Lactose intolerant"
                name="medicalNotes"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Step1
