import { useHistory } from 'react-router'
import ArrowRightw from '../../../../../assets/Images/ArrowRightw.svg'
import CategoryImage from '../../../../../helper/CategoryImageLink'
import star from '../../../../../assets/Images/star.png'
import availble from '../../../../../assets/Images/spotsAvailable.png'
import waitlist from '../../../../../assets/Images/waitlist.png'
import pending from '../../../../../assets/Images/pending.png'
import almost from '../../../../../assets/Images/almost.svg'
import limited from '../../../../../assets/Images/limited.svg'
import dummy from '../../../../../assets/Images/No-image-found.jpeg'
import clock from '../../../../../assets/Images/Clock.svg'
import { Avatar, Button, Dropdown, Popover } from 'antd'
import Down from '../../../../../helper/Icons/DownIcon'
import {
  capitalizeFirstLetter,
  convertToLocal,
  getNameSignature,
} from '../../../../../helper/utility'
import ManageButton from '../SingleCard/ManageButton'
import { Link } from 'react-router-dom'
import { getEventTimeArray } from '../SingleCard/TimeDropdown'
import { CommonEventResp } from 'types/event/events'
import { Clock, MapPin } from '@phosphor-icons/react'

interface EventTime {
  date: string
  startTime: string
  endTime: string
}

const CommonEventCard = ({ event }: { event: CommonEventResp }) => {
  const imgSrc =
    event?.seatsStatus === 'Pending Confirmation'
      ? pending
      : event?.seatsStatus === 'Limited seats left'
      ? limited
      : event?.seatsStatus === 'Almost Full'
      ? almost
      : event?.seatsStatus === 'Waitlist'
      ? waitlist
      : event?.seatsStatus === 'Spots Available'
      ? availble
      : ''
  const colors =
    event?.seatsStatus === 'Pending Confirmation'
      ? '#FEF4E8'
      : event?.seatsStatus === 'Limited seats left'
      ? '#E7F0F8'
      : event?.seatsStatus === 'Almost Full'
      ? '#E8E9F7'
      : event?.seatsStatus === 'Waitlist'
      ? '#FFE2E0'
      : event?.seatsStatus === 'Spots Available'
      ? '#F1F8E7'
      : ''

  const eventTimeArr = getEventTimeArray(event)
  const items = [
    {
      key: '1',
      label: eventTimeArr.map((item: EventTime, index: number) => {
        return (
          <div key={index}>
            <div className="flex justify-between">
              <h4 className="text-12 w-400 w-40p">{item.date}</h4>
              <div className="flex justify-between w-50p">
                <div className="w-10p mr-10px">
                  <img src={clock} alt="time" className="w-25px" />
                </div>
                <div className="w-80p text-start">
                  <h4 className="text-10 w-400">
                    {item.startTime} - {item.endTime}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        )
      }),
    },
  ]

  if (event.type === 'community') {
    return (
      <div className="shadow py-10px px-20px radius-16 mt-15px">
        <Link to="#">
          <h3>{event.name}</h3>
        </Link>
        <div className="flex justify-between align-center mt-5px">
          <Link to={`#`}>
            <div className="flex align-center">
              <Avatar
                src={event?.organization?.photo?.thumb}
                alt="organizationImg"
              >
                {getNameSignature(event?.organization?.name)}
              </Avatar>
              <h4 className="ml-10px text-14px w-500">
                {event?.organization?.name}
              </h4>
            </div>
          </Link>
          <div className="flex align-center justify-center">
            <Link to={`#`}>
              <h4 className="prim-txt w-500 mr-20px text-center text-12 pointer">
                View Details
              </h4>
            </Link>
            <ManageButton
              id={event?.entityId}
              name={event.name}
              type={event.type}
            />
          </div>
        </div>
        <div className="flex gap-10px mt-10px">
          <MapPin size={20} color="#000" />
          <h5>{`${event.location.title}: ${event.location.street}, ${event.location.city}, ${event.location.state}`}</h5>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="w-full flex mt-30px align-center">
        {/* <Link to={{ pathname: `/events-details/${event?._id}` }}> */}
        <Link
          to={{
            pathname:
              event.type === 'event'
                ? `/events-details/${event.entityId}`
                : '#',
          }}
        >
          <img
            src={event?.coverPhotos[0] ? event?.coverPhotos[0]?.thumb : dummy}
            alt="event"
            style={{ width: '350px', height: '180px', borderRadius: '8px' }}
          />
        </Link>
        <div className="w-full ml-30px">
          <div className="flex align-center">
            {event.type === 'event' || event.type === 'league' ? (
              <Popover
                content={
                  event.type === 'event'
                    ? event.category.name
                    : event.sport.name
                }
                placement="bottom"
                className="event-popover"
              >
                <img
                  src={
                    event.type === 'event'
                      ? event.category.icon.post
                      : event.sport.icon
                  }
                  alt="categorie"
                  className="w-40px"
                />
              </Popover>
            ) : null}
            <Link
              to={{
                pathname: `${
                  event.type === 'event'
                    ? `/events-details/${event.entityId}`
                    : '#'
                }`,
              }}
            >
              <h2 className="ml-10px text-18 w-600">
                {capitalizeFirstLetter(event.name)}
              </h2>
            </Link>
          </div>

          <div className="flex align-center  mt-20px event-detail">
            <div className="seat flex align-center ml-5px">
              <div
                style={{ backgroundColor: colors, width: '220px' }}
                className="flex align-center radius-8 px-10px py-5px justify-center"
              >
                <Avatar src={imgSrc} alt="status" size={24}>
                  Stat
                </Avatar>
                <h4 className="ml-10px text-12 w-600">{event?.seatsStatus}</h4>
              </div>
            </div>
            <Dropdown
              placement="bottom"
              menu={{
                items: items,
              }}
              overlayStyle={{ width: '300px' }}
            >
              <Button className="date">
                <div className="flex">
                  <span>
                    {convertToLocal(event?.startDate, 'MMM')}
                    <b className="bold">
                      {convertToLocal(event?.startDate, 'D')}
                    </b>
                  </span>
                  <img className="arrow" src={ArrowRightw} alt="" />
                  <span>
                    {convertToLocal(event?.endDate, 'MMM')}
                    <b className="bold">
                      {convertToLocal(event?.endDate, 'D')}
                    </b>
                  </span>
                  <Down top={'2px'} />
                </div>
              </Button>
            </Dropdown>
            <div className="border-1px px-30px py-7px radius-6 ml-10px">
              <h3 className="w-600 text-12">
                {capitalizeFirstLetter(event?.status)}
              </h3>
            </div>
          </div>
          <div className="flex justify-between align-center mt-20px">
            <Link to={`/organization-profile/${event?.organization?._id}`}>
              <div className="flex align-center">
                <Avatar
                  src={event?.organization?.photo?.thumb}
                  alt="organizationImg"
                >
                  {getNameSignature(event?.organization?.name)}
                </Avatar>
                <h4 className="ml-10px text-14px w-500">
                  {event?.organization?.name}
                </h4>
              </div>
            </Link>
            <div className="flex align-center justify-center">
              <Link
                to={`${
                  event.type === 'event'
                    ? `/events-details/${event.entityId}`
                    : '#'
                }`}
              >
                <h4 className="prim-txt w-500 mr-20px text-center text-12 pointer">
                  View Details
                </h4>
              </Link>
              <ManageButton
                type={event.type}
                id={event?.entityId}
                name={event.name}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CommonEventCard
