import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { Card, Row, Col, Result, Button } from 'antd'
import { ConnectedRouter } from 'connected-react-router'
import Organazation from './pages/Organization'
import Mentors from './pages/Mentors'
import Dashboard from './pages/Dashboard'
import Login from './pages/Auth/login'
import Family from './pages/Family/index'
import Events from './pages/Events'
import JobRequest from './pages/JobRequest'
import Analytics from './pages/Analytics'
import Newsfeed from './pages/Newsfeed'
import Location from './pages/Location'
import Inventory from './pages/Inventory/index'
import Activity from './pages/Activity/index'
import Settings from './pages/Settings'
import AdminProfile from './pages/Settings/Admin/AdminProfile'
import Signup from './pages/Auth/Signup'
import OrganizationProfile from './pages/Organization/Profiles/OrganizationProfile'
import OraganazationModel from './pages/Modals/OrganizationModal'
import ProposedMentors from './pages/JobRequest/Profiles/ProposedMentors/ProposedMentors'
import MentorProfile from './pages/Mentors/Profiles/Mentor/MentorProfile'
import ParentProfile from './pages/Family/Profiles/GuardianProfile/index'
import FamilyProfile from './pages/Family/Profiles/FamilyProfile/FamilyProfile'
import KidProfile from './pages/Family/Profiles/KidProfile/KidProfileEdit/KidProfile'
import KidDetails from './pages/Family/Profiles/KidProfile/KidDetails/kidDetails'
import Kid from './pages/Family/Profiles/KidProfile'
import CoachProfile from './pages/Mentors/Profiles/Coach/CoachProfile'
import AskQuestions from './pages/Settings/AskQuestions'
import EventsDetails from './pages/Events/Profiles'
import CreateEvent from './pages/Events/Views/CreateEvent/CreateEvent'
import Organizer from './pages/Organizer'
import OrganizerProfile from './pages/Organizer/Profiles/OrganizerProfile'
import CalenderApp from './pages/Calender'
import Jobs from './pages/Jobs'
import Sessions from './pages/Sessions'
import SessionDetail from './pages/Sessions/Profiles/SessionDetail'
import PhoneLogin from './pages/Auth/PhoneLogin'
import PhoneVerify from './pages/Auth/PhoneVerify'
import { history } from './Redux/app-store'
import ProposeMentor from './pages/JobRequest/ProposeMentor'
import RequestDetails from './pages/JobRequest/RequestDetails/index'
import UploadPhoto from './pages/Settings/Admin/UploadNewPhotoPopup'
import LiveUpdateModal from './pages/Modals/EventsModal/EventDetails/LiveUpdate/LiveUpdateModal'
import KidsAttending from './components/Events/ParticipantsAttending/index'
import Adult from './pages/Family/Profiles/AdultProfile'
import CreateLeague from 'pages/Events/Views/CreateLeague/CreateLeague'
import Teams from 'pages/Teams'
import EventChats from 'pages/Events/Chats'
import SpecificListingPage from 'pages/Settings/Listings/SpecificListingPage'
import CreateCommunity from 'pages/Events/Views/CreateCommunity/CreateCommunity'
import CreateExternal from 'pages/Events/Views/CreateExternal/CreateExternal'

const NoMatchPage = () => {
  return (
    <Row className="margin-top">
      <Col xs={{ span: 12, offset: 6 }}>
        <Card>
          <div className="card-body">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={
                  <Button
                    type="primary"
                    onClick={() => {
                      history.push('/')
                    }}
                  >
                    Back Home
                  </Button>
                }
              />
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  )
}

const ErrorPage = (error: any) => {
  return (
    <Row className="margin-top">
      <Col xs={{ span: 12, offset: 6 }}>
        <Card>
          <div className="card-body">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <Result
                status={error.error.statusCode}
                title={error.error.statusCode}
                subTitle={error.error.message}
                extra={
                  <Button
                    type="primary"
                    onClick={() => {
                      history.goBack()
                    }}
                  >
                    Back
                  </Button>
                }
              />
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  )
}

const RestrictedRoute = ({
  component: Component,
  error,
  isLoggedIn,
  ...rest
}: any) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        error ? (
          <ErrorPage error={error} />
        ) : (
          <Component {...props} />
        )
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location },
          }}
        />
      )
    }
  />
)

const Router = ({ history, isLoggedIn, error }: any) => {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" exact={true} component={Login} />
        <RestrictedRoute
          path="/dashboard"
          component={Dashboard}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/manage-listing/:id"
          exact={true}
          component={SpecificListingPage}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/organization"
          exact={true}
          component={Organazation}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/mentors"
          exact={true}
          component={Mentors}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/events/create-event"
          exact={true}
          component={CreateEvent}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/events/update-event/:id"
          exact={true}
          component={CreateEvent}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/events/create-league"
          exact={true}
          component={CreateLeague}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/events/update-league/:id"
          exact={true}
          component={CreateLeague}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/create-community"
          exact={true}
          component={CreateCommunity}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/update-community/:id"
          exact={true}
          component={CreateCommunity}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/create-external"
          exact={true}
          component={CreateExternal}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/update-external/:id"
          exact={true}
          component={CreateExternal}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/mentor-profile/:id"
          exact={true}
          component={MentorProfile}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/family"
          exact={true}
          component={Family}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/events"
          exact={true}
          component={Events}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/events/:id/attendess"
          exact={true}
          component={KidsAttending}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/jobrequest"
          exact={true}
          component={JobRequest}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/propose-mentor"
          exact={true}
          component={ProposeMentor}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/RequestDetails/:id"
          exact={true}
          component={RequestDetails}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/analytics"
          exact={true}
          component={Analytics}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/newsfeed"
          exact={true}
          component={Newsfeed}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/location"
          exact={true}
          component={Location}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/inventory"
          exact={true}
          component={Inventory}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/activity"
          exact={true}
          component={Activity}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/proposed-mentors"
          exact={true}
          component={ProposedMentors}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/settings"
          exact={true}
          component={Settings}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/admin-profile"
          exact={true}
          component={AdminProfile}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/change"
          exact={true}
          component={UploadPhoto}
          isLoggedIn={isLoggedIn}
        />
        <Route path="/Signup" exact={true} component={Signup} />

        <RestrictedRoute
          path="/organization-profile/:id"
          exact={true}
          component={OrganizationProfile}
          isLoggedIn={isLoggedIn}
        />
        <Route path="/test" exact={true} component={LiveUpdateModal} />
        <RestrictedRoute
          path="/mentor-profile"
          exact={true}
          component={MentorProfile}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/parent-profile/:id"
          exact={true}
          component={ParentProfile}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/family-profile/:id"
          exact={true}
          component={FamilyProfile}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/kid-profile"
          exact={true}
          component={KidProfile}
          isLoggedIn={isLoggedIn}
        />

        <RestrictedRoute
          path="/coach-profile"
          exact={true}
          component={CoachProfile}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/kid-details"
          exact={true}
          component={KidDetails}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/kid-profile/:id"
          exact={true}
          component={Kid}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/adult-profile/:id"
          exact={true}
          component={Adult}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/askQuestions"
          exact={true}
          component={AskQuestions}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/events-details/:id"
          exact={true}
          component={EventsDetails}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/event-chat/:id"
          exact={true}
          component={EventChats}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/organizer"
          exact={true}
          component={Organizer}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/organizer-profile/:id"
          exact={true}
          component={OrganizerProfile}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/teams"
          exact={true}
          component={Teams}
          isLoggedIn={isLoggedIn}
        />

        {/* <RestrictedRoute
          path="/otherAccount"
          exact={true}
          component={OtherAccount}
          isLoggedIn={isLoggedIn}
        /> */}

        <RestrictedRoute
          path="/organization-modal"
          exact={true}
          component={OraganazationModel}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/sessions"
          exact={true}
          component={Sessions}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/jobs"
          exact={true}
          component={Jobs}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/calendar"
          exact={true}
          component={CalenderApp}
          isLoggedIn={isLoggedIn}
        />
        <RestrictedRoute
          path="/sessiondetails"
          exact={true}
          component={SessionDetail}
          isLoggedIn={isLoggedIn}
        />
        <Route path="/phonelogin" exact={true} component={PhoneLogin} />
        <RestrictedRoute
          path="/phoneverify"
          exact={true}
          component={PhoneVerify}
          isLoggedIn={isLoggedIn}
        />
        <Route path="*" component={NoMatchPage} />
      </Switch>
    </ConnectedRouter>
  )
}
export default connect((state: any) => ({
  isLoggedIn: state.auth.authenticated,
  error: state.failure.error,
}))(Router)
