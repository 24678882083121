import React from 'react'
import { Checkbox, Col, Form, FormInstance, Input, Row } from 'antd'
interface WaitTimeProps {
  form: FormInstance
  data: any | undefined // Replace `any` with the appropriate type
}

const WaitTime: React.FC<WaitTimeProps> = ({ form, data }) => {
  return (
    <Row className="mt-20px">
      <Col span={24}>
        <label className="input-label text-16 required-input mb-10px">
          Wait Time
        </label>

        {/* Maximum Wait Time Section */}
        <div className="flex gap-40px">
          <span className="text-14 w-600 pt-15px">Maximum</span>
          <div className="flex align-center justify-between gap-16px">
            {['days', 'hours', 'mins'].map(unit => (
              <Form.Item
                key={`max-${unit}`}
                className="form-control mb-0"
                style={{ maxWidth: '130px' }}
              >
                <div className="flex flex-col align-center gap-5px">
                  <Form.Item
                    name={['waitTime', 'max', unit]}
                    rules={[
                      {
                        required: true,
                        message: `Maximum ${unit} is required`,
                      },
                    ]}
                    noStyle
                  >
                    <Input
                      type="number"
                      placeholder="0"
                      className="text-end radius-12px"
                      onWheel={e => e.currentTarget.blur()}
                    />
                  </Form.Item>
                  <p className="input-label">
                    {unit.charAt(0).toUpperCase() + unit.slice(1)}
                  </p>
                </div>
              </Form.Item>
            ))}
          </div>
        </div>

        {/* Minimum Wait Time Section */}
        <div className="flex gap-40px mt-15px">
          <span className="text-14 w-600 pt-15px">Minimum</span>
          <div className="flex align-center justify-between gap-16px">
            {['days', 'hours', 'mins'].map(unit => (
              <Form.Item
                key={`min-${unit}`}
                className="form-control mb-0"
                style={{ maxWidth: '130px' }}
              >
                <div className="flex flex-col align-center gap-5px">
                  <Form.Item
                    name={['waitTime', 'min', unit]}
                    rules={[
                      {
                        required: true,
                        message: `Minimum ${unit} is required`,
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      placeholder="0"
                      className="text-end radius-12px"
                      onWheel={e => e.currentTarget.blur()}
                    />
                  </Form.Item>
                  <p className="input-label">
                    {unit.charAt(0).toUpperCase() + unit.slice(1)}
                  </p>
                </div>
              </Form.Item>
            ))}
          </div>
        </div>
      </Col>

      {/* Checkbox for skipping playoffs */}
      <Col span={24} className="mt-15px">
        <div className="d-flex align-items-center checkbox-container">
          <Form.Item
            name={['waitTime', 'skipForPlayOff']}
            valuePropName="checked"
          >
            <Checkbox>
              <div className="w-600 text-14 mt-8px">Skip for play offs</div>
            </Checkbox>
          </Form.Item>
        </div>
      </Col>
    </Row>
  )
}

export default WaitTime
