import React from 'react'
import { Col, Form, FormInstance, Input, Row } from 'antd'
import { useCreateGrade, useUpdateGrade } from 'api/Listings/Grades'
import { myStore } from 'state/store'
import { gradeStore } from 'state/listing/gradeListing'

interface GradeFormProps {
  form: FormInstance
}

const GradeForm: React.FC<GradeFormProps> = ({ form }) => {
  const setModalBtnLoading = myStore(store => store.setModalBtnLoader)
  const { setAddGradeModal, gradeData } = gradeStore(store => ({
    setAddGradeModal: store.setAddGradeModal,
    gradeData: store.gradeData,
  }))
  const { mutate: createGrade } = useCreateGrade()
  const { mutate: updateGrade } = useUpdateGrade()

  const formSchema = {
    name: gradeData ? gradeData.name : undefined,
  }

  const onFinish = (values: { name: string }) => {
    setModalBtnLoading(true)

    if (gradeData) {
      updateGrade(
        { gradeId: gradeData._id, payload: values },
        {
          onSuccess: () => {
            setModalBtnLoading(false)
            setAddGradeModal(false)
          },
        },
      )
    } else {
      createGrade(values, {
        onSuccess: () => {
          setModalBtnLoading(false)
          setAddGradeModal(false)
        },
      })
    }
  }
  return (
    <div>
      <Form
        name="grade-form"
        initialValues={formSchema}
        form={form}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
      >
        <Row className="mt-10px">
          <Col span={24}>
            <label className="input-label required-input">Name</label>
            <Form.Item
              colon={false}
              name="name"
              rules={[{ required: true, message: 'Name required' }]}
            >
              <Input placeholder="Enter Name" required name="name" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default GradeForm
