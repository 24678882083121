import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { axiosGet, axiosPatch, axiosPost } from 'Redux/axiosHelper'
import {
  ActivityCategoryListing,
  CreateActivityCategoryPayload,
} from 'types/listing/listings'

const getActivityCategories = async () => {
  const response = await axiosGet(`v1/activityCategory`)
  return response.data
}
export const useGetActivityCategories = () => {
  const activityCategoryListing = useQuery<ActivityCategoryListing[]>(
    ['activity-category-listing'],
    getActivityCategories,
    { enabled: false },
  )
  return activityCategoryListing
}

// create new activity category
const createActivityCategory = async (
  payload: CreateActivityCategoryPayload,
) => {
  const response = await axiosPost(payload, `v1/activityCategory`)
  return response.data
}

export const useCreateActivityCategory = () => {
  const queryClient = useQueryClient()
  return useMutation(createActivityCategory, {
    onSuccess: (newActivityCategory: ActivityCategoryListing) => {
      queryClient.setQueryData(
        ['activity-category-listing'],
        (oldData: ActivityCategoryListing[] | undefined) => {
          if (!oldData) return [newActivityCategory]
          return [...oldData, newActivityCategory]
        },
      )
    },
  })
}

// update category
const updateActivityCategory = async ({
  activityCategoryId,
  payload,
}: {
  activityCategoryId: string
  payload: CreateActivityCategoryPayload
}) => {
  const response = await axiosPatch(
    payload,
    `v1/activityCategory/${activityCategoryId}`,
  )
  return response.data
}

export const useUpdateActivityCategory = () => {
  const queryClient = useQueryClient()
  return useMutation(updateActivityCategory, {
    onSuccess: (updatedData: ActivityCategoryListing) => {
      queryClient.setQueryData(
        ['activity-category-listing'],
        (oldData: ActivityCategoryListing[] | undefined) => {
          if (!oldData) return [updatedData]
          return oldData.map((activityCategory: ActivityCategoryListing) =>
            activityCategory._id === updatedData._id
              ? updatedData
              : activityCategory,
          )
        },
      )
    },
  })
}
