import IconsTextNavigation from 'components/global/IconsTextNavigation'
import React from 'react'

const Agreements: React.FC = () => {
  return (
    <>
      <div>
        <IconsTextNavigation iconName="Checks" heading="Agreements" />
      </div>
    </>
  )
}

export default Agreements
