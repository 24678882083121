import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Input, Select, Checkbox } from 'antd'
import { creationEventStore } from 'state/event/eventCreationStore'
import Loader from 'helper/Loader'
import ButtonSpinner from 'components/global/ButtonSpinner'
import {
  useGetActivityGrade,
  useCustomActivityStep2,
} from 'api/EventCreation/CreationStep2'
import {
  ActivityListType,
  ActivityStep2Payload,
  defaultActivitiesResp,
} from 'types/event/events'
import './style/styles.scss'

const { Option } = Select

interface Step2Props {
  handleBack: () => void
  handleNext: () => void
  data?: defaultActivitiesResp
}

const Step2: React.FC<Step2Props> = ({ handleBack, handleNext, data }) => {
  const [form] = Form.useForm()
  const setCustomActivity = creationEventStore(store => store.setCustomActivity)
  const customActivity: any = creationEventStore(store => store.customActivity)
  const {
    data: gradesData,
    isLoading: gradesLoading,
    isFetching: gradesFetching,
    refetch: gradesRefetch,
  } = useGetActivityGrade()
  const { mutate: createActivityStep2, isLoading: createActivityStep2Loading } =
    useCustomActivityStep2()

  const formSchema = {
    noOfKids: data ? data?.noOfKids : undefined,
    gradeIds: data ? data.gradeIds : [],
    interns: data ? data?.interns : undefined,
    mentors: data ? data?.mentors : undefined,
    m1: data ? data?.m1 : undefined,
    m2: data ? data?.m2 : undefined,
    eventManager: data ? data?.eventManager : false,
  }
  const onFinish = (values: ActivityStep2Payload) => {
    if (customActivity._id) {
      createActivityStep2(
        { payload: values, activityId: customActivity._id },
        {
          onSuccess: data => {
            setCustomActivity(data)
            handleNext()
          },
        },
      )
    }
  }

  useEffect(() => {
    gradesRefetch()
  }, [])

  return (
    <div>
      {gradesLoading || gradesFetching ? (
        <div className="py-30px ">
          <Loader />
        </div>
      ) : (
        <Form
          name="step2"
          initialValues={formSchema}
          form={form}
          onFinish={onFinish}
          layout={'vertical'}
          autoComplete="off"
        >
          <h3 className="text-20px w-600">People</h3>
          <Row>
            <Col className="flex align-center">
              <div
                style={{ width: '100%' }}
                className="relative required-input"
              >
                Number of kids
              </div>
              <Form.Item
                name="noOfKids"
                style={{ width: '20%' }}
                rules={[
                  { required: true, message: 'Number of kids is required' },
                ]}
              >
                <Input
                  type="number"
                  placeholder="0"
                  className="text-end radius-6"
                  onWheel={e => e.currentTarget.blur()}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <label className="input-label required-input">Grade</label>
            </Col>
            <Col style={{ width: '100%' }}>
              <Form.Item
                className="form-control"
                name="gradeIds"
                rules={[{ required: true, message: 'Grade is required' }]}
              >
                <Select
                  mode={'multiple'}
                  className="form-control-field"
                  placeholder="Select"
                >
                  {gradesData.length > 0 &&
                    gradesData.map((item: ActivityListType) => (
                      <Option
                        value={item._id}
                        key={item._id}
                        name={item.name}
                        _id={item._id}
                      >
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <h3 className="text-20px w-600">Staff needed</h3>
          <Row>
            <Col className="flex align-center">
              <div
                style={{ width: '100%' }}
                className="relative required-input"
              >
                Interns
              </div>
              <Form.Item
                name="interns"
                style={{ width: '20%' }}
                rules={[{ required: true, message: 'Interns is required' }]}
              >
                <Input
                  type="number"
                  placeholder="0"
                  className="text-end radius-6"
                  onWheel={e => e.currentTarget.blur()}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col className="flex align-center">
              <div
                style={{ width: '100%' }}
                className="relative required-input"
              >
                Mentors
              </div>
              <Form.Item
                name="mentors"
                style={{ width: '20%' }}
                rules={[{ required: true, message: 'Mentors is required' }]}
              >
                <Input
                  type="number"
                  placeholder="0"
                  className="text-end radius-6"
                  onWheel={e => e.currentTarget.blur()}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col className="flex align-center">
              <div
                style={{ width: '100%' }}
                className="relative required-input"
              >
                M1
              </div>
              <Form.Item
                name="m1"
                style={{ width: '20%' }}
                rules={[{ required: true, message: 'M1 is required' }]}
              >
                <Input
                  type="number"
                  placeholder="0"
                  className="text-end radius-6"
                  onWheel={e => e.currentTarget.blur()}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col className="flex align-center">
              <div
                style={{ width: '100%' }}
                className="relative required-input"
              >
                M2
              </div>
              <Form.Item
                name="m2"
                style={{ width: '20%' }}
                rules={[{ required: true, message: 'M2 is required' }]}
              >
                <Input
                  type="number"
                  placeholder="0"
                  className="text-end radius-6"
                  onWheel={e => e.currentTarget.blur()}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="checkbox">
            <Form.Item name="eventManager" valuePropName="checked">
              <Checkbox name="eventManager">Event manager</Checkbox>
            </Form.Item>
          </Row>

          <div className="mt-10px flex align-center justify-between">
            <button className="create-activity-btn" onClick={handleBack}>
              Back
            </button>
            <button className="confirm-btn" type="submit">
              {createActivityStep2Loading ? (
                <ButtonSpinner color="#000" size={20} />
              ) : (
                'Next'
              )}
            </button>
          </div>
        </Form>
      )}
    </div>
  )
}

export default Step2
