import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { axiosDelete, axiosGet, axiosPost } from 'Redux/axiosHelper'
import { FamilyMemberAdult } from 'types/family/adult'
import { FamilyMemberKid, KidAdultsResp } from 'types/family/kid'

// get kid authorized adults
const getKidAdultsById = async (kidId: string) => {
  const response = await axiosGet(`v1/adult-role/adults/${kidId}`)
  return response.data
}

export const useGetKidAdultsById = (kidId: string) => {
  const kidAdults = useQuery<FamilyMemberAdult[]>(
    ['kid-adults-by-kidId'],
    () => (kidId ? getKidAdultsById(kidId) : null),
    { enabled: false },
  )
  return kidAdults
}

// add adult to the kid
const addAdultToKid = async ({
  kidId,
  payload,
}: {
  kidId: string
  payload: { adultIds: string[] }
}) => {
  const response = await axiosPost(payload, `v1/adult-role/add-adults/${kidId}`)
  return response.data
}
export const useAddAdultToKid = () => {
  const queryClient = useQueryClient()
  return useMutation(addAdultToKid, {
    onSuccess: (updatedData: FamilyMemberAdult[]) => {
      queryClient.setQueryData(
        ['kid-adults-by-kidId'],
        (oldData: FamilyMemberAdult[] | undefined) => {
          if (!oldData) return updatedData
          return [...oldData, ...updatedData]
        },
      )
    },
  })
}

// get adult kids
const getKidsByAdultId = async (adultId: string) => {
  const response = await axiosGet(`v1/adult-role/kids/${adultId}`)
  return response.data
}

export const useGetKidsByAdultId = (adultId: string) => {
  const adultKids = useQuery<FamilyMemberKid[]>(
    ['adult-kids-by-adultId'],
    () => (adultId ? getKidsByAdultId(adultId) : null),
    { enabled: false },
  )
  return adultKids
}

// add kids to authorized adult
const addKidsToAdult = async ({
  adultId,
  payload,
}: {
  adultId: string
  payload: { kidIds: string[] }
}) => {
  const response = await axiosPost(payload, `v1/adult-role/add-kids/${adultId}`)
  return response.data
}
export const useAddkidsToAdult = () => {
  const queryClient = useQueryClient()
  return useMutation(addKidsToAdult, {
    onSuccess: updatedData => {
      queryClient.setQueryData(
        ['adult-kids-by-adultId'],
        (oldData: FamilyMemberKid[] | undefined) => {
          if (!oldData) return updatedData
          return [...oldData, ...updatedData]
        },
      )
    },
  })
}

//remove kid adult association
const deleteKidAdultAssociation = async ({
  kidId,
  adultId,
}: {
  kidId: string
  adultId: string
}) => {
  const response = await axiosDelete(`v1/adult-role/${kidId}/${adultId}`)
  return response.data
}
export const useDeleteKidAdultAssociation = () => {
  const queryClient = useQueryClient()
  return useMutation(deleteKidAdultAssociation, {
    onSuccess: deletedAdult => {
      queryClient.setQueryData(
        ['kid-adults-by-kidId'],
        (oldData: FamilyMemberAdult[] | undefined) => {
          if (!oldData) return
          return oldData.filter(old => old._id !== deletedAdult.adult._id)
        },
      )
      queryClient.setQueryData(
        ['adult-kids-by-adultId'],
        (oldData: FamilyMemberAdult[] | undefined) => {
          if (!oldData) return
          return oldData.filter(old => old._id !== deletedAdult.kid._id)
        },
      )
    },
  })
}
