import React from 'react'
import { Pie, PieConfig } from '@ant-design/charts'

const DonutChart = ({ activities }: any) => {
  if (!activities?.length) {
    return null
  }
  var data = [
    {
      type: activities[0]?.title,
      value: activities[0]?.duration,
    },

    {
      type: activities[1]?.title,
      value: activities[1]?.duration,
    },
    {
      type: activities[2]?.title,
      value: activities[2]?.duration,
    },
    {
      type: activities[3]?.title,
      value: activities[3]?.duration,
    },
    {
      type: activities[4]?.title,
      value: activities[4]?.duration,
    },
  ]
  // color: ["#44D7B6", "#FFA825", "#0091FF", "#F96E3B", "#96EDCC", "#32C5FF"],

  const config: PieConfig = {
    appendPadding: 5,
    color: ['#4D53BF', '#0091FF', '#32C5FF', '#44D7B6', '#96EDCC'],
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.8,
    innerRadius: 0.6,
    pieStyle: {
      fillOpacity: 0.5,
      stroke: 'white',
      lineWidth: 10,
      lineDash: [0, 0],
      strokeOpacity: 1,
      shadowBlur: 10,
      shadowOffsetX: 5,
      shadowOffsetY: 5,
      cursor: 'pointer',
      //r: "50%",
    },
    label: {
      type: 'inner',
      offset: '-50%',
      content: '',
    },
    legend: {
      style: {
        textAlign: 'center',
        fontSize: 18,
      },
    },

    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '18px',
        },
        content:
          ' <div> <h5 className="secondary"> Total hours</h5> <h1> 35:25</h1> </div>',
      },
    },
  }
  return <Pie {...config} />
}

export default DonutChart
