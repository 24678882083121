import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import closeIcon from 'assets/Images/close.svg'
import plusBlue from 'assets/Images/plusBlue.svg'
import ConfirmationModal from './ConfirmationModal'
import { useGetEmailTemplates } from 'api/EmailTemplate'
import { creationEventStore } from 'state/event/eventCreationStore'
import Spinner from 'components/Spinner'
import EmptyData from 'components/global/EmptyData'
import { AddTemplateToEventPayload } from 'types/event/eventStep5'
import {
  useAddEmailtemplateToEvent,
  useRemoveEmailTemplateFromEvent,
} from 'api/EventCreation/creationStep5'
import { EmailTemplateResp } from 'types/globalTypes'
import TemplateInfo from './TemplateInfo'

interface AddTemplateToEventProps {
  openModal: boolean
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
}

const AddTemplateToEvent: React.FC<AddTemplateToEventProps> = ({
  openModal,
  closeModal,
}) => {
  const eventData = creationEventStore(store => store.eventData)
  const setEventData = creationEventStore(store => store.setEventData)
  const [openTemplateModal, setOpenTemplateModal] = useState<boolean>(false)
  const [loaderId, setLoaderId] = useState<string | null>(null)
  const [filteredTemplate, setFilteredTemplates] = useState<
    EmailTemplateResp[]
  >([])
  const [templateData, setTemplateData] = useState<
    EmailTemplateResp | undefined
  >()
  const {
    data: orgEmailTemplates,
    isLoading: orgEmailLoading,
    isFetching: orgEmailFetching,
    refetch: orgEmailRefetch,
  } = useGetEmailTemplates(eventData.organizationId)
  const handleCancel = () => {
    closeModal(false)
  }
  const { mutate: addTemplate } = useAddEmailtemplateToEvent()
  const { mutate: removeTemplate } = useRemoveEmailTemplateFromEvent()

  const handleEditTemplate = (emailTemplate: EmailTemplateResp) => {
    setTemplateData(emailTemplate)
    setOpenTemplateModal(true)
  }

  const handleAddTemplate = () => {
    setTemplateData(null)
    setOpenTemplateModal(true)
  }

  const handleAddToEvent = (
    templateId: string,
    operation: 'add' | 'remove',
  ) => {
    const payload: AddTemplateToEventPayload = {
      fromTemplate: true,
      emailTemplateIds: [templateId],
    }
    setLoaderId(templateId)
    if (operation === 'add') {
      addTemplate(
        { payload, eventId: eventData._id },
        {
          onSuccess: data => {
            setLoaderId(null)
            setEventData(data)
            handleCancel()
          },
          onError: () => {
            setLoaderId(null)
          },
        },
      )
    } else {
      removeTemplate(
        { eventId: eventData._id, templateId: templateId },
        {
          onSuccess: data => {
            setLoaderId(null)
            setEventData(data)
            handleCancel()
          },
          onError: () => {
            setLoaderId(null)
          },
        },
      )
    }
  }

  useEffect(() => {
    orgEmailRefetch()
  }, [eventData.organizationId])

  useEffect(() => {
    if (orgEmailTemplates) {
      setFilteredTemplates(
        orgEmailTemplates.filter(orgTemp =>
          eventData?.emailTemplates.length > 0
            ? !eventData.emailTemplates.some(
                eventTemp => eventTemp.templateId === orgTemp._id,
              )
            : true,
        ),
      )
    }
  }, [orgEmailTemplates])

  return (
    <div>
      <Modal
        open={openModal}
        onCancel={handleCancel}
        className="confirmation-email"
        footer={false}
        width={700}
        centered
      >
        <div className="flex-center pt-20px justify-between ">
          <h2 className="w-600 text-18">Email communication</h2>
          <img
            src={closeIcon}
            alt="close-button"
            onClick={handleCancel}
            className="close button pointer"
          />
        </div>
        <div className="mt-20px h-25rem overflow-auto-y scroller">
          {orgEmailLoading || orgEmailFetching ? (
            <div className="py-30px flex justify-center">
              <Spinner />
            </div>
          ) : filteredTemplate.length > 0 ||
            eventData?.emailTemplates.length > 0 ? (
            <div>
              {filteredTemplate.map(template => {
                return (
                  <div key={template._id}>
                    <TemplateInfo
                      template={template}
                      loaderId={loaderId}
                      isEventTemplate={false}
                      handleAddToEvent={handleAddToEvent}
                      handleEditTemplate={handleEditTemplate}
                    />
                  </div>
                )
              })}
              {eventData?.emailTemplates.map(template => {
                return (
                  <div key={template._id}>
                    <TemplateInfo
                      template={template}
                      loaderId={loaderId}
                      isEventTemplate
                      handleAddToEvent={handleAddToEvent}
                      handleEditTemplate={handleEditTemplate}
                    />
                  </div>
                )
              })}
            </div>
          ) : (
            <div className="h-full flex align-center justify-center">
              <EmptyData message="No templates available" />
            </div>
          )}
        </div>
        <div className="mt-30px flex gap-20px justify-between align-center">
          <button className=" btn-secondary" onClick={handleCancel}>
            cancel
          </button>
          <button
            className="pointer flex align-center gap-16px simple-btn"
            onClick={handleAddTemplate}
          >
            <img src={plusBlue}></img> <p>Add New template</p>
          </button>
        </div>
      </Modal>

      {openTemplateModal && (
        <ConfirmationModal
          openModal={openTemplateModal}
          closeModal={setOpenTemplateModal}
          data={templateData}
        />
      )}
    </div>
  )
}

export default AddTemplateToEvent
