import React from 'react'
import CustomModal from 'components/global/CustomModal'
import { gradeStore } from 'state/listing/gradeListing'
import GradeForm from './ModalContent/GradeForm'
import useFormSubmit from 'helper/Hooks/formSubmit'
import CustomModalFooter from 'components/global/CustomModalFooter'

const GradeModal: React.FC = () => {
  const { form, handleSubmit } = useFormSubmit()
  const { addGradeModal, setAddGradeModal, gradeData } = gradeStore(store => ({
    addGradeModal: store.addGradeModal,
    setAddGradeModal: store.setAddGradeModal,
    gradeData: store.gradeData,
  }))

  return (
    <>
      <CustomModal
        openModal={addGradeModal}
        closeModal={setAddGradeModal}
        title={gradeData ? 'Edit grade' : 'Add new grade'}
        footer={
          <CustomModalFooter
            multibutton={false}
            btnAlignment="justify-end"
            btnText={gradeData ? 'Update' : 'Create'}
            btnType="primary"
            onSubmit={handleSubmit}
          />
        }
      >
        <GradeForm form={form} />
      </CustomModal>
    </>
  )
}

export default GradeModal
