import IconsTextNavigation from 'components/global/IconsTextNavigation'
import React from 'react'

const RequiredDetails: React.FC = () => {
  return (
    <>
      <div>
        <IconsTextNavigation
          iconName="CheckCircle"
          heading="Required details"
        />
      </div>
    </>
  )
}

export default RequiredDetails
