import { TeamType } from 'types/team/team'
import { create } from 'zustand'

const teamCreationStore = (set: any) => ({
  // modals
  teamModalVisible: false,
  setTeamModalVisible: (state: boolean) =>
    set(() => ({ teamModalVisible: state })),

  // datavalues
  teamData: null,
  setTeamData: (state: TeamType) => set(() => ({ teamData: state })),

  // states
  isUpdatingTeam: false,
  setIsUpdatingTeam: (state: boolean) => set(() => ({ isUpdatingTeam: state })),
})

export const teamStore = create(teamCreationStore)
