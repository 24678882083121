import CustomModal from 'components/global/CustomModal'
import CustomModalFooter from 'components/global/CustomModalFooter'
import useFormSubmit from 'helper/Hooks/formSubmit'
import React, { useEffect } from 'react'
import { adultStore } from 'state/family/adult'
import AdultCreate from './ModalContent/createAdult.tsx/AdultCreate'
import { infoMessage } from 'api/global'
import { FamilyMemberAdult } from 'types/family/adult'
import { myStore } from 'state/store'
import { useAddkidsToAdult } from 'api/family/adultRole'

interface CreateAdultPayload {
  familyId: string
}
interface AdultStoreTypes {
  adultCreationModal: boolean
  setAdultCreationModal: (state: boolean) => void
  adultCurrentStep: number
  setAdultCurrentStep: (state: number) => void
  selectedKids: string[]
  setSelectedKids: (state: string[]) => void
  adultData: FamilyMemberAdult
  setAdultData: (state: FamilyMemberAdult) => void
}

const CreateAdult: React.FC<CreateAdultPayload> = ({ familyId }) => {
  const { form, handleSubmit } = useFormSubmit()
  const { mutate: addKidsToAdult, isLoading: addKidsToAdultLoading } =
    useAddkidsToAdult()
  const {
    adultCreationModal,
    setAdultCreationModal,
    adultCurrentStep,
    setAdultCurrentStep,
    selectedKids,
    setSelectedKids,
    adultData,
    setAdultData,
  }: AdultStoreTypes = adultStore(store => ({
    adultCreationModal: store.adultCreationModal,
    setAdultCreationModal: store.setAdultCreationModal,
    adultCurrentStep: store.adultCurrentStep,
    setAdultCurrentStep: store.setAdultCurrentStep,
    selectedKids: store.selctedKids,
    setSelectedKids: store.setSelectedKids,
    adultData: store.adultData,
    setAdultData: store.setAdultData,
  }))
  const { setModalBtnLoader } = myStore(store => ({
    setModalBtnLoader: store.setModalBtnLoader,
  }))

  const handleBack = () => {
    setAdultCurrentStep(0)
  }

  const handleClose = () => {
    setAdultCurrentStep(0)
    setAdultData(null)
    setAdultCreationModal(false)
  }

  const handleAddKids = () => {
    if (selectedKids.length === 0) {
      return infoMessage('Select atleast one kid')
    }
    // todo add kids
    // addKidsToAdult(
    //   {
    //     adultId: adultData._id,
    //     payload: { kidsIds: selectedKids },
    //   },
    //   {
    //     onSuccess: () => {
    //       setAdultData(null)
    //       setSelectedKids([])
    //       setAdultCurrentStep(0)
    //       setAdultCreationModal(false)
    //     },
    //   },
    // )
    // todo
    addKidsToAdult(
      {
        adultId: adultData._id,
        payload: { kidIds: selectedKids },
      },
      {
        onSuccess: () => {
          setAdultData(null)
          setSelectedKids([])
          setAdultCurrentStep(0)
          setAdultCreationModal(false)
        },
      },
    )
  }

  useEffect(() => {
    setModalBtnLoader(addKidsToAdultLoading)
  }, [addKidsToAdultLoading])

  return (
    <>
      <CustomModal
        openModal={adultCreationModal}
        isCloseModalCustom
        closeModal={handleClose}
        title={
          adultCurrentStep === 0 ? 'Add Authorized Adults' : 'Select Child'
        }
        footer={
          <CustomModalFooter
            multibutton={adultCurrentStep === 0 ? false : true}
            btnAlignment={
              adultCurrentStep === 0 ? 'justify-end' : 'justify-between'
            }
            btnType="primary"
            closeModal={handleBack}
            btnText={adultCurrentStep === 0 ? 'next' : 'Back,Add kids'}
            onSubmit={adultCurrentStep === 0 ? handleSubmit : handleAddKids}
          />
        }
      >
        <AdultCreate form={form} familyId={familyId} />
      </CustomModal>
    </>
  )
}

export default CreateAdult
