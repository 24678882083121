import CustomModal from 'components/global/CustomModal'
import React, { useEffect } from 'react'
import { leagueEventStore } from 'state/event/leagueEvent'
import TeamConstraintList from './ModalContent/TeamConstraintList'
import CustomModalFooter from 'components/global/CustomModalFooter'

interface TeamConstraintModalProps {
  leagueId: string
}

const TeamConstraintModal: React.FC<TeamConstraintModalProps> = ({
  leagueId,
}) => {
  const {
    teamConstraintModal,
    setTeamConstraintModal,
    setAddTeamConstraintModal,
  } = leagueEventStore(store => ({
    teamConstraintModal: store.teamConstraintModal,
    setTeamConstraintModal: store.setTeamConstraintModal,
    setAddTeamConstraintModal: store.setAddTeamConstraintModal,
  }))

  const handleSave = () => {
    setTeamConstraintModal(false)
  }

  const handleAddTeamConstraint = () => {
    setAddTeamConstraintModal(true)
  }

  return (
    <CustomModal
      openModal={teamConstraintModal}
      closeModal={setTeamConstraintModal}
      title="Team Constraints"
      footer={
        <CustomModalFooter
          multibutton={true}
          btnAlignment="justify-between"
          btnText="Add Team Constraint,Save"
          onSubmit={handleSave}
          closeModal={handleAddTeamConstraint}
        />
      }
    >
      <TeamConstraintList leagueId={leagueId} />
    </CustomModal>
  )
}

export default TeamConstraintModal
