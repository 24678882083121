import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { axiosDelete, axiosGet, axiosPatch, axiosPost } from 'Redux/axiosHelper'
import {
  AddFaqPayload,
  AddOrgDocsPayload,
  AddTemplateToEventPayload,
  EventFaqs,
  OrgDocumentResp,
  OrgFaqs,
} from 'types/event/eventStep5'
import { EmailTemplate } from 'types/globalTypes'

export const eventAdditionalSetting = async ({
  payload,
  eventId,
}: {
  payload: any
  eventId: string
}) => {
  const response = await axiosPatch(
    payload,
    `v1/organization-events/${eventId}/event-step5`,
  )
  return response.data
}

export const useEventAdditionalSetting = () => {
  const queryClient = useQueryClient()
  return useMutation(eventAdditionalSetting, {
    onSuccess: updatedData => {
      //   queryClient.setQueryData(
      //     ['default-event-activities'],
      //     (oldData: any[] | undefined) => {
      //       return oldData ? [...oldData, newActivity] : [newActivity]
      //     },
      //   )
    },
  })
}

// * get organization faqs
export const getOrganizationFaq = async (orgId: string) => {
  const response = await axiosGet(`v1/event-questions/organization/${orgId}`)
  return response.data
}

export const useGetOrganizationFaq = (orgId: string) => {
  const orgFaqs = useQuery<OrgFaqs[]>(
    ['org-faqs'],
    () => getOrganizationFaq(orgId),
    {
      enabled: false,
    },
  )
  return orgFaqs
}

// * get event faq
const getEventFaq = async (eventId: string) => {
  const response = await axiosGet(`v1/event-questions/event/${eventId}`)
  return response.data
}

export const useGetEventFaq = (eventId: string) => {
  const getEventFaqs = useQuery<EventFaqs[]>(
    ['event-faqs'],
    () => getEventFaq(eventId),
    { enabled: false },
  )
  return getEventFaqs
}

// * post add event faq
export const addEventFaq = async (payload: AddFaqPayload) => {
  const response = await axiosPost(payload, `v1/event-questions/create`)
  return response.data
}
export const useAddEventFaq = () => {
  const queryClient = useQueryClient()
  return useMutation(addEventFaq, {
    onSuccess: newQuestion => {
      queryClient.setQueryData(
        ['event-faqs'],
        (oldData: EventFaqs[] | undefined) => {
          return oldData ? [...oldData, newQuestion] : [newQuestion]
        },
      )
    },
  })
}

const updateEventQuestion = async ({
  questionId,
  payload,
}: {
  questionId: string
  payload: AddFaqPayload
}) => {
  const response = await axiosPatch(
    payload,
    `v1/event-questions/update/${questionId}`,
  )
  return response.data
}
export const useUpdateEventQuestion = () => {
  const queryClient = useQueryClient()
  return useMutation(updateEventQuestion, {
    onSuccess: updatedFaq => {
      queryClient.setQueryData(
        ['event-faqs'],
        (oldData: EventFaqs[] | undefined) => {
          if (!oldData) return [updatedFaq]
          return oldData.map(data =>
            data._id === updatedFaq._id ? updatedFaq : data,
          )
        },
      )
    },
  })
}

// delete request for question deletion
const removeQuestion = async ({ questionId }: { questionId: string }) => {
  const response = await axiosDelete(`v1/event-questions/delete/${questionId}`)
  return response.data
}

export const useRemoveQuestion = () => {
  const queryClient = useQueryClient()
  return useMutation(removeQuestion, {
    onSuccess: (deletedQuestion: EventFaqs) => {
      queryClient.setQueryData(
        ['event-faqs'],
        (oldData: EventFaqs[] | undefined) => {
          if (!oldData) return []

          return oldData.filter(data => data._id !== deletedQuestion._id)
        },
      )
    },
  })
}

// * get org documents
const getOrgDocuments = async (orgId: string) => {
  const response = await axiosGet(`v1/document-types`, {
    organizationId: orgId,
  })
  return response.data
}

export const useGetOrgDocuments = (orgId: string) => {
  const orgDocuments = useQuery<OrgDocumentResp[]>(
    ['org-docs'],
    () => getOrgDocuments(orgId),
    { enabled: false },
  )
  return orgDocuments
}

// * create org doc
const addOrgDocument = async (payload: AddOrgDocsPayload) => {
  const response = await axiosPost(payload, `v1/document-types/create`)
  return response.data
}

export const useAddOrgDocument = () => {
  const queryClient = useQueryClient()
  return useMutation(addOrgDocument, {
    onSuccess: newDoc => {
      queryClient.setQueryData(
        ['org-docs'],
        (oldData: OrgDocumentResp[] | undefined) => {
          return oldData ? [...oldData, newDoc] : [newDoc]
        },
      )
    },
  })
}

// * update org doc
const updateOrgDocument = async ({
  payload,
  typeId,
}: {
  payload: AddOrgDocsPayload
  typeId: string
}) => {
  const response = await axiosPatch(
    payload,
    `v1/document-types/update/${typeId}`,
  )
  return response.data
}

export const useUpdateOrgDocument = () => {
  const queryClient = useQueryClient()
  return useMutation(updateOrgDocument, {
    onSuccess: (updatedDoc: OrgDocumentResp) => {
      queryClient.setQueriesData(
        ['org-docs'],
        (oldData: OrgDocumentResp[] | undefined) => {
          if (!oldData) return [updatedDoc]
          return oldData.map(data =>
            data._id === updatedDoc._id ? updatedDoc : data,
          )
        },
      )
    },
  })
}

// * delete org docs
const deleteOrgDocument = async (typeId: string) => {
  const response = await axiosDelete(`v1/document-types/${typeId}`)
  return response.data
}
export const useDeleteOrgDocument = () => {
  const queryClient = useQueryClient()
  return useMutation(deleteOrgDocument, {
    onSuccess: (deletedDoc: OrgDocumentResp) => {
      queryClient.setQueryData(
        ['org-docs'],
        (oldData: OrgDocumentResp[] | undefined) => {
          if (!oldData) return []
          return oldData.filter(data => data._id !== deletedDoc._id)
        },
      )
    },
  })
}

// * add email template to event
const addEmailtemplateToEvent = async ({
  payload,
  eventId,
}: {
  payload: AddTemplateToEventPayload
  eventId: string
}) => {
  const response = await axiosPatch(
    payload,
    `v1/organization-events/${eventId}/new/add-email-template`,
  )
  return response.data
}

export const useAddEmailtemplateToEvent = () => {
  return useMutation(addEmailtemplateToEvent, {
    onSuccess: data => {},
  })
}

// * delete email template from event
const removeEmailTemplateFromEvent = async ({
  eventId,
  templateId,
}: {
  eventId: string
  templateId: string
}) => {
  const response = await axiosDelete(
    `v1/organization-events/${eventId}/new/remove-email-template/${templateId}`,
  )
  return response.data
}

export const useRemoveEmailTemplateFromEvent = () => {
  return useMutation(removeEmailTemplateFromEvent)
}

// * edit email template from the event
const editEmailTemplateFromEvent = async ({
  payload,
  eventId,
  templateId,
}: {
  payload: EmailTemplate
  eventId: string
  templateId: string
}) => {
  const response = await axiosPatch(
    payload,
    `v1/organization-events/${eventId}/new/edit-email-template/${templateId}`,
  )
  return response.data
}

export const useEditEmailTemplateFromEvent = () => {
  return useMutation(editEmailTemplateFromEvent)
}
