import CustomModal from 'components/global/CustomModal'
import CustomModalFooter from 'components/global/CustomModalFooter'
import React from 'react'
import { leagueEventStore } from 'state/event/leagueEvent'
import DivisionConstraintList from './ModalContent/DivisionConstraintList'

interface DivisionConstraintModalProps {
  leagueId: string
}

const DivisionConstraintModal: React.FC<DivisionConstraintModalProps> = ({
  leagueId,
}) => {
  const {
    divisionConstraintModal,
    setDivisionConstraintModal,
    setAddDivisionConstraintModal,
    setDivisionConstraintData,
  } = leagueEventStore(store => ({
    divisionConstraintModal: store.divisionConstraintModal,
    setDivisionConstraintModal: store.setDivisionConstraintModal,
    setAddDivisionConstraintModal: store.setAddDivisionConstraintModal,
    setDivisionConstraintData: store.setDivisionConstraintData,
  }))

  const handleSave = () => {
    setDivisionConstraintModal(false)
  }

  const handleAddDivisionConstraint = () => {
    setDivisionConstraintData(null)
    setAddDivisionConstraintModal(true)
  }

  return (
    <>
      <CustomModal
        openModal={divisionConstraintModal}
        closeModal={setDivisionConstraintModal}
        title="Division Constraints"
        footer={
          <CustomModalFooter
            multibutton={true}
            btnAlignment="justify-between"
            btnText="Add devision constraints,Save"
            onSubmit={handleSave}
            closeModal={handleAddDivisionConstraint}
          />
        }
      >
        <DivisionConstraintList leagueId={leagueId} />
      </CustomModal>
    </>
  )
}

export default DivisionConstraintModal
