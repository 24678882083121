import React from 'react'
import IconsTextNavigation from 'components/global/IconsTextNavigation'
import AddTravelConstraintModal from '../Modals/Travel/AddTravelConstraintModal'
import { leagueEventStore } from 'state/event/leagueEvent'
import { ConstaintStepStatusesResp } from 'types/league/league'

interface TravelConstraintsProps {
  status: ConstaintStepStatusesResp
}

const TravelConstraints: React.FC<TravelConstraintsProps> = ({ status }) => {
  const { travelConstraintModal, setTravelConstraintModal } = leagueEventStore(
    store => ({
      travelConstraintModal: store.travelConstraintModal,
      setTravelConstraintModal: store.setTravelConstraintModal,
    }),
  )
  return (
    <>
      <div onClick={() => setTravelConstraintModal(true)}>
        <IconsTextNavigation
          iconName="Path"
          heading="Travel Constraints"
          isCompleted={status.travelConstraints}
        />
      </div>
      {travelConstraintModal && <AddTravelConstraintModal />}
    </>
  )
}

export default TravelConstraints
