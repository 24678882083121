import React, { useState } from 'react'
import {
  Modal,
  Form,
  Row,
  Col,
  Input,
  Radio,
  RadioChangeEvent,
  Button,
} from 'antd'
import closeIcon from 'assets/Images/close.svg'
import {
  useAddAdditionalFee,
  useEditAdditionalFee,
} from 'api/EventCreation/creationStep3'
import { creationEventStore } from 'state/event/eventCreationStore'
import { AdditionalFeePayload, AdditionalFeeResp } from 'types/event/eventStep3'
import ButtonSpinner from 'components/global/ButtonSpinner'
import { infoMessage } from 'api/global'

interface AddFeeProps {
  modalOpen: boolean
  modalClose: React.Dispatch<React.SetStateAction<boolean>>
  data?: AdditionalFeeResp
}

const AddFee: React.FC<AddFeeProps> = ({ modalOpen, modalClose, data }) => {
  const eventData = creationEventStore(store => store.eventData)
  const setEventData = creationEventStore(store => store.setEventData)
  const [priceType, setPriceType] = useState<'price' | 'priceRange'>(
    data?.priceRange ? 'priceRange' : 'price',
  )
  const { mutate: addAdditionalFeeMutate, isLoading: addAdditionalFeeLoading } =
    useAddAdditionalFee()
  const {
    mutate: editAdditionalFeeMutate,
    isLoading: editAdditionalFeeLoading,
  } = useEditAdditionalFee()
  const [form] = Form.useForm()

  const formSchema = {
    name: data?.name || undefined,
    priceType: data?.priceRange ? 'priceRange' : 'price',
    price: data?.price || undefined,
    min: data?.priceRange?.min || undefined,
    max: data?.priceRange?.max || undefined,
  }

  const handleCancel = () => {
    modalClose(false)
  }

  const onFinish = values => {
    if (!values.name) {
      return infoMessage('Name is required')
    }
    if (priceType === 'price' && (!values.price || values.price === 0)) {
      return infoMessage('Price must be greater than 0')
    }
    if (
      priceType === 'priceRange' &&
      (!values.min || !values.max || values.min === 0 || values.max === 0)
    ) {
      return infoMessage('Price must be greater than 0')
    }
    let payload: AdditionalFeePayload
    if (priceType === 'price') {
      payload = values
    } else {
      payload = {
        name: values.name,
        priceRange: {
          min: values.min,
          max: values.max,
        },
      }
    }
    if (data?._id) {
      editAdditionalFeeMutate(
        { payload, eventId: eventData?._id, feeId: data._id },
        {
          onSuccess: data => {
            setEventData(data)
            handleCancel()
          },
        },
      )
    } else {
      addAdditionalFeeMutate(
        { payload, eventId: eventData?._id },
        {
          onSuccess: data => {
            setEventData(data)
            handleCancel()
          },
        },
      )
    }
  }

  return (
    <div>
      <Modal
        open={modalOpen}
        onCancel={handleCancel}
        className="admission-ticket-modal"
        footer={false}
        centered
      >
        <div className="flex-center pt-20px justify-between ">
          <h2 className="w-600 text-18">
            {data ? 'Edit Fee' : 'Add more fees'}
          </h2>
          <img
            src={closeIcon}
            alt="close-button"
            onClick={handleCancel}
            className="close button pointer"
          />
        </div>
        <div className="mt-20px overflow-auto-y overflow-hidden-x scroller">
          <Form
            form={form}
            layout="horizontal"
            initialValues={formSchema}
            onFinish={onFinish}
          >
            <Row gutter={10}>
              <Col>
                <label className="input-label required-input">
                  Enter fee name
                </label>
              </Col>
              <Col>
                <Form.Item className="form-control" name="name">
                  <Input
                    placeholder="Fee name"
                    className="form-control-field"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10} className="mt-10px">
              <Col>
                <Form.Item name="priceType" className="w-100">
                  <Radio.Group
                    onChange={(val: RadioChangeEvent) => {
                      setPriceType(val.target.value)
                    }}
                    defaultValue={priceType}
                  >
                    <div>
                      <div className="flex justify-between align-center w-50p">
                        <Radio value={'price'} className="mr-40 radio">
                          Price
                        </Radio>
                        {priceType === 'price' && (
                          <div className="flex align-center">
                            <p className="mr-10px">$</p>
                            <Form.Item name="price" className="mb-0px">
                              <Input
                                placeholder="0"
                                className="br-12 custom-input-num-field font16 text-end"
                                type="number"
                                onWheel={e => e.currentTarget.blur()}
                              />
                            </Form.Item>
                          </div>
                        )}
                      </div>
                      <div className="flex gap-16px align-center">
                        <Radio value={'priceRange'} className="mr-40 radio">
                          Price range
                        </Radio>
                        {priceType === 'priceRange' && (
                          <div className="flex gap-16px align-center">
                            <div className="flex align-center">
                              <p className="mr-10px">$</p>
                              <Form.Item name="min" className="mb-0px">
                                <Input
                                  placeholder="0"
                                  className="br-12 custom-input-num-field font16 text-end"
                                  type="number"
                                  style={{ width: '100px' }}
                                  onWheel={e => e.currentTarget.blur()}
                                />
                              </Form.Item>
                            </div>
                            <p>-</p>
                            <div className="flex align-center">
                              <p className="mr-10px">$</p>
                              <Form.Item name="max" className="mb-0px">
                                <Input
                                  placeholder="0"
                                  className="br-12 custom-input-num-field font16 text-end"
                                  type="number"
                                  style={{ width: '100px' }}
                                  onWheel={e => e.currentTarget.blur()}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Row justify="end" className="mt-20px">
                <Button className="stepper-button" htmlType="submit">
                  {addAdditionalFeeLoading || editAdditionalFeeLoading ? (
                    <ButtonSpinner size={20} color={'#000'} />
                  ) : data ? (
                    'Edit'
                  ) : (
                    'Add'
                  )}
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default AddFee
