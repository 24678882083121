import React, { useState } from 'react'
import { creationEventStore } from 'state/event/eventCreationStore'
import EmptyData from 'components/global/EmptyData'
import { Plus, Trash, PencilLine } from '@phosphor-icons/react'
import AddTaskModal from './Modals/AddTaskModal'
import { useAddActivityStep4 } from 'api/EventCreation/CreationStep2'
import ButtonSpinner from 'components/global/ButtonSpinner'

interface Step4Props {
  handleBack: () => void
  handleNext: () => void
}

const Step4: React.FC<Step4Props> = ({ handleBack, handleNext }) => {
  const customActivity = creationEventStore(store => store.customActivity)
  const setCustomActivity = creationEventStore(store => store.setCustomActivity)
  const [tasks, setTasks] = useState<string[]>(
    customActivity ? customActivity.tasks : [],
  )
  const [openTaskModal, setOpenTaskModal] = useState<boolean>(false)
  const [itemIndex, setItemIndex] = useState<number | undefined>()
  const { mutate: addTasks, isLoading: addTasksLoading } = useAddActivityStep4()

  const handleAdd = () => {
    setItemIndex(undefined)
    setOpenTaskModal(true)
  }
  const handleDeleteGroup = (index: number) => {
    const updatedGroups = tasks.filter((_, i) => i !== index)
    setTasks(updatedGroups)
  }

  const hanldeEdit = (index: number) => {
    setItemIndex(index)
    setOpenTaskModal(true)
  }

  const hanldeAddActivityStep4 = () => {
    const payload = {
      tasks: tasks,
    }
    if (customActivity._id) {
      addTasks(
        {
          payload,
          activityId: customActivity._id,
        },
        {
          onSuccess: data => {
            setCustomActivity(data)
            handleNext()
          },
        },
      )
    }
  }

  return (
    <div>
      <div>
        <h3 className="text-20px w-600">Tasks</h3>
      </div>
      <div className="main-inventory-container">
        {tasks.length === 0 ? (
          <div className="no-supplies-height flex flex-col align-center justify-center h-18rem">
            <EmptyData message="No tasks are available" />
            <button
              className="big-btn gap-10px mt-10px"
              onClick={() => setOpenTaskModal(true)}
            >
              <Plus size={18} color="#2ba7ca" />
              Add task
            </button>
          </div>
        ) : (
          <div className="h-18rem overflow-auto-y overflow-hidden-x scrooler">
            {tasks.map((task: string, index: number) => {
              return (
                <div
                  key={index}
                  className="flex align-center justify-between mt-10px"
                >
                  <p>{task}</p>
                  <div className="flex align-center gap-20px">
                    <PencilLine
                      size={20}
                      color="#000"
                      className="pointer"
                      onClick={() => hanldeEdit(index)}
                    />
                    <Trash
                      size={20}
                      color="#ff6961"
                      onClick={() => handleDeleteGroup(index)}
                      className="pointer"
                    />
                  </div>
                </div>
              )
            })}
            <div className="mt-15px">
              <button className="big-btn gap-10px mx-auto" onClick={handleAdd}>
                <Plus size={18} color="#2ba7ca" />
                Add task
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="mt-10px flex align-center justify-between">
        <button className="create-activity-btn" onClick={handleBack}>
          Cancel
        </button>
        <button className="confirm-btn" onClick={hanldeAddActivityStep4}>
          {addTasksLoading ? <ButtonSpinner color="#000" size={20} /> : 'Next'}
        </button>
      </div>
      {openTaskModal && (
        <AddTaskModal
          modalOpen={openTaskModal}
          closeModal={setOpenTaskModal}
          tasks={tasks}
          setTasks={setTasks}
          index={itemIndex}
        />
      )}
    </div>
  )
}

export default Step4
