import React from 'react'
import IconsTextNavigation from 'components/global/IconsTextNavigation'

const MentorRequest: React.FC = () => {
  return (
    <>
      <div>
        <IconsTextNavigation
          iconName="MagnifyingGlass"
          heading="Mentor Request"
        />
      </div>
    </>
  )
}

export default MentorRequest
