import { Button, Col, Form, Input, Radio, RadioChangeEvent, Row } from 'antd'
import { infoMessage } from 'api/global'
import { useLeagueStep2 } from 'api/LeagueCreation/leagueCreation'
import ButtonSpinner from 'components/global/ButtonSpinner'
import React, { useState } from 'react'
import { leagueEventStore } from 'state/event/leagueEvent'
import { LeagueStep2Payload } from 'types/league/league'

interface LeagueStep2Props {
  handleNext: () => void
  handleBack: () => void
}

const Step2: React.FC<LeagueStep2Props> = ({ handleNext, handleBack }) => {
  const {
    mutate: createLeagueStep2Mutation,
    isLoading: createLeagueStep2Loading,
  } = useLeagueStep2()
  const { leagueData, setLeagueData } = leagueEventStore(store => ({
    leagueData: store.leagueData,
    setLeagueData: store.setLeagueData,
  }))
  const [form] = Form.useForm()
  const [timeLabel, setTimeLabel] = useState<'rounds' | 'weeks'>(
    leagueData.weeks ? 'weeks' : 'rounds',
  )

  const formSchema = {
    gameSessions: timeLabel,
    minTeamSize: leagueData.minTeamSize ? leagueData.minTeamSize : undefined,
    games: leagueData.games ? leagueData.games : undefined,
    rounds: leagueData.rounds ? leagueData.rounds : undefined,
    weeks: leagueData.weeks ? leagueData.weeks : undefined,
    duration: leagueData.duration ? leagueData.duration : undefined,
    playOffDuration: leagueData.playOffDuration
      ? leagueData.playOffDuration
      : undefined,
    goals: leagueData.goals ? leagueData.goals : undefined,
    type: leagueData.type ? leagueData.type : 'standings',
  }

  const onChangeRounds = (e: RadioChangeEvent) => {
    setTimeLabel(e.target.value)
  }

  const onFinish = (values: LeagueStep2Payload) => {
    if (!leagueData._id)
      return infoMessage('League is not created! Something went wrong')

    createLeagueStep2Mutation(
      { leagueId: leagueData._id, payload: values },
      {
        onSuccess: data => {
          setLeagueData(data)
          handleNext()
        },
      },
    )
  }
  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        initialValues={formSchema}
        onFinish={onFinish}
      >
        <Row gutter={10}>
          <Col className="mt-15px">
            <label className="input-label text-16 required-input mb-10px">
              Would you like to use rounds or weeks?
            </label>
            <Form.Item
              className="form-control mb-0"
              name="gameSessions"
              rules={[{ required: true, message: 'League type is required' }]}
            >
              <Radio.Group defaultValue={'rounds'} onChange={onChangeRounds}>
                <Radio value={'rounds'} className="mr-40 radio">
                  Rounds
                </Radio>
                <Radio value={'weeks'} className="mr-40 radio">
                  Week
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col className="mt-15px">
            <label className="input-label text-16 required-input mb-10px">
              Minimum team size
            </label>
            <Form.Item className="form-control mb-0" style={{ width: '150px' }}>
              <div className="flex align-center gap-20px">
                <Form.Item
                  name="minTeamSize"
                  rules={[{ required: true, message: 'Team size is required' }]}
                  noStyle
                >
                  <Input
                    type="number"
                    placeholder="0"
                    className="text-end radius-12px"
                    onWheel={e => e.currentTarget.blur()}
                  />
                </Form.Item>
                <p>Players</p>
              </div>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col className="mt-15px">
            <label className="input-label text-16 required-input mb-10px">
              How many games will each team play?
            </label>

            <div className="flex gap-20px align-center">
              <Form.Item
                className="form-control mb-0"
                style={{ width: '170px' }}
              >
                <div className="flex align-center gap-20px">
                  <Form.Item
                    name="games"
                    rules={[{ required: true, message: 'Games is required' }]}
                    noStyle
                  >
                    <Input
                      name="games"
                      type="number"
                      placeholder="0"
                      className="text-end radius-12px"
                      onWheel={e => e.currentTarget.blur()}
                    />
                  </Form.Item>
                  <p className="text-space-nowrap">Games in</p>
                </div>
              </Form.Item>

              <Form.Item
                className="form-control mb-0"
                name={timeLabel}
                style={{ width: '150px' }}
              >
                <div className="flex align-center gap-20px">
                  <Form.Item
                    name={timeLabel}
                    rules={[
                      {
                        required: true,
                        message: `${timeLabel} size is required`,
                      },
                    ]}
                    noStyle
                  >
                    <Input
                      name="games"
                      type="number"
                      placeholder="0"
                      className="text-end radius-12px"
                      onWheel={e => e.currentTarget.blur()}
                    />
                  </Form.Item>
                  <p>{timeLabel}</p>
                </div>
              </Form.Item>
            </div>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col className="mt-15px">
            <label className="input-label required-input mb-10px">
              Game duration (mins)
            </label>
            <Form.Item
              className="form-control mb-0"
              name="duration"
              style={{ width: '150px' }}
              rules={[{ required: true, message: `Game duration is required` }]}
            >
              <Input
                name="duration"
                type="number"
                placeholder="60"
                className="text-end radius-12px"
                onWheel={e => e.currentTarget.blur()}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col className="mt-15px">
            <label className="input-label required-input mb-10px">
              Playoff game duration (mins)
            </label>
            <Form.Item
              className="form-control mb-0"
              name="playOffDuration"
              rules={[{ required: true, message: `Play off is required` }]}
              style={{ width: '150px' }}
            >
              <Input
                name="playOffDuration"
                type="number"
                placeholder="60"
                className="text-end radius-12px"
                onWheel={e => e.currentTarget.blur()}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col className="mt-15px">
            <label className="input-label required-input mb-10px">
              Number of goals collected by a team
            </label>
            <Form.Item
              className="form-control mb-0"
              name="goals"
              style={{ width: '150px' }}
              rules={[{ required: true, message: ` Goals is required` }]}
            >
              <Input
                name="goals"
                type="number"
                placeholder="10"
                className="text-end radius-12px"
                onWheel={e => e.currentTarget.blur()}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col className="mt-15px">
            <label className="text-16 input-label required-input mb-10px">
              What kind of game is it?
            </label>
            <Form.Item
              className="form-control mb-0"
              name="type"
              rules={[{ required: true, message: `Game type is required` }]}
            >
              <Radio.Group defaultValue={'standings'}>
                <Radio value={'standings'} className="mr-40 radio">
                  Standings
                </Radio>
                <Radio value={'tournament'} className="mr-40 radio">
                  Tournament
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-between" className="mb-26 mt-40px">
          <Button className="cancel-button" onClick={handleBack}>
            Back
          </Button>
          <Button
            className="stepper-button"
            disabled={createLeagueStep2Loading}
            htmlType="submit"
          >
            {createLeagueStep2Loading ? (
              <ButtonSpinner size={20} color="#000" />
            ) : (
              'Next'
            )}
          </Button>
        </Row>
      </Form>
    </div>
  )
}

export default Step2
