import AppLayout from 'components/layout/layout'
import React, { useEffect, useState } from 'react'
import TeamPageHeader from './Header/TeamPageHeader'
import TableView from './Views/TableView'
import { GetAllTeamsParams } from 'types/team/team'
import { useGetAllTeams } from 'api/Team/Team'
import Spinner from 'components/Spinner'
import { teamStore } from 'state/team/team'
import CreateTeam from './Modals/CreateTeam'

const Teams: React.FC = () => {
  const { teamModalVisible } = teamStore(store => ({
    teamModalVisible: store.teamModalVisible,
  }))
  const [params, setParams] = useState<GetAllTeamsParams>({
    page: 1,
    limit: 10,
  })
  const { data, isLoading, isFetching, refetch } = useGetAllTeams(params)

  useEffect(() => {
    refetch()
  }, [params, refetch])

  return (
    <>
      <AppLayout>
        <div className="px-40px py-40px">
          <TeamPageHeader />
          <div className="mt-20px">
            {isLoading || isFetching ? (
              <div className="flex justify-center py-20px w-full">
                <Spinner />
              </div>
            ) : (
              <TableView data={data} params={params} setParams={setParams} />
            )}
          </div>
        </div>
      </AppLayout>
      {teamModalVisible && <CreateTeam />}
    </>
  )
}

export default Teams
