import { useEffect } from 'react'
import AppLayout from '../../../components/layout/layout'
import { useParams } from 'react-router-dom'
import {
  Row,
  Col,
  Avatar,
  Button,
  Space,
  Dropdown,
  Menu,
  Tabs,
  Tooltip,
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import deleteSvg from '../../../assets/Images/delete_white.svg'
import pencilLine from '../../../assets/Images/PencilLine.svg'
import Events from './Events'
import Organizations from './Organizations'
import StatusDropdown from '../../../helper/StatusDropdown'
import OrganizerBasicInfo from './BasicInformation'
import { getNameSignature } from 'helper/utility'
import { getAllergyList } from '../../../api/Lists'
import { useQuery } from '@tanstack/react-query'
import icon from 'assets/Images/otherCategory.svg'
import { useGetOrganizerById } from 'api/Organizers'
import Spinner from 'components/Spinner'
import { organizerStore } from 'state/organizer/organizer'
import OrganizerDataType from 'types/organizer'
import CreateOrganizer from '../Modals/CreateOrganizer'
const { TabPane } = Tabs

interface OrganizerStoreStatesTypes {
  organizerModalVisible: boolean
  setOrganizerModalVisible: (state: boolean) => void
  setOrganizerData: (state: OrganizerDataType) => void
  setIsUpdating: (state: boolean) => void
}

const OrganizerProfile = () => {
  let { id }: { id: string } = useParams()
  const {
    data: organizer,
    isLoading: organizerLoading,
    isFetching: organizerFetching,
    refetch: organizerRefetch,
  } = useGetOrganizerById(id)
  const { data: AllergyData, isLoading } = useQuery(['getAllergyList'], () =>
    getAllergyList(),
  )
  const {
    organizerModalVisible,
    setOrganizerModalVisible,
    setOrganizerData,
    setIsUpdating,
  }: OrganizerStoreStatesTypes = organizerStore(store => ({
    organizerModalVisible: store.organizerModalVisible,
    setOrganizerModalVisible: store.setOrganizerModalVisible,
    setOrganizerData: store.setOrganizerData,
    setIsUpdating: store.setIsUpdating,
  }))

  const getAllergywithIcon = (data: any) => {
    const allergy = AllergyData?.find(
      (allergy: any) => allergy._id === data?._id,
    )

    if (allergy) {
      return (
        <Tooltip placement="bottom" title={allergy?.name || data?.name}>
          <Avatar
            src={allergy?.icon || icon}
            size={20}
            className="allergy-icon"
          />
        </Tooltip>
      )
    } else {
      return (
        <Tooltip placement="bottom" title={data?.name}>
          <Avatar src={icon} size={20} className="allergy-icon" />
        </Tooltip>
      )
    }
  }

  const hanldeEditOrganizer = () => {
    setOrganizerData(organizer)
    setIsUpdating(true)
    setOrganizerModalVisible(true)
  }

  useEffect(() => {
    organizerRefetch()
  }, [id])
  const actionMenu = (
    <Menu>
      <Menu.Item key="Editprofile" onClick={hanldeEditOrganizer}>
        <Space>
          <img alt="img" src={pencilLine}></img> Edit profile
        </Space>
      </Menu.Item>
      <Menu.Item
        key="Delete"
        onClick={() => {
          console.log('Organizer delete functionality will be implemented...')
        }}
      >
        <Space>
          <img alt="img" src={deleteSvg}></img> Delete
        </Space>
      </Menu.Item>
    </Menu>
  )

  return (
    <AppLayout id={id}>
      {organizerLoading || organizerFetching ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <>
          <div className="bg-grey">
            <div className="org-profile-header">
              <Row gutter={10}>
                <Col xl={{ span: 10 }}>
                  <Row align="middle">
                    <Col span={24} lg={{ span: 24 }}>
                      <div className="d-flex align-center">
                        <Avatar size={80} src={organizer?.photo?.post}>
                          {getNameSignature(
                            organizer?.firstName + organizer?.lastName,
                          )}
                        </Avatar>
                        <div className="avatar-name align-self-center">
                          <h1 style={{ marginBottom: '0px' }}>
                            <b className="b1">{organizer?.firstName}</b>
                          </h1>
                          {isLoading ||
                            (organizer?.allergies?.length > 0 && (
                              <div>
                                <div className="flex  flex-wrap w-full align-items-center">
                                  {organizer?.allergies?.map((data: any) => {
                                    return (
                                      <div
                                        key={data?._id}
                                        className="mt-10px allergyChips "
                                        style={{ marginRight: '10px' }}
                                      >
                                        {getAllergywithIcon(data)}
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xl={{ span: 14 }}>
                  <Row>
                    <Col span={24} className="Organization-header">
                      <Space>
                        <h5 className="status-title">
                          <b className="b1">Status</b>
                        </h5>
                        <div className="profile-head-action-dropdown">
                          <StatusDropdown
                            id={organizer?.id}
                            name="organizer"
                            status={organizer.status}
                          />
                        </div>
                      </Space>
                      <Dropdown
                        overlay={actionMenu}
                        className="status_dropdown"
                      >
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          className="actionButton actionButton-org-profile"
                        >
                          Manage
                        </Button>
                      </Dropdown>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <Row>
              <Tabs
                defaultActiveKey="1"
                style={{ width: '100%', height: '100%' }}
                className="bg-white profile-tabs"
              >
                <TabPane tab="Basic Info" key="1">
                  <OrganizerBasicInfo parent={organizer}></OrganizerBasicInfo>
                </TabPane>
                <TabPane tab="Organizations" key="2">
                  <Organizations />
                </TabPane>
                <TabPane tab="Events" key="3">
                  <Events />
                </TabPane>
              </Tabs>
            </Row>
          </div>
        </>
      )}
      {organizerModalVisible && <CreateOrganizer />}
    </AppLayout>
  )
}

export default OrganizerProfile
