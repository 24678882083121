import { useMutation, useQuery } from '@tanstack/react-query'
import { axiosGet, axiosPatch, axiosPost } from 'Redux/axiosHelper'
import {
  CommunityStep1Payload,
  CommunityStep2Payload,
  CommunityStep3Payload,
} from 'types/community/communitySteps'

//get community by id
const getCommunityById = async (id: string) => {
  const response = await axiosGet(`v1/community/${id}`)
  return response.data
}

export const useGetCommunityById = (id: string) => {
  const communityById = useQuery(
    ['get-community-by-id'],
    () => getCommunityById(id),
    { enabled: false },
  )
  return communityById
}

// create community step 1
const createCommunity = async (payload: CommunityStep1Payload) => {
  const response = await axiosPost(payload, `v1/community`)
  return response.data
}

export const useCreateCommunity = () => {
  return useMutation(createCommunity)
}

// update community step 1
const updateCommunity = async ({
  payload,
  communityId,
}: {
  payload: CommunityStep1Payload
  communityId: string
}) => {
  const response = await axiosPatch(payload, `v1/community/${communityId}`)
  return response.data
}

export const useUpdateCommunity = () => {
  return useMutation(updateCommunity)
}

// add or update community step 2
const communityStep2 = async ({
  payload,
  communityId,
}: {
  payload: CommunityStep2Payload
  communityId: string
}) => {
  const response = await axiosPatch(
    payload,
    `v1/community/contact-details/${communityId}`,
  )
  return response.data
}

export const useCommunityStep2 = () => {
  return useMutation(communityStep2)
}

// add or update community step 3
const communityStep3 = async ({
  payload,
  communityId,
}: {
  payload: CommunityStep3Payload
  communityId: string
}) => {
  const response = await axiosPatch(
    payload,
    `v1/community/working-hours/${communityId}`,
  )
  return response.data
}

export const useCommunityStep3 = () => {
  return useMutation(communityStep3)
}

// update community status
const changeCommunityStatus = async ({
  payload,
  communityId,
}: {
  payload: { status: string }
  communityId: string
}) => {
  const response = await axiosPatch(
    payload,
    `v1/community/status/${communityId}`,
  )
  return response.data
}

export const useChangeCommunityStatus = () => {
  return useMutation(changeCommunityStatus)
}
