import React, { useEffect, useState } from 'react'
import Spinner from 'components/Spinner'
import { Empty } from 'antd'
import {
  CalendarX,
  Clock,
  MapPin,
  PencilSimple,
  Trash,
} from '@phosphor-icons/react'
import AlertModal from 'helper/AlertModal'
import {
  useDeleteDivisionConstraint,
  useGetDivisionConstraints,
} from 'api/LeagueCreation/leagueDivisionConstraints'
import { DivisonConstraintResp } from 'types/league/divisionConstraints'
import { convertMinutesToLocalTime } from 'helper/utility'
import { leagueEventStore } from 'state/event/leagueEvent'

interface DivisionConstraintListProps {
  leagueId: string
}

const DivisionConstraintList: React.FC<DivisionConstraintListProps> = ({
  leagueId,
}) => {
  const [alertModal, setAlertModal] = useState<boolean>(false)
  const [constraint, setConstraint] = useState<DivisonConstraintResp>()
  const { setDivisionConstraintData, setAddDivisionConstraintModal } =
    leagueEventStore(store => ({
      setDivisionConstraintData: store.setDivisionConstraintData,
      setAddDivisionConstraintModal: store.setAddDivisionConstraintModal,
    }))
  const {
    data: divisionConstraints,
    isLoading: divisionConstraintsLoading,
    isFetching: divisionConstraintsFetching,
    refetch: refetchDivisionConstraints,
  } = useGetDivisionConstraints(leagueId)
  const { mutate: deleteDivisionConstraintMutation, isLoading: isDeleting } =
    useDeleteDivisionConstraint()

  const handleDeleteConstraint = (constraint: DivisonConstraintResp) => {
    setConstraint(constraint)
    setAlertModal(true)
  }

  const onYesClick = () => {
    deleteDivisionConstraintMutation(constraint._id, {
      onSuccess: () => {
        setAlertModal(false)
      },
    })
  }

  const handleEditConstraint = (constraint: DivisonConstraintResp) => {
    setDivisionConstraintData(constraint)
    setAddDivisionConstraintModal(true)
  }

  useEffect(() => {
    refetchDivisionConstraints()
  }, [])

  return (
    <>
      <div className="mb-20px">
        <p className="text-gray">
          Set certain divisions to ONLY be scheduled for certain times and
          venues.
        </p>
        <>
          {divisionConstraintsLoading || divisionConstraintsFetching ? (
            <div className="flex align-center justify-center py-40px">
              <Spinner />
            </div>
          ) : (
            <div>
              {!divisionConstraints.length ? (
                <div className="py-40px">
                  <Empty />
                </div>
              ) : (
                divisionConstraints.map((constraint: DivisonConstraintResp) => {
                  return (
                    <div
                      key={constraint._id}
                      className="mt-15px bg-greyLight radius-12 py-20px px-20px flex align-start justify-between gap-30px"
                    >
                      <div>
                        <h2 className="text-16 w-600">
                          {/* {constraint.division.ageGroup.name} */}
                        </h2>
                        <div className="flex align-center mt-3px gap-5px">
                          <MapPin size={18} color="#939598" />
                          <p className="text-14 w-500 text-gray">
                            {constraint.venue?.title}
                          </p>
                        </div>
                        <div className="flex align-center mt-3px gap-5px">
                          <CalendarX size={18} color="#939598" />
                          <div className="flex text-14 w-500 text-gray">
                            {constraint.days.map(day => (
                              <p className="text-14 w-500 text-gray">{day},</p>
                            ))}
                          </div>
                        </div>
                        <div className="flex align-center mt-3px gap-5px">
                          <Clock size={18} color="#939598" />
                          <p className="text-14 w-500 text-gray">
                            {' '}
                            {convertMinutesToLocalTime(
                              constraint.timings.start,
                            )}{' '}
                            -{' '}
                            {convertMinutesToLocalTime(constraint.timings.end)}
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-10px  align-center">
                        <PencilSimple
                          size={22}
                          color="#000"
                          className="pointer"
                          onClick={() => handleEditConstraint(constraint)}
                        />
                        <Trash
                          size={22}
                          color="#ff6961"
                          className="pointer"
                          onClick={() => handleDeleteConstraint(constraint)}
                        />
                      </div>
                    </div>
                  )
                })
              )}
            </div>
          )}
        </>

        {alertModal && (
          <AlertModal
            isModalVisible={alertModal}
            setIsModalVisible={setAlertModal}
            data={{
              name: 'Team Constraint',
              // heading: `Delete the Team Constraint ${constraint?.division.ageGroup.name}`,
              message1: `Are you sure you want to delete the constraint?`,
            }}
            onYesClick={onYesClick}
            loading={isDeleting}
          />
        )}
      </div>
    </>
  )
}

export default DivisionConstraintList
