import React, { useState } from 'react'
import './styles/component-styles.scss'
import {
  Copy,
  EyeSlash,
  Pencil,
  Trash,
  Eye,
  CurrencyCircleDollar,
  Users,
} from '@phosphor-icons/react'
import {
  DropinTicketResp,
  TicketsResp,
  UpdateTicketStatusPayload,
} from 'types/event/eventStep4'
import { capitalizeFirstLetter } from 'helper/utility'
import { Dropdown } from 'antd'
import AdmissionTicket from '../Modals/AdmissionTickets/AdmissionTicket'
import DropInTickets from '../Modals/DropinTicket/DropInTickets'
import {
  useChangeTicketStatus,
  useDeleteTicketUsingType,
  useDuplicateTicketUsingType,
} from 'api/EventCreation/CreationStep4'
import { creationEventStore } from 'state/event/eventCreationStore'
import AlertModal from 'helper/AlertModal'
import {
  AlertModalData,
  getAdmissionTicketPriceSentence,
  getAgeRangeWithGender,
} from '../../../utility'
import AlertDuplication from 'helper/DuplicationAlert'
import ButtonSpinner from 'components/global/ButtonSpinner'

type TicketType = 'admission' | 'additional' | 'dropin' | 'discount'
interface TicketsComp {
  visible: boolean
  details?: TicketsResp | DropinTicketResp
  ticketType: TicketType
}

const TicketComp: React.FC<TicketsComp> = ({
  visible,
  details,
  ticketType,
}) => {
  console.log(details, 'these are the detials...')

  const event = creationEventStore(store => store.eventData)
  const setEventData = creationEventStore(store => store.setEventData)
  const [openAdmissionTicket, setOpenAdmissionTicekt] = useState<boolean>(false)
  const [openDropIn, setOpenDropIn] = useState<boolean>(false)
  const [alertModalOpen, setAlertModalOpen] = useState<boolean>(false)
  const [alertDuplicationOpen, setAlertDuplicationOpen] =
    useState<boolean>(false)
  const { mutate: deleteAdmission, isLoading: deleteAdmissionLoading } =
    useDeleteTicketUsingType()
  const { mutate: duplicateTicket, isLoading: duplicateLoading } =
    useDuplicateTicketUsingType()
  const { mutate: changeTicketStatus, isLoading: changeTicketStatusLoading } =
    useChangeTicketStatus()

  const hanldeYesDelete = () => {
    deleteAdmission(
      { eventId: event?._id, ticketId: details._id, type: ticketType },
      {
        onSuccess: newData => {
          setEventData(newData)
          setAlertModalOpen(false)
        },
      },
    )
  }

  const hanldeYesDuplicate = () => {
    if (ticketType === 'admission' || ticketType === 'additional') {
      duplicateTicket(
        { eventId: event?._id, ticketId: details._id, ticketType },
        {
          onSuccess: newData => {
            setEventData(newData)
            setAlertDuplicationOpen(false)
          },
        },
      )
    }
  }

  const handleMenuClick = (
    operation: 'edit' | 'duplicate' | 'visibility' | 'delete',
  ) => {
    if (operation === 'edit') {
      if (ticketType === 'admission' || ticketType === 'additional') {
        setOpenAdmissionTicekt(true)
      } else {
        setOpenDropIn(true)
      }
    } else if (operation === 'delete') {
      setAlertModalOpen(true)
    } else if (operation === 'duplicate') {
      setAlertDuplicationOpen(true)
    } else if (operation === 'visibility') {
      const getTicketkey = () => {
        return ticketType === 'admission'
          ? 'admissionTicketId'
          : ticketType === 'additional'
          ? 'additionalOptionId'
          : ticketType === 'dropin'
          ? 'dropInTicketId'
          : undefined
      }
      const payload = {
        status: visible ? 'disabled' : 'active',
        [getTicketkey()]: details._id,
      } as UpdateTicketStatusPayload
      changeTicketStatus(
        { eventId: event?._id, payload },
        { onSuccess: newData => setEventData(newData) },
      )
    }
  }

  const manageOptions = [
    {
      key: 'edit',
      label: (
        <div
          className="flex gap-16px align-center"
          onClick={() => handleMenuClick('edit')}
        >
          <Pencil size={20} color="#000" />
          <p className="text-14 w-500">Edit ticket</p>
        </div>
      ),
    },
    {
      key: 'duplicate',
      label: (
        <div
          className="flex gap-16px align-center"
          onClick={() => handleMenuClick('duplicate')}
        >
          <Copy size={20} color="#000" />
          <p className="text-14 w-500">Duplicate ticket</p>
        </div>
      ),
    },
    {
      key: 'visibility',
      label: (
        <div
          className="flex gap-16px align-center"
          onClick={() => handleMenuClick('visibility')}
        >
          {visible ? (
            <EyeSlash size={20} color="#000" />
          ) : (
            <Eye size={20} color="#000" />
          )}
          <p className="text-14 w-500">Make {visible ? 'Disable' : 'Enable'}</p>
        </div>
      ),
    },
    {
      key: 'delete',
      label: (
        <div
          className="flex gap-16px align-center"
          onClick={() => handleMenuClick('delete')}
        >
          <Trash size={20} color="#ff6961" />
          <p className="text-14 w-500 danger-txt">Delete ticket</p>
        </div>
      ),
    },
  ]
  return (
    <>
      {ticketType === 'admission' || ticketType === 'additional' ? (
        <div className="image-ticket-main-cont">
          <div className="img-container">
            {(details as TicketsResp).photo ? (
              <img
                src={(details as TicketsResp).photo.post}
                alt="ticket_photo"
                style={{ width: '100%', height: '100%' }}
              />
            ) : (
              <div
                className="w-full h-full"
                style={{ background: `#${details.color}` }}
              />
            )}
          </div>
          <div>
            <h2 className="text-18 w-500">
              {capitalizeFirstLetter(details.name)}
            </h2>
            <div className="mt-5px flex align-center gap-10px radius-full bg-grey w-fit px-10px py-5px">
              <CurrencyCircleDollar size={24} color="#000" />
              <p>
                {getAdmissionTicketPriceSentence(
                  details.price,
                  (details as TicketsResp).numberOfDays,
                  (details as TicketsResp).days,
                )}
              </p>
            </div>
            <div className="mt-5px flex align-center gap-10px radius-full bg-grey w-fit px-10px py-5px">
              <Users size={24} color="#000" />
              <p>
                {getAgeRangeWithGender(
                  (details as TicketsResp).minAge as number,
                  (details as TicketsResp).maxAge as number,
                  (details as TicketsResp).guestAllowedToVisit,
                )}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-10px justify-between align-end text-right">
            <h2 className="text-18 w-500">{`$${(
              details as TicketsResp
            ).price.toFixed(2)}`}</h2>

            {changeTicketStatusLoading ? (
              <ButtonSpinner size={20} color={'#000'} />
            ) : visible ? (
              <Eye size={20} color="#000" />
            ) : (
              <EyeSlash size={20} color="#000" />
            )}

            <Dropdown
              menu={{ items: manageOptions }}
              placement="bottomLeft"
              arrow={false}
              className={`ml-20px mr-10px ${
                changeTicketStatusLoading && 'no-pointer-events'
              }`}
              overlayStyle={{ width: '300px' }}
              trigger={['click']}
            >
              <button className={`ticket-button pointer`}>Manage</button>
            </Dropdown>
          </div>
        </div>
      ) : (
        <div
          className={`ticket-main-cont ${
            ticketType === 'discount' && 'discount-cont'
          }`}
          style={{ backgroundColor: `#${details.color}` }}
        >
          <div className="content">
            <div className="flex justify-between align-center">
              <h2 className="text-18 w-600">
                {capitalizeFirstLetter(details.name)}
              </h2>
              {changeTicketStatusLoading ? (
                <ButtonSpinner size={20} color={'#000'} />
              ) : visible ? (
                <Eye size={20} color="#000" />
              ) : (
                <EyeSlash size={20} color="#000" />
              )}
            </div>

            {ticketType !== 'dropin' ? (
              <p className="text-14 w-500">{`${
                (details as TicketsResp).ticketType !== 'preselected'
                  ? (details as TicketsResp).numberOfDays
                  : (details as TicketsResp).days.length
              } days for $${details.price.toFixed(2)}`}</p>
            ) : (
              <p className="text-14 w-600 mt-15px">{`$${
                (details as DropinTicketResp).price
              }/${(details as DropinTicketResp).priceUnit}`}</p>
            )}
            {ticketType !== 'discount' && (
              <Dropdown
                menu={{ items: manageOptions }}
                placement="bottomLeft"
                arrow={false}
                className={`ml-20px mr-10px ${
                  changeTicketStatusLoading && 'no-pointer-events'
                }`}
                overlayStyle={{ width: '300px' }}
                trigger={['click']}
              >
                <button className={`ticket-button pointer`}>Manage</button>
              </Dropdown>
            )}
          </div>
          <div className="dull-color"></div>
        </div>
      )}

      {openAdmissionTicket && (
        <AdmissionTicket
          modalOpen={openAdmissionTicket}
          modalClose={setOpenAdmissionTicekt}
          type={ticketType}
          data={ticketType !== 'dropin' && (details as TicketsResp)}
        />
      )}

      {openDropIn && (
        <DropInTickets
          modalOpen={openDropIn}
          modalClose={setOpenDropIn}
          data={ticketType === 'dropin' && (details as DropinTicketResp)}
        />
      )}

      {alertModalOpen && (
        <AlertModal
          isModalVisible={alertModalOpen}
          setIsModalVisible={setAlertModalOpen}
          data={AlertModalData(ticketType, 'delete')}
          onYesClick={hanldeYesDelete}
          loading={deleteAdmissionLoading}
        />
      )}

      {alertDuplicationOpen && (
        <AlertDuplication
          isModalVisible={alertDuplicationOpen}
          setIsModalVisible={setAlertDuplicationOpen}
          data={AlertModalData(ticketType, 'duplicate')}
          onYesClick={hanldeYesDuplicate}
          loading={duplicateLoading}
        />
      )}
    </>
  )
}

export default TicketComp
