import React from 'react'
import { CaretDown } from '@phosphor-icons/react'
import { Avatar, Checkbox } from 'antd'
import { getNameSignature } from 'helper/utility'
import { Person } from 'types/globalTypes'
import user from 'assets/Images/userAvatar.svg'
import { eventShatStore } from 'state/event/eventChat'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import {
  useGetAttendeesPhoneById,
  useGetEmailsByAttendeeIds,
} from 'api/Contact/Contact'
import ButtonSpinner from 'components/global/ButtonSpinner'

interface AttendeesProps {
  participants: Person[]
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  contactType: 'email' | 'sms' | 'chat'
}

function Button({ btnTitle }: { btnTitle: string }) {
  return (
    <button className="flex align-center justify-center gap-10px border-1px radius-full px-15px py-10px bg-transparent">
      <span className="text-14 w-500">{btnTitle}</span>
      <CaretDown size={20} color="#000" />
    </button>
  )
}

const Attendees: React.FC<AttendeesProps> = ({
  participants,
  setOpen,
  contactType,
}) => {
  const {
    selectedParticipants,
    setSelectedParticipants,
    setSelectedParticipantsEmails,
    setSelectedParticipantsData,
  } = eventShatStore(store => ({
    selectedParticipants: store.selectedParticipants,
    setSelectedParticipants: store.setSelectedParticipants,
    setSelectedParticipantsEmails: store.setSelectedParticipantsEmails,
    setSelectedParticipantsData: store.setSelectedParticipantsData,
  }))

  const { mutate: getEmailsMutation, isLoading: getEmailsLoading } =
    useGetEmailsByAttendeeIds()
  const { mutate: getPhoneNumbersMutation, isLoading: getPhoneNumbersLoading } =
    useGetAttendeesPhoneById()

  const handleSelectAll = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      const selectedPersons = participants?.map((participant: Person) => {
        return participant._id
      })
      setSelectedParticipants(selectedPersons)
      setSelectedParticipantsData(participants)
    } else {
      setSelectedParticipants([])
    }
  }

  const handleSave = () => {
    if (contactType === 'chat') {
      setOpen(false)
      return
    }
    if (contactType === 'email') {
      const payload = {
        attendeesIds: selectedParticipants,
      }
      getEmailsMutation(payload, {
        onSuccess: data => {
          setSelectedParticipantsEmails(data)
          setOpen(false)
        },
      })
      return
    }
  }

  const onChange = (checkedValues: CheckboxValueType[]) => {
    setSelectedParticipants(checkedValues as string[])
    const selectedPersons = checkedValues.map(item =>
      participants.find(participant => participant._id === item),
    )
    setSelectedParticipantsData(selectedPersons)
  }

  return (
    <>
      <div className="mt-10px flex align-center flex-wrap gap-16px">
        <Button btnTitle="Age" />
        <Button btnTitle="Gender" />
        <Button btnTitle="Tickets" />
        <Button btnTitle="Days" />
      </div>
      <div
        className="mt-10px overflow-auto-y scroller"
        style={{ height: 'auto', maxHeight: '250px' }}
      >
        {!participants.length ? (
          <div className="flex align-center justify-center ">
            <h3 className="text-14 w-500">No participants found</h3>
          </div>
        ) : (
          <>
            <div className="mt-20px flex align-center gap-20px">
              <Checkbox
                onChange={handleSelectAll}
                checked={selectedParticipants?.length === participants.length}
              />
              <div className="flex align-center gap-10px">
                <Avatar src={user} size={30} />
                <h3 className="text-14 w-400">
                  All attendees{' '}
                  <span className="text-14 w-400 text-gray inline ml-5px">
                    (34)
                  </span>
                </h3>
              </div>
            </div>

            <Checkbox.Group
              onChange={onChange}
              className="flex flex-column flex-wrap"
              value={selectedParticipants}
            >
              {participants.map((item: Person) => {
                return (
                  <div
                    className="mt-20px flex align-center gap-20px"
                    key={item._id}
                  >
                    <Checkbox value={item._id} key={item._id} />
                    <div className="flex align-center gap-10px">
                      <Avatar src={item.photo?.post} size={30}>
                        {getNameSignature(item.firstName)}
                      </Avatar>
                      <h3 className="text-14 w-400">
                        {item.firstName + ' ' + item.lastName}
                      </h3>
                    </div>
                  </div>
                )
              })}
            </Checkbox.Group>
          </>
        )}
      </div>
      <div className="mt-20px flex align-center justify-between">
        <button className="text-gray text-14 w-500 border-none bg-transparent">
          Clear
        </button>
        <button
          className="px-20px py-12px text-white radius-12 bg-blue border-none pointer"
          onClick={handleSave}
        >
          {getEmailsLoading ? <ButtonSpinner size={20} color="#000" /> : 'Save'}
        </button>
      </div>
    </>
  )
}

export default Attendees
