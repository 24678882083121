import CustomModal from 'components/global/CustomModal'
import CustomModalFooter from 'components/global/CustomModalFooter'
import useFormSubmit from 'helper/Hooks/formSubmit'
import React, { useEffect } from 'react'
import { organizationStore } from 'state/organization/organization'
import CreateOrganizationContent from './ModalContent/CreateOrganization/CreateOrganizationContent'
import { useCreateOrganization } from 'api/Organizations'
import { failureMessage } from 'api/global'
import { myStore } from 'state/store'
import WarningModal from 'components/global/WarningModal'

const CreateOrganization: React.FC = () => {
  const { form, handleSubmit } = useFormSubmit()
  const {
    mutate: createOrganizationMutation,
    isLoading: createOrganizationMutationLoading,
  } = useCreateOrganization()
  const {
    organizeationModalVisible,
    setOrganizationModalVisible,
    organizationData,
    setOrganizationData,
    organizationCounter,
    setOrganizationCounter,
    isUpdating,
    setIsUpdating,
  } = organizationStore(store => ({
    organizeationModalVisible: store.organizationModalVisible,
    setOrganizationModalVisible: store.setOrganizationModalVisible,
    organizationData: store.organizationData,
    setOrganizationData: store.setOrganizationData,
    organizationCounter: store.organizationCurrentStep,
    setOrganizationCounter: store.setOrganizationCurrentStep,
    isUpdating: store.isUpdating,
    setIsUpdating: store.setIsUpdating,
  }))
  const setModalBtnLoader = myStore(store => store.setModalBtnLoader)
  const { setWarningModal } = myStore(store => ({
    setWarningModal: store.setOpenWarningModal,
  }))

  const yesClose = () => {
    setWarningModal(false)
    setOrganizationModalVisible(false)
    setOrganizationData(null)
    setOrganizationCounter(0)
  }

  const hanldeCrossClick = () => {
    setWarningModal(true)
  }

  const handleBack = () => {
    console.log('handleBack needs to be implemented')
  }

  const handleCreateOrganization = () => {
    if (!organizationData.ownerId) {
      return failureMessage('Please select an owner')
    }
    createOrganizationMutation(organizationData, {
      onSuccess: () => {
        setIsUpdating(false)
        setOrganizationData(null)
        setOrganizationCounter(0)
        setOrganizationModalVisible(false)
      },
    })
  }

  useEffect(() => {
    setModalBtnLoader(createOrganizationMutationLoading)
  }, [createOrganizationMutationLoading])

  return (
    <>
      <CustomModal
        openModal={organizeationModalVisible}
        isCloseModalCustom
        closeModal={hanldeCrossClick}
        title={`${isUpdating ? 'Update' : 'Add'} Organization`}
        footer={
          <CustomModalFooter
            multibutton={false}
            btnAlignment="justify-end"
            btnType="primary"
            closeModal={handleBack}
            btnText={
              organizationCounter === 2
                ? 'Create organization'
                : isUpdating
                ? 'Update'
                : 'Next'
            }
            onSubmit={
              organizationCounter === 2
                ? handleCreateOrganization
                : handleSubmit
            }
          />
        }
      >
        <CreateOrganizationContent form={form} />
      </CustomModal>

      <WarningModal yesClose={yesClose} />
    </>
  )
}

export default CreateOrganization
