import { useHistory } from 'react-router'
import { AppstoreOutlined, SettingOutlined } from '@ant-design/icons'
import { Layout, Menu, Space, Spin } from 'antd'
import 'antd/dist/antd.css'
import Calendar from '../../assets/Images/Calendar.svg'
import AnalyticsIcon from '../../assets/Images/AnalyticsIcon.svg'
import Newspaper from '../../assets/Images/Newspaper.svg'
import Location from '../../assets/Images/MapPinLine.svg'
import Inventory from '../../assets/Images/Inventory.svg'
import buildings_svg from '../../assets/Images/Buildings.svg'
import userPlus_svg from '../../assets/Images/UserPlus.svg'
import medal_svg from '../../assets/Images/Medal.svg'
import user_svg from '../../assets/Images/3 User.svg'
import sessionIcon_svg from '../../assets/Images/SessionIcon.svg'
import Jobs_svg from '../../assets/Images/jobs.svg'
import work_svg from '../../assets/Images/Work.svg'
import dashboardIcon from '../../assets/Images/dashboardIcon.svg'
import { useSelector } from 'react-redux'
import Loader from 'helper/Loader'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { getMe } from '../../api/Organizations'
import { string } from 'yup'
import activityIcon from 'assets/Images/activityIcon.svg'
import { Link } from 'react-router-dom'
const { Sider } = Layout

const DefaultSidebarV2 = () => {
  // const {
  //   isLoading,
  //   isFetching,
  //   data: data,
  //   refetch,
  // } = useQuery(['getMe'], () => getMe(), { enabled: false })
  // const history = useHistory()
  // useEffect(() => {
  //   refetch()
  // }, [refetch])
  let data: { role?: string } | undefined

  const [count, setCount] = useState(0)
  const {
    isLoading,

    data: getdata,
    refetch,
  } = useQuery(['getMe'], () => getMe())
  const history = useHistory()
  data = getdata
  // useEffect(() => {
  //   if (count === 0) {
  //     refetch()
  //     setCount(1)
  //   }
  // }, [count, refetch])
  // console.log(count, 'count')
  return (
    <Sider className="side-bar" width={'278px'}>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '20rem',
          }}
        >
          <Loader />
        </div>
      ) : (
        <Menu mode="inline" style={{ height: '100%' }}>
          <Menu.Item
            key="dashboard"
            id="overview"
            // onClick={() => {
            //   history.push('/dashboard')
            // }}
            className={
              history.location.pathname === '/dashboard' ? 'activeMenu' : ''
            }
          >
            <Link to="/dashboard">
              <Space size={5}>
                <img src={dashboardIcon} /> <h5 className="b3">Overview</h5>
              </Space>
            </Link>
          </Menu.Item>

          <Menu.Item
            key="analytics"
            id="analytics"
            // onClick={() => {
            //   history.push('/analytics')
            // }}
            className={
              history.location.pathname === '/analytics' ? 'activeMenu' : ''
            }
          >
            <Link to="/analytics">
              <Space size={5}>
                <img alt="alt" src={AnalyticsIcon} />{' '}
                <h5 className="b3">Analytics</h5>
              </Space>
            </Link>
          </Menu.Item>
          {data?.role === 'admin' && (
            <Menu.Item
              key="settings"
              id="settings"
              // onClick={() => {
              //   history.push('/settings')
              // }}
              className={
                history.location.pathname === '/settings' ? 'activeMenu' : ''
              }
            >
              <Link to="/settings">
                <Space size={15}>
                  <SettingOutlined />
                  <h5 className="b3"> Settings</h5>
                </Space>
              </Link>
            </Menu.Item>
          )}

          <Menu.ItemGroup key="g1" title="PEOPLE">
            <Menu.Item
              key="organization"
              id="organization"
              // onClick={() => {
              //   history.push({
              //     pathname: '/organization',
              //   })
              // }}
              className={
                history.location.pathname === '/organization'
                  ? 'activeMenu'
                  : ''
              }
            >
              <Link to={'/organization'}>
                <Space size={15}>
                  <img src={buildings_svg} alt=""></img>
                  <h5 className="b3">Organizations</h5>
                </Space>
              </Link>
            </Menu.Item>

            <Menu.Item
              key="organizer"
              id="organizer"
              // onClick={() => {
              //   history.push({
              //     pathname: '/organizer',
              //   })
              // }}
              className={
                history.location.pathname === '/organizer' ? 'activeMenu' : ''
              }
            >
              <Link to={'/organizer'}>
                <Space size={18}>
                  <img src={userPlus_svg} alt=""></img>
                  <h5 className="b3"> Organizer</h5>
                </Space>
              </Link>
            </Menu.Item>
            <Menu.Item
              key="teams"
              id="teams"
              className={
                history.location.pathname === '/teams' ? 'activeMenu' : ''
              }
            >
              <Link to={'/teams'}>
                <Space size={18}>
                  <img src={userPlus_svg} alt=""></img>
                  <h5 className="b3">Teams</h5>
                </Space>
              </Link>
            </Menu.Item>
            <Menu.Item
              key="mentors"
              id="mentors"
              // onClick={() => {
              //   history.push('/mentors')
              // }}
              className={
                history.location.pathname === '/mentors' ? 'activeMenu' : ''
              }
            >
              <Link to={'/mentors'}>
                <Space size={14}>
                  <img src={medal_svg} alt=""></img>
                  <h5 className="b3"> Mentors</h5>
                </Space>
              </Link>
            </Menu.Item>
            <Menu.Item
              key="family"
              id="family"
              // onClick={() => {
              //   history.push('/family')
              // }}
              className={
                history.location.pathname === '/family' ? 'activeMenu' : ''
              }
            >
              <Link to="/family">
                <Space size={8}>
                  <img src={user_svg} alt=""></img>{' '}
                  <h5 className="b3"> Families</h5>
                </Space>
              </Link>
            </Menu.Item>
          </Menu.ItemGroup>

          <Menu.ItemGroup
            key="g2"
            title="EVENTS"
            style={{ paddingBottom: '20px' }}
          >
            <Menu.Item
              key="events"
              id="events"
              // onClick={() => {
              //   history.push('/events')
              // }}
              className={
                history.location.pathname === '/events' ? 'activeMenu' : ''
              }
            >
              <Link to="/events">
                <Space size={18}>
                  <img src={Calendar} alt=""></img>
                  <h5 className="b3"> Events</h5>
                </Space>
              </Link>
            </Menu.Item>
            {/* <Menu.Item
            key="calendar"
            id="calendar"
            onClick={() => {
              history.push("/calendar");
            }}
            className={
              history.location.pathname === "/calendar" ? "activeMenu" : ""
            }
          >
            <Space size={15}>
              <img src={Calendar} alt=""></img>
              <h5 className="b3">Calendar</h5>
            </Space>
          </Menu.Item> */}
            {data?.role === 'admin' && (
              <Menu.Item
                key="jobrequest"
                id="jobrequest"
                // onClick={() => {
                //   history.push('/jobrequest')
                // }}
                className={
                  history.location.pathname === '/jobrequest'
                    ? 'activeMenu'
                    : ''
                }
              >
                <Link to="/jobrequest">
                  <Space>
                    <img src={work_svg} alt=""></img>{' '}
                    <h5 className="b3">Request</h5>
                  </Space>
                </Link>
              </Menu.Item>
            )}
            {data?.role === 'admin' && (
              <Menu.Item
                key="sessions"
                id="sessions"
                // onClick={() => {
                //   history.push('/sessions')
                // }}
                className={
                  history.location.pathname === '/sessions' ? 'activeMenu' : ''
                }
              >
                <Link to="/sessions">
                  <Space>
                    <img src={sessionIcon_svg} alt=""></img>{' '}
                    <h5 className="b3">Sessions</h5>
                  </Space>
                </Link>
              </Menu.Item>
            )}
            {data?.role === 'admin' && (
              <Menu.Item
                key="jobs"
                id="jobs"
                // onClick={() => {
                //   history.push('/jobs')
                // }}
                className={
                  history.location.pathname === '/jobs' ? 'activeMenu' : ''
                }
              >
                <Link to="/jobs">
                  <Space>
                    <img src={Jobs_svg} alt=""></img>{' '}
                    <h5 className="b3">Jobs</h5>
                  </Space>
                </Link>
              </Menu.Item>
            )}

            <Menu.Item
              key="newsfeed"
              id="newsfeed"
              // onClick={() => {
              //   history.push('/newsfeed')
              // }}
              className={
                history.location.pathname === '/newsfeed' ? 'activeMenu' : ''
              }
            >
              <Link to="/newsfeed">
                <Space>
                  <img alt="alt" src={Newspaper} /> <h5 className="b3">News</h5>
                </Space>
              </Link>
            </Menu.Item>
            {data?.role === 'admin' && (
              <Menu.Item
                key="Locations"
                id="locations"
                // onClick={() => {
                //   history.push('/location')
                // }}
                className={
                  history.location.pathname === '/location' ? 'activeMenu' : ''
                }
              >
                <Link to="/location">
                  <Space>
                    <img alt="alt" src={Location} />{' '}
                    <h5 className="b3">Locations</h5>
                  </Space>
                </Link>
              </Menu.Item>
            )}
            {
              <Menu.Item
                key="Inventory"
                id="inventory"
                // onClick={() => {
                //   history.push('/inventory')
                // }}
                className={
                  history.location.pathname === '/inventory' ? 'activeMenu' : ''
                }
              >
                <Link to="/inventory">
                  <Space>
                    <img alt="Inventory" src={Inventory} />
                    <h5 className="b3">Inventory</h5>
                  </Space>
                </Link>
              </Menu.Item>
            }
            {data?.role === 'admin' && (
              <Menu.Item
                key="Activity"
                id="activity"
                // onClick={() => {
                //   history.push('/activity')
                // }}
                className={
                  history.location.pathname === '/activity' ? 'activeMenu' : ''
                }
              >
                <Link to="/activity">
                  <Space>
                    <img alt="alt" src={activityIcon} />{' '}
                    <h5 className="b3">Activity</h5>
                  </Space>
                </Link>
              </Menu.Item>
            )}
          </Menu.ItemGroup>
        </Menu>
      )}
    </Sider>
  )
}

export default DefaultSidebarV2
