import React, { useEffect } from 'react'
import { Col, Form, FormInstance, Input, Row, Select } from 'antd'
import AgeGroup from './InputComps/AgeGroup'
import GenderSelect from 'helper/GenderSelect'
import { leagueEventStore } from 'state/event/leagueEvent'
import {
  useCreateDivision,
  useUpdateDivsion,
} from 'api/LeagueCreation/leagueDivision'
import { CreateDivisionPayload } from 'types/league/division'
import { myStore } from 'state/store'
import { infoMessage } from 'api/global'
import SkillLevel from './InputComps/SkillLevel'
import AddRegion from '../AddRegion'
import { X } from '@phosphor-icons/react'

interface DivisionFormProps {
  form: FormInstance
}

const DivisionForm: React.FC<DivisionFormProps> = ({ form }) => {
  const {
    leagueData,
    setAddDevisonModal,
    divisionData,
    setIsUpdatingDivision,
    setDivisionData,
    setLeagueData,
    addRegionModal,
    setAddRegionModal,
    regions,
    setRegions,
  } = leagueEventStore(store => ({
    leagueData: store.leagueData,
    setAddDevisonModal: store.setAddDevisonModal,
    divisionData: store.divisionData,
    setIsUpdatingDivision: store.setIsUpdatingDivision,
    setDivisionData: store.setDivisionData,
    setLeagueData: store.setLeagueData,
    addRegionModal: store.addRegionModal,
    setAddRegionModal: store.setAddRegionModal,
    regions: store.regions,
    setRegions: store.setRegions,
  }))
  const setModalBtnLoader = myStore(store => store.setModalBtnLoader)
  const {
    mutate: createDivisionMutation,
    isLoading: creaetDivisionMutationLoading,
  } = useCreateDivision()
  const { mutate: updateDivisionMutation, isLoading: updateDivisionLoading } =
    useUpdateDivsion()

  const genderLevels = [
    {
      key: 'male',
      name: 'Male',
    },
    {
      key: 'female',
      name: 'Female',
    },
    {
      key: 'coed',
      name: 'Co Education',
    },
  ]

  const formSchema = {
    ageGroupId: divisionData ? divisionData.ageGroup._id : undefined,
    gender: divisionData ? divisionData.gender : undefined,
    skillLevelId: divisionData ? divisionData.skillLevel._id : undefined,
  }

  const onFinish = (values: CreateDivisionPayload) => {
    if (!leagueData._id)
      return infoMessage('League is not created! Something went wrong')
    if (!regions.length) return infoMessage('Region is required')
    const payload: CreateDivisionPayload = { ...values }
    payload.organizationId = leagueData.organizationId
    payload.regions = regions

    if (divisionData) {
      updateDivisionMutation(
        { divisionId: divisionData._id, payload },
        {
          onSuccess: () => {
            setIsUpdatingDivision(false)
            setDivisionData(null)
            setRegions([])
            setAddDevisonModal(false)
          },
        },
      )
    } else {
      createDivisionMutation(
        { leagueId: leagueData._id, payload },
        {
          onSuccess: () => {
            setLeagueData({
              ...leagueData,
              statuses: { ...leagueData.statuses, divisions: true },
            })
            setRegions([])
            setAddDevisonModal(false)
          },
        },
      )
    }
  }

  useEffect(() => {
    setModalBtnLoader(creaetDivisionMutationLoading || updateDivisionLoading)
  }, [creaetDivisionMutationLoading || updateDivisionLoading])

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        initialValues={formSchema}
        onFinish={onFinish}
      >
        <Row>
          <Col span={24}>
            <label className="input-label required-input">Age group</label>
            <Form.Item
              name={divisionData ? 'ageGroupId' : 'ageGroupIds'}
              className="form-control allergies-field "
              rules={[{ required: true, message: 'Age group is required' }]}
            >
              <AgeGroup
                data={divisionData ? divisionData.ageGroup : undefined}
                form={form}
                multiple={divisionData ? false : true}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="mt-10px">
            <label className="input-label required-input">Genders</label>
            <Form.Item
              name={divisionData ? 'gender' : 'genders'}
              rules={[{ required: true, message: 'Gender is required' }]}
            >
              <Select
                className="h-50px select-field"
                defaultValue={divisionData ? divisionData.gender : undefined}
                mode={divisionData ? undefined : 'multiple'}
                placeholder={'Select'}
              >
                {genderLevels.map((gender: { key: string; name: string }) => (
                  <Select.Option key={gender.key} value={gender.key}>
                    {gender.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="mt-10px">
            <label className="input-label required-input">Skill level</label>
            <Form.Item
              name={divisionData ? 'skillLevelId' : 'skillLevelIds'}
              rules={[{ required: true, message: 'skill is required' }]}
            >
              <SkillLevel
                data={divisionData ? divisionData.skillLevel : undefined}
                form={form}
                multiple={divisionData ? false : true}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label className="input-label required-input">Region</label>
            <Input
              readOnly
              placeholder="Add Region"
              onClick={() => setAddRegionModal(true)}
            />
          </Col>
          <Col span={24} className="mt-15px">
            <div className="flex gap-10px">
              {regions.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex align-center justify-center gap-10px bg-grey py-10px px-10px radius-full w-fit"
                  >
                    <p>{item}</p>
                    <X
                      size={20}
                      color="#000"
                      className="pointer"
                      onClick={() =>
                        setRegions(regions.filter((_, i) => i !== index))
                      }
                    />
                  </div>
                )
              })}
            </div>
          </Col>
        </Row>
      </Form>

      {addRegionModal && <AddRegion />}
    </>
  )
}

export default DivisionForm
