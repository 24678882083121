import { Trash } from '@phosphor-icons/react'
import { convertMinutesToLocalTime } from 'helper/utility'
import React from 'react'
import { communityStore } from 'state/event/community'
import { leagueEventStore } from 'state/event/leagueEvent'
import { WeekDays } from 'types/globalTypes'

interface TimeFieldProps {
  startTime: number
  endTime: number
  day: WeekDays
  index: number
}

const TimeField: React.FC<TimeFieldProps> = ({
  startTime,
  endTime,
  day,
  index,
}) => {
  const { timeSlots, setTimeSlots } = communityStore(store => ({
    timeSlots: store.timeSlots,
    setTimeSlots: store.setTimeSlots,
  }))

  const handleDeleteTime = (day: WeekDays, index: number) => {
    const dayKey = day.toLocaleLowerCase()
    const updatedDaySlots = [...timeSlots[dayKey]]
    updatedDaySlots.splice(index, 1)

    setTimeSlots({
      ...timeSlots,
      [dayKey]: updatedDaySlots,
    })
  }

  return (
    <div className="flex align-center gap-20px justify-between">
      <div className="flex align-center gap-16px">
        <div className="border-1px radius-12 py-12px px-30px">
          <p className="text-14 w-500">
            {convertMinutesToLocalTime(startTime)}
          </p>
        </div>
        <p>-</p>
        <div className="border-1px radius-12 py-12px px-30px">
          <p className="text-14 w-500">{convertMinutesToLocalTime(endTime)}</p>
        </div>
      </div>
      <div>
        <Trash
          size={24}
          color="#ff6961"
          weight="thin"
          className="pointer"
          onClick={() => handleDeleteTime(day, index)}
        />
      </div>
    </div>
  )
}

export default TimeField
