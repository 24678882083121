import React, { useEffect, useState } from 'react'
import { Col, Form, FormInstance, Input, Row } from 'antd'
import { validatePassword } from 'helper/utility'
import checker from 'assets/Images/checker.svg'
import active_checker from 'assets/Images/active_checker.svg'
import { organizerStore } from 'state/organizer/organizer'
import { useCreateOrganizer } from 'api/Organizers'
import OrganizerDataType from 'types/organizer'
import { myStore } from 'state/store'

interface Step2Props {
  form: FormInstance
}

interface OrganizerStatesProps {
  setOrganizerCurrentStep: (state: number) => void
  organizerData: OrganizerDataType
  setOrganizerData: (state: OrganizerDataType) => void
  setOrganizerId: (state: string) => void
}

const Step2: React.FC<Step2Props> = ({ form }) => {
  const {
    setOrganizerCurrentStep,
    organizerData,
    setOrganizerData,
    setOrganizerId,
  }: OrganizerStatesProps = organizerStore(store => ({
    setOrganizerCurrentStep: store.setOrganizerCurrentStep,
    organizerData: store.organizerData,
    setOrganizerData: store.setOrganizerData,
    setOrganizerId: store.setOrganizerId,
  }))
  const setModalBtnLoader = myStore(store => store.setModalBtnLoader)
  const { mutate: createOrganizerMutation, isLoading: createOrganizerLoading } =
    useCreateOrganizer()
  const [passwordConditions, setPasswordConditions] = useState({
    length: false,
    containsNumbers: false,
    containsUppercase: false,
  })
  const handlePasswordChange = (password: string) => {
    setPasswordConditions({
      length: password.length >= 8,
      containsNumbers: /\d/.test(password),
      containsUppercase: /[A-Z]/.test(password),
    })
  }

  const confirmPassword = ({ getFieldValue }: any) => ({
    validator(_: any, value: string) {
      if (getFieldValue('password') === value) {
        return Promise.resolve()
      }
      return Promise.reject(new Error('Passwords do not match'))
    },
  })

  const onFinish = (values: { password: string; confirmPassword: string }) => {
    const payload = { ...organizerData, password: values.password }
    createOrganizerMutation(payload, {
      onSuccess: data => {
        setOrganizerData(data)
        setOrganizerId(data._id)
        setOrganizerCurrentStep(2)
      },
    })
  }

  useEffect(() => {
    setModalBtnLoader(createOrganizerLoading)
  }, [createOrganizerLoading])

  return (
    <div className="mt-20px">
      <Form
        name="create-password-form"
        form={form}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
        onValuesChange={changedValues => {
          if (changedValues.password) {
            handlePasswordChange(changedValues.password)
          }
        }}
      >
        <p className="text-14 w-400 mt-20px">
          Please create a secure password.
        </p>
        <Row gutter={24} className="mb-40 mt-15px">
          <Col span={24}>
            <Form.Item
              name="password"
              className="password-field"
              rules={[{ validator: validatePassword }]}
              hasFeedback
            >
              <Input.Password placeholder="Enter new password" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="confirmPassword"
              className="password-field"
              dependencies={['password']}
              rules={[confirmPassword(form)]}
              hasFeedback
            >
              <Input.Password placeholder="Re-enter new password" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <div className="w-100 d-flex gap-20">
              <div>
                {passwordConditions.length ? (
                  <img alt="valid" src={active_checker} className="mr-10" />
                ) : (
                  <img alt="invalid" src={checker} className="mr-10" />
                )}
                8 characters
              </div>
              <div>
                {passwordConditions.containsNumbers ? (
                  <img alt="valid" src={active_checker} className="mr-10" />
                ) : (
                  <img alt="invalid" src={checker} className="mr-10" />
                )}
                1 number
              </div>
              <div>
                {passwordConditions.containsUppercase ? (
                  <img alt="valid" src={active_checker} className="mr-10" />
                ) : (
                  <img alt="invalid" src={checker} className="mr-10" />
                )}
                1 capital letter
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Step2
