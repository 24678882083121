import React, { useEffect, useState } from 'react'
import { Button, Empty, Row } from 'antd'
import { Plus } from '@phosphor-icons/react'
import { leagueEventStore } from 'state/event/leagueEvent'
import AddDevision from './Modals/AddDevision'
import {
  useDeleteDivision,
  useGetLeagueDivisions,
} from 'api/LeagueCreation/leagueDivision'
import { DivisonListingResp } from 'types/league/division'
import Spinner from 'components/Spinner'
import AlertModal from 'helper/AlertModal'
import { infoMessage } from 'api/global'
import Division from './Division'
interface LeagueStep3Props {
  handleNext: () => void
  handleBack: () => void
}

const Step3: React.FC<LeagueStep3Props> = ({ handleNext, handleBack }) => {
  const {
    addDevisionModal,
    setAddDevisonModal,
    divisionData,
    setDivisionData,
    leagueData,
    setIsUpdatingDivision,
    setLeagueData,
  } = leagueEventStore(store => ({
    addDevisionModal: store.addDevisionModal,
    setAddDevisonModal: store.setAddDevisonModal,
    divisionData: store.divisionData,
    setDivisionData: store.setDivisionData,
    leagueData: store.leagueData,
    setIsUpdatingDivision: store.setIsUpdatingDivision,
    setLeagueData: store.setLeagueData,
  }))
  const [alertModal, setAlertModal] = useState<boolean>(false)
  const {
    data: devisionsListData,
    isLoading: devisionsListLoading,
    isFetching: devisionsListFetching,
    refetch: refetchDevisionsList,
  } = useGetLeagueDivisions(leagueData._id)
  const { mutate: deleteDivisionMutation, isLoading: deleteDivisionLoading } =
    useDeleteDivision()

  const handleYesDelete = () => {
    deleteDivisionMutation(divisionData._id, {
      onSuccess: () => {
        devisionsListData.length === 1 &&
          setLeagueData({
            ...leagueData,
            statuses: { ...leagueData.statuses, divisions: false },
          })
        setAlertModal(false)
        setDivisionData(null)
      },
      onError: () => {
        setAlertModal(false)
        setDivisionData(null)
      },
    })
  }
  const alertModalData = {
    name: 'Delete',
    heading: 'Delete discount',
    message1: 'Are you sure you want to delete discount?',
  }

  const hanldeEditDivision = (division: DivisonListingResp) => {
    setDivisionData(division)
    setIsUpdatingDivision(true)
    setAddDevisonModal(true)
  }

  const handleDeleteDivsion = (division: DivisonListingResp) => {
    setDivisionData(division)
    setAlertModal(true)
  }

  const handleAddDivisionName = (divisionId: string) => {
    console.log(divisionId)
  }
  const handleAddDivisionPrice = (divisionId: string) => {
    console.log(divisionId)
  }

  const handleNextClick = () => {
    if (!devisionsListData?.length) return infoMessage('Please add devisions')
    handleNext()
  }

  useEffect(() => {
    refetchDevisionsList()
  }, [])

  return (
    <div className="mt-15px">
      <h2 className="text-24 w-600">Add divisions</h2>
      {devisionsListLoading || devisionsListFetching ? (
        <div className="py-20px flex align-center justify-center">
          <Spinner />
        </div>
      ) : (
        <div
          className="overflow-auto-y scroller"
          style={{ height: `calc(100vh - 400px)` }}
        >
          {!devisionsListData.length ? (
            <div className="flex flex-col align-center justify-center">
              <Empty />
              <p className="text-gray">No divison created yet</p>
            </div>
          ) : (
            <>
              {devisionsListData.map(
                (item: DivisonListingResp, index: number) => {
                  return (
                    <Division
                      key={index}
                      item={item}
                      hanldeEditDivision={hanldeEditDivision}
                      handleDeleteDivsion={handleDeleteDivsion}
                      handleAddDivisionName={handleAddDivisionName}
                      handleAddDivisionPrice={handleAddDivisionPrice}
                    />
                  )
                },
              )}
            </>
          )}
        </div>
      )}

      <div className="btn-container">
        <Row justify="space-between" className="mb-26 mt-40px">
          <Button
            className="cancel-button flex align-center justify-center gap-10px"
            onClick={() => {
              setAddDevisonModal(true)
            }}
          >
            <Plus size={20} color="#000" />
            <span>Add devision</span>
          </Button>
          <Button className="stepper-button" onClick={handleNextClick}>
            Next
          </Button>
        </Row>
      </div>

      {addDevisionModal && <AddDevision />}

      {alertModal && (
        <AlertModal
          isModalVisible={alertModal}
          setIsModalVisible={setAlertModal}
          data={alertModalData}
          onYesClick={handleYesDelete}
          loading={deleteDivisionLoading}
        />
      )}
    </div>
  )
}

export default Step3
