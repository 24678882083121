import React from 'react'
import { Modal } from 'antd'
import clsoeIcon from 'assets/Images/close.svg'
import { InventoryResponse } from 'types/event/events'
import '../style/styles.scss'
interface ViewSupplyModalProps {
  modalOpen: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  data: InventoryResponse
  selectedSupplies: string[]
  setSelectedSupllies: React.Dispatch<React.SetStateAction<string[]>>
}

const ViewSupplyModal: React.FC<ViewSupplyModalProps> = ({
  modalOpen,
  setModalOpen,
  data,
  selectedSupplies,
  setSelectedSupllies,
}) => {
  const handleCancel = () => {
    setModalOpen(false)
  }

  const hanldeSelect = () => {
    const isSelected = selectedSupplies.includes(data._id)
    if (isSelected) {
      setSelectedSupllies(selectedSupplies.filter(id => id !== data._id))
    } else {
      setSelectedSupllies([...selectedSupplies, data._id])
    }
    handleCancel()
  }

  return (
    <div>
      <Modal
        open={modalOpen}
        onCancel={handleCancel}
        className="select-activityModal"
        footer={false}
      >
        <div className="flex-center pt-20px justify-between ">
          <h2 className="w-600 text-18">Inventory</h2>
          <img
            src={clsoeIcon}
            alt="close-button"
            onClick={handleCancel}
            className="close button pointer"
          />
        </div>

        <div className="supply-view scroller overflow-auto-y h-30rem ">
          {data.image?.post && (
            <div className="img-container mt-20px">
              <img src={data.image?.post} alt={data.name} />
            </div>
          )}
          <h3 className="mt-15px text-16 w-600">{data.name}</h3>
          <div className="mt-10px flex gap-10px">
            <span className="text-14 w-600">Unit {data.totalQuantity}:</span>
            <span className="text-14 w-400 text-gray">${data.price}</span>
          </div>

          <div className="mt-10px">
            <h3 className="text-14 w-500 text-gray">Storage locations</h3>
            {data.storageLocations.map(loc => {
              return (
                <p className="text-black">{`${loc.locationId.title}: ${loc.locationId.street}, ${loc.locationId.city}`}</p>
              )
            })}
          </div>

          <div className="mt-10px">
            <h3 className="text-14 w-500 text-gray">Weight</h3>
            <p className="text-black">{`${data.weight.value} ${data.weight.unit}`}</p>
          </div>

          <div className="mt-10px">
            <h3 className="text-14 w-500 text-gray">Description</h3>
            <p className="text-black">{data.description}</p>
          </div>

          <div className="mt-10px">
            <h3 className="text-14 w-500 text-gray">Group title</h3>
            <div className="mt-10px flex flex-wrap align-center gap-10px">
              {data.group.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="border-1px px-10px py-5px radius-full flex justify-center align-center bg-grey"
                  >
                    {item}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="mt-10px flex align-center justify-between">
          <button className="create-activity-btn" onClick={handleCancel}>
            Cancel
          </button>
          <button className="confirm-btn" onClick={hanldeSelect}>
            {selectedSupplies.includes(data._id) ? 'Deselect' : 'Select'}
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default ViewSupplyModal
