import React from 'react'
import AppLayout from 'components/layout/layout'
import './styles/styles.scss'
import { leagueEventStore } from 'state/event/leagueEvent'
import { leagueSteps } from './constants'
import Step1 from './screens/Step1/Step1'
import { infoMessage } from 'api/global'
import Step2 from './screens/Step2/Step2'
import Step3 from './screens/Step3/Step3'
import Step4 from './screens/Step4/Step4'
import Step5 from './screens/Step5/Step5'
import Step6 from './screens/Step6/Step6'

const CreateLeague: React.FC = () => {
  const { leagueStepCounter, setLeagueStepCounter } = leagueEventStore(
    store => ({
      leagueStepCounter: store.leagueStepCounter,
      setLeagueStepCounter: store.setLeagueStepCounter,
    }),
  )

  const handleNextClick = () => {
    if (leagueStepCounter === leagueSteps.length) {
      return infoMessage('You have reached the end of the wizard')
    } else {
      setLeagueStepCounter(leagueStepCounter + 1)
    }
  }

  const handleBackClick = () => {
    if (leagueStepCounter === 0) {
      return infoMessage('You have reached the start of the wizard')
    } else {
      setLeagueStepCounter(leagueStepCounter - 1)
    }
  }

  return (
    <AppLayout>
      <div className="create-league-main-content-wapper w-100">
        <div className="create-league-main-content-cotainer">
          <h2 className="text-24 w-600">
            Step {leagueStepCounter + 1}. {leagueSteps[leagueStepCounter]}
          </h2>
          <div className="mt-20px">
            {leagueStepCounter === 0 && (
              <Step1
                handleNext={handleNextClick}
                handleBack={handleBackClick}
              />
            )}
            {leagueStepCounter === 1 && (
              <Step2
                handleNext={handleNextClick}
                handleBack={handleBackClick}
              />
            )}
            {leagueStepCounter === 2 && (
              <Step3
                handleNext={handleNextClick}
                handleBack={handleBackClick}
              />
            )}
            {leagueStepCounter === 3 && (
              <Step4
                handleNext={handleNextClick}
                handleBack={handleBackClick}
              />
            )}
            {leagueStepCounter === 4 && (
              <Step5
                handleNext={handleNextClick}
                handleBack={handleBackClick}
              />
            )}
            {leagueStepCounter === 5 && (
              <Step6
                handleNext={handleNextClick}
                handleBack={handleBackClick}
              />
            )}
          </div>
        </div>
      </div>
    </AppLayout>
  )
}

export default CreateLeague
