import { create } from 'zustand'

const activityCategoryListingStore = (set: any) => ({
  addActivityCategoryModal: false,
  setAddActivityCategoryModal: (state: boolean) =>
    set({ addActivityCategoryModal: state }),
  activityCategoryListingData: null,
  setActivityCategoryListingData: (state: any) =>
    set({ activityCategoryListingData: state }),
})

export const activityCategoryStore = create(activityCategoryListingStore)
