import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { Avatar, Table } from 'antd'

import { capitalizeFirstLetter, getNameSignature } from 'helper/utility'
import { InventoryResponse } from 'types/event/events'

interface InventoryItem {
  key: string
  name: string
  icon: string
  storageLocation: string
  quantity: number
  price: number
  type: string
}

interface InventoryTableProps {
  dataset: InventoryResponse[]
}

const InventoryTable: React.FC<InventoryTableProps> = ({ dataset }) => {
  const columns: ColumnsType<InventoryItem> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: InventoryItem) => (
        <div className="flex align-center gap-10px">
          <Avatar src={record.icon} size={40}>
            {getNameSignature(text)}
          </Avatar>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: 'Storage locations',
      dataIndex: 'storageLocation',
      key: 'storageLocation',
    },
    {
      title: 'Quantity, unit',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'center',
    },
    {
      title: 'Price, USD',
      dataIndex: 'price',
      key: 'price',
      render: (price: number) => price.toFixed(2),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text: string) => text || '-',
    },
  ]

  const data: InventoryItem[] = dataset.map(
    (inventoryItem: InventoryResponse, index: number) => {
      return {
        key: index.toString(),
        name: inventoryItem.name,
        icon: inventoryItem.image?.post,
        storageLocation: `${inventoryItem.storageLocations[0].locationId.street},${inventoryItem.storageLocations[0].locationId.city}, ${inventoryItem.storageLocations[0].locationId.country}`,
        quantity: inventoryItem.totalQuantity,
        price:
          inventoryItem.price ||
          inventoryItem.priceRange?.min - inventoryItem.priceRange?.max,
        type: capitalizeFirstLetter(inventoryItem.type),
      }
    },
  )

  return (
    <div className="mt-20px">
      <Table<InventoryItem>
        className="colored_header_table"
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </div>
  )
}

export default InventoryTable
