import { Faqs } from 'types/event/eventStep5'
import { create } from 'zustand'

const eventCommonStoreState = (set: any) => ({
  // modal
  faqModalOpen: false,
  setFaqModalOpen: (state: boolean) =>
    set(() => ({
      faqModalOpen: state,
    })),
  addQuestionModal: false,
  setAddQuestionModal: (state: boolean) =>
    set(() => ({ addQuestionModal: state })),

  // data sets
  faqData: undefined,
  setFaqData: (state: Faqs) => set(() => ({ faqData: state })),
  selectedQuestions: [],
  setSelectedQuestions: (state: string[]) =>
    set(() => ({
      selectedQuestions: state,
    })),
})

export const eventCommonStore = create(eventCommonStoreState)
