import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { axiosGet, axiosPatch, axiosPost } from 'Redux/axiosHelper'
import {
  CreateEventCategoryPayload,
  EventCategoryListing,
} from 'types/listing/listings'

const getEventCategories = async () => {
  const response = await axiosGet(`v1/eventCategory/`)
  return response.data
}
export const useGetEventCategories = () => {
  const eventCategories = useQuery<EventCategoryListing[]>(
    ['all-event-categories'],
    getEventCategories,
    {
      enabled: false,
    },
  )
  return eventCategories
}

// create new event category
const createEventCategory = async (payload: CreateEventCategoryPayload) => {
  const response = await axiosPost(payload, `v1/eventCategory`)
  return response.data
}
export const useCreateEventCategory = () => {
  const queryClient = useQueryClient()
  return useMutation(createEventCategory, {
    onSuccess: (newEventCategory: EventCategoryListing) => {
      queryClient.setQueryData(
        ['all-event-categories'],
        (oldData: EventCategoryListing[] | undefined) => {
          if (!oldData) return [newEventCategory]
          return [...oldData, newEventCategory]
        },
      )
    },
  })
}

// update event category
const updateEventCategory = async ({
  eventCategoryId,
  payload,
}: {
  eventCategoryId: string
  payload: CreateEventCategoryPayload
}) => {
  const response = await axiosPatch(
    payload,
    `v1/eventCategory/${eventCategoryId}`,
  )
  return response.data
}

export const useUpdateEventCategory = () => {
  const queryClient = useQueryClient()
  return useMutation(updateEventCategory, {
    onSuccess: (updatedEventCategory: EventCategoryListing) => {
      queryClient.setQueryData(
        ['all-event-categories'],
        (oldData: EventCategoryListing[] | undefined) => {
          if (!oldData) return [updatedEventCategory]
          return oldData.map((eventCategory: EventCategoryListing) =>
            eventCategory._id === updatedEventCategory._id
              ? updatedEventCategory
              : eventCategory,
          )
        },
      )
    },
  })
}
