import React, { useEffect } from 'react'
import TeamConstraints from './Constraints/TeamConstraints'
import DivisionConstraints from './Constraints/DivisionConstraints'
import GamesConstraints from './Constraints/GamesConstraints'
import TravelConstraints from './Constraints/TravelConstraints'
import DatesConstraints from './Constraints/DatesConstraints'
import { Button } from 'antd'
import { leagueEventStore } from 'state/event/leagueEvent'
import { useLeagueConstraintsStatus } from 'api/LeagueCreation/leagueCreation'
import { failureMessage } from 'api/global'
import Spinner from 'components/Spinner'

interface Step5Props {
  handleNext: () => void
  handleBack: () => void
}

const Step5: React.FC<Step5Props> = ({ handleNext, handleBack }) => {
  const { leagueData } = leagueEventStore(store => ({
    leagueData: store.leagueData,
  }))
  const {
    data: constraintStatus,
    isLoading: isLoadingConstraintStatus,
    isFetching: isFetchingConstraintStatus,
    refetch: refetchConstraintStatus,
  } = useLeagueConstraintsStatus(leagueData._id)

  useEffect(() => {
    if (!leagueData._id) {
      return failureMessage('LeagueId is not found')
    }
    refetchConstraintStatus()
  }, [leagueData._id, refetchConstraintStatus])

  return (
    <div>
      {isLoadingConstraintStatus || isFetchingConstraintStatus ? (
        <div className="py-40px flex justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          <h2 className="text-24 w-600">League Configuration</h2>
          <div className="mt-20px">
            <TeamConstraints status={constraintStatus} />
          </div>
          <div className="mt-20px">
            <DivisionConstraints status={constraintStatus} />
          </div>
          <div className="mt-20px">
            <GamesConstraints status={constraintStatus} />
          </div>
          <div className="mt-20px">
            <TravelConstraints status={constraintStatus} />
          </div>
          {/* <div className="mt-20px">
            <OfficialConstraints />
          </div> */}
          <div className="mt-20px">
            <DatesConstraints status={constraintStatus} />
          </div>
        </>
      )}
      <div className="flex align-center justify-between mb-26 mt-40px">
        <Button
          className="cancel-button flex align-center justify-center gap-10px"
          onClick={handleBack}
        >
          Back
        </Button>
        <Button className="stepper-button" onClick={handleNext}>
          Next
        </Button>
      </div>
    </div>
  )
}

export default Step5
