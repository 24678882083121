import React, { useEffect, useState } from 'react'
import AllMessages from './Sections/AllMessages'
import MessageContainer from './Sections/MessageContainer'
import './styles.scss'
import RecepientBox from 'components/global/chat/RecepientBox'
import { ChatRoomsType } from 'types/chat'
import { useGetEventParticipants } from 'api/Events'
import { useParams } from 'react-router-dom'
import Spinner from 'components/Spinner'
import { useGetAdminDetails } from 'api/Overview'
import { eventShatStore } from 'state/event/eventChat'
import { Person } from 'types/globalTypes'

interface MainChatProps {
  chatRoomIds: ChatRoomsType[]
}

const MainChat: React.FC<MainChatProps> = ({ chatRoomIds }) => {
  const { id }: { id: string } = useParams()
  const [showReceipientContainer, setShowReceipientContainer] = useState(true)
  const { activeChatRoom, setActiveChatRoom, setSelectedChatRoomMembers } =
    eventShatStore(store => ({
      activeChatRoom: store.selectedChatRoomId,
      setActiveChatRoom: store.setSelectedChatRoomId,
      setSelectedChatRoomMembers: store.setSelectedChatRoomMembers,
    }))

  const {
    data: eventParticipantsData,
    isLoading: isEventParticipantsLoading,
    isFetching: isEventParticipantsFetching,
    refetch: refetchEventParticipants,
  } = useGetEventParticipants(id)

  const {
    data: adminData,
    isLoading: isAdminLoading,
    isFetching: isAdminFetching,
    refetch: refetchAdminData,
  } = useGetAdminDetails()

  useEffect(() => {
    refetchEventParticipants()
    refetchAdminData()
  }, [id])

  useEffect(() => {
    setActiveChatRoom(chatRoomIds && chatRoomIds[0]?.id)
    setSelectedChatRoomMembers(chatRoomIds && chatRoomIds[0]?.data.memberIds)
  }, [chatRoomIds])

  return (
    <div className="flex bg-white h-full">
      {isEventParticipantsLoading ||
      isEventParticipantsFetching ||
      isAdminLoading ||
      isAdminFetching ? (
        <div className="py-40px w-full flex justify-center align-center">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="all-chats-name">
            <AllMessages
              chatRoomIds={chatRoomIds}
              participants={[
                ...eventParticipantsData.kids.map((kid: Person) => ({
                  ...kid,
                  role: 'kid',
                })),
                ...eventParticipantsData.guardians.map((guardian: Person) => ({
                  ...guardian,
                  role: 'guardian',
                })),
                ...eventParticipantsData.authorizedAdults.map(
                  (adult: Person) => ({
                    ...adult,
                    role: 'authorizedAdult',
                  }),
                ),
                { ...adminData },
              ]}
              activeChatRoom={activeChatRoom}
              setActiveChatRoom={setActiveChatRoom}
            />
          </div>
          <div
            className={`message-container px-20px ${
              showReceipientContainer ? 'recipient' : 'recipient-hidden'
            }`}
          >
            <MessageContainer
              showReciepientContainer={showReceipientContainer}
              setShowReceipientContainer={setShowReceipientContainer}
              activeChatRoomId={activeChatRoom}
              me={adminData}
              participants={[
                ...eventParticipantsData.kids.map((kid: Person) => ({
                  ...kid,
                  role: 'kid',
                })),
                ...eventParticipantsData.guardians.map((guardian: Person) => ({
                  ...guardian,
                  role: 'guardian',
                })),
                ...eventParticipantsData.authorizedAdults.map(
                  (adult: Person) => ({
                    ...adult,
                    role: 'authorizedAdult',
                  }),
                ),
                { ...adminData },
              ]}
            />
          </div>
          <div
            className={`recepient-container scroller overflow-auto-y ${
              showReceipientContainer ? '' : 'hidden'
            }`}
          >
            {[
              ...eventParticipantsData.kids.map((kid: Person) => ({
                ...kid,
                role: 'kid',
              })),
              ...eventParticipantsData.guardians.map((guardian: Person) => ({
                ...guardian,
                role: 'guardian',
              })),
              ...eventParticipantsData.authorizedAdults.map(
                (adult: Person) => ({
                  ...adult,
                  role: 'authorizedAdult',
                }),
              ),
              { ...adminData },
            ].map(item => {
              return (
                <RecepientBox
                  key={item._id}
                  avatar={item.photo?.post}
                  name={item.firstName}
                  role={item.role}
                />
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

export default MainChat
