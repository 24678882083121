import { create } from 'zustand'

const communityStoreState = (set: any) => ({
  communityStepCounter: 0,
  setCommunityStepCounter: (state: number) =>
    set(() => ({ communityStepCounter: state })),

  //   datasets
  communityData: null,
  setCommunityData: (state: number) => set(() => ({ communityData: state })),

  timeSlots: {},
  setTimeSlots: (state: any) =>
    set(() => ({
      timeSlots: state,
    })),

  // modals
  addTimeModal: false,
  setAddTimeModal: (state: boolean) => set(() => ({ addTimeModal: state })),
  copyTimeModal: false,
  setCopyTimeModal: (state: boolean) => set(() => ({ copyTimeModal: state })),
  addSpecialSchedule: false,
  setAddSpecialSchedule: (state: boolean) =>
    set(() => ({ addSpecialSchedule: state })),
  statusModalVisible: false,
  setStatusModalVisible: (state: boolean) =>
    set(() => ({ statusModalVisible: state })),
})

export const communityStore = create(communityStoreState)
