import React, { useEffect, useState } from 'react'
import { Button, Col, Row, TimePicker } from 'antd'
import CustomModal from 'components/global/CustomModal'
import {
  convertMinutesToLocalTime,
  convertTimeToUtcMinutes,
} from 'helper/utility'
import moment from 'moment'
import { Moment } from 'moment'
import Calendar from 'react-calendar'
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { SpecialSchedule } from 'types/globalTypes'
import { externalStore } from 'state/event/external'

const SpecialScheduleModal = ({
  addSpecialScheduleModalVisible,
  setAddSpecialScheduleModalVisible,
  specialScheduleData,
  setSpecialScheduleData,
  selectedDates,
  setEventTime,
}) => {
  const { externalData } = externalStore(store => ({
    externalData: store.externalData,
  }))
  const [specialScheduleInputData, setSpecialScheduleInputData] = useState([])
  const [defaultTime, setDefaultTime] = useState({
    startTime: externalData.timings.startTime
      ? moment(
          convertMinutesToLocalTime(externalData.timings.startTime, 'HH:mm'),
          'HH:mm',
        )
      : moment('9:00', 'HH:mm'),
    endTime: externalData.timings.endTime
      ? moment(
          convertMinutesToLocalTime(externalData.timings.endTime, 'HH:mm'),
          'HH:mm',
        )
      : moment('15:00', 'HH:mm'),
  })

  useEffect(() => {
    setSpecialScheduleInputData([...specialScheduleData])
  }, [specialScheduleData])
  useEffect(() => {
    setEventTime(defaultTime)
  }, [defaultTime])

  const [selectedDate, setSelectedDate] = useState<any>('')

  const handleTileClick = (date: any) => {
    const formattedDate = moment(date).format('YYYY-MM-DD')
    setSelectedDate(formattedDate)
  }

  const tileDisabled = ({ date }: { date: Date }) =>
    !selectedDates.find((d: string) => d === moment(date).format('YYYY-MM-DD'))

  const addSpecialscheduleInput = (date: String) => {
    setSpecialScheduleInputData([
      ...specialScheduleInputData,
      {
        day: date,
        startTime: 240,
        endTime: 600,
      },
    ])
  }

  const handleDefaultStartTimeChange = (
    time: Moment | null,
    timeString: string,
  ) => {
    setDefaultTime({ ...defaultTime, startTime: moment(time) })
  }
  const handleDefaultEndTimeChange = (
    time: Moment | null,
    timeString: string,
  ) => {
    setDefaultTime({ ...defaultTime, endTime: moment(time) })
  }

  const handleStartTimeChange = (time: Moment | null, timeString: string) => {
    let objIndex = specialScheduleInputData.findIndex(
      (obj: any) => obj.day == selectedDate,
    )
    specialScheduleInputData[objIndex].startTime =
      convertTimeToUtcMinutes(timeString)
  }
  const handleEndTimeChange = (time: Moment | null, timeString: string) => {
    let objIndex = specialScheduleInputData.findIndex(
      (obj: any) => obj.day == selectedDate,
    )
    specialScheduleInputData[objIndex].endTime =
      convertTimeToUtcMinutes(timeString)
  }

  const removeSpecialscheduleInput = (index: number) => {
    const current: any = specialScheduleInputData[index]
    setSpecialScheduleInputData([
      ...specialScheduleInputData.filter(
        (data: any) => data.day !== current.day,
      ),
    ])
  }

  return (
    <CustomModal
      openModal={addSpecialScheduleModalVisible}
      closeModal={setAddSpecialScheduleModalVisible}
      title="Add time"
      width={500}
      footer={false}
    >
      <Col>
        <div>
          <div className="">
            <Row className="mt-20">
              <div style={{ marginBottom: 20 }}>
                <Calendar
                  onClickDay={handleTileClick}
                  tileDisabled={tileDisabled}
                  tileClassName={({ date, view }) => {
                    if (selectedDate === moment(date).format('YYYY-MM-DD')) {
                      return 'selected'
                    }
                  }}
                />
              </div>

              <div className="mt-20px">
                <h2 className="text-14 w-600">Weekly default schedule</h2>
                <div className="flex align-center gap-16px mt-15px w-80p">
                  <TimePicker
                    className="w-100 form-control-field"
                    placeholder="Start Time"
                    format="h:mm a"
                    value={defaultTime.startTime}
                    showNow={false}
                    clearIcon={false}
                    onChange={handleDefaultStartTimeChange}
                  />
                  <div>-</div>
                  <TimePicker
                    className="w-100 form-control-field"
                    placeholder="End Time"
                    format="h:mm a"
                    value={defaultTime.endTime}
                    showNow={false}
                    clearIcon={false}
                    onChange={handleDefaultEndTimeChange}
                  />
                </div>
              </div>

              <Col
                span={24}
                className="mt-15px flex gap-10px justify-between align-center"
              >
                <span style={{ marginBottom: 10 }}>
                  <h2 className="text-14 w-600">Add special schedule</h2>
                </span>
                <PlusCircleOutlined
                  onClick={() => {
                    if (selectedDate) {
                      console.log(specialScheduleInputData)
                      if (
                        specialScheduleInputData.find(
                          (d: any) => d.day === selectedDate,
                        )
                      ) {
                        console.log('do nothing')
                      } else {
                        addSpecialscheduleInput(selectedDate)
                      }
                    }
                  }}
                />
              </Col>
              <Col span={24}>
                {specialScheduleInputData.map((s: SpecialSchedule, index) => {
                  return (
                    <Row className="d-flex justify-between align-items-center mb-10 w-full">
                      <Col span={24}>
                        <span style={{ marginBottom: 10 }}>
                          <b>{s?.day}</b>
                        </span>
                      </Col>
                      <div className="flex justify-between align-center w-full">
                        <div className="flex align-center gap-16px mt-15px">
                          <TimePicker
                            className="w-100 form-control-field"
                            placeholder="Start Time"
                            format="h:mm a"
                            showNow={false}
                            clearIcon={false}
                            defaultValue={moment(
                              convertMinutesToLocalTime(s.startTime),
                              'hh:mm A',
                            )}
                            onChange={handleStartTimeChange}
                          />
                          <div>-</div>
                          <TimePicker
                            className="w-100 form-control-field"
                            placeholder="End Time"
                            format="h:mm a"
                            showNow={false}
                            clearIcon={false}
                            defaultValue={moment(
                              convertMinutesToLocalTime(s.endTime),
                              'hh:mm A',
                            )}
                            onChange={handleEndTimeChange}
                          />
                        </div>

                        <DeleteOutlined
                          onClick={() => removeSpecialscheduleInput(index)}
                          style={{
                            color: '#FF6961',
                            paddingLeft: '5px',
                          }}
                        />
                      </div>
                    </Row>
                  )
                })}
              </Col>
            </Row>
          </div>
          <Row>
            <Col className="d-flex">
              <Button
                className="route_button-events br-8"
                onClick={() => setAddSpecialScheduleModalVisible(false)}
              >
                Cancel
              </Button>
              <Button
                className="venue_button-events br-8"
                onClick={() => {
                  setSpecialScheduleData([
                    ...specialScheduleInputData.filter(
                      (data: any) => data.day && data.startTime && data.endTime,
                    ),
                  ])
                  setAddSpecialScheduleModalVisible(false)
                }}
              >
                Apply
              </Button>
            </Col>
          </Row>
        </div>
      </Col>
    </CustomModal>
  )
}

export default SpecialScheduleModal
