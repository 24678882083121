import React, { useEffect, useState } from 'react'
import { Avatar, Checkbox, Dropdown, Empty, Input } from 'antd'
import { organizationStore } from 'state/organization/organization'
import { useGetOrganizationByEmailOrName } from 'api/Organizers'
import Spinner from 'components/Spinner'
import OrganizerDataType from 'types/organizer'
import { getNameSignature } from 'helper/utility'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

const Step3: React.FC = () => {
  const { organizationData, setOrganizationData } = organizationStore(
    store => ({
      organizationData: store.organizationData,
      setOrganizationData: store.setOrganizationData,
    }),
  )
  const [params, setParams] = useState<{ searchString: string }>({
    searchString: '',
  })
  const [selectedOrg, setSelectedOrg] = useState<OrganizerDataType>()
  const dropdownRef = React.useRef<HTMLDivElement>(null)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const handleDropdownClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  const {
    data: organizers,
    isLoading: organizersLoading,
    isFetching: organizersFetching,
    refetch: organizersRefetch,
  } = useGetOrganizationByEmailOrName(params)

  const handleSearch = () => {
    setDropdownOpen(true)
    organizersRefetch()
    setParams({ ...params, searchString: '' })
  }

  const handleItemClick = (
    selectedItem: OrganizerDataType,
    e: React.MouseEvent,
  ) => {
    setSelectedOrg(selectedItem)
    setOrganizationData({
      ...organizationData,
      ownerId: selectedItem._id,
      designation: 'owner',
    })
    setDropdownOpen(false)
  }

  const onChange = (
    e: CheckboxChangeEvent,
    selectedItem: OrganizerDataType,
  ) => {
    e.target.checked && setSelectedOrg(selectedItem)
    console.log(selectedItem, 'selectedItem...')
    e.target.checked &&
      setOrganizationData({
        ...organizationData,
        ownerId: selectedItem._id,
        designation: 'owner',
      })
    setDropdownOpen(false)
  }

  const hanldeRemoveOrg = () => {
    setSelectedOrg(undefined)
    setOrganizationData({
      ...organizationData,
      ownerId: undefined,
      designation: 'owner',
    })
  }

  const items =
    organizersLoading || organizersFetching
      ? [
          {
            key: 'load',
            label: (
              <div
                className="w-full justify-center align-center flex"
                style={{ height: '200px' }}
              >
                <Spinner />
              </div>
            ),
          },
        ]
      : !organizers?.length
      ? [
          {
            key: 'no-data',
            label: (
              <div
                className="w-full justify-center align-center flex flex-col"
                style={{ height: '200px' }}
              >
                <Empty />
                <p>No organization exists with this search term</p>
              </div>
            ),
          },
        ]
      : organizers?.map((item: OrganizerDataType) => ({
          key: item._id,
          label: (
            <div ref={dropdownRef}>
              <div
                className="flex align-center justify-between"
                onClick={e => handleItemClick(item, e)}
              >
                <div className="flex align-center gap-10px">
                  <Avatar src={item.photo?.post} size={34}>
                    {getNameSignature(`${item.firstName} ${item.lastName}`)}
                  </Avatar>
                  <div>
                    <p>
                      {item.firstName} {item.lastName}
                    </p>
                    <span className="text-gray text-12">{item.email}</span>
                  </div>
                </div>
                <Checkbox
                  onChange={e => onChange(e, item)}
                  name={item.firstName}
                  checked={selectedOrg?._id === item._id}
                  className="custom-category-checkbox"
                ></Checkbox>
              </div>
            </div>
          ),
        }))

  return (
    <div className="mt-20px" style={{ minHeight: '300px' }}>
      <>
        <label className="input-label">Search for admin</label>
        <div
          className="flex align-center justify-between gap-20px"
          ref={dropdownRef}
          onClick={handleDropdownClick}
        >
          <Dropdown
            menu={{
              items,
            }}
            placement="bottomLeft"
            open={dropdownOpen}
            trigger={[]}
            className="category-input-block"
            overlayStyle={{
              minWidth: '400px',
              maxWidth: '400px',
              maxHeight: '200px',
              overflowY: 'auto',
              background: '#fff',
              borderRadius: '8px',
              boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            }}
          >
            <Input
              placeholder="Search by email"
              type="email"
              name="email"
              value={params.searchString}
              onChange={e =>
                setParams({ ...params, searchString: e.target.value })
              }
            />
          </Dropdown>
          <button
            disabled={!params.searchString}
            className={`${
              !params.searchString ? 'btn-disabled' : 'borderd-btn'
            }`}
            onClick={handleSearch}
          >
            Search
          </button>
        </div>

        {selectedOrg && (
          <div className="mt-20px flex align-center justify-between gap-20px">
            <div className="flex align-center gap-10px">
              <Avatar src={selectedOrg.photo?.post} size={34}>
                {getNameSignature(
                  `${selectedOrg.firstName} ${selectedOrg.lastName}`,
                )}
              </Avatar>
              <div>
                <p>
                  {selectedOrg.firstName} {selectedOrg.lastName}
                </p>
                <span className="text-gray text-12">{selectedOrg.email}</span>
              </div>
            </div>

            <span
              className="danger-txt text-14 w-600 pointer"
              onClick={hanldeRemoveOrg}
            >
              Remove
            </span>
          </div>
        )}
      </>
    </div>
  )
}

export default Step3
