import React from 'react'
import IconsTextNavigation from 'components/global/IconsTextNavigation'
import { leagueEventStore } from 'state/event/leagueEvent'
import DivisionConstraintModal from '../Modals/Division/DivisionConstraintModal'
import AddDivisionConstraints from '../Modals/Division/AddDivisionConstraints'
import { ConstaintStepStatusesResp } from 'types/league/league'

interface DivisionConstraintsProps {
  status: ConstaintStepStatusesResp
}

const DivisionConstraints: React.FC<DivisionConstraintsProps> = ({
  status,
}) => {
  const {
    divisionConstraintModal,
    setDivisionConstraintModal,
    addDivisionConstraintModal,
    leagueData,
  } = leagueEventStore(store => ({
    divisionConstraintModal: store.divisionConstraintModal,
    setDivisionConstraintModal: store.setDivisionConstraintModal,
    addDivisionConstraintModal: store.addDivisionConstraintModal,
    leagueData: store.leagueData,
  }))
  return (
    <>
      <div onClick={() => setDivisionConstraintModal(true)}>
        <IconsTextNavigation
          iconName="Target"
          heading="Division Constraints"
          isCompleted={status.divisionConstraints}
        />
      </div>
      {divisionConstraintModal && (
        <DivisionConstraintModal leagueId={leagueData._id} />
      )}
      {addDivisionConstraintModal && <AddDivisionConstraints />}
    </>
  )
}

export default DivisionConstraints
