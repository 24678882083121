import { BasicListingType } from 'types/listing/listings'
import { create } from 'zustand'

const gradeCreationStore = (set: any) => ({
  addGradeModal: false,
  setAddGradeModal: (state: boolean) => set(() => ({ addGradeModal: state })),
  gradeData: null,
  setGradeData: (state: BasicListingType) => set(() => ({ gradeData: state })),
})

export const gradeStore = create(gradeCreationStore)
