import { Person } from 'types/globalTypes'
import { create } from 'zustand'

const eventChatCreationStore = (set: any) => ({
  chatModalVisible: false,
  setChatModalVisible: (state: boolean) =>
    set(() => ({ chatModalVisible: state })),

  selectedParticipants: [],
  setSelectedParticipants: (state: string[]) =>
    set(() => ({
      selectedParticipants: state,
    })),
  selectedParticipantsData: [],
  setSelectedParticipantsData: (state: Person[]) =>
    set(() => ({
      selectedParticipantsData: state,
    })),
  selectedParticipantsEmails: [],
  setSelectedParticipantsEmails: (state: string[]) =>
    set(() => ({
      selectedParticipantsEmails: state,
    })),

  selectedChatRoomId: undefined,
  setSelectedChatRoomId: (state: string) =>
    set(() => ({ selectedChatRoomId: state })),
  selectedChatRoomMembers: [],
  setSelectedChatRoomMembers: (state: string[]) =>
    set(() => ({ selectedChatRoomMembers: state })),

  sendingMessage: false,
  setSendingMessage: (state: boolean) => set(() => ({ sendingMessage: state })),
})

export const eventShatStore = create(eventChatCreationStore)
