import React, { useState } from 'react'
import { Plus } from '@phosphor-icons/react'
import AddFee from '../modal/AddFee'
import { creationEventStore } from 'state/event/eventCreationStore'
import StaffAndSalary from './StaffAndSalary'
import EstimatedSummary from './EstimatedSummary'

interface CalculationProps {}

const Calculation: React.FC<CalculationProps> = () => {
  const eventData = creationEventStore(store => store.eventData)
  const [addMoreFees, setAddMoreFees] = useState<boolean>(false)
  const [numberOfStaff, setNumberOfStaff] = useState<number>(1)
  const [estimatedSalary, setEstimatedSalary] = useState<{
    min: number
    max: number
  }>({ min: 0, max: 0 })

  return (
    <>
      <div>
        <div>
          <StaffAndSalary
            data={eventData}
            numberOfStaff={numberOfStaff}
            setNumberOfStaff={setNumberOfStaff}
            estimatedSalary={estimatedSalary}
            setEstimatedSalary={setEstimatedSalary}
          />
          <button
            className="fees-btn w-full mt-25px"
            onClick={() => setAddMoreFees(true)}
          >
            <Plus size={10} color="#000" />
            <span className="text-15 w-500">Add more fees</span>
          </button>
        </div>

        <div className="mt-25px">
          <EstimatedSummary
            data={eventData}
            numberOfStaff={numberOfStaff}
            estimatedSalary={estimatedSalary}
          />
        </div>
      </div>

      {addMoreFees && (
        <AddFee modalOpen={addMoreFees} modalClose={setAddMoreFees} />
      )}
    </>
  )
}

export default Calculation
