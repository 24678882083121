import { useEffect, useState } from 'react'
import AppLayout from '../../components/layout/layout'
import { Row, Col } from 'antd'
import EventHeader from './Header/index'
import { myStore } from '../../state/eventStore'
import './styles.scss'
import { useQuery } from '@tanstack/react-query'
import { getEvents, useGetCommonEventListing } from '../../api/Events'
import EventView from '../../components/Events/Views'
import Loader from '../../helper/Loader'
import { creationEventStore } from 'state/event/eventCreationStore'
import EventTypeModal from './Modals/EventType'

const Events = () => {
  const { eventTypeModalVisible } = creationEventStore(store => ({
    eventTypeModalVisible: store.eventTypeModalVisible,
  }))
  const [parms, setParms] = useState({
    page: 1,
    orderBy: 'desc',
  })

  // const { isLoading, isFetching, data, refetch } = useQuery(
  //   ['getEvents', parms],
  //   () => getEvents(parms),
  //   {
  //     enabled: false,
  //   },
  // )
  const { isLoading, isFetching, data, refetch } =
    useGetCommonEventListing(parms)
  useEffect(() => {
    refetch()
  }, [parms])
  const view = myStore(store => store.view)
  return (
    <AppLayout>
      <div className="container-event">
        <div className="page-content events-page-content">
          <EventHeader setParm={setParms} parms={parms} />
          <Row gutter={[0, 20]}>
            <Col>
              <div>
                {isLoading || isFetching ? (
                  <div className="h-full">
                    <Loader />
                  </div>
                ) : (
                  <EventView
                    data={data}
                    parms={parms}
                    setParms={setParms}
                    view={view}
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {eventTypeModalVisible && <EventTypeModal />}
    </AppLayout>
  )
}

export default Events
