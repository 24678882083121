import { Button, Checkbox, Select, Table } from 'antd'
import AdminsMain from './Admin/AdminsMain'
import { useGetAppSettings, useUpdateAppSettings } from 'api/Admin/Settings'
import { useEffect, useState } from 'react'
import Spinner from 'components/Spinner'
import ButtonSpinner from 'components/global/ButtonSpinner'
import type { ColumnsType } from 'antd/lib/table'
import {
  AppSettingsConfigurationPayload,
  DataType,
} from 'types/settings/settings'
import { settings } from 'cluster'

enum Apps {
  Family = 'family',
  Mentor = 'mentor',
  Organizer = 'organizer',
}

const { Option } = Select
const onChange = (value: string) => {
  console.log(`selected ${value}`)
}
const GeneralSetting = () => {
  const {
    data: appConfiguration,
    isLoading,
    isFetching,
    refetch,
  } = useGetAppSettings()
  const { mutate: updateAppSettings, isLoading: updateAppSettingsLoading } =
    useUpdateAppSettings()

  const [checkedApps, setCheckedApps] = useState<Apps[]>([])
  const [payload, setPayload] = useState<AppSettingsConfigurationPayload>()
  const handleEnablePasswordSkip = (app: Apps, checked: boolean) => {
    const updatedApps = checked
      ? [...checkedApps, app]
      : checkedApps.filter(item => item !== app)

    setCheckedApps(updatedApps)

    const enrichedData = [Apps.Family, Apps.Mentor, Apps.Organizer].map(
      app => ({
        app,
        enableSkipPassword: updatedApps.some(item => item === app),
      }),
    )

    setPayload({ settings: enrichedData })
  }

  const hanldeUpdateSetting = () => {
    updateAppSettings(payload)
  }

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    if (appConfiguration) {
      const initialCheckedApps: Apps[] = []
      if (appConfiguration.family.enableSkipPassword)
        initialCheckedApps.push(Apps.Family)
      if (appConfiguration.mentor.enableSkipPassword)
        initialCheckedApps.push(Apps.Mentor)
      if (appConfiguration.organizer.enableSkipPassword)
        initialCheckedApps.push(Apps.Organizer)

      setCheckedApps(initialCheckedApps)
    }
  }, [appConfiguration])

  if (isLoading || isFetching) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Spinner />
      </div>
    )
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Settings',
      dataIndex: 'settings',
    },
    {
      title: 'Family',
      dataIndex: 'family',
    },
    {
      title: 'Mentor',
      dataIndex: 'mentor',
    },
    {
      title: 'Organizer',
      dataIndex: 'organizer',
    },
  ]

  const data = [
    {
      key: '1',
      settings: 'Enable skip password',
      family: (
        <Checkbox
          checked={checkedApps.includes(Apps.Family)}
          className="settings-checkbox"
          onChange={e =>
            handleEnablePasswordSkip(Apps.Family, e.target.checked)
          }
        ></Checkbox>
      ),
      mentor: (
        <Checkbox
          checked={checkedApps.includes(Apps.Mentor)}
          className="settings-checkbox"
          onChange={e =>
            handleEnablePasswordSkip(Apps.Mentor, e.target.checked)
          }
        ></Checkbox>
      ),
      organizer: (
        <Checkbox
          checked={checkedApps.includes(Apps.Organizer)}
          className="settings-checkbox"
          onChange={e =>
            handleEnablePasswordSkip(Apps.Organizer, e.target.checked)
          }
        ></Checkbox>
      ),
    },
  ]

  return (
    <div>
      <h1 className="general-s-heading">General Settings</h1>
      <Table
        columns={columns}
        dataSource={data}
        className="permission-table"
        style={{ width: '100%', overflowX: 'auto', marginBottom: '30px' }}
        pagination={false}
      />
      <div className="w-100 flex justify-end">
        <Button
          className=" br-10 px-30 py-8"
          type="primary"
          disabled={updateAppSettingsLoading}
          onClick={hanldeUpdateSetting}
        >
          {updateAppSettingsLoading ? (
            <ButtonSpinner size={20} color="#000" />
          ) : (
            'Update Settings'
          )}
        </Button>
      </div>

      <div
        style={{ maxWidth: '430px' }}
        className="w-100 pr-20 position-relative"
      >
        <p className="pra-heading mt-10">Default phone country code:</p>

        <Select
          className="dropdown-setting default-phone-country-field text-color-black"
          placeholder="Identify country code by user’s IP address"
          optionFilterProp="children"
          onChange={onChange}
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          <Option value="jack">181.20.20.123</Option>
          <Option value="lucy">186.53.30.303</Option>
          <Option value="tom">110.20.30.454</Option>
        </Select>
      </div>
      <AdminsMain />
    </div>
  )
}
export default GeneralSetting
