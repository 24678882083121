import React, { useEffect } from 'react'
import CustomModal from 'components/global/CustomModal'
import { adultStore } from 'state/family/adult'
import CustomModalFooter from 'components/global/CustomModalFooter'
import AccountFound from './ModalContent/AccountFound/AccountFound'
import { failureMessage, infoMessage } from 'api/global'
import { useCreateAdult } from 'api/family/adult'
import { CreateAdultPayload, FamilyMemberAdult } from 'types/family/adult'
import { myStore } from 'state/store'
import moment from 'moment'
import { useAddkidsToAdult } from 'api/family/adultRole'

interface StoreStates {
  adultExistModal: boolean
  setAdultExistModal: (state: boolean) => void
  adultData: FamilyMemberAdult
  setAdultData: (state: FamilyMemberAdult) => void
  adultCurrentStep: number
  setAdultCurrentStep: (state: number) => void
  selectedKids: string[]
  setSlectedKids: (state: string[]) => void
}

const AlreadyExist = ({ familyId }: { familyId: string }) => {
  const {
    adultExistModal,
    setAdultExistModal,
    adultData,
    setAdultData,
    adultCurrentStep,
    setAdultCurrentStep,
    selectedKids,
    setSlectedKids,
  }: StoreStates = adultStore(store => ({
    adultExistModal: store.adultExistModal,
    setAdultExistModal: store.setAdultExistModal,
    adultData: store.adultData,
    setAdultData: store.setAdultData,
    adultCurrentStep: store.adultCurrentStep,
    setAdultCurrentStep: store.setAdultCurrentStep,
    selectedKids: store.selctedKids,
    setSlectedKids: store.setSelectedKids,
  }))
  const { setModalBtnLoader } = myStore(store => ({
    setModalBtnLoader: store.setModalBtnLoader,
  }))
  const { mutate: createAdultMutation, isLoading: createAdultLoading } =
    useCreateAdult()
  const { mutate: addKidsToAdult, isLoading: addKidsToAdultLoading } =
    useAddkidsToAdult()

  const handleClose = () => {
    if (adultCurrentStep === 0) {
      setAdultData(null)
      setAdultExistModal(false)
    } else {
      setAdultCurrentStep(0)
    }
  }

  const handleCancel = () => {
    setAdultData(null)
    setAdultCurrentStep(0)
    setAdultExistModal(false)
  }

  const handleSubmit = () => {
    if (adultCurrentStep === 0) {
      if (!adultData) return failureMessage('No data for adult found')
      const payload: CreateAdultPayload = {
        photoId: adultData?.photoId ? adultData?.photoId : undefined,
        email: adultData?.email ? adultData?.email : undefined,
        firstName: adultData.firstName,
        lastName: adultData.lastName,
        nickName: adultData?.nickName ? adultData.nickName : undefined,
        middleName: adultData?.middleName ? adultData.middleName : undefined,
        dob: adultData?.dob
          ? moment(adultData.dob).format('YYYY-MM-DD')
          : undefined,
        gender: adultData?.gender ? adultData.gender : undefined,
        phone: adultData?.phone ? adultData.phone : undefined,
        isEmergencyContact: adultData?.isEmergencyContact
          ? adultData.isEmergencyContact
          : undefined,
        allergies: adultData?.allergies ? adultData.allergies : undefined,
        medicalNotes: adultData?.medicalNotes
          ? adultData.medicalNotes
          : undefined,
        relationId: adultData?.relation._id
          ? adultData.relation?._id
          : undefined,
        address: adultData?.address ? adultData.address : undefined,
      }
      createAdultMutation(
        { familyId, payload },
        {
          onSuccess: data => {
            setAdultData(data)
            setAdultCurrentStep(1)
          },
        },
      )
    }

    if (adultCurrentStep === 1) {
      if (selectedKids.length === 0) {
        return infoMessage('Select atleast one kid')
      }
      addKidsToAdult(
        {
          adultId: adultData._id,
          payload: { kidIds: selectedKids },
        },
        {
          onSuccess: data => {
            setAdultData(null)
            setSlectedKids([])
            setAdultCurrentStep(0)
            setAdultExistModal(false)
          },
        },
      )
    }
  }

  useEffect(() => {
    setModalBtnLoader(createAdultLoading || addKidsToAdultLoading)
  }, [createAdultLoading, addKidsToAdultLoading])

  return (
    <>
      <CustomModal
        openModal={adultExistModal}
        isCloseModalCustom
        closeModal={handleCancel}
        title={adultCurrentStep === 0 ? 'Account found' : 'Select Child'}
        footer={
          <CustomModalFooter
            multibutton={adultCurrentStep === 0 ? true : false}
            btnAlignment="justify-between"
            btnType="primary"
            btnText={adultCurrentStep === 0 ? 'Back,Confirm adult' : 'Add kids'}
            closeModal={handleClose}
            onSubmit={handleSubmit}
          />
        }
      >
        <AccountFound familyId={familyId} />
      </CustomModal>
    </>
  )
}

export default AlreadyExist
