import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { axiosGet, axiosPost } from 'Redux/axiosHelper'
import {
  AppSettingsResp,
  AppSettingsConfigurationPayload,
} from 'types/settings/settings'

// get app settings
const getAppSettings = async () => {
  const response = await axiosGet(`v1/admins/app-settings`)
  return response.data
}

export const useGetAppSettings = () => {
  const appSettings = useQuery<AppSettingsResp>(
    ['apps-settings'],
    () => getAppSettings(),
    {
      enabled: false,
    },
  )
  return appSettings
}

// post app settings
const updateAppSettings = async (payload: AppSettingsConfigurationPayload) => {
  const response = await axiosPost(payload, `v1/admins/app-settings`)
  return response.data
}

export const useUpdateAppSettings = () => {
  const queryClient = useQueryClient()
  return useMutation(updateAppSettings, {
    onSuccess: updatedData => {
      queryClient.setQueryData(['apps-settings'], updatedData)
    },
  })
}
