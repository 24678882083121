import CustomModal from 'components/global/CustomModal'
import CustomModalFooter from 'components/global/CustomModalFooter'
import useFormSubmit from 'helper/Hooks/formSubmit'
import React from 'react'
import { relationStore } from 'state/listing/relationListing'
import RelationForm from './ModalContent/RelationForm'

const RelationModal: React.FC = () => {
  const { form, handleSubmit } = useFormSubmit()
  const { addRelationModal, setAddRelationModal, relationData } = relationStore(
    store => ({
      addRelationModal: store.addRelationModal,
      setAddRelationModal: store.setAddRelationModal,
      relationData: store.relationData,
    }),
  )
  return (
    <>
      <CustomModal
        openModal={addRelationModal}
        closeModal={setAddRelationModal}
        title={relationData ? 'Edit Relation' : 'Add new Relation'}
        footer={
          <CustomModalFooter
            multibutton={false}
            btnAlignment="justify-end"
            btnText={relationData ? 'Update' : 'Create'}
            btnType="primary"
            onSubmit={handleSubmit}
          />
        }
      >
        <RelationForm form={form} />
      </CustomModal>
    </>
  )
}

export default RelationModal
