import { useMutation } from '@tanstack/react-query'
import { axiosPost } from 'Redux/axiosHelper'
import { ChatNotificationPayload } from 'types/chat'

const sendMessageNotification = async (payload: ChatNotificationPayload) => {
  const response = await axiosPost(payload, `v1/notifications/message`)
  return response.data
}

export const useSendMessageNotification = () => {
  return useMutation(sendMessageNotification)
}
