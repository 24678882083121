import React, { useEffect } from 'react'
import AppLayout from 'components/layout/layout'
import ChatHeader from '../Header/ChatHeader'
import { useParams } from 'react-router-dom'
import { useGetEventById } from 'api/EventCreation/EventCreation'
import Spinner from 'components/Spinner'
import MainChat from './MainChat'
import { eventShatStore } from 'state/event/eventChat'
import ChatModal from './Modals/ChatModal'
import { useFindOrCreateChatRoom } from 'helper/Hooks/chatMessages'

const EventChats: React.FC = () => {
  const { chatModalVisible } = eventShatStore(store => ({
    chatModalVisible: store.chatModalVisible,
    setChatModalVisible: store.setChatModalVisible,
  }))
  const { id }: { id: string } = useParams()
  const chatRoomIds = useFindOrCreateChatRoom(id)
  const {
    data: eventData,
    isLoading: eventDataLoading,
    isFetching: eventDataFetching,
    isError: eventDataError,
    refetch: eventDataRefetch,
  } = useGetEventById(id)

  useEffect(() => {
    eventDataRefetch()
  }, [id])

  return (
    <AppLayout>
      <div className="container">
        <div>
          {eventDataLoading || eventDataFetching ? (
            <div className="px-40px py-40px w-full flex justify-center align-center border-btm">
              <Spinner />
            </div>
          ) : eventDataError ? (
            <div>Something went wrong</div>
          ) : (
            <>
              <div className="px-40px py-40px border-btm">
                <ChatHeader eventData={eventData} />
              </div>
              <MainChat chatRoomIds={chatRoomIds} />
            </>
          )}
        </div>
      </div>

      {chatModalVisible && <ChatModal eventData={eventData} />}
    </AppLayout>
  )
}

export default EventChats
