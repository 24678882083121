import { PlusCircle } from '@phosphor-icons/react'
import { Button, TimePicker } from 'antd'
import CustomModal from 'components/global/CustomModal'
import SelectDatesCalendar from 'components/global/SelectDatesCalendar'
import { convertMinutesToLocalTime } from 'helper/utility'
import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import { SpecialSchedule } from 'types/globalTypes'
import CustomModalFooter from 'components/global/CustomModalFooter'

interface SelectDateModalProps {
  openModal: boolean
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
  selectedDates: string[]
  setSelectedDates: React.Dispatch<React.SetStateAction<string[]>>
  setAddSpecialScheduleModalVisible: React.Dispatch<
    React.SetStateAction<boolean>
  >
  specialScheduleData: SpecialSchedule[]
  setSpecialScheduleData: React.Dispatch<
    React.SetStateAction<SpecialSchedule[]>
  >
  setEventTime: any
  externalData?: any
}

const SelectDateModal: React.FC<SelectDateModalProps> = ({
  openModal,
  closeModal,
  selectedDates,
  setSelectedDates,
  setAddSpecialScheduleModalVisible,
  specialScheduleData,
  setSpecialScheduleData,
  setEventTime,
  externalData,
}) => {
  const [defaultTime, setDefaultTime] = useState({
    startTime: moment('9:00', 'HH:mm'),
    endTime: moment('15:00', 'HH:mm'),
  })
  const handleClose = () => {
    closeModal(false)
  }

  const handleStartTimeChange = (time: Moment | null, timeString: string) => {
    setDefaultTime({ ...defaultTime, startTime: moment(time) })
  }
  const handleEndTimeChange = (time: Moment | null, timeString: string) => {
    setDefaultTime({ ...defaultTime, endTime: moment(time) })
  }
  const removeSpecialscheduleInput = (index: number) => {
    const current: any = specialScheduleData[index]
    setSpecialScheduleData([
      ...specialScheduleData.filter((data: any) => data.day !== current.day),
    ])
  }

  const handleSelectAllDays = () => {
    const today = moment().startOf('day')
    const currentMonth = moment().month()
    const currentYear = moment().year()

    const allDaysOfMonth = Array.from(
      { length: moment().daysInMonth() },
      (_, i) =>
        moment(`${currentYear}-${currentMonth + 1}-${i + 1}`, 'YYYY-MM-DD'),
    )
      .filter(date => date.isSameOrAfter(today))
      .map(date => date.format('YYYY-MM-DD'))

    setSelectedDates(allDaysOfMonth)
  }

  const handleSelectAllWeekdays = () => {
    const today = moment().startOf('day')
    const currentMonth = moment().month()
    const currentYear = moment().year()

    // Get all weekdays (Monday - Friday) of the current month
    const allWeekdaysOfMonth = Array.from(
      { length: moment().daysInMonth() },
      (_, i) =>
        moment(`${currentYear}-${currentMonth + 1}-${i + 1}`, 'YYYY-MM-DD'),
    )
      .filter(date => date.isSameOrAfter(today) && date.isoWeekday() < 6) // Exclude weekends (Saturday = 6, Sunday = 7)
      .map(date => date.format('YYYY-MM-DD'))

    setSelectedDates(allWeekdaysOfMonth)
  }

  useEffect(() => {
    setEventTime(defaultTime)
  }, [defaultTime])

  return (
    <CustomModal
      openModal={openModal}
      closeModal={handleClose}
      title="Select dates"
      width={500}
      footer={
        <CustomModalFooter
          multibutton={false}
          btnAlignment="justify-end"
          btnText="Save"
          btnType="primary"
          onSubmit={handleClose}
        />
      }
    >
      <>
        <SelectDatesCalendar
          isLimited={false}
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
        />

        <div className="mt-15px w-full flex justify-center align-center gap-10px flex-col">
          <button className="btn-secondary" onClick={handleSelectAllDays}>
            Add all days of this month
          </button>
          <button className="btn-secondary" onClick={handleSelectAllWeekdays}>
            Add weekdays of this month
          </button>
        </div>

        {/* <div className="mt-20px">
          <h2 className="text-14 w-600">Weekly default schedule</h2>
          <div className="flex align-center gap-16px mt-15px w-80p">
            <TimePicker
              className="w-100 form-control-field"
              placeholder="Start Time"
              format="h:mm a"
              value={defaultTime.startTime}
              showNow={false}
              clearIcon={false}
              onChange={handleStartTimeChange}
            />
            <div>-</div>
            <TimePicker
              className="w-100 form-control-field"
              placeholder="End Time"
              format="h:mm a"
              value={defaultTime.endTime}
              showNow={false}
              clearIcon={false}
              onChange={handleEndTimeChange}
            />
          </div>
        </div> */}

        {/* <div className="flex align-center justify-between gap-16px mt-20px">
          <h2 className="text-14 w-600">Add special schedule</h2>
          <PlusCircle
            className="pointer"
            size={20}
            color="#000"
            onClick={() => setAddSpecialScheduleModalVisible(true)}
          />
        </div>
        {specialScheduleData.length > 0 && (
          <div className="mt-20px">
            <div>
              {specialScheduleData.map(
                (specialSchedule: SpecialSchedule, index: number) => {
                  return (
                    <div className="mt-10px" key={index}>
                      <h4 className="text-14 w-600">
                        {moment(specialSchedule.day).format('DD-MM-YYYY')}
                      </h4>
                      <div>
                        <div className="flex justify-between align-center w-full">
                          <div className="flex align-center gap-16px mt-15px">
                            <TimePicker
                              className="w-100 form-control-field"
                              placeholder="Start Time"
                              format="h:mm a"
                              showNow={false}
                              clearIcon={false}
                              value={moment(
                                convertMinutesToLocalTime(
                                  specialSchedule.startTime,
                                ),
                                'hh:mm A',
                              )}
                              onChange={handleStartTimeChange}
                              disabled
                            />
                            <div>-</div>
                            <TimePicker
                              className="w-100 form-control-field"
                              placeholder="End Time"
                              format="h:mm a"
                              showNow={false}
                              clearIcon={false}
                              value={moment(
                                convertMinutesToLocalTime(
                                  specialSchedule.endTime,
                                ),
                                'hh:mm A',
                              )}
                              onChange={handleEndTimeChange}
                              disabled
                            />
                          </div>

                          <DeleteOutlined
                            onClick={() => removeSpecialscheduleInput(index)}
                            style={{
                              color: '#FF6961',
                              paddingLeft: '5px',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )
                },
              )}
            </div>
          </div>
        )} */}
      </>
    </CustomModal>
  )
}

export default SelectDateModal
