import React, { useEffect, useState } from 'react'
import { useGetFaqs } from 'api/Faq/Faq'
import Spinner from 'components/Spinner'
import { OrgFaqs } from 'types/event/eventStep5'
import FaqContainer from './FaqContainer'
import { capitalizeFirstLetter } from 'helper/utility'
import { eventCommonStore } from 'state/event/eventCommon'

interface FaqModalContentProps {
  organizationId: string
  referenceModalId: string
  reference: 'event' | 'organization' | 'league'
}

const FaqModalContent: React.FC<FaqModalContentProps> = ({
  organizationId,
  referenceModalId,
  reference,
}) => {
  const { selectedQuestion, setSelectedQuestion } = eventCommonStore(store => ({
    selectedQuestion: store.selectedQuestions,
    setSelectedQuestion: store.setSelectedQuestions,
  }))

  const {
    data: orgFaqs,
    isLoading: orgFaqsLoading,
    isFetching: orgFaqsFetching,
    refetch: orgFaqRefetch,
  } = useGetFaqs(organizationId, 'organization')

  const {
    data: referenceFaq,
    isLoading: referenceFaqsLoading,
    isFetching: referenceFaqsFetching,
    refetch: referenceFaqRefetch,
  } = useGetFaqs(referenceModalId, reference)

  useEffect(() => {
    orgFaqRefetch()
    referenceFaqRefetch()
  }, [reference, referenceModalId])

  return (
    <div>
      <h3 className="text-16 w-600">General Question</h3>
      <p className="text-gray w-400 text-14">
        Select questions that could be interested for potential visitors of your
        event
      </p>

      {orgFaqsLoading ||
      orgFaqsFetching ||
      referenceFaqsLoading ||
      referenceFaqsFetching ? (
        <div className="h-full flex align-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="mt-10px">
            {orgFaqs.length > 0 ? (
              orgFaqs.map((faq: OrgFaqs) => {
                return (
                  <div key={faq._id} className="mt-10px">
                    <FaqContainer
                      data={faq}
                      questions={selectedQuestion}
                      setQuestion={setSelectedQuestion}
                      editable={false}
                      reference="league"
                    />
                  </div>
                )
              })
            ) : (
              <p className="text-14 w-400 text-gray">No questions</p>
            )}
          </div>
          <div className="mt-10px">
            <h3 className="text-16 w-600 mt-20px">
              {capitalizeFirstLetter(reference)} specific question
            </h3>
            {referenceFaq.length > 0 ? (
              referenceFaq.map((faq: OrgFaqs) => {
                return (
                  <div className="mt-10px">
                    <FaqContainer
                      data={faq}
                      questions={selectedQuestion}
                      setQuestion={setSelectedQuestion}
                      editable={true}
                      reference="league"
                    />
                  </div>
                )
              })
            ) : (
              <p className="text-14 w-400 text-gray">No questions</p>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default FaqModalContent
