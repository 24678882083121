import eventIcon from 'assets/Images/event-type-event.png'
import communityIcon from 'assets/Images/event-type-community.png'
import externalIcon from 'assets/Images/event-type-external.png'
import fundRasiserIcon from 'assets/Images/event-type-fundraiser.png'
import leagueIcon from 'assets/Images/event-type-league.png'
import { EventTypeListing } from 'types/event/events'

export const eventTypesList: EventTypeListing[] = [
  {
    id: 1,
    title: 'Event',
    icon: eventIcon,
  },
  {
    id: 2,
    title: 'Community',
    icon: communityIcon,
  },
  {
    id: 3,
    title: 'External',
    icon: externalIcon,
  },
  {
    id: 4,
    title: 'Fundraiser',
    icon: fundRasiserIcon,
  },
  {
    id: 5,
    title: 'League',
    icon: leagueIcon,
  },
]
