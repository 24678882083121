import React, { useState } from 'react'
import CustomModal from 'components/global/CustomModal'
import CustomModalFooter from 'components/global/CustomModalFooter'
import { leagueEventStore } from 'state/event/leagueEvent'
import { WeekDays } from 'types/globalTypes'
import { weekDays } from 'pages/Events/Views/CreateEvent/utility'
import { Checkbox } from 'antd'
import { infoMessage } from 'api/global'

interface TimeCopyProps {
  day: WeekDays
}

const TimeCopy: React.FC<TimeCopyProps> = ({ day }) => {
  const { copyTimeModal, setCopyTimeModal, timeSlots, setTimeSlots } =
    leagueEventStore(store => ({
      copyTimeModal: store.copyTimeModal,
      setCopyTimeModal: store.setCopyTimeModal,
      timeSlots: store.timeSlots,
      setTimeSlots: store.setTimeSlots,
    }))
  const [checkedDays, setCheckedDays] = useState<WeekDays[]>([])

  const handleSubmit = () => {
    if (!checkedDays.length)
      return infoMessage('Please select at least one day')
    console.log(timeSlots)
    const dayKey = day.toLowerCase()

    if (!timeSlots || !timeSlots[dayKey] || timeSlots[dayKey].length === 0) {
      setCopyTimeModal(false)
      return infoMessage(`First add some time to ${day}`)
    }

    const updatedTimeSlots = { ...timeSlots }
    checkedDays.forEach((day: WeekDays) => {
      const selectedDay = day.toLowerCase()
      updatedTimeSlots[selectedDay] = timeSlots[dayKey]
    })

    setTimeSlots(updatedTimeSlots)
    setCopyTimeModal(false)
  }

  const handleCheckboxChange = (day: WeekDays, isChecked: boolean) => {
    if (isChecked) {
      setCheckedDays(prev => [...prev, day])
    } else {
      setCheckedDays(prev => prev.filter(item => item !== day))
    }
  }

  return (
    <>
      <CustomModal
        title="Enter time"
        openModal={copyTimeModal}
        closeModal={setCopyTimeModal}
        footer={
          <CustomModalFooter
            btnAlignment="justify-end"
            btnText="Save"
            multibutton={false}
            btnType="primary"
            onSubmit={handleSubmit}
          />
        }
      >
        <div>
          {weekDays
            .filter(weekDay => weekDay !== day)
            .map((weekDay: WeekDays, index: number) => {
              return (
                <div
                  className="mt-15px flex align-center justify-between gap-20px pr-10px"
                  key={index}
                >
                  <p className="text-15 w-500">{weekDay}</p>
                  <Checkbox
                    onChange={e =>
                      handleCheckboxChange(weekDay, e.target.checked)
                    }
                  />
                </div>
              )
            })}
        </div>
      </CustomModal>
    </>
  )
}

export default TimeCopy
