import CustomModal from 'components/global/CustomModal'
import CustomModalFooter from 'components/global/CustomModalFooter'
import useFormSubmit from 'helper/Hooks/formSubmit'
import React from 'react'
import { goalStore } from 'state/listing/GoalListing'
import GoalForm from './ModalContent/GoalForm'

const GoalModal: React.FC = () => {
  const { form, handleSubmit } = useFormSubmit()
  const { addGoalModal, setAddGoalModal, goalData } = goalStore(store => ({
    addGoalModal: store.addGoalModal,
    setAddGoalModal: store.setAddGoalModal,
    goalData: store.goalData,
  }))
  return (
    <>
      <CustomModal
        openModal={addGoalModal}
        closeModal={setAddGoalModal}
        title={goalData ? 'Edit grade' : 'Add new grade'}
        footer={
          <CustomModalFooter
            multibutton={false}
            btnAlignment="justify-end"
            btnText={goalData ? 'Update' : 'Create'}
            btnType="primary"
            onSubmit={handleSubmit}
          />
        }
      >
        <GoalForm form={form} />
      </CustomModal>
    </>
  )
}

export default GoalModal
