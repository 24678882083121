import React from 'react'
import CustomModal from 'components/global/CustomModal'
import { leagueEventStore } from 'state/event/leagueEvent'
import useFormSubmit from 'helper/Hooks/formSubmit'
import AddTravelConstraintForm from './ModalContent/AddTravelConstraintForm'
import CustomModalFooter from 'components/global/CustomModalFooter'

const AddTravelConstraintModal: React.FC = () => {
  const { form, handleSubmit } = useFormSubmit()
  const { travelConstraintModal, setTravelConstraintModal } = leagueEventStore(
    store => ({
      travelConstraintModal: store.travelConstraintModal,
      setTravelConstraintModal: store.setTravelConstraintModal,
    }),
  )
  return (
    <CustomModal
      openModal={travelConstraintModal}
      closeModal={setTravelConstraintModal}
      title="Travel Constraints"
      footer={
        <CustomModalFooter
          multibutton={false}
          btnAlignment="justify-end"
          btnText="Save"
          btnType="primary"
          onSubmit={handleSubmit}
        />
      }
    >
      <AddTravelConstraintForm form={form} />
    </CustomModal>
  )
}

export default AddTravelConstraintModal
