import React, { useState } from 'react'
import { OrgDocumentResp } from 'types/event/eventStep5'
import pdfIcon from 'assets/Images/pdf.svg'
import { Pencil, PencilLine, Trash } from '@phosphor-icons/react'
import { Dropdown, Switch } from 'antd'
import AlertModal from 'helper/AlertModal'
import { AlertModalGeneric } from 'pages/Events/Views/CreateEvent/utility'
import { useDeleteOrgDocument } from 'api/EventCreation/creationStep5'
import AddNewDoc from './AddNewDoc'

interface SelectDocContainerProps {
  data: OrgDocumentResp
  eventSelectedDocs: string[]
  setEventSelectedDocs: React.Dispatch<React.SetStateAction<string[]>>
}

const SelectDocContainer: React.FC<SelectDocContainerProps> = ({
  data,
  eventSelectedDocs,
  setEventSelectedDocs,
}) => {
  const [alertModal, setAlertModal] = useState<boolean>(false)
  const [docModalVisible, setDocModalVisible] = useState<boolean>(false)
  const { mutate: deleteDocument, isLoading: deleteDocumentLoading } =
    useDeleteOrgDocument()
  const handleMenuClick = (operation: 'edit' | 'delete') => {
    if (operation === 'edit') {
      setDocModalVisible(true)
    } else {
      setAlertModal(true)
    }
  }

  const handleYesDelete = () => {
    deleteDocument(data._id, {
      onSuccess: () => setAlertModal(false),
      onError: () => setAlertModal(false),
    })
  }

  const menuOptions = [
    {
      key: 'edit',
      label: (
        <div
          className="flex gap-16px align-center"
          onClick={() => handleMenuClick('edit')}
        >
          <Pencil size={20} color="#000" />
          <p className="text-16 w-500">Edit question</p>
        </div>
      ),
    },
    {
      key: 'delete',
      label: (
        <div
          className="flex gap-16px align-center"
          onClick={() => handleMenuClick('delete')}
        >
          <Trash size={20} color="#ff6961" />
          <p className="text-16 w-500 danger-txt">Delete question</p>
        </div>
      ),
    },
  ]

  const hanldeSelect = (id: string) => {
    if (eventSelectedDocs.includes(id)) {
      const updatedArr = eventSelectedDocs.filter(item => item !== id)
      setEventSelectedDocs(updatedArr)
    } else {
      setEventSelectedDocs([...eventSelectedDocs, id])
    }
  }

  return (
    <>
      <div className="py-15px border-btm">
        <div className="flex justify-between align-center">
          <div className="flex gap-16px align-center">
            <img src={pdfIcon} alt="pdf-icon" width={40} height={40} />
            <h3>{data.name}</h3>
          </div>
          <div className="flex gap-16px align-center">
            {!data.isDefault && (
              <Dropdown
                menu={{ items: menuOptions }}
                placement="bottomRight"
                arrow={false}
                className={`ml-20px mr-10px`}
                overlayStyle={{ width: '200px' }}
                trigger={['click']}
              >
                <PencilLine size={20} color="#000" className="pointer" />
              </Dropdown>
            )}
            <Switch
              checked={eventSelectedDocs.includes(data._id)}
              onChange={() => hanldeSelect(data._id)}
            />
          </div>
        </div>
        <p className="mt-10px">{data.description}</p>
      </div>
      {alertModal && (
        <AlertModal
          isModalVisible={alertModal}
          setIsModalVisible={setAlertModal}
          data={AlertModalGeneric('Document', 'Document!')}
          onYesClick={handleYesDelete}
          loading={false}
        />
      )}

      {docModalVisible && (
        <AddNewDoc
          openModal={docModalVisible}
          closeModal={setDocModalVisible}
          data={data}
        />
      )}
    </>
  )
}

export default SelectDocContainer
