import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { axiosGet, axiosPatch, axiosPost } from 'Redux/axiosHelper'
import { BasicListingType } from 'types/listing/listings'

const getGoals = async () => {
  const response = await axiosGet(`v1/goal`)
  return response.data
}
export const useGetGoals = () => {
  const goals = useQuery<BasicListingType[]>([`all-goal-listings`], getGoals, {
    enabled: false,
  })
  return goals
}

// create new goal
const createGoal = async (payload: { name: string }) => {
  const response = await axiosPost(payload, `v1/goal`)
  return response.data
}

export const useCreateGoal = () => {
  const queryClient = useQueryClient()
  return useMutation(createGoal, {
    onSuccess: (newGoal: BasicListingType) => {
      queryClient.setQueryData(
        [`all-goal-listings`],
        (oldData: BasicListingType[] | undefined) => {
          if (!oldData) return [newGoal]
          return [...oldData, newGoal]
        },
      )
    },
  })
}

// update goal

const updateGoal = async ({
  goalId,
  payload,
}: {
  goalId: string
  payload: { name: string }
}) => {
  const response = await axiosPatch(payload, `v1/goal/${goalId}`)
  return response.data
}

export const useUpdateGoal = () => {
  const queryClient = useQueryClient()
  return useMutation(updateGoal, {
    onSuccess: updateGoal => {
      queryClient.setQueryData(
        [`all-goal-listings`],
        (oldData: BasicListingType[] | undefined) => {
          if (!oldData) return [updateGoal]
          return oldData.map(goal =>
            goal._id === updateGoal._id ? updateGoal : goal,
          )
        },
      )
    },
  })
}
