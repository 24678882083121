import React, { useEffect, useState } from 'react'
import IconsTextNavigation from 'components/global/IconsTextNavigation'
import ItemsBringModal from '../../Modals/ItemsBringModal'
import { creationEventStore } from 'state/event/eventCreationStore'

const ItemstoBring: React.FC = () => {
  const payload: any = creationEventStore(store => store.step5Payload)
  const [openItemsModal, setOpenItemsModal] = useState<boolean>(false)
  const [items, setItems] = useState<string[]>(payload.itemsToBring || [])

  useEffect(() => {
    setItems(payload.itemsToBring || [])
  }, [payload])

  return (
    <>
      <div onClick={() => setOpenItemsModal(true)}>
        <IconsTextNavigation
          iconName="ShoppingBagOpen"
          heading="Items to bring"
          details={items.length > 0 ? items.length : 'No items'}
        />
      </div>
      {openItemsModal && (
        <ItemsBringModal
          openModal={openItemsModal}
          closeModal={setOpenItemsModal}
          prevItems={items}
          setPrevItems={setItems}
        />
      )}
    </>
  )
}

export default ItemstoBring
