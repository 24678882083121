import React, { useState } from 'react'
import { Layout, Steps } from 'antd'
import './styles/sidebar.scss'
import { List } from '@phosphor-icons/react'
import { communityStore } from 'state/event/community'
import { externalStore } from 'state/event/external'

const { Sider } = Layout
const ExternalSidebar: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const { stepCounter, setStepCounter, externalData } = externalStore(
    store => ({
      stepCounter: store.externalStepCounter,
      setStepCounter: store.setExternalStepCounter,
      externalData: store.externalData,
    }),
  )
  const { Step } = Steps
  const steps = [
    {
      title: 'Step 1',
      description: (
        <div className="flex justify-between align-center">
          <b className="text-18 w-600">Settings</b>
          <button
            className={`progress-bar-btn ${
              externalData && externalData.organization
                ? 'active'
                : 'non-active disabled'
            }`}
            disabled={!externalData?.organization}
            onClick={() => setStepCounter(0)}
          >
            {externalData && externalData.statuses.settings
              ? 'Review'
              : 'Start'}
          </button>
        </div>
      ),
    },
    {
      title: 'Step 2',
      description: (
        <div className="flex justify-between align-center">
          <b className="text-18 w-600">Working days and time</b>
          <button
            className={`progress-bar-btn ${
              externalData && externalData.statuses.dateTime
                ? 'active'
                : 'non-active disabled'
            }`}
            disabled={!externalData?.organization}
            onClick={() => setStepCounter(1)}
          >
            {externalData && externalData.organization ? 'Review' : 'Start'}
          </button>
        </div>
      ),
    },
  ]

  return (
    <div>
      <div
        className="event-list-icon"
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        <List size={30} color="#000" />
      </div>

      <Sider
        className={`side-bar create-event-side-bar  ${
          isSidebarOpen ? 'sidebar-open' : ''
        }`}
        width={'auto'}
        style={{ maxWidth: 'auto' }}
      >
        <div className="d-flex flex-column justify-between stepper-section-header pb-10">
          <div>
            <h2 className="mb-16 create-event-sidebar-heading">
              Create external
            </h2>
            <span className="mb-20 complete-all-steps">
              Complete all following stept to create external
            </span>
            <Steps direction="vertical" current={stepCounter}>
              {steps.map(item => (
                <Step
                  key={item.title}
                  className="stepper event-stepper event-step-bar"
                  title={item.title}
                  description={item.description}
                />
              ))}
            </Steps>
          </div>
        </div>
      </Sider>
    </div>
  )
}

export default ExternalSidebar
