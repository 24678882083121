import React from 'react'
import CustomModal from 'components/global/CustomModal'
import { teamStore } from 'state/team/team'
import CustomModalFooter from 'components/global/CustomModalFooter'
import useFormSubmit from 'helper/Hooks/formSubmit'
import CreateTeamForm from './ModalContent/CreateTeamForm'

const CreateTeam: React.FC = () => {
  const { form, handleSubmit } = useFormSubmit()
  const { teamModalVisible, setTeamModalVisible, isUpdatingTeam } = teamStore(
    store => ({
      teamModalVisible: store.teamModalVisible,
      setTeamModalVisible: store.setTeamModalVisible,
      isUpdatingTeam: store.isUpdatingTeam,
    }),
  )
  return (
    <>
      <CustomModal
        openModal={teamModalVisible}
        closeModal={setTeamModalVisible}
        title={isUpdatingTeam ? 'Update Team' : 'Create Team'}
        footer={
          <CustomModalFooter
            multibutton={false}
            btnAlignment="justify-end"
            btnText={isUpdatingTeam ? 'Update' : 'Create'}
            btnType="primary"
            onSubmit={handleSubmit}
          />
        }
      >
        <CreateTeamForm form={form} />
      </CustomModal>
    </>
  )
}

export default CreateTeam
