import React from 'react'
import CustomModal from 'components/global/CustomModal'
import CustomModalFooter from 'components/global/CustomModalFooter'
import SelectDatesCalendar from 'components/global/SelectDatesCalendar'

interface SelectDateModalProps {
  leagueData: any //todo
  openModal: boolean
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
  selectedDates: string[]
  setSelectedDates: React.Dispatch<React.SetStateAction<string[]>>
}

const SelectDateModal: React.FC<SelectDateModalProps> = ({
  leagueData,
  openModal,
  closeModal,
  selectedDates,
  setSelectedDates,
}) => {
  const hanldeCloseModal = () => {
    setSelectedDates([])
    closeModal(false)
  }

  const handleSubmit = () => {
    closeModal(false)
  }

  return (
    <>
      <CustomModal
        openModal={openModal}
        isCloseModalCustom
        closeModal={hanldeCloseModal}
        title="Select Dates"
        width={500}
        footer={
          <CustomModalFooter
            multibutton={false}
            btnAlignment="justify-end"
            btnType="primary"
            btnText="Select"
            onSubmit={handleSubmit}
          />
        }
      >
        <SelectDatesCalendar
          isLimited
          isRange
          startDate={leagueData.startDate}
          endDate={leagueData.endDate}
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
        />
      </CustomModal>
    </>
  )
}

export default SelectDateModal
