import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { axiosDelete, axiosGet, axiosPatch, axiosPost } from 'Redux/axiosHelper'
import { BasicListingType } from 'types/listing/listings'

const getGrades = async () => {
  const response = await axiosGet(`v1/grade`)
  return response.data
}
export const useGetGrades = () => {
  const grades = useQuery<BasicListingType[]>(
    ['all-grades-listing'],
    getGrades,
    {
      enabled: false,
    },
  )
  return grades
}

// create new grade
const createGrade = async (payload: { name: string }) => {
  const response = await axiosPost(payload, `v1/grade`)
  return response.data
}

export const useCreateGrade = () => {
  const queryClient = useQueryClient()
  return useMutation(createGrade, {
    onSuccess: (newGrade: BasicListingType) => {
      queryClient.setQueryData(
        ['all-grades-listing'],
        (oldData: BasicListingType[] | undefined) => {
          if (!oldData) return [newGrade]
          return [...oldData, newGrade]
        },
      )
    },
  })
}

// update grade
const updateGrade = async ({
  gradeId,
  payload,
}: {
  gradeId: string
  payload: { name: string }
}) => {
  const response = await axiosPatch(payload, `v1/grade/${gradeId}`)
  return response.data
}

export const useUpdateGrade = () => {
  const queryClient = useQueryClient()
  return useMutation(updateGrade, {
    onSuccess: updateGrade => {
      queryClient.setQueryData(
        ['all-grades-listing'],
        (oldData: BasicListingType[] | undefined) => {
          if (!oldData) return [updateGrade]
          return oldData.map(grade =>
            grade._id === updateGrade._id ? updateGrade : grade,
          )
        },
      )
    },
  })
}

// remove grade
const removeGrade = async (gradeId: string) => {
  const response = await axiosDelete(`v1/grade/${gradeId}`)
  return response.data
}
export const useRemoveGrade = () => {
  const queryClient = useQueryClient()
  return useMutation(removeGrade, {
    onSuccess: removeGrade => {
      queryClient.setQueryData(
        ['all-grades-listing'],
        (oldData: BasicListingType[] | undefined) => {
          if (!oldData) return []
          return oldData.filter(grade => grade._id !== removeGrade._id)
        },
      )
    },
  })
}
