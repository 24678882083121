import React from 'react'
import { FormInstance } from 'antd'
import { organizationStore } from 'state/organization/organization'
import GlobalCounterStep from 'components/global/CounterStep'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'

interface CreateOrganizationProps {
  form: FormInstance
}

const CreateOrganizationContent: React.FC<CreateOrganizationProps> = ({
  form,
}) => {
  const { organizationCurrentStep, isUpdating } = organizationStore(store => ({
    organizationCurrentStep: store.organizationCurrentStep,
    isUpdating: store.isUpdating,
  }))
  const steps = [
    { title: 'Organization Details' },
    { title: 'Socails' },
    !isUpdating && { title: 'Organizer' },
  ].filter(Boolean)

  return (
    <>
      <GlobalCounterStep steps={steps} currentStep={organizationCurrentStep} />
      {organizationCurrentStep === 0 && <Step1 form={form} />}
      {organizationCurrentStep === 1 && <Step2 form={form} />}
      {organizationCurrentStep === 2 && <Step3 />}
    </>
  )
}

export default CreateOrganizationContent
