import React from 'react'
import { Col, Form, FormInstance, Input, Row } from 'antd'
import { myStore } from 'state/store'
import { highlightedElementStore } from 'state/listing/highlightedElementListing'
import {
  useCreateHighlightedElem,
  useUpdateHighlightedElem,
} from 'api/Listings/HighlightedElem'

interface HighlightedElemFormProps {
  form: FormInstance
}

const HighlightedElemForm: React.FC<HighlightedElemFormProps> = ({ form }) => {
  const setModalBtnLoading = myStore(store => store.setModalBtnLoader)
  const { setAddHighlightedElementModal, highlightedElementData } =
    highlightedElementStore(store => ({
      setAddHighlightedElementModal: store.setAddHighlightedElementModal,
      highlightedElementData: store.highlightedElementData,
    }))
  const { mutate: createHighlightedElem } = useCreateHighlightedElem()
  const { mutate: updateHighlightedElem } = useUpdateHighlightedElem()

  const formSchema = {
    name: highlightedElementData ? highlightedElementData.name : undefined,
  }

  const onFinish = (values: { name: string }) => {
    setModalBtnLoading(true)

    if (highlightedElementData) {
      updateHighlightedElem(
        { highlightedElemId: highlightedElementData._id, payload: values },
        {
          onSuccess: () => {
            setModalBtnLoading(false)
            setAddHighlightedElementModal(false)
          },
          onError: () => {
            setModalBtnLoading(false)
          },
        },
      )
    } else {
      createHighlightedElem(values, {
        onSuccess: () => {
          setModalBtnLoading(false)
          setAddHighlightedElementModal(false)
        },
        onError: () => {
          setModalBtnLoading(false)
        },
      })
    }
  }

  return (
    <div>
      <Form
        name="highlighted-elem-form"
        initialValues={formSchema}
        form={form}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
      >
        <Row className="mt-10px">
          <Col span={24}>
            <label className="input-label required-input">Name</label>
            <Form.Item
              colon={false}
              name="name"
              rules={[{ required: true, message: 'Name required' }]}
            >
              <Input placeholder="Enter Name" required name="name" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default HighlightedElemForm
