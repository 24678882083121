import React, { useEffect, useState } from 'react'
import WorkingHours from './WorkingHours'
import { communityStore } from 'state/event/community'
import { CaretLeft, CaretRight } from '@phosphor-icons/react'
import { Button, Row } from 'antd'
import { infoMessage } from 'api/global'
import { useCommunityStep3 } from 'api/Community/CommunityCreation'
import ButtonSpinner from 'components/global/ButtonSpinner'
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import SpecialScheduleModal from './Modals/SpecialScheduleModal'
import { SpecialSchedule } from 'types/globalTypes'
import moment from 'moment'
import { convertMinutesToLocalTime } from 'helper/utility'
import StatusModal from './Modals/StatusModal'

interface Props {
  handleBack: () => void
}

const CommunityStep3: React.FC<Props> = ({ handleBack }) => {
  const {
    timeSlots,
    setTimeSlots,
    communityData,
    setCommunityData,
    addSpecialSchedule,
    setAddSpecialSchedule,
    statusModalVisible,
    setStatusModalVisible,
  } = communityStore(store => ({
    timeSlots: store.timeSlots,
    setTimeSlots: store.setTimeSlots,
    communityData: store.communityData,
    setCommunityData: store.setCommunityData,
    addSpecialSchedule: store.addSpecialSchedule,
    setAddSpecialSchedule: store.setAddSpecialSchedule,
    statusModalVisible: store.statusModalVisible,
    setStatusModalVisible: store.setStatusModalVisible,
  }))
  const [specialScheduleData, setSpecialScheduleData] = useState<
    SpecialSchedule[]
  >([])
  const { mutate: communityStep3Mutation, isLoading } = useCommunityStep3()

  const handleFinish = () => {
    const isValidated = Object.keys(timeSlots).some(element => {
      if (!timeSlots[element].length) {
        infoMessage('Please add time in selected day')
        return true
      }
    })

    if (isValidated) return
    const payload = {
      workingHours: {
        ...timeSlots,
        specialSchedules: specialScheduleData.map((data: SpecialSchedule) => ({
          ...data,
          day: moment(data.day, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss[Z]'),
        })),
      },
    }
    communityStep3Mutation(
      {
        payload,
        communityId: communityData._id,
      },
      {
        onSuccess: data => {
          setCommunityData(data)
          setStatusModalVisible(true)
        },
      },
    )
  }

  useEffect(() => {
    const allWorkingHours = communityData.workingHours
      ? Object.keys(communityData.workingHours).reduce(
          (acc, day) => {
            acc[day] = communityData.workingHours[day]
            return acc
          },
          {} as Record<string, any>,
        )
      : {}
    setTimeSlots(allWorkingHours)
    setSpecialScheduleData(
      communityData.workingHours?.specialSchedules
        ? communityData.workingHours.specialSchedules
        : [],
    )
  }, [])

  return (
    <>
      <div
        className="create-event-main-content-box"
        style={{ margin: '0 auto' }}
      >
        <h2 className="text-24 w-600">Working hours</h2>
        <WorkingHours />

        <h2 className="text-14 w-600 mt-20px">Special schedules</h2>
        {specialScheduleData && specialScheduleData.length > 0 && (
          <div className="w-full">
            <Row style={{ marginTop: 0 }} className="w-full">
              <div className="w-full flex align-center px-12px pointer h-full py-5px border-1px radius-12">
                {specialScheduleData && specialScheduleData.length > 0 && (
                  <div className=" w-full flex justify-between align-center">
                    <p className="text-gray">Special Schedule</p>
                    <div className="flex gap-10px">
                      <div className="flex flex-col gap-10px">
                        {specialScheduleData.map(
                          (item: SpecialSchedule, index: number) => {
                            return (
                              <div className="" key={index}>
                                <p className="text-14px w-500">{`${moment(
                                  item.day,
                                ).format(
                                  'MMM DD,YYYY',
                                )}, ${convertMinutesToLocalTime(
                                  item.startTime,
                                )} - ${convertMinutesToLocalTime(
                                  item.endTime,
                                )}`}</p>
                              </div>
                            )
                          },
                        )}
                      </div>
                      <CaretRight size={16} color="#000" className="mt-10px" />
                    </div>
                  </div>
                )}
              </div>
            </Row>
          </div>
        )}

        <div className="flex justify-between align-center gap-16px mt-20px">
          <h2 className="text-14 w-600">Add special schedule</h2>
          <div className="-mt-10px">
            <PlusCircleOutlined onClick={() => setAddSpecialSchedule(true)} />
          </div>
        </div>

        <div className="mt-15px event-button-cont">
          <h5
            className="flex gap-10px align-center pointer"
            onClick={() => handleBack()}
          >
            <CaretLeft size={20} color="#000" />
            <span className="text-15 w-500">Back</span>
          </h5>
          <Button className="stepper-button" onClick={handleFinish}>
            {isLoading ? <ButtonSpinner size={20} color="#000" /> : 'Finish'}
          </Button>
        </div>
      </div>

      {addSpecialSchedule && (
        <SpecialScheduleModal
          specialScheduleData={specialScheduleData}
          setSpecialScheduleData={setSpecialScheduleData}
        />
      )}

      {statusModalVisible && <StatusModal />}
    </>
  )
}

export default CommunityStep3
