import React, { useState, useRef, useEffect } from 'react'
import { Input } from 'antd'
import { Paperclip, Smiley, PaperPlaneTilt } from '@phosphor-icons/react'
import Picker from 'emoji-picker-react'
import '../styles/styles.scss'
import { sendMessage, updateChatRoom } from 'helper/Chat/ChatHelpers'
import Uploadfile from './UploadFile'
import { eventShatStore } from 'state/event/eventChat'
import ButtonSpinner from '../ButtonSpinner'
import { Person } from 'types/globalTypes'
import { useSendMessageNotification } from 'api/Notification'
import { serverTimestamp, Timestamp } from 'firebase/firestore'

interface SendInputProps {
  chatroomId: string
  participants: Person[]
}

const SendInput: React.FC<SendInputProps> = ({ chatroomId, participants }) => {
  const [imageUrls, setImageUrls] = useState<string>('')
  const [sendFile, setSendFile] = useState<boolean>(false)
  const [messageType, setType] = useState<
    'Image' | 'Video' | 'Voicenote' | 'Text' | 'File'
  >('Text')
  const [thumbnailUrl, setThumbnailUrl] = useState<string>('')
  const [inputStr, setInputStr] = useState<string>('')
  const [showPicker, setShowPicker] = useState<boolean>(false)
  const [imageName, setImageName] = useState<string>()
  const emojiPickerRef = useRef(null)
  const { sendingMessage, setSendingMessage } = eventShatStore(store => ({
    sendingMessage: store.sendingMessage,
    setSendingMessage: store.setSendingMessage,
  }))
  const selectedChatRoomMembers = eventShatStore(
    store => store.selectedChatRoomMembers,
  )
  const { mutate: messageNotificationMutation } = useSendMessageNotification()

  const onEmojiClick = (event, emojiObject) => {
    setInputStr(prevInput => prevInput + event.emoji)
    // setShowPicker(false);
  }

  const togglePicker = event => {
    event.stopPropagation()
    setShowPicker(val => !val)
  }

  useEffect(() => {
    const closeEmojiPicker = event => {
      if (
        showPicker &&
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target)
      ) {
        setShowPicker(false)
      }
    }

    document.addEventListener('click', closeEmojiPicker)

    return () => {
      document.removeEventListener('click', closeEmojiPicker)
    }
  }, [showPicker])

  const hanldeSendMessage = async (e: any) => {
    e.preventDefault()
    const senderId = localStorage.getItem('adminId')
    const payload = {
      chatRoomId: chatroomId,
      fileUrl: imageUrls || '',
      messageText: inputStr,
      // messageType: imageUrls ? "Image" : "text",
      messageType: messageType,
      recieverId: null,
      senderId: localStorage.getItem('adminId'),
      time: new Date().toISOString,
      thumbnailUrl: thumbnailUrl,
    }
    setSendingMessage(true)
    await sendMessage(
      chatroomId,
      senderId,
      null,
      messageType,
      inputStr,
      imageUrls,
      thumbnailUrl,
      [senderId],
    )
    await updateChatRoom(chatroomId, {
      lastMessage: {
        fileUrl: '',
        messageText: inputStr.trim(),
        messageType,
        recieverId: null,
        senderId,
        thumbnailUrl,
        readByUsers: [senderId],
        time: serverTimestamp() as Timestamp,
      },
    })
    messageNotificationMutation({
      chatRoomId: chatroomId,
      members: selectedChatRoomMembers
        .map(member => {
          const participant = participants.find(
            p => p._id === member && p.role !== 'admin',
          )
          return participant
            ? {
                id: participant._id,
                role: participant.role as
                  | 'kid'
                  | 'guardian'
                  | 'authorizedAdult',
                name: participant.firstName,
                familyId: participant.familyId,
              }
            : null
        })
        .filter(Boolean),
      lastMessage: inputStr.trim(),
      lastMessageType: messageType,
      senderId: senderId,
      title: 'Chat',
    })

    setSendingMessage(false)
    setInputStr('')
    setImageUrls(null)
    setImageName(null)
    setType('Text')
  }

  return (
    <div>
      <form action="" onSubmit={hanldeSendMessage}>
        <Input
          type="text"
          placeholder="Write a message..."
          className={`input-box`}
          value={inputStr}
          onChange={e => setInputStr(e.target.value)}
        />

        {imageName && (
          <div>
            <h3 className="text-12 w-400">{imageName}</h3>
            <div
              className={`${
                messageType === 'Video' || messageType === 'Image'
                  ? 'd-block'
                  : 'd-hidden'
              }`}
            >
              <img
                src={messageType === 'Video' ? thumbnailUrl : imageUrls}
                alt=""
                width={80}
                height={80}
              />
            </div>
            {messageType.toLowerCase() === 'voicenote' && (
              <audio controls>
                <source src={imageUrls} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            )}
          </div>
        )}
        <div className="flex justify-between align-center mt-20px">
          <div className="icons flex align-center gap-16px">
            <Paperclip
              size={24}
              height={24}
              color="#000000"
              onClick={() => setSendFile(!sendFile)}
            />
            <Smiley
              size={24}
              height={24}
              color="#000000"
              onClick={event => togglePicker(event)}
            />
          </div>
          <div
            ref={emojiPickerRef}
            className="emoji"
            style={{ position: 'absolute', bottom: '7rem', zIndex: '99999' }}
          >
            {showPicker && (
              <Picker onEmojiClick={onEmojiClick} searchDisabled={true} />
            )}
          </div>
          <button className={`send`} type="submit">
            {sendingMessage ? (
              <ButtonSpinner size={16} color="#2ba7ca" />
            ) : (
              <PaperPlaneTilt
                size={24}
                height={24}
                color={inputStr.length > 0 || imageUrls ? '#2BA7CA' : '#939598'}
                weight="fill"
              />
            )}
          </button>
        </div>
        {sendFile && (
          <Uploadfile
            isModalOpen={sendFile}
            setIsOpenModal={setSendFile}
            setImgLink={setImageUrls}
            setType={setType}
            setThumbnailUrl={setThumbnailUrl}
            setImageName={setImageName}
          />
        )}
      </form>
    </div>
  )
}

export default SendInput
