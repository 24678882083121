import { Check } from '@phosphor-icons/react'
import { Avatar } from 'antd'
import { getNameSignature } from 'helper/utility'
import React from 'react'

interface MessageBoxProps {
  recepient: 'sender' | 'receiver'
  messageType: 'image' | 'video' | 'file' | 'voicenote' | 'text'
  fileUrl?: string
  messageText?: string
  name: string
  time: string
  avatar?: string
}

const MessageBox: React.FC<MessageBoxProps> = ({
  recepient,
  messageType,
  fileUrl,
  messageText,
  name,
  time,
  avatar,
}) => {
  return (
    <div
      className={`flex gap-10px justify-end ${
        recepient === 'sender'
          ? 'flex-row text-right'
          : 'flex-row-reverse text-left'
      } align-end message-box mt-20px`}
    >
      <div
        className={`px-12px py-5px br-12 message-box-width ${
          recepient === 'sender' ? 'background-send' : 'background-received'
        }`}
      >
        <h3 className="text-16 w-600">{name}</h3>
        <div className="mt-8">
          {messageType.toLowerCase() === 'image' && (
            <img src={fileUrl} width={250} height={150} alt="img" />
          )}
        </div>
        {messageType.toLowerCase() === 'video' && (
          <div className="mt-8px">
            <video width="250" height="150" controls>
              <source src={fileUrl} type="video/mp4" />
            </video>
          </div>
        )}

        {messageType.toLowerCase() === 'voicenote' && (
          <audio controls>
            <source src={fileUrl} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        )}

        {messageType.toLowerCase() === 'file' && (
          <div className="mt-8px">
            <object
              data={fileUrl}
              type="application/pdf"
              height="100%"
              width="100%"
              className="cursor-pointer"
            >
              <p>Your browser does not support embedded PDFs.</p>
            </object>
            <a
              href={fileUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer font12 b2 text-color-black"
            >
              Click here to download
            </a>
          </div>
        )}
        <p className="text-14 w-400 mt-5px text-left">{messageText}</p>
        <div className="flex align-center justify-end">
          <p className="text-12 w-400 text-gray mr-5px">{time}</p>
          <Check size={14} color={'#32312F80'} />
        </div>
      </div>
      <div className="avatar">
        <Avatar size={40} src={avatar}>
          {getNameSignature(name)}
        </Avatar>
      </div>
    </div>
  )
}

export default MessageBox
