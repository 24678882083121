import axios from 'axios'
import { axiosGet, axiosPatch, axiosPost, axiosPut } from '../Redux/axiosHelper'
import { OrganizationResp, RequestParms } from '../types/globalTypes'
import { BASE_URL, failureMessage, successMessage } from './global'
import OrganazationsDataType from '../pages/Organization/types'
import { myStore } from 'state/roleStore'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  CreateOrganizationPayload,
  OrganizationAnalyticsType,
  OrganizerNotAddedOrganizationType,
  OrgApiResponse,
  OrgOrganizerParams,
  OrgOrganizerType,
} from 'types/organization'
export const getOrganizations = async (params: RequestParms) => {
  const response = await axiosGet(`v1/admins/organizations`, params)
  return response.data
}

export const getMe = async () => {
  const response = await axiosGet(`v1/admins/me`)
  localStorage.setItem('role', response?.data?.role)
  myStore.setState({ role: response?.data?.role })
  myStore.setState({ organizationId: response?.data?.primaryOrganizationId })
  // console.log('role', response?.data?.primaryOrganizationId)

  return response.data
}
export const changeOrganizationStatus = async (
  params: string,
  payload: string,
) => {
  const response = await axios.patch(
    `${BASE_URL}v1/organizations/${params}/status`,
    {
      status: payload,
    },
  )
  if (response) {
    successMessage('Status changed Successfully !')
  } else {
    failureMessage('Error changing Status')
  }
}
export const getFamiliesForOrganization = async (
  organizationKey: any,
  params: any,
) => {
  const response = await axiosGet(
    `v1/families/for-organization/${organizationKey}`,
    params,
  )
  return response.data
}

export const getOrganizationFamily = async (payload: any, params: any) => {
  const response = await axiosGet(
    `v1/organization-subscription/${payload}`,
    params,
  )
  return response.data
}
// update the members of the Organization
export const updateOrganizationMemeberStatus = async (
  organizationKey: string,
  data: any,
  status: string,
) => {
  try {
    const response = await axiosPatch(
      {
        status: status,
        accessLevel: await data?.accessLevel,
        designation: await data?.designation,
      },
      `v1/organizations/${organizationKey}/change-status/${data?.key}`,
    )
    return response
  } catch (error) {
    console.error('Error updating organization member status:', error)
    throw error
  }
}
export const getOrganizersOfOrganization = async (
  id: string,
  params: RequestParms,
) => {
  const response = await axiosGet(`v1/organizations/${id}/get-members`, params)
  return response?.data
}
// todo extra code
export const addNewOrganization = async (data: OrganizationResp) => {
  const response = await axios.post(
    `${BASE_URL}v1/admins/create-organization`,
    data,
  )
  response.status === 200
    ? successMessage('New Organization added Successfully !!')
    : failureMessage('Unable to add new Organization')
}
// todo extra code
export const assignOrganization = async (organizations: any) => {
  let id = localStorage.getItem('organizerId')
  const response = await axios.put(
    `${BASE_URL}v1/admins/organizer/${id}/assign-organization`,
    organizations,
  )
  response.status === 200
    ? successMessage('Organization assigned Successfully !!')
    : failureMessage('Unable to add assign the Organization')
}
export const updateOrganization = async (
  id: string,
  payload: OrganazationsDataType,
) => {
  const response = await axios.patch(
    `${BASE_URL}v1/organizations/${id}`,
    payload,
  )
  response.status === 200
    ? successMessage('Organization updated Successfully !!')
    : failureMessage('Unable to update the Organization')
}
export const importOrganizationFile = async (payload: any) => {
  try {
    const response = await axios.post(
      `${BASE_URL}v1/imports/organization`,
      payload,
    )
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(`Unexpected status code: ${response.status}`)
    }
  } catch (error) {
    failureMessage(`${error.response.data.message}`)
    return null
  }
}
export const confirmOrganizationImportData = async (payload: any) => {
  try {
    const response = await axios.put(
      `${BASE_URL}v1/imports/organization`,
      payload,
    )
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(`Unexpected status code: ${response.status}`)
    }
  } catch (error) {
    failureMessage(`${error.response.data.message}`)
    return null
  }
}
export const OrgchangeEmail = async (
  organizationId: string,
  payload: string,
) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}v1/organizations/change-email/${organizationId}`,
      payload,
    )
    if (response.status === 200) {
      successMessage('Email change Successfully !')
      return response.data
    } else {
      failureMessage(response?.data?.message || 'An error occurred')
      return Promise.reject(response.data)
    }
  } catch (error) {
    if (error.response) {
      failureMessage(error.response.data.message || 'An error occurred')
    } else {
      failureMessage('An error occurred')
    }
  }
}

const getOrganizationDetails = async (organizationId: string) => {
  const response = await axiosGet(`v1/organizations/${organizationId}`)
  return response.data
}

export const useGetOrganizationDetails = (organizationId: string) => {
  return useQuery<OrganizationResp>(
    ['organization-details'],
    () => getOrganizationDetails(organizationId),
    { enabled: false },
  )
}

// get organization analytics
export const getOrganizationAnalytics = async (organizationId: string) => {
  const response = await axiosGet(
    `v1/organizations/${organizationId}/analytics`,
  )
  return response.data
}

export const useGetOrganizationAnalytics = (orgId: string) => {
  const orgAnalytics = useQuery<OrganizationAnalyticsType>(
    ['org-analytics-by-id'],
    () => (orgId ? getOrganizationAnalytics(orgId) : null),
    { enabled: false },
  )
  return orgAnalytics
}

export const getOrganizationMembers = async (
  params: OrgOrganizerParams,
  id: string,
) => {
  const response = await axiosGet(`v1/organizations/${id}/get-members`, params)
  return response.data
}

export const useGetOrgOrganizers = (params: OrgOrganizerParams, id: string) => {
  const organizations = useQuery<OrgOrganizerType[]>(
    ['org-organizers'],
    () => getOrganizationMembers(params, id),
    { enabled: false },
  )
  return organizations
}

// create new organization
const createOrganization = async (payload: CreateOrganizationPayload) => {
  const response = await axiosPost(payload, `v1/admins/create-organization`)
  return response.data
}
export const useCreateOrganization = () => {
  const queryClient = useQueryClient()
  return useMutation(createOrganization, {
    onSuccess: data => {
      queryClient.setQueryData(
        ['getOrganization-listing'],
        (oldData: OrgApiResponse | undefined) => {
          if (!oldData) return
          return {
            ...oldData,
            records: [data, ...oldData.records],
            count: oldData.count + 1,
          }
        },
      )
    },
  })
}

// update organization
const updateOrganizationById = async ({
  payload,
  organizationId,
}: {
  payload: CreateOrganizationPayload
  organizationId: string
}) => {
  const response = await axiosPatch(payload, `v1/admins/${organizationId}`)
  return response.data
}
export const useUpdateOrganizationById = () => {
  const queryClient = useQueryClient()
  return useMutation(updateOrganizationById, {
    onSuccess: updatedData => {
      queryClient.setQueryData(['organization-details'], () => {
        return { ...updatedData }
      })
      queryClient.setQueryData(
        ['getOrganization-listing'],
        (oldData: OrgApiResponse | undefined) => {
          if (!oldData) return
          return {
            ...oldData,
            records: oldData.records.map(data =>
              data._id === updatedData._id ? updatedData : data,
            ),
            count: oldData.count + 1,
          }
        },
      )
    },
  })
}

// get organizations to add organizer in it
const getOrganizationsToAddOrganizer = async (
  organizerId: string,
  params: OrganizerNotAddedOrganizationType,
) => {
  const response = await axiosGet(
    `v1/organizers/search-organizations/${organizerId}`,
    params,
  )
  return response.data
}

export const useGetOrganizationsToAddOrganizer = (
  organizerId: string,
  params: OrganizerNotAddedOrganizationType,
) => {
  const organizationsToAddOrganizer = useQuery<OrgApiResponse>(
    ['get-organizations-to-add-organizer'],
    () => getOrganizationsToAddOrganizer(organizerId, params),
    { enabled: false },
  )
  return organizationsToAddOrganizer
}
