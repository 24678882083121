import React, { useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import { SpecialSchedule } from 'types/organization'
import moment from 'moment'
import SelectMultiDaysCalenderModel from '../GeneralDetailModal/SelectMultiDaysCalenderModel'
import AddSpecialScheduleModal from '../GeneralDetailModal/AddSpecialScheduleModal'
import { convertMinutesToLocalTime } from 'helper/utility'
import { CaretRight } from '@phosphor-icons/react'
import '../styles/general-details.scss'
import { infoMessage } from 'api/global'

interface DateandTimePropTypes {
  selectedDates?: string[]
  setSelectedDates?: React.Dispatch<React.SetStateAction<string[]>>
  specialScheduleData?: any
  setSpecialScheduleData?: any
  setAddSpecialScheduleModalVisible?: any
  eventTime?: any
  setEventTime?: any
  type?: 'admission' | 'additional' | 'dropin' | 'discount'
  allowedTicketId?: string
}

const DateandTimeInputs: React.FC<DateandTimePropTypes> = ({
  selectedDates,
  setSelectedDates,
  specialScheduleData,
  setSpecialScheduleData,
  eventTime,
  setEventTime,
  type,
  allowedTicketId,
}) => {
  const [arrangedSelectedDates, setArrangedSelectedDates] = useState<string[]>()
  const [selectDaysModalVisible, setSelectDaysModalVisible] =
    useState<boolean>(false)
  const [addSpecialScheduleModalVisible, setAddSpecialScheduleModalVisible] =
    useState<boolean>(false)

  const handleDaysModal = () => {
    if (type === 'additional') {
      if (!allowedTicketId) {
        return infoMessage('First select allowed ticket')
      } else {
        setSelectDaysModalVisible(true)
      }
    } else {
      setSelectDaysModalVisible(true)
    }
  }

  useEffect(() => {
    if (selectedDates?.length > 0) {
      const sortedDates = [...selectedDates].sort(
        (a, b) => new Date(a).getTime() - new Date(b).getTime(),
      )
      setArrangedSelectedDates(sortedDates)
    }
  }, [selectedDates])

  return (
    <>
      <Row className="mt-10px">
        <Col>
          <label htmlFor="" className="input-label required-input">
            Date and Time
          </label>
          <Col style={{ height: 50 }}>
            <div
              onClick={handleDaysModal}
              className="w-full border-1px border-top flex align-center px-12px pointer h-full"
            >
              <div className=" w-full flex justify-between align-center">
                <p className="text-gray">Dates</p>
                <div className="d-flex align-center gap-10px">
                  {arrangedSelectedDates?.length > 0 ? (
                    <p className="text-14px w-500">{`${moment(
                      arrangedSelectedDates[0],
                    ).format('MMM DD')} - ${moment(
                      arrangedSelectedDates[arrangedSelectedDates.length - 1],
                    ).format('MMM DD')}, ${moment(
                      arrangedSelectedDates[0],
                    ).format('yyyy')}`}</p>
                  ) : (
                    <p className="text-gray">Select</p>
                  )}
                  <CaretRight size={16} color="#000" />
                </div>
              </div>
            </div>
          </Col>

          <Col style={{ marginTop: 0, height: 50 }}>
            <div
              className={`w-full border-1px flex align-center px-12px pointer h-full ${
                specialScheduleData.length === 0 && 'border-bottom'
              }`}
              // onClick={() => setScheduleModalVisible(true)}
              onClick={handleDaysModal}
            >
              <div className=" w-full flex justify-between align-center">
                <p className="text-gray">Time</p>
                <div className="d-flex align-center gap-10px">
                  {selectedDates?.length > 0 ? (
                    <p className="text-14px w-500">{`${moment(
                      eventTime.startTime,
                    ).format('hh:mm A')} - ${moment(eventTime.endTime).format(
                      'hh:mm A',
                    )}`}</p>
                  ) : (
                    <p className="text-gray">Select</p>
                  )}
                  <CaretRight size={16} color="#000" />
                </div>
              </div>
            </div>
          </Col>
        </Col>

        {specialScheduleData && specialScheduleData.length > 0 && (
          <div className="w-full">
            <Row style={{ marginTop: 0 }} className="w-full">
              <div className="w-full border-1px flex align-center px-12px pointer h-full py-5px border-bottom">
                {specialScheduleData && specialScheduleData.length > 0 && (
                  <div className=" w-full flex justify-between">
                    <p className="text-gray mt-10px">Special Schedule</p>
                    <div className="flex gap-10px">
                      <div className="flex flex-col gap-10px">
                        {specialScheduleData.map(
                          (item: SpecialSchedule, index: number) => {
                            return (
                              <div className="" key={index}>
                                <p className="text-14px w-500 mt-5px">{`${moment(
                                  item.day,
                                ).format(
                                  'MMM DD,YYYY',
                                )}, ${convertMinutesToLocalTime(
                                  item.startTime,
                                )} - ${convertMinutesToLocalTime(
                                  item.endTime,
                                )}`}</p>
                              </div>
                            )
                          },
                        )}
                      </div>
                      <CaretRight size={16} color="#000" className="mt-10px" />
                    </div>
                  </div>
                )}
              </div>
            </Row>
          </div>
        )}
      </Row>

      {selectDaysModalVisible && (
        <SelectMultiDaysCalenderModel
          specialScheduleModalVisible={addSpecialScheduleModalVisible}
          setSpecialScheduleVisible={setAddSpecialScheduleModalVisible}
          isSelectMultiDaysCalenderVisible={selectDaysModalVisible}
          setIsSelectMultiDaysCalenderVisible={setSelectDaysModalVisible}
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
          eventTime={eventTime}
          setEventTime={setEventTime}
          specialScheduleData={specialScheduleData}
          setSpecialScheduleData={setSpecialScheduleData}
          step1={type ? false : true}
          type={type}
          allowedTicketId={allowedTicketId}
        />
      )}
      {addSpecialScheduleModalVisible && (
        <AddSpecialScheduleModal
          addSpecialScheduleModalVisible={addSpecialScheduleModalVisible}
          setAddSpecialScheduleModalVisible={setAddSpecialScheduleModalVisible}
          selectedDates={selectedDates}
          setSpecialScheduleData={setSpecialScheduleData}
          specialScheduleData={specialScheduleData}
        />
      )}
    </>
  )
}

export default DateandTimeInputs
