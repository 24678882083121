import React, { useEffect } from 'react'
import { FormInstance, Select } from 'antd'
import { useGetGrades } from 'api/Listings/Grades'
import { BasicListingType } from 'types/listing/listings'
import { capitalizeFirstLetter } from 'helper/utility'
import Spinner from 'components/Spinner'

interface GradeInputProps {
  form: FormInstance
  gradeId: string
  data: string | undefined
}

const GradeInput: React.FC<GradeInputProps> = ({ form, gradeId, data }) => {
  const { data: gradesData, isLoading, isFetching, refetch } = useGetGrades()

  useEffect(() => {
    refetch()
  }, [])

  return (
    <>
      <Select
        className="h-50px select-field"
        placeholder={'Grade'}
        onChange={(_, item: { value: string }) => {
          form.setFieldsValue({
            gradeId: item.value,
          })
        }}
        defaultValue={data}
      >
        {isLoading || isFetching ? (
          <div className="py-40px flex justify-center">
            <Spinner />
          </div>
        ) : (
          gradesData.map((grade: BasicListingType) => (
            <Select.Option key={grade._id} value={grade._id} className="">
              {capitalizeFirstLetter(grade.name)}
            </Select.Option>
          ))
        )}
      </Select>
    </>
  )
}

export default GradeInput
