import React, { useEffect, useState } from 'react'
import { useAddOrgLocation } from 'api/EventCreation/EventCreation'
import MapModal from 'helper/Map/MapModal'
import LocationListingModal from 'pages/Events/Views/CreateEvent/Screens/GeneralDetails/GeneralDetailModal/LocationListingModal'
import { Address } from 'types/globalTypes'
import { FormInstance } from 'antd'

interface VenueInputProps {
  mapData: Address | null
  setMapData: React.Dispatch<React.SetStateAction<Address>>
  leagueData: any // todo
  form: FormInstance
}

const VenueInput: React.FC<VenueInputProps> = ({
  mapData,
  setMapData,
  leagueData,
  form,
}) => {
  const [locationModalVisible, setLocationModalVisible] =
    useState<boolean>(false)
  const [activeKey, setActiveKey] = useState<string>('1')
  const [mapModalVisible, setMapModalVisible] = useState<boolean>(false)
  const [isFromPreviousLoc, setIsFromPreviousLoc] = useState<boolean>(false)
  const { mutate, isLoading } = useAddOrgLocation()

  const setFieldsValue = (leagueData: any) => {
    if (leagueData.timeSlots?.venue) {
      form.setFieldsValue({ location: leagueData.timeSlots.venue.title })
    }
  }

  useEffect(() => {
    if (!mapData?._id && mapData) {
      mutate(
        { payload: mapData, organizationId: leagueData.organizationId },
        {
          onSuccess: result => {
            setLocationModalVisible(false)
          },
        },
      )
    }
  }, [mapData])

  return (
    <>
      <div
        className="w-full border-1px radius-10 flex align-center px-12px pointer h-50px"
        onClick={() => setLocationModalVisible(true)}
      >
        {mapData ? (
          <div className=" w-full flex justify-between align-center ">
            <p className="text-gray">{`${mapData.title}: ${mapData.apartment}, ${mapData.city}, ${mapData.country}`}</p>
          </div>
        ) : (
          <p className="text-gray">Select Location</p>
        )}
      </div>

      {locationModalVisible && (
        <LocationListingModal
          isModalVisible={locationModalVisible}
          setModalVisible={setLocationModalVisible}
          organizationId={leagueData.organizationId}
          activeKeyTab={activeKey}
          setDefaultTabKey={setActiveKey}
          setMapModalVisible={setMapModalVisible}
          setMapModalData={setMapData}
          setFieldsValue={setFieldsValue}
          setIsFromPreviousLoc={setIsFromPreviousLoc}
        />
      )}
      {mapModalVisible && (
        <MapModal
          mapData={isFromPreviousLoc && mapData}
          setMapModalVisible={setMapModalVisible}
          setMapModalData={setMapData}
          setFieldsValue={setFieldsValue}
        />
      )}
    </>
  )
}

export default VenueInput
