import React, { useState } from 'react'
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
} from 'antd'
import { organizationTypes } from '../../constants'
import { validatePhone } from 'helper/utility'
import PhoneInput from 'react-phone-input-2'
import { Address, Phone } from 'types/globalTypes'
import { organizationStore } from 'state/organization/organization'
import MapModal from 'helper/Map/MapModal'
import moment from 'moment'

interface Step1Props {
  form: FormInstance
}

const Step1: React.FC<Step1Props> = ({ form }) => {
  const { organizationData, setOrganizationData, setOrganizationCurrentStep } =
    organizationStore(store => ({
      organizationData: store.organizationData,
      setOrganizationData: store.setOrganizationData,
      setOrganizationCurrentStep: store.setOrganizationCurrentStep,
    }))

  const [phone, setPhone] = useState<Phone>({
    dialCode: organizationData ? organizationData.phone?.dialCode : '',
    isoCode: organizationData ? organizationData.phone?.isoCode : '',
    number: organizationData ? organizationData.phone?.number : '',
  })

  const [mapModalVisible, setMapModalVisible] = useState(false)
  const [mapModalData, setMapModalData] = useState<Address>(
    organizationData ? organizationData.address : undefined,
  )
  const [mailingAddress, setMailingAddress] = useState<Address>(
    organizationData ? organizationData.mailingAddress : undefined,
  )
  const [isChecked, setIsChecked] = useState({
    isLandlinePhone: organizationData
      ? organizationData.isLandlinePhone
      : false,
    isSameCommunication: organizationData
      ? organizationData.sameEmailForCommunication
      : false,
    isSameMailingAddress: organizationData
      ? organizationData.isSameMailingAddress
      : false,
  })

  const formSchema = {
    name: organizationData ? organizationData.name : undefined,
    nonProfit:
      organizationData && organizationData.nonProfit ? 'Non-profit' : 'Profit',
    phone:
      organizationData && organizationData.phone
        ? organizationData.phone.number
        : undefined,
    email: organizationData ? organizationData.email : undefined,
    communicationEmail: organizationData
      ? organizationData.communicationEmail
      : undefined,
    establishedOn:
      organizationData && organizationData.establishedOn
        ? moment(organizationData.establishedOn)
        : undefined,
  }

  const onFinsish = values => {
    const payload = { ...values }
    payload.nonProfit = payload.nonProfit === 'non-profit' ? true : false
    payload.phone = phone.number && phone.number.length > 4 ? phone : undefined
    payload.isLandlinePhone =
      phone.number && phone.number.length > 4
        ? isChecked.isLandlinePhone
        : undefined
    payload.sameEmailForCommunication = isChecked.isSameCommunication
      ? true
      : false
    payload.address = mapModalData ? mapModalData : undefined
    payload.isSameMailingAddress = isChecked.isSameMailingAddress
    payload.mailingAddress =
      !isChecked.isSameMailingAddress && mailingAddress
        ? mailingAddress
        : undefined
    payload.establishedOn = values.establishedOn
      ? moment(values.dob).format('YYYY-MM-DD')
      : undefined

    setOrganizationData({ ...organizationData, ...payload })
    setOrganizationCurrentStep(1)
  }

  return (
    <div className="mt-20px">
      <Form
        name="organization-creation-form"
        initialValues={formSchema}
        form={form}
        onFinish={onFinsish}
        layout="vertical"
        autoComplete="off"
      >
        <Row>
          <Col span={24}>
            <label className="input-label required-input">
              Organization name
            </label>
            <Form.Item
              name="name"
              rules={[{ required: true, message: 'Name is required' }]}
            >
              <Input placeholder="Ex.: Bamrec" type="name" name="name" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24} className="mt-10px">
            <label className="input-label">Organization type</label>
            <Form.Item name="nonProfit">
              <Select
                className="h-50px select-field"
                placeholder={'Select'}
                onChange={(v, item: any) => {
                  form.setFieldsValue({
                    nonProfit: item.key,
                  })
                }}
              >
                {organizationTypes.map(
                  (type: { key: string; name: string }) => (
                    <Select.Option key={type.key} value={type.name}>
                      {type.name}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label className="input-label">Phone number</label>
            <Form.Item
              name="phone"
              className="phone-number-form"
              rules={[{ validator: validatePhone }]}
            >
              <PhoneInput
                country={phone?.isoCode || 'us'}
                onChange={(value: string, data: any) => {
                  setPhone({
                    dialCode: '+' + data?.dialCode,
                    number: '+' + value,
                    isoCode: data.countryCode.toUpperCase(),
                  })
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        {phone?.number?.length > 4 && (
          <Row gutter={0} className="modal-checker">
            <Col span={24}>
              <Form.Item name="isLandlinePhone" valuePropName="checked">
                <div className="d-flex align-items-center checkbox-container">
                  <Checkbox
                    name="isEmergencyContact checkbox-style"
                    checked={isChecked.isLandlinePhone}
                    onChange={e =>
                      setIsChecked({
                        ...isChecked,
                        isLandlinePhone: e.target.checked,
                      })
                    }
                  >
                    <div className="w-600 text-12 mt-8px">
                      It's landline phone
                    </div>
                  </Checkbox>
                </div>
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row>
          <Col span={24}>
            <label className="input-label required-input">
              Organization email
            </label>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: 'Email is required' },
                { type: 'email', message: 'Email format is not right' },
              ]}
            >
              <Input
                placeholder="example@gmail.com"
                type="email"
                name="email"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={0} className="modal-checker">
          <Col span={24}>
            <Form.Item name="sameEmailForCommunication" valuePropName="checked">
              <div className="d-flex align-items-center checkbox-container">
                <Checkbox
                  name="sameEmailForCommunication checkbox-style"
                  checked={isChecked.isSameCommunication}
                  onChange={e =>
                    setIsChecked({
                      ...isChecked,
                      isSameCommunication: e.target.checked,
                    })
                  }
                >
                  <div className="w-600 text-12 mt-8px">
                    Same email for communication
                  </div>
                </Checkbox>
              </div>
            </Form.Item>
          </Col>
        </Row>

        {!isChecked.isSameCommunication && (
          <Row>
            <Col span={24}>
              <label className="input-label">Communication email</label>
              <Form.Item
                name="communicationEmail"
                rules={[
                  { type: 'email', message: 'Email format is not right' },
                ]}
              >
                <Input
                  placeholder="example@gmail.com"
                  type="email"
                  name="communicationEmail"
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row>
          <Col span={24}>
            <label className="input-label">Physical address</label>
            <Form.Item>
              <Input
                placeholder="Location"
                onClick={() => {
                  setMapModalVisible(true)
                }}
                value={
                  mapModalData
                    ? mapModalData.city + ',' + mapModalData.state
                    : ''
                }
              />
              {mapModalVisible && (
                <MapModal
                  setMapModalVisible={setMapModalVisible}
                  setMapModalData={setMapModalData}
                  mapData={mapModalData}
                />
              )}{' '}
            </Form.Item>
          </Col>
        </Row>

        {mapModalData && (
          <Row gutter={0} className="modal-checker">
            <Col span={24}>
              <Form.Item name="isSameMailingAddress" valuePropName="checked">
                <div className="d-flex align-items-center checkbox-container">
                  <Checkbox
                    name="isSameMailingAddress checkbox-style"
                    checked={isChecked.isSameMailingAddress}
                    onChange={e =>
                      setIsChecked({
                        ...isChecked,
                        isSameMailingAddress: e.target.checked,
                      })
                    }
                  >
                    <div className="w-600 text-12 mt-8px">
                      Same mailing address
                    </div>
                  </Checkbox>
                </div>
              </Form.Item>
            </Col>
          </Row>
        )}

        {!isChecked.isSameMailingAddress && mapModalData && (
          <Row>
            <Col span={24}>
              <label className="input-label">Mailing address</label>
              <Form.Item>
                <Input
                  placeholder="Location"
                  onClick={() => {
                    setMapModalVisible(true)
                  }}
                  value={
                    mailingAddress
                      ? mailingAddress.city + ',' + mailingAddress.state
                      : ''
                  }
                />
                {mapModalVisible && (
                  <MapModal
                    setMapModalVisible={setMapModalVisible}
                    setMapModalData={setMailingAddress}
                    mapData={mailingAddress}
                  />
                )}{' '}
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row>
          <Col span={24}>
            <label className="input-label">Date established</label>
            <Form.Item name="establishedOn">
              <DatePicker name="establishedOn" className="selector w-full" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Step1
