import { create } from 'zustand'
import { BasicListingType } from 'types/listing/listings'

const ageGroupCreationStore = (set: any) => ({
  addAgeGroupModal: false,
  setAddAgeGroupModal: (state: boolean) =>
    set(() => ({ addAgeGroupModal: state })),
  ageGroupData: null,
  setAgeGroupData: (state: BasicListingType) =>
    set(() => ({ ageGroupData: state })),
})

export const ageGroupStore = create(ageGroupCreationStore)
