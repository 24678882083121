import React, { useEffect, useState } from 'react'
import { PencilSimpleLine } from '@phosphor-icons/react'
import { Table } from 'antd'
import { useGetGrades, useRemoveGrade } from 'api/Listings/Grades'
import Spinner from 'components/Spinner'
import AlertModal from 'helper/AlertModal'
import { capitalizeFirstLetter } from 'helper/utility'
import moment from 'moment'
import { gradeStore } from 'state/listing/gradeListing'
import { BasicListingType } from 'types/listing/listings'

interface GradesTableProps {
  id: string
}

const GradesTable: React.FC<GradesTableProps> = ({ id }) => {
  const [tableData, setTableData] = useState([])
  const [selectedGradeData, setSelectedGradeData] = useState<BasicListingType>()
  const [alertModal, setAlertModal] = useState<boolean>(false)
  const { setAddGradeModal, setGradeData } = gradeStore(store => ({
    setAddGradeModal: store.setAddGradeModal,
    setGradeData: store.setGradeData,
  }))
  const { data, isLoading, isFetching, refetch } = useGetGrades()
  const { mutate: removeGrade, isLoading: removeGradeLoading } =
    useRemoveGrade()

  const onYesClick = () => {
    removeGrade(selectedGradeData._id, {
      onSuccess: () => {
        setAlertModal(false)
      },
    })
  }

  const handleEditClick = (data: BasicListingType) => {
    setGradeData(data)
    setAddGradeModal(true)
  }
  const handleDeleteClick = (data: BasicListingType) => {
    setSelectedGradeData(data)
    setAlertModal(true)
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => (
        <p className="text-14 w-400">{capitalizeFirstLetter(text)}</p>
      ),
    },
    {
      title: 'Last Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text: string) => (
        <p className="text-14 w-400">{moment(text).format('DD MMM, YYYY')}</p>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      render: (data: BasicListingType) => (
        <div className="flex align-center gap-20px">
          <PencilSimpleLine
            size={20}
            color="#000"
            className="pointer"
            onClick={() => handleEditClick(data)}
          />
        </div>
      ),
    },
  ]

  useEffect(() => {
    refetch()
  }, [id])

  useEffect(() => {
    if (data) {
      const enrichedTableData = data.map((grade: BasicListingType) => {
        return {
          Key: grade._id,
          name: grade.name,
          updatedAt: grade.updatedAt,
          actions: grade,
        }
      })
      setTableData(enrichedTableData)
    }
  }, [data])

  return (
    <>
      <div>
        {isLoading || isFetching ? (
          <div className="w-full h-70vh flex align-center justify-center">
            <Spinner />
          </div>
        ) : (
          <Table
            className="colored_header_table"
            columns={columns}
            dataSource={tableData}
            pagination={false}
          />
        )}
      </div>
      {alertModal && (
        <AlertModal
          isModalVisible={alertModal}
          setIsModalVisible={setAlertModal}
          data={{
            name: `Delete grade ${selectedGradeData.name}`,
            heading: `Are you sure you want to delete grade ${selectedGradeData.name}?`,
          }}
          onYesClick={onYesClick}
          loading={removeGradeLoading}
        />
      )}
    </>
  )
}

export default GradesTable
