import React, { useState } from 'react'
import { CaretLeft } from '@phosphor-icons/react'
import { Button, Col, Form, Input, Row } from 'antd'
import { formValidationOnRequiredFields, validatePhone } from 'helper/utility'
import PhoneInput from 'react-phone-input-2'
import { Phone } from 'types/globalTypes'
import { infoMessage } from 'api/global'
import { communityStore } from 'state/event/community'
import { useCommunityStep2 } from 'api/Community/CommunityCreation'
import ButtonSpinner from 'components/global/ButtonSpinner'

interface CommunityStep2Props {
  handleNext: () => void
  handleBack: () => void
}

const CommunityStep2: React.FC<CommunityStep2Props> = ({
  handleBack,
  handleNext,
}) => {
  const [form] = Form.useForm()
  const { communityData, setCommunityData } = communityStore(store => ({
    communityData: store.communityData,
    setCommunityData: store.setCommunityData,
  }))
  const [phone, setPhone] = useState<Phone>({
    dialCode: communityData.phone ? communityData.phone.dialCode : '',
    isoCode: communityData.phone ? communityData.phone.isoCode : '',
    number: communityData.phone ? communityData.phone.number : '',
  })
  const {
    mutate: createUpdateCommunityStep2,
    isLoading: updateCommunityLoadingStep2,
  } = useCommunityStep2()

  const formSchema = {
    email: communityData ? communityData.email : undefined,
    phone: communityData.phone ? communityData.phone.number : undefined,
    websiteUrl: communityData ? communityData.websiteUrl : undefined,
    customUrl: communityData ? communityData.customUrl : undefined,
  }

  const onFinish = (values: {
    email: string
    websiteUrl: string
    customUrl: string
  }) => {
    if (!formValidationOnRequiredFields(values, ['email', 'websiteUrl'])) return
    if (phone.number.length < 3) return infoMessage('Phone number is required')

    const payload = { ...values, phone: phone }

    createUpdateCommunityStep2(
      { payload, communityId: communityData._id },
      {
        onSuccess: data => {
          setCommunityData(data)
          handleNext()
        },
      },
    )
  }

  return (
    <div className="create-event-main-content-box" style={{ margin: '0 auto' }}>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={formSchema}
        layout="vertical"
        autoComplete="off"
      >
        <h2>Step 2. Contact Details</h2>
        <Row>
          <Col span={24}>
            <label htmlFor="" className="input-label required-input">
              Email
            </label>
            <Form.Item colon={false} name="email" className="form-control">
              <Input
                placeholder="example@gmail.com"
                name="email"
                className="form-control-field"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label className="input-label required-input">Phone number</label>
            <Form.Item
              name="phone"
              className="phone-number-form"
              rules={[{ validator: validatePhone }]}
            >
              <PhoneInput
                country={phone?.isoCode.toLowerCase() || 'us'}
                onChange={(value: string, data: any) => {
                  setPhone({
                    dialCode: '+' + data?.dialCode,
                    number: '+' + value,
                    isoCode: data.countryCode.toUpperCase(),
                  })
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label htmlFor="" className="input-label required-input">
              Website
            </label>
            <Form.Item colon={false} name="websiteUrl" className="form-control">
              <Input
                placeholder="Enter Website URL"
                name="websiteUrl"
                className="form-control-field"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label htmlFor="" className="input-label">
              Custom URL
            </label>
            <Form.Item colon={false} name="customUrl" className="form-control">
              <Input
                placeholder="Custom Url"
                name="customUrl"
                className="form-control-field"
              />
            </Form.Item>
          </Col>
        </Row>

        <div className="mt-15px event-button-cont">
          <h5
            className="flex gap-10px align-center pointer"
            onClick={() => handleBack()}
          >
            <CaretLeft size={20} color="#000" />
            <span className="text-15 w-500">Back</span>
          </h5>
          <Button
            className="stepper-button"
            disabled={updateCommunityLoadingStep2}
            htmlType="submit"
          >
            {updateCommunityLoadingStep2 ? (
              <ButtonSpinner size={20} color="#000" />
            ) : (
              'Next'
            )}
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default CommunityStep2
