import React from 'react'
import { Col, Form, FormInstance, Input, Row } from 'antd'
import { myStore } from 'state/store'
import { relationStore } from 'state/listing/relationListing'
import { useCreateRelation, useUpdateRelation } from 'api/Listings/Relation'

interface RelationFormProps {
  form: FormInstance
}

const RelationForm: React.FC<RelationFormProps> = ({ form }) => {
  const setModalBtnLoading = myStore(store => store.setModalBtnLoader)
  const { setAddRelationModal, relationData } = relationStore(store => ({
    setAddRelationModal: store.setAddRelationModal,
    relationData: store.relationData,
  }))
  const { mutate: createRelation } = useCreateRelation()
  const { mutate: updateRelation } = useUpdateRelation()

  const formSchema = {
    name: relationData ? relationData.name : undefined,
  }

  const onFinish = (values: { name: string }) => {
    setModalBtnLoading(true)

    if (relationData) {
      updateRelation(
        { relationId: relationData._id, payload: values },
        {
          onSuccess: () => {
            setModalBtnLoading(false)
            setAddRelationModal(false)
          },
        },
      )
    } else {
      createRelation(values, {
        onSuccess: () => {
          setModalBtnLoading(false)
          setAddRelationModal(false)
        },
      })
    }
  }
  return (
    <div>
      <Form
        name="grade-form"
        initialValues={formSchema}
        form={form}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
      >
        <Row className="mt-10px">
          <Col span={24}>
            <label className="input-label required-input">Name</label>
            <Form.Item
              colon={false}
              name="name"
              rules={[{ required: true, message: 'Name required' }]}
            >
              <Input placeholder="Enter Name" required name="name" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default RelationForm
