import { OrganizationResp } from 'types/globalTypes'
import OrganizerDataType from 'types/organizer'
import { create } from 'zustand'

const organizerCreationStore = (set: any) => ({
  // modal
  organizerModalVisible: false,
  setOrganizerModalVisible: (state: boolean) =>
    set(() => ({ organizerModalVisible: state })),
  // data sets
  organizerData: null,
  setOrganizerData: (state: OrganizerDataType) =>
    set(() => ({ organizerData: state })),
  assignedOrganizations: [],
  setAssignedOrganizations: (state: OrganizationResp[]) =>
    set(() => ({ assignedOrganizations: state })),
  organizerId: null,
  setOrganizerId: (state: string) => set(() => ({ organizerId: state })),
  // counters and other states
  organizerCurrentStep: 0,
  setOrganizerCurrentStep: (state: number) =>
    set(() => ({ organizerCurrentStep: state })),
  isUpdating: false,
  setIsUpdating: (state: boolean) => set(() => ({ isUpdating: state })),
})

export const organizerStore = create(organizerCreationStore)
