import CustomModal from 'components/global/CustomModal'
import CustomModalFooter from 'components/global/CustomModalFooter'
import React from 'react'
import { leagueEventStore } from 'state/event/leagueEvent'
import Region from './ModalContent/Region'
import useFormSubmit from 'helper/Hooks/formSubmit'

interface AddRegionProps {}

const AddRegion: React.FC<AddRegionProps> = () => {
  const { form, handleSubmit } = useFormSubmit()
  const { addRegionModal, setAddRegionModal } = leagueEventStore(store => ({
    addRegionModal: store.addRegionModal,
    setAddRegionModal: store.setAddRegionModal,
  }))

  return (
    <>
      <CustomModal
        openModal={addRegionModal}
        isCloseModalCustom
        closeModal={() => setAddRegionModal(false)}
        title="Add region"
        footer={
          <CustomModalFooter
            multibutton={false}
            btnAlignment="justify-end"
            btnText="Save"
            btnType="primary"
            onSubmit={handleSubmit}
          />
        }
      >
        <Region form={form} />
      </CustomModal>
    </>
  )
}

export default AddRegion
