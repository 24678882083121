import React from 'react'
import { Button } from 'antd'
import AppLayout from 'components/layout/layout'
import { capitalizeFirstLetter } from 'helper/utility'
import { useParams } from 'react-router-dom'
import SkillsTable from './Tables/SkillsTable'
import { skillCreateStore } from 'state/listing/skillListing'
import { infoMessage } from 'api/global'
import SkillModal from './Modals/SkillModal'
import GradesTable from './Tables/GradesTable'
import { gradeStore } from 'state/listing/gradeListing'
import GradeModal from './Modals/GradeModal'
import RelationsTable from './Tables/RelationsTable'
import { relationStore } from 'state/listing/relationListing'
import RelationModal from './Modals/RelationModal'
import EventCategoriesTable from './Tables/EventCategoriesTable'
import { categoryStore } from 'state/listing/eventCategoryListing'
import EventCategoryModal from './Modals/EventCategoryModal'
import ColorTable from './Tables/ColorTable'
import { colorStore } from 'state/listing/colorListing'
import ColorModal from './Modals/ColorModal'
import { activityCategoryStore } from 'state/listing/activityCategoryListing'
import ActivityCategoriesTable from './Tables/ActivityCategoriesTable'
import ActivityCategoryModal from './Modals/ActivityCategoryModal'
import GoalTable from './Tables/GoalTable'
import { goalStore } from 'state/listing/GoalListing'
import GoalModal from './Modals/GoalModal'
import HighlightedElementsTable from './Tables/HighlightedElements'
import { highlightedElementStore } from 'state/listing/highlightedElementListing'
import HighlightedElemModal from './Modals/HighlightedElemModal'
import AgegroupTable from './Tables/AgegroupTable'
import { ageGroupStore } from 'state/listing/ageGroupListing'
import AgeGroupModal from './Modals/AgeGroupModal'

const SpecificListingPage: React.FC = () => {
  const { id }: { id: string } = useParams()
  const { addSkillModal, setAddSkillModal, setSkillData } = skillCreateStore(
    store => ({
      addSkillModal: store.addSkillModal,
      setAddSkillModal: store.setAddSkillModal,
      setSkillData: store.setSkillData,
    }),
  )
  const { addGradeModal, setAddGradeModal, setGradeData } = gradeStore(
    store => ({
      addGradeModal: store.addGradeModal,
      setAddGradeModal: store.setAddGradeModal,
      setGradeData: store.setGradeData,
    }),
  )
  const { addRelationModal, setAddRelationModal, setRelationData } =
    relationStore(store => ({
      addRelationModal: store.addRelationModal,
      setAddRelationModal: store.setAddRelationModal,
      setRelationData: store.setRelationData,
    }))
  const {
    addEventCategoryModal,
    setAddEventCategoryModal,
    setEventCategoryData,
  } = categoryStore(store => ({
    addEventCategoryModal: store.addEventCategoryModal,
    setAddEventCategoryModal: store.setAddEventCategoryModal,
    setEventCategoryData: store.setEventCategoryData,
  }))
  const { addColorModal, setAddColorModal, setColorListingData } = colorStore(
    store => ({
      addColorModal: store.addColorModal,
      setAddColorModal: store.setAddColorModal,
      setColorListingData: store.setColorListingData,
    }),
  )
  const {
    addActivityCategoryModal,
    setAddActivityCategoryModal,
    setActivityCategoryListingData,
  } = activityCategoryStore(store => ({
    addActivityCategoryModal: store.addActivityCategoryModal,
    setAddActivityCategoryModal: store.setAddActivityCategoryModal,
    setActivityCategoryListingData: store.setActivityCategoryListingData,
  }))
  const { addGoalModal, setAddGoalModal, setGoalData } = goalStore(store => ({
    addGoalModal: store.addGoalModal,
    setAddGoalModal: store.setAddGoalModal,
    setGoalData: store.setGoalData,
  }))
  const {
    addHighlightedElementModal,
    setAddHighlightedElementModal,
    setHighlightedElementData,
  } = highlightedElementStore(store => ({
    addHighlightedElementModal: store.addHighlightedElementModal,
    setAddHighlightedElementModal: store.setAddHighlightedElementModal,
    setHighlightedElementData: store.setHighlightedElementData,
  }))
  const { addAgeGroupModal, setAddAgeGroupModal, setAgeGroupData } =
    ageGroupStore(store => ({
      addAgeGroupModal: store.addAgeGroupModal,
      setAddAgeGroupModal: store.setAddAgeGroupModal,
      setAgeGroupData: store.setAgeGroupData,
    }))

  const handleNewClick = () => {
    switch (id) {
      case 'skill':
        setSkillData(null)
        setAddSkillModal(true)
        break
      case 'grade':
        setGradeData(null)
        setAddGradeModal(true)
        break
      case 'relation':
        setRelationData(null)
        setAddRelationModal(true)
        break
      case 'event-category':
        setEventCategoryData(null)
        setAddEventCategoryModal(true)
        break
      case 'activity-category':
        setActivityCategoryListingData(null)
        setAddActivityCategoryModal(true)
        break
      case 'colors':
        setColorListingData(null)
        setAddColorModal(true)
        break
      case 'goal':
        setGoalData(null)
        setAddGoalModal(true)
        break
      case 'highlighted-element':
        setHighlightedElementData(null)
        setAddHighlightedElementModal(true)
        break
      case 'age-group':
        setAgeGroupData(null)
        setAddAgeGroupModal(true)
        break
      default:
        infoMessage('Selected modal not found')
        break
    }
  }

  const handleComponent = () => {
    switch (id) {
      case 'skill':
        return <SkillsTable id={id} />
      case 'grade':
        return <GradesTable id={id} />
      case 'relation':
        return <RelationsTable id={id} />
      case 'event-category':
        return <EventCategoriesTable id={id} />
      case 'activity-category':
        return <ActivityCategoriesTable id={id} />
      case 'colors':
        return <ColorTable id={id} />
      case 'goal':
        return <GoalTable id={id} />
      case 'highlighted-element':
        return <HighlightedElementsTable id={id} />
      case 'age-group':
        return <AgegroupTable id={id} />
    }
  }

  return (
    <AppLayout id={id}>
      <div className="container px-40px py-40px scroller overflow-auto-y">
        <div className="flex align-center justify-between">
          <h1 className="text-24 w-600">{capitalizeFirstLetter(id)}</h1>
          <div>
            <Button className={'btn-new primary br-8'} onClick={handleNewClick}>
              Add new
            </Button>
          </div>
        </div>

        <div className="mt-20px">{handleComponent()}</div>
      </div>

      {addSkillModal && <SkillModal />}
      {addGradeModal && <GradeModal />}
      {addRelationModal && <RelationModal />}
      {addEventCategoryModal && <EventCategoryModal />}
      {addColorModal && <ColorModal />}
      {addActivityCategoryModal && <ActivityCategoryModal />}
      {addGoalModal && <GoalModal />}
      {addHighlightedElementModal && <HighlightedElemModal />}
      {addAgeGroupModal && <AgeGroupModal />}
    </AppLayout>
  )
}

export default SpecificListingPage
