import React from 'react'
import {
  CurrencyDollarSimple,
  PencilSimple,
  TextT,
  Trash,
} from '@phosphor-icons/react'
import { capitalizeFirstLetter } from 'helper/utility'
import { DivisonListingResp } from 'types/league/division'
import maleIcon from 'assets/Images/maleIcon.svg'
import femaleIcon from 'assets/Images/femaleIcon.svg'

interface DivisionProps {
  item: DivisonListingResp
  hanldeEditDivision: (division: DivisonListingResp) => void
  handleDeleteDivsion: (division: DivisonListingResp) => void
  handleAddDivisionName: (id: string) => void
  handleAddDivisionPrice: (id: string) => void
}

const Division: React.FC<DivisionProps> = ({
  item,
  hanldeEditDivision,
  handleDeleteDivsion,
  handleAddDivisionName,
  handleAddDivisionPrice,
}) => {
  return (
    <div className="flex align-center flex-col justify-between gap-20px px-10px py-10px radius-16 shadow mt-20px">
      <div className="flex align-center gap-20px justify-between w-full">
        <div className="flex align-center gap-10px">
          <div className="border-1px radius-12 py-20px px-20px">
            <img
              src={
                item.gender === 'male'
                  ? maleIcon
                  : item.gender === 'female'
                  ? femaleIcon
                  : undefined
              }
              alt="gender-icon"
              className="w-40px h-40px"
            />
          </div>
          <div>
            <h3>{capitalizeFirstLetter(item.gender)}</h3>

            <div className="flex gap-10px flex-wrap">
              <div className="flex align-center justify-center gap-10px bg-grey py-5px px-5px radius-16 w-fit">
                <p>{item.ageGroup.name}</p>
              </div>
              <div className="flex align-center justify-center gap-10px bg-grey py-5px px-5px radius-16 w-fit">
                <p>{item.skillLevel.name}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex align-center justify-center gap-16px">
          <PencilSimple
            className="pointer"
            size={24}
            color="#000"
            onClick={() => hanldeEditDivision(item)}
          />
          <Trash
            className="pointer"
            size={24}
            color="#ff6961"
            onClick={() => handleDeleteDivsion(item)}
          />
        </div>
      </div>

      <div className="mt-5px border-top w-full pt-15px">
        <div
          className="flex align-center gap-10px pointer"
          onClick={() => handleAddDivisionName(item._id)}
        >
          <TextT size={28} color="#2ba7ca" />
          <h3 className="text-prim text-15 w-600">Add division name</h3>
        </div>
        <div
          className="flex align-center gap-10px pointer mt-10px"
          onClick={() => handleAddDivisionPrice(item._id)}
        >
          <CurrencyDollarSimple size={28} color="#2ba7ca" />
          <h3 className="text-prim text-15 w-600">Add price</h3>
        </div>
      </div>
    </div>
  )
}

export default Division
