import React, { useState } from 'react'
import { Avatar, Dropdown } from 'antd'
import { getNameSignature } from 'helper/utility'
import { DownOutlined } from '@ant-design/icons'
import { AccessLevelTypes } from 'types/organization'
import { useAssignToOrganization } from 'api/Organizers'
import { organizerStore } from 'state/organizer/organizer'
import ButtonSpinner from 'components/global/ButtonSpinner'
import { OrganizationResp } from 'types/globalTypes'

interface OrgRoleProps {
  accessLevel: AccessLevelTypes
  organization: any
  setRole: React.Dispatch<React.SetStateAction<AccessLevelTypes>>
  isUpdate?: boolean
}

const OrgRole: React.FC<OrgRoleProps> = ({
  accessLevel,
  organization,
  setRole,
  isUpdate,
}) => {
  const { mutate: assignToOrganizationMutation } = useAssignToOrganization()

  const { organizerId, assignedOrganizations, setAssignedOrganizations } =
    organizerStore(store => ({
      organizerId: store.organizerId,
      assignedOrganizations: store.assignedOrganizations,
      setAssignedOrganizations: store.setAssignedOrganizations,
    }))
  const [loading, setLoading] = useState<string>('')

  const hanldeMenuClick = (
    role: AccessLevelTypes,
    organization: OrganizationResp,
  ) => {
    if (!isUpdate) {
      setRole(role)
    } else {
      setLoading(organization._id)
      const payload = {
        organizations: [
          {
            organizationId: organization._id,
            designation: role,
            accessLevel: role,
          },
        ],
      }
      assignToOrganizationMutation(
        {
          organizerId,
          payload,
        },
        {
          onSuccess: assignedData => {
            setLoading('')
            const updatedAssignedOrgs = assignedOrganizations.map(org => {
              const matchedOrg = assignedData.find(
                assignedOrg => assignedOrg._id === org._id,
              )
              return matchedOrg ? matchedOrg : org
            })
            setAssignedOrganizations(updatedAssignedOrgs)
          },
          onError: () => {
            setLoading('')
          },
        },
      )
    }
  }

  const rolesItems = [
    {
      key: 'full-admin',
      label: (
        <div
          className="py-10px px-15px pointer border-btm"
          onClick={() => hanldeMenuClick('owner', organization)}
        >
          <h3 className="text-14px w-500">Full admin</h3>
        </div>
      ),
    },
    {
      key: 'organzier',
      label: (
        <div
          className="py-10px px-15px pointer border-btm"
          onClick={() => hanldeMenuClick('organizer', organization)}
        >
          <h3 className="text-14px w-500">Organizer</h3>
        </div>
      ),
    },
    {
      key: 'Staff-member',
      label: (
        <div
          className="py-10px px-15px pointer border-btm"
          onClick={() => hanldeMenuClick('staff-member', organization)}
        >
          <h3 className="text-14px w-500">Staff member</h3>
          <p className="text-gray mt-5px">
            Cannot edit organization profile or create events
          </p>
        </div>
      ),
    },
    {
      key: 'view-only',
      label: (
        <div
          className="py-10px px-15px pointer border-btm"
          onClick={() => hanldeMenuClick('view-only', organization)}
        >
          <h3 className="text-14px w-500">View only</h3>
          <p className="text-gray mt-5px">
            Cannot edit organization profile or create or edit events
          </p>
        </div>
      ),
    },
    {
      key: 'event-manager',
      label: (
        <div
          className="py-10px px-15px pointer border-btm"
          onClick={() => hanldeMenuClick('event-manager', organization)}
        >
          <h3 className="text-14px w-500">Event Manager</h3>
          <p className="text-gray mt-5px">
            Only some specific events that are assigned to them can be viewed
          </p>
        </div>
      ),
    },
  ]

  return (
    <>
      <div className="flex align-center gap-16px">
        <Avatar src={organization.photo?.post}>
          {getNameSignature(organization.name)}
        </Avatar>
        <p>{organization.name}</p>
      </div>
      <Dropdown
        menu={{
          items: rolesItems,
        }}
        placement="bottomRight"
        trigger={['click']}
        overlayStyle={{
          minWidth: '300px',
          maxWidth: '380px',
          maxHeight: '200px',
          overflowY: 'auto',
          background: '#fff',
          borderRadius: '8px',
          boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        }}
      >
        <div className="flex align-center gap-20px border-btn py-15px pointer">
          <div
            className="flex align-center justify-center gap-10px border-1px radius-12 h-40px px-15px"
            style={{ minWidth: '150px' }}
          >
            {loading === organization._id ? (
              <ButtonSpinner size={20} color="#000" />
            ) : (
              <>
                <p>{accessLevel}</p> <DownOutlined />
              </>
            )}
          </div>
        </div>
      </Dropdown>
    </>
  )
}

export default OrgRole
