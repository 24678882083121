import { useMutation, useQuery } from '@tanstack/react-query'
import { axiosGet, axiosPatch, axiosPost } from 'Redux/axiosHelper'
import {
  ExternalStep1Payload,
  ExternalStep2Payload,
} from 'types/external/externalEvent'

// get external event
const getExternalEvent = async (externalId: string) => {
  const response = await axiosGet(`v1/external/details/${externalId}`)
  return response.data
}
export const useGetExternalEvent = (id: string) => {
  return useQuery(['externalEvent'], () => getExternalEvent(id), {
    enabled: false,
  })
}

// create external step 1
const createExternal = async (payload: ExternalStep1Payload) => {
  const response = await axiosPost(payload, `v1/external`)
  return response.data
}

export const useCreateExternal = () => {
  return useMutation(createExternal)
}

// update external setp 1
const updateExternal = async ({
  payload,
  externalId,
}: {
  payload: ExternalStep1Payload
  externalId: string
}) => {
  const response = await axiosPatch(payload, `v1/external/${externalId}`)
  return response.data
}
export const useUpdateExternal = () => {
  return useMutation(updateExternal)
}

// create or update external step 2
const createExternalStep2 = async ({
  payload,
  externalId,
}: {
  payload: ExternalStep2Payload
  externalId: string
}) => {
  const response = await axiosPatch(payload, `v1/external/${externalId}`)
  return response.data
}

export const useCreateExternalStep2 = () => {
  return useMutation(createExternalStep2)
}

// change status of external
const changeExternalStatus = async ({
  payload,
  externalId,
}: {
  payload: any
  externalId: string
}) => {
  const response = await axiosPatch(payload, `v1/external/status/${externalId}`)
  return response.data
}

export const useChangeExternalStatus = () => {
  return useMutation(changeExternalStatus)
}
