import React, { useEffect, useState } from 'react'
import { Col, Form, FormInstance, Input, Row } from 'antd'
import { Minus, Plus } from '@phosphor-icons/react'
import { WeekDays } from 'types/globalTypes'
import WaitTime from './WaitTime'
import BackToBack from './BackToBack'
import { LeagueStep7Payload } from 'types/league/league'
import { convertGivenDayTypeToOther } from 'helper/utility'
import { useLeagueStep7 } from 'api/LeagueCreation/leagueCreation'
import { leagueEventStore } from 'state/event/leagueEvent'
import { myStore } from 'state/store'

interface AddGameConstraintFormProps {
  form: FormInstance
}

const AddGameConstraintForm: React.FC<AddGameConstraintFormProps> = ({
  form,
}) => {
  const { leagueData, setLeagueData, setGameConstraintModal } =
    leagueEventStore(store => ({
      leagueData: store.leagueData,
      setLeagueData: store.setLeagueData,
      setGameConstraintModal: store.setgameConstraintModal,
    }))
  const setModalBtnLoader = myStore(store => store.setModalBtnLoader)
  const [showAdvancedOptions, setShowAdvancedOptions] = useState<boolean>(false)
  const [selectedDays] = useState<WeekDays[]>([])

  const {
    mutate: gameConstraintMutate,
    isLoading: gameConstraintMutationLoading,
  } = useLeagueStep7()

  const formSchema = {
    gamesPerDay: {
      maxGamesPerDay: leagueData.gameConstraints?.gamesPerDay
        ? leagueData.gameConstraints.gamesPerDay.maxGamesPerDay
        : 0,
    },
    waitTime: {
      max: {
        days: leagueData.gameConstraints?.waitTime?.max
          ? leagueData.gameConstraints.waitTime.max.days
          : undefined,
        hours: leagueData.gameConstraints?.waitTime?.max
          ? leagueData.gameConstraints.waitTime.max.hours
          : undefined,
        mins: leagueData.gameConstraints?.waitTime?.max
          ? leagueData.gameConstraints.waitTime.max.mins
          : undefined,
      },
      min: {
        days: leagueData.gameConstraints?.waitTime?.min
          ? leagueData.gameConstraints.waitTime.min.days
          : undefined,
        hours: leagueData.gameConstraints?.waitTime?.min
          ? leagueData.gameConstraints.waitTime.min.hours
          : undefined,
        mins: leagueData.gameConstraints?.waitTime?.min
          ? leagueData.gameConstraints.waitTime.min.mins
          : undefined,
      },
      skipForPlayOff:
        leagueData.gameConstraints?.waitTime?.skipForPlayOff || undefined,
    },
    maxBackToBackGames: {
      noOfGames: leagueData.gameConstraints?.maxBackToBackGames
        ? leagueData.gameConstraints.maxBackToBackGames.noOfGames
        : undefined,
      skipForPlayOff: leagueData.gameConstraints?.maxBackToBackGames
        ? leagueData.gameConstraints.maxBackToBackGames.skipForPlayOff
        : undefined,
    },
  }
  const onFinish = (values: LeagueStep7Payload) => {
    const payload: LeagueStep7Payload = {
      ...values,
      gamesPerDay: {
        ...values.gamesPerDay,
        days: convertGivenDayTypeToOther(selectedDays, 'tiny'),
      },
    }

    gameConstraintMutate(
      { leagueId: leagueData._id, payload },
      {
        onSuccess: data => {
          setLeagueData({
            ...leagueData,
            gameConstraints: data.gameConstraints,
          })
          setGameConstraintModal(false)
        },
      },
    )
  }

  useEffect(() => {
    setModalBtnLoader(gameConstraintMutationLoading)
  }, [gameConstraintMutationLoading])

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={formSchema}
      onFinish={onFinish}
    >
      <Row className="mt-10px">
        <Col span={24}>
          <label className="input-label text-16">Games per day</label>
          <Form.Item
            name={['gamesPerDay', 'maxGamesPerDay']}
            rules={[
              { required: true, message: 'MaxGames per days is required' },
            ]}
            className="mt-10px"
            style={{ width: '130px' }}
          >
            <Input
              type="number"
              placeholder="0"
              className="text-end radius-12px"
              onWheel={e => e.currentTarget.blur()}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col>
          <div
            className="flex align-center gap-10px pointer"
            onClick={() => setShowAdvancedOptions(prev => !prev)}
          >
            {!showAdvancedOptions ? (
              <Plus size={20} color="#2ba7ca" />
            ) : (
              <Minus size={20} color="#2ba7ca" className={`animate-rotate`} />
            )}
            <span className="text-15 w-500 prim-txt">Advanced options</span>
          </div>
        </Col>
      </Row>

      {showAdvancedOptions &&
        // <AdvancedOptions
        //   form={form}
        //   data={undefined}
        //   selectedDays={selectedDays}
        //   setSelectedDays={setSelectedDays}
        // />
        null}

      <WaitTime form={form} data={undefined} />
      <BackToBack form={form} data={undefined} />
    </Form>
  )
}

export default AddGameConstraintForm
