import { Avatar, FormInstance, Select } from 'antd'
import { useGetAllTeamsOfOneOrganization } from 'api/Team/Team'
import Spinner from 'components/Spinner'
import { getNameSignature } from 'helper/utility'
import React, { useEffect } from 'react'
import { TeamType } from 'types/team/team'

interface TeamInputProps {
  organizationId: string
  form: FormInstance
  data: string
}

const TeamInput: React.FC<TeamInputProps> = ({
  organizationId,
  form,
  data,
}) => {
  const {
    data: orgTeamsData,
    isLoading: orgTeamsLoading,
    isFetching: orgTeamsFetching,
    refetch: refetchOrgTeams,
  } = useGetAllTeamsOfOneOrganization(organizationId)

  useEffect(() => {
    refetchOrgTeams()
  }, [])

  return (
    <div>
      <Select
        className="h-50px select-field"
        placeholder="Select team"
        defaultValue={data}
        onChange={(_, item: { key: string; value: string }) => {
          form.setFieldsValue({
            teamId: item.value,
          })
        }}
      >
        {orgTeamsLoading || orgTeamsFetching ? (
          <div className="py-20px flex justify-center">
            <Spinner />
          </div>
        ) : (
          orgTeamsData.map((team: TeamType) => (
            <Select.Option key={team._id} value={team._id}>
              <div className="flex align-center gap-10px">
                <Avatar size={24} src={team.profilePhoto?.post}>
                  {getNameSignature(team.name)}
                </Avatar>
                <p>{team.name}</p>
              </div>
            </Select.Option>
          ))
        )}
      </Select>
    </div>
  )
}

export default TeamInput
