import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { axiosGet, axiosPatch, axiosPost } from 'Redux/axiosHelper'
import { BasicListingType } from 'types/listing/listings'

const getAgeGroup = async () => {
  const response = await axiosGet(`v1/ageGroup/`)
  return response.data
}

export const useGetAgeGroup = () => {
  const ageGroup = useQuery<BasicListingType[]>(
    ['age-groups-listings'],
    getAgeGroup,
    { enabled: false },
  )
  return ageGroup
}

// create new age group
const createAgeGroup = async (payload: { name: string }) => {
  const response = await axiosPost(payload, `v1/ageGroup/`)
  return response.data
}

export const useCreateAgeGroup = () => {
  const queryClient = useQueryClient()
  return useMutation(createAgeGroup, {
    onSuccess: (newAgeGroup: BasicListingType) => {
      queryClient.setQueryData(
        ['age-groups-listings'],
        (oldData: BasicListingType[] | undefined) => {
          if (!oldData) return [newAgeGroup]
          return [...oldData, newAgeGroup]
        },
      )
    },
  })
}

// update age group
const updateAgeGroup = async ({
  ageGroupId,
  payload,
}: {
  ageGroupId: string
  payload: { name: string }
}) => {
  const response = await axiosPatch(payload, `v1/ageGroup/${ageGroupId}`)
  return response.data
}

export const useUpdateAgeGroup = () => {
  const queryClient = useQueryClient()
  return useMutation(updateAgeGroup, {
    onSuccess: updateAgeGroup => {
      queryClient.setQueryData(
        ['age-groups-listings'],
        (oldData: BasicListingType[] | undefined) => {
          if (!oldData) return [updateAgeGroup]
          return oldData.map(ageGroup =>
            ageGroup._id === updateAgeGroup._id ? updateAgeGroup : ageGroup,
          )
        },
      )
    },
  })
}
