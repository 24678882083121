import { Dropdown } from 'antd'
import React, { useEffect, useRef } from 'react'

const CustomSelectMenu: React.FC<any> = ({
  open,
  setOpen,
  menuItems,
  width,
  children,
}) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const handleDocumentClick = (e: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setOpen(false)
    }
  }
  const handleDropdownClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])
  return (
    <div
      className="flex align-center flex-wrap w-full"
      ref={dropdownRef}
      onClick={handleDropdownClick}
    >
      <Dropdown
        menu={{
          items: menuItems,
        }}
        placement="bottomLeft"
        arrow={false}
        open={open}
        overlayStyle={{ width: `${width ? width : '400px'}` }}
      >
        {children}
      </Dropdown>
    </div>
  )
}

export default CustomSelectMenu
