import React, { useEffect, useState } from 'react'
import { Col, Form, FormInstance, Input, Row } from 'antd'
import UploadDocument from 'components/global/UploadDocument'
import { Photo } from 'types/globalTypes'
import { myStore } from 'state/store'
import { activityCategoryStore } from 'state/listing/activityCategoryListing'
import {
  useCreateActivityCategory,
  useUpdateActivityCategory,
} from 'api/Listings/ActivityCategories'

interface ActivityCategoryFormProps {
  form: FormInstance
}

const ActivityCategoryForm: React.FC<ActivityCategoryFormProps> = ({
  form,
}) => {
  const { setAddActivityCategoryModal, categoryData } = activityCategoryStore(
    store => ({
      categoryData: store.activityCategoryListingData,
      setAddActivityCategoryModal: store.setAddActivityCategoryModal,
    }),
  )

  const [documentIds, setDocumentIds] = useState<string[]>(
    categoryData ? [categoryData.iconId] : [],
  )
  const [photos] = useState<Photo[]>(categoryData ? [categoryData.icon] : [])
  const [error, setError] = useState<string>('')
  const setModalBtnLoading = myStore(store => store.setModalBtnLoader)

  const { mutate: createActivityCategory } = useCreateActivityCategory()
  const { mutate: updateEventCategory } = useUpdateActivityCategory()

  const formSchema = {
    name: categoryData ? categoryData.name : undefined,
  }

  const onFinish = (values: any) => {
    if (documentIds.length === 0) {
      setError('Icon is required')
      return
    }
    const payload = {
      ...values,
      iconId: documentIds[0],
    }
    setModalBtnLoading(true)
    if (categoryData) {
      updateEventCategory(
        { activityCategoryId: categoryData._id, payload },
        {
          onSuccess: () => {
            setModalBtnLoading(false)
            setAddActivityCategoryModal(false)
          },
          onError: () => {
            setModalBtnLoading(false)
          },
        },
      )
    } else {
      createActivityCategory(payload, {
        onSuccess: () => {
          setModalBtnLoading(false)
          setAddActivityCategoryModal(false)
        },
        onError: () => {
          setModalBtnLoading(false)
        },
      })
    }
  }

  useEffect(() => {
    if (documentIds.length) {
      setError('')
    }
  }, [documentIds])

  return (
    <div>
      <Form
        name="event-category-form"
        form={form}
        initialValues={formSchema}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
      >
        <Row>
          <Col>
            <label className="custom-field-label required-input">
              Add Icon
            </label>
          </Col>
          <Col span={12}>
            <UploadDocument
              documentIds={documentIds}
              setDocumentIds={setDocumentIds}
              multiple={false}
              docType="image/*"
              files={photos}
            />
          </Col>
          {error && (
            <Col className="-mt-20px" span={24}>
              <p className="ant-form-item-explain-error">{error}</p>
            </Col>
          )}
        </Row>
        <Row className="-mt-10px">
          <Col span={24}>
            <label className="input-label required-input">Name</label>
            <Form.Item
              colon={false}
              name="name"
              rules={[{ required: true, message: 'Name is required' }]}
            >
              <Input placeholder="Enter Name" required name="name" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default ActivityCategoryForm
