import { useQuery } from '@tanstack/react-query'
import { axiosGet } from 'Redux/axiosHelper'
import { BasicListingType } from 'types/listing/listings'

const getLeagueSkills = async () => {
  const response = await axiosGet(`v1/league-skill-level`)
  return response.data
}
export const useGetLeagueSkills = () => {
  const leagueSkills = useQuery<BasicListingType[]>(
    ['league-skills-listing'],
    getLeagueSkills,
    { enabled: false },
  )
  return leagueSkills
}
