import React, { useState } from 'react'
import { PencilLine, Trash } from '@phosphor-icons/react'
import { creationEventStore } from 'state/event/eventCreationStore'
import { AdditionalFeeResp } from 'types/event/eventStep3'
import AddFee from '../modal/AddFee'
import { useDeleteAdditionalFee } from 'api/EventCreation/creationStep3'
import AlertModal from 'helper/AlertModal'

const AdditionalFee = () => {
  const setEventData = creationEventStore(store => store.setEventData)
  const [openFeeModal, setOpenFeeModal] = useState<boolean>(false)
  const [openAlertModal, setOpenAlertModal] = useState<boolean>(false)
  const [feeData, setFeeData] = useState<AdditionalFeeResp | undefined>()
  const eventData = creationEventStore(store => store.eventData)

  const alertModalData = {
    name: 'Additional fee',
    heading: 'Delete this fee',
    message1: `Are you sure you want to delete this fee?`,
  }
  const { mutate: deleteAdditionalFee, isLoading: deleteAdditionalFeeLoading } =
    useDeleteAdditionalFee()

  const handleEdit = (fee: AdditionalFeeResp) => {
    setFeeData(fee)
    setOpenFeeModal(true)
  }

  const handleDelete = (fee: AdditionalFeeResp) => {
    setFeeData(fee)
    setOpenAlertModal(true)
  }

  const onYesClick = () => {
    deleteAdditionalFee(
      { eventId: eventData?._id, feeId: feeData._id },
      {
        onSuccess: data => {
          setEventData(data)
          setOpenAlertModal(false)
        },
      },
    )
  }

  return (
    eventData?.additionalFee.length > 0 && (
      <>
        <div className="mt-10px">
          {eventData?.additionalFee.map((fee: AdditionalFeeResp) => {
            return (
              <div className="flex justify-between align-center mt-10px gap-16px">
                <div className="flex align-center gap-16px">
                  <h3 className="text-14 w-600 text-darkBlue">{fee.name}</h3>
                  <PencilLine
                    size={20}
                    color="#000"
                    className="pointer"
                    onClick={() => handleEdit(fee)}
                  />
                  <Trash
                    size={20}
                    color={'#FF6961'}
                    onClick={() => handleDelete(fee)}
                    className="pointer"
                  />
                </div>
                <h3 className="text-14 w-600 text-darkBlue">
                  {`${
                    fee?.price
                      ? `$${fee.price.toFixed(2)}`
                      : `$${fee?.priceRange?.min.toFixed(
                          2,
                        )} ~ ${fee?.priceRange?.max.toFixed(2)}`
                  }`}
                </h3>
              </div>
            )
          })}
        </div>

        {openFeeModal && (
          <AddFee
            modalOpen={openFeeModal}
            modalClose={setOpenFeeModal}
            data={feeData}
          />
        )}

        {openAlertModal && (
          <AlertModal
            isModalVisible={openAlertModal}
            setIsModalVisible={setOpenAlertModal}
            data={alertModalData}
            onYesClick={onYesClick}
            loading={deleteAdditionalFeeLoading}
          />
        )}
      </>
    )
  )
}

export default AdditionalFee
