import React, { useEffect, useState } from 'react'
import { EventResp } from 'types/event/events'
import EstimatedActivitySummary from './EstimatedActivitySummary'
import { PriceRange } from 'types/event/eventStep3'
import { creationEventStore } from 'state/event/eventCreationStore'
import AdditionalFee from './AdditionalFee'
interface EstimatedSummaryProps {
  data: EventResp
  numberOfStaff: number
  estimatedSalary: PriceRange
}

const EstimatedSummary: React.FC<EstimatedSummaryProps> = ({
  data,
  numberOfStaff,
  estimatedSalary,
}) => {
  const [estimatedVenuePrice, setEstimatedVenuePrice] = useState<
    number | PriceRange
  >(0)
  const step3Payload = creationEventStore(store => store.step3Payload)
  const setStep3Payload = creationEventStore(store => store.setStep3Payload)

  useEffect(() => {
    if (data?.venue?.oneTimeCostEnabled) {
      if (data?.venue?.price) {
        setEstimatedVenuePrice(data.venue?.price)
        setStep3Payload({ venuePrice: data.venue?.price })
      } else {
        setEstimatedVenuePrice({
          min: data.venue?.priceRange.min,
          max: data.venue?.priceRange.max,
        })
        setStep3Payload({
          venuePrice: {
            min: data.venue?.priceRange.min,
            max: data.venue?.priceRange.max,
          },
        })
      }
    } else if (data?.venue?.pricePerPersonEnabled) {
      if (data?.venue?.price) {
        setEstimatedVenuePrice({
          min: data.venue?.price * (data.minGuestsRequired || 1),
          max: data.venue?.price * (data.maxGuestsAllowed || 1),
        })
        setStep3Payload({
          venuePrice: {
            min: data.venue?.price * (data.minGuestsRequired || 1),
            max: data.venue?.price * (data.maxGuestsAllowed || 1),
          },
        })
      } else {
        setEstimatedVenuePrice({
          min: data.venue?.priceRange.min * (data.minGuestsRequired || 1),
          max: data.venue?.priceRange.max * (data.maxGuestsAllowed || 1),
        })
        setStep3Payload({
          venuePrice: {
            min: data.venue?.priceRange.min * (data.minGuestsRequired || 1),
            max: data.venue?.priceRange.max * (data.maxGuestsAllowed || 1),
          },
        })
      }
    } else {
      setEstimatedVenuePrice(0)
      setStep3Payload({ venuePrice: 0 })
    }
  }, [data])

  return (
    <>
      <h3 className="text-13 w-600">Estimated Summary</h3>
      <div className="mt-20px">
        <div className=" flex align-center justify-between gap-20px mt-10px">
          <p>Staff (x{numberOfStaff})</p>
          <p>{`$${(estimatedSalary.min * numberOfStaff).toFixed(2)} ~ $${(
            estimatedSalary.max * numberOfStaff
          ).toFixed(2)}`}</p>
        </div>

        <div className=" flex align-center justify-between gap-20px mt-10px">
          <p>Venue</p>

          {typeof estimatedVenuePrice === 'number'
            ? `$${estimatedVenuePrice.toFixed(2)}`
            : `$${estimatedVenuePrice.min.toFixed(
                2,
              )} ~ $${estimatedVenuePrice.max.toFixed(2)}`}
        </div>
        <EstimatedActivitySummary data={data} />
      </div>

      <div className="mt-25px">
        <AdditionalFee />
      </div>

      <div className="flex align-center justify-between mt-25px">
        <h3 className="text-15 w-600">Estimated Total</h3>
        {/* <h3 className="text-15 w-600">$730.00 - $840.00</h3> */}
        <h3 className="text-15 w-600">{`$${step3Payload.estimatedTotalPrice.min.toFixed(
          2,
        )} - $${step3Payload.estimatedTotalPrice.max.toFixed(2)}`}</h3>
      </div>
    </>
  )
}

export default EstimatedSummary
