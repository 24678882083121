import IconsTextNavigation from 'components/global/IconsTextNavigation'
import React from 'react'

const Security: React.FC = () => {
  return (
    <>
      <div>
        <IconsTextNavigation iconName="ShieldCheck" heading="Security" />
      </div>
    </>
  )
}

export default Security
