import CustomModal from 'components/global/CustomModal'
import CustomModalFooter from 'components/global/CustomModalFooter'
import useFormSubmit from 'helper/Hooks/formSubmit'
import React from 'react'
import { colorStore } from 'state/listing/colorListing'
import ColorForm from './ModalContent/ColorForm'

const ColorModal: React.FC = () => {
  const { form, handleSubmit } = useFormSubmit()
  const { addColorModal, setAddColorModal, colorData } = colorStore(store => ({
    addColorModal: store.addColorModal,
    setAddColorModal: store.setAddColorModal,
    colorData: store.colorListingData,
  }))
  return (
    <>
      <CustomModal
        openModal={addColorModal}
        closeModal={setAddColorModal}
        title={colorData ? 'Edit color' : 'Add new color'}
        footer={
          <CustomModalFooter
            multibutton={false}
            btnAlignment="justify-end"
            btnText={colorData ? 'Update' : 'Create'}
            btnType="primary"
            onSubmit={handleSubmit}
          />
        }
      >
        <ColorForm form={form} />
      </CustomModal>
    </>
  )
}

export default ColorModal
