import React, { useEffect, useState } from 'react'
import { Checkbox, Col, Form, FormInstance, Row } from 'antd'
import { Address, LowerCaseWeekDays, WeekDays } from 'types/globalTypes'
import { leagueEventStore } from 'state/event/leagueEvent'
import {
  convertGivenDayTypeToOther,
  convertToLocal,
  convertToUtc,
  getDayNamesBetweenDates,
} from 'helper/utility'
import { CaretRight } from '@phosphor-icons/react'
import SelectDateModal from '../SelectDateModal'
import TeamInput from './TeamInput'
import VenueInput from './VenueInput'
import { CreateTeamConstraintPayload } from 'types/league/teamConstraints'
import {
  useCreateTeamLeagueConstraint,
  useUpdateTeamLeagueConstraint,
} from 'api/LeagueCreation/leagueTeamConstraints'
import { myStore } from 'state/store'
import moment from 'moment'
import { infoMessage } from 'api/global'

interface AddTeamConstraintFormProps {
  form: FormInstance
}

const AddTeamConstraintForm: React.FC<AddTeamConstraintFormProps> = ({
  form,
}) => {
  const {
    leagueData,
    setAddTeamConstraintModal,
    teamConstraintData,
    setTeamConstraintData,
  } = leagueEventStore(store => ({
    leagueData: store.leagueData,
    setAddTeamConstraintModal: store.setAddTeamConstraintModal,
    teamConstraintData: store.teamConstraintData,
    setTeamConstraintData: store.setTeamConstraintData,
  }))

  const setModalBtnLoader = myStore(store => store.setModalBtnLoader)
  const [checkedDays, setCheckedDays] = useState<WeekDays[]>([])
  const [mapData, setMapModalData] = useState<Address | null>(
    teamConstraintData ? teamConstraintData.venue : null,
  )
  const [selectDateModal, setSelectDateModal] = useState<boolean>(false)
  const [selectedDates, setSelectedDates] = useState<string[]>([])

  const {
    mutate: createTeamConstraintMutation,
    isLoading: isCreatingTeamConstraint,
  } = useCreateTeamLeagueConstraint()
  const {
    mutate: updateTeamConstraintMutation,
    isLoading: isUpdatingTeamConstraint,
  } = useUpdateTeamLeagueConstraint()

  const formSchema = {
    teamId: teamConstraintData ? teamConstraintData.teamId : undefined,
  }

  const handleCheckboxChange = (day: WeekDays, isChecked: boolean) => {
    if (isChecked) {
      setCheckedDays(prev => [...prev, day])
    } else {
      setCheckedDays(prev => prev.filter(item => item !== day))
    }
  }

  const onFinish = (values: { teamId: string }) => {
    if (!mapData) return infoMessage('Venue is required!')
    if (!values.teamId) return infoMessage('Team is required!')
    const payload: CreateTeamConstraintPayload = {
      ...values,
      venueId: mapData._id,
      organizationId: leagueData.organizationId,
      dates: selectedDates.map((item: string) =>
        convertToUtc(item, 'YYYY-MM-DDTHH:mm:ss[Z]'),
      ),
      days: convertGivenDayTypeToOther(checkedDays, 'tiny'),
    }

    if (teamConstraintData) {
      updateTeamConstraintMutation(
        {
          constraintId: teamConstraintData._id,
          payload,
        },
        {
          onSuccess: () => {
            setTeamConstraintData(null)
            setAddTeamConstraintModal(false)
            setModalBtnLoader(false)
          },
        },
      )
    } else {
      createTeamConstraintMutation(
        { leagueId: leagueData._id, payload },
        {
          onSuccess: () => {
            setAddTeamConstraintModal(false)
            setModalBtnLoader(false)
          },
        },
      )
    }
  }

  useEffect(() => {
    if (teamConstraintData) {
      setCheckedDays(
        convertGivenDayTypeToOther(teamConstraintData.days, 'full'),
      )
      // setSelectedDates(teamConstraintData.dates)
      const formatedDates = teamConstraintData.dates.map((date: string) => {
        return convertToLocal(date, 'YYYY-MM-DD')
      })
      setSelectedDates(formatedDates)
    }
  }, [])

  useEffect(() => {
    setModalBtnLoader(isCreatingTeamConstraint || isUpdatingTeamConstraint)
  }, [isCreatingTeamConstraint || isUpdatingTeamConstraint])

  return (
    <>
      <div>
        <Form
          form={form}
          layout="vertical"
          initialValues={formSchema}
          onFinish={onFinish}
        >
          <Row className="mt-10px">
            <Col span={24}>
              <label className="input-label">Teams</label>
              <Form.Item name="teamId">
                <TeamInput
                  organizationId={leagueData.organizationId}
                  form={form}
                  data={
                    teamConstraintData && teamConstraintData.teamId
                      ? teamConstraintData.teamId
                      : undefined
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="mt-10px">
            <Col>
              <label className="required-input  input-label">Venue</label>
            </Col>
            <Col>
              <VenueInput
                mapData={mapData}
                setMapData={setMapModalData}
                leagueData={leagueData}
                form={form}
              />
            </Col>
          </Row>
          <Row className="mt-20px">
            <Col>
              <label className="input-label">Select days</label>
            </Col>
            <Col>
              <div>
                {getDayNamesBetweenDates(
                  convertToLocal(leagueData.startDate),
                  convertToLocal(leagueData.endDate),
                ).map((day: WeekDays, index: number) => {
                  return (
                    <div className="py-15px border-btm " key={index}>
                      <div className="flex align-center justify-between gap-20px">
                        <div className="flex align-center gap-10px">
                          <Checkbox
                            checked={checkedDays.includes(day)}
                            onChange={e =>
                              handleCheckboxChange(day, e.target.checked)
                            }
                          />
                          <h4 className="text-14 w-500">{day}</h4>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </Col>
          </Row>
          <Row className="mt-20px">
            <Col>
              <label htmlFor="" className="input-label ">
                Select dates
              </label>
              <div
                className="w-full border-1px radius-12 flex align-center px-12px pointer h-50px"
                onClick={() => setSelectDateModal(true)}
              >
                <div className=" w-full flex justify-between align-center">
                  <p className="text-gray">Dates</p>
                  <div className="d-flex align-center gap-10px">
                    {selectedDates?.length > 0 ? (
                      <p className="text-14px w-500">{`${moment(
                        selectedDates[0],
                      ).format('MMM DD')} - ${moment(
                        selectedDates[selectedDates.length - 1],
                      ).format('MMM DD')}, ${moment(selectedDates[0]).format(
                        'yyyy',
                      )}`}</p>
                    ) : (
                      <p className="text-gray">Select</p>
                    )}
                    <CaretRight size={16} color="#000" />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      {selectDateModal && (
        <SelectDateModal
          leagueData={leagueData}
          openModal={selectDateModal}
          closeModal={setSelectDateModal}
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
        />
      )}
    </>
  )
}

export default AddTeamConstraintForm
