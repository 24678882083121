import { FormInstance, Select } from 'antd'
import { useGetAgeGroups } from 'api/Lists'
import Spinner from 'components/Spinner'
import React, { useEffect } from 'react'
import { AgeGroups } from 'types/globalTypes'

interface AgeGroupProps {
  data: any
  form: FormInstance
  multiple: boolean
}

const AgeGroup: React.FC<AgeGroupProps> = ({ data, form, multiple }) => {
  const {
    data: ageGroupsData,
    isLoading: ageGroupsLoading,
    isFetching: ageGroupsFetching,
    refetch: refetchAgeGroups,
  } = useGetAgeGroups()

  useEffect(() => {
    refetchAgeGroups()
  }, [])

  return (
    <div>
      <Select
        placeholder={'Select Age'}
        defaultValue={
          multiple
            ? data?.map((item: { _id: string; name: string }) => item._id)
            : data?._id
        }
        onChange={selectedValues => {
          form.setFieldsValue({
            // ageGroupIds: selectedValues,
            [data ? 'ageGroupId' : 'ageGroupIds']: selectedValues,
          })
        }}
        mode={multiple ? 'multiple' : undefined}
        className="h-50px select-field"
      >
        {ageGroupsLoading || ageGroupsFetching ? (
          <div className="py-20px flex align-center justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            {ageGroupsData.map((age: AgeGroups) => (
              <Select.Option key={age._id} value={age._id}>
                {age?.name}
              </Select.Option>
            ))}
          </>
        )}
      </Select>
    </div>
  )
}

export default AgeGroup
