import { List, PencilLine, Trash } from '@phosphor-icons/react'
import EmptyData from 'components/global/EmptyData'
import React, { useState } from 'react'
import { leagueEventStore } from 'state/event/leagueEvent'

interface SelectedItem {
  index: number
  item: string
}

const ItemsToBringContent = () => {
  const {
    itemsToBring,
    setItemsToBring,
    setSelectedItemToBring,
    setAddItemModal,
    setItemsToBringModal,
  } = leagueEventStore(store => ({
    itemsToBring: store.itemsToBring,
    setItemsToBring: store.setItemsToBring,
    setSelectedItemToBring: store.setSelectedItemToBring,
    setAddItemModal: store.setAddItemModal,
    setItemsToBringModal: store.setItemsToBringModal,
  }))

  const handleRemoveItem = (index: number) => {
    setItemsToBring(itemsToBring.filter((_, i) => index !== i))
  }
  const handleEdit = (index: number, item: string) => {
    setSelectedItemToBring({ index, item })
    setItemsToBringModal(false)
    setAddItemModal(true)
  }

  return (
    <div>
      <p>
        Listed below what is mandatory things should kid bring with themselves
        for the event
      </p>
      {itemsToBring.length > 0 ? (
        <div className="mt-20px">
          {itemsToBring.map((item, index) => {
            return (
              <div
                key={index}
                className="flex align-center justify-between my-20px"
              >
                <div className="flex align-center gap-10px">
                  <List size={20} color="#B3B3BF" />
                  <p>{item}</p>
                </div>
                <div className="flex align-center gap-16px">
                  <PencilLine
                    size={20}
                    color="#000"
                    className="pointer"
                    onClick={() => handleEdit(index, item)}
                  />
                  <Trash
                    size={20}
                    color={'#FF6961'}
                    className="pointer"
                    onClick={() => handleRemoveItem(index)}
                  />
                </div>
              </div>
            )
          })}
        </div>
      ) : (
        <div className="py-20px w-full flex justify-center">
          <EmptyData message="No item is added" />
        </div>
      )}
    </div>
  )
}

export default ItemsToBringContent
