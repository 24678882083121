import { Button } from 'antd'
import React from 'react'
import ScheduleEmail from './Communication/ScheduleEmail'
import Faq from './Communication/Faq'
import Agreements from './Communication/Agreements'
import ItemsToBring from './Communication/ItemsToBring'
import MandatoryInfo from './Communication/MandatoryInfo'
import RequiredDocuments from './Communication/RequiredDocuments'
import MentorRequest from './Communication/MentorRequest'
import Security from './Communication/Security'
import RequiredDetails from './Communication/RequiredDetails'

interface Step5Props {
  handleNext: () => void
  handleBack: () => void
}

const Step6: React.FC<Step5Props> = ({ handleNext, handleBack }) => {
  return (
    <div>
      <h2 className="text-24 w-600">Communication</h2>
      <div
        className="scroller overflow-auto-y"
        style={{ height: `calc(100vh - 360px)` }}
      >
        <div className="mt-20px">
          <ScheduleEmail />
        </div>
        <div className="mt-20px">
          <Faq />
        </div>
        <div className="mt-20px">
          <ItemsToBring />
        </div>
        <div className="mt-20px">
          <Agreements />
        </div>
        <div className="mt-20px">
          <MandatoryInfo />
        </div>
        <div className="mt-20px">
          <RequiredDocuments />
        </div>
        <div className="mt-20px">
          <MentorRequest />
        </div>
        <div className="mt-20px">
          <RequiredDetails />
        </div>
        <div className="mt-20px">
          <Security />
        </div>
      </div>
      <div className="flex align-center justify-between mb-26 mt-40px">
        <Button
          className="cancel-button flex align-center justify-center gap-10px"
          onClick={handleBack}
        >
          Back
        </Button>
        <Button className="stepper-button" onClick={handleNext}>
          Next
        </Button>
      </div>
    </div>
  )
}

export default Step6
