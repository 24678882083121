import CustomModal from 'components/global/CustomModal'
import React from 'react'
import { leagueEventStore } from 'state/event/leagueEvent'
import ItemsToBringContent from './ModalContent/ItemsToBringContent'
import CustomModalFooter from 'components/global/CustomModalFooter'

const ItemsToBringModal: React.FC = () => {
  const {
    itemsToBringModal,
    setItemsToBringModal,
    setAddItemModal,
    setSelectedItemToBring,
  } = leagueEventStore(store => ({
    itemsToBringModal: store.itemsToBringModal,
    setItemsToBringModal: store.setItemsToBringModal,
    setAddItemModal: store.setAddItemModal,
    setSelectedItemToBring: store.setSelectedItemToBring,
  }))

  const handleSubmit = () => {
    setItemsToBringModal(false)
  }
  const handleAddNewItemClick = () => {
    setSelectedItemToBring(undefined)
    setAddItemModal(true)
    setItemsToBringModal(false)
  }

  return (
    <>
      <CustomModal
        openModal={itemsToBringModal}
        closeModal={setItemsToBringModal}
        title="Items to bring"
        footer={
          <CustomModalFooter
            multibutton={true}
            btnAlignment="justify-between"
            btnText="Add new item, Save"
            onSubmit={handleSubmit}
            closeModal={handleAddNewItemClick}
          />
        }
      >
        <ItemsToBringContent />
      </CustomModal>
    </>
  )
}

export default ItemsToBringModal
