import React, { useState } from 'react'
import { Button, Col, DatePicker, Form, Radio, Row } from 'antd'
import UploadDocument from 'components/global/UploadDocument'
import {
  Address,
  CategoryType,
  OrganizationResp,
  Photo,
} from 'types/globalTypes'
import { Link } from 'react-router-dom'
import SportsSelect from './InputComps/SportsSelect'
import Location from './InputComps/Location'
import { LeagueStep1Payload } from 'types/league/league'
import moment from 'moment'
import { startOfLocalDayInUTC } from 'helper/utility'
import {
  useCreateLeague,
  useUpdateLeague,
} from 'api/LeagueCreation/leagueCreation'
import ButtonSpinner from 'components/global/ButtonSpinner'
import { leagueEventStore } from 'state/event/leagueEvent'
import { failureMessage, infoMessage } from 'api/global'
import OrganizationInput from 'components/global/inputs/OrganizationInput/OrganizationInput'

interface LeagueStep1Props {
  handleNext: () => void
  handleBack: () => void
}

const Step1: React.FC<LeagueStep1Props> = ({ handleNext, handleBack }) => {
  const {
    mutate: createLeagueMutation,
    isLoading: createLeagueMutationLoading,
  } = useCreateLeague()
  const { mutate: updateLeagueMutation, isLoading: updateLoading } =
    useUpdateLeague()
  const { leagueData, setLeagueData } = leagueEventStore(store => ({
    leagueData: store.leagueData,
    setLeagueData: store.setLeagueData,
  }))
  const [selectedOrganization, setSelectedOrganization] =
    useState<OrganizationResp>(leagueData ? leagueData.organization : undefined)
  const [mapData, setMapModalData] = useState<Address | null>(null)
  const [documentIds, setDocumentIds] = useState<string[]>(
    leagueData ? leagueData.coverPhotosIds : [],
  )
  const [photos] = useState<Photo[]>(leagueData ? leagueData.coverPhotos : [])
  const [sports, setSports] = useState<CategoryType>(
    leagueData ? leagueData.sport : undefined,
  )
  const [form] = Form.useForm()

  const formSchema = {
    season: leagueData ? leagueData.season : 'summer',
    year: leagueData ? moment(leagueData.year, 'YYYY') : undefined,
    startDate: leagueData ? moment(leagueData.startDate) : undefined,
    endDate: leagueData ? moment(leagueData.endDate) : undefined,
    sportId: leagueData ? leagueData.sportId : undefined,
    organizationId: leagueData ? leagueData.organizationId : undefined,
  }

  const onFinish = (values: LeagueStep1Payload) => {
    if (!selectedOrganization) {
      return failureMessage('Organization is required')
    }
    if (!mapData) {
      return failureMessage('Location is required')
    }
    const payload: LeagueStep1Payload = { ...values }
    payload.year = moment(values.year).format('YYYY')
    payload.startDate = startOfLocalDayInUTC(values.startDate)
    payload.endDate = startOfLocalDayInUTC(values.endDate)
    payload.organizationId = selectedOrganization?._id
    payload.locationId = mapData._id
    payload.coverPhotosIds = documentIds

    if (leagueData) {
      if (!leagueData._id)
        return infoMessage("League doesn't exist! something went wrong")
      updateLeagueMutation(
        { leagueId: leagueData._id, payload },
        {
          onSuccess: data => {
            setLeagueData(data)
            handleNext()
          },
        },
      )
    } else {
      createLeagueMutation(payload, {
        onSuccess: data => {
          setLeagueData(data)
          handleNext()
        },
      })
    }
  }

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        initialValues={formSchema}
        onFinish={onFinish}
      >
        <OrganizationInput
          selectedOrganization={selectedOrganization}
          setSelectedOrganization={setSelectedOrganization}
        />
        <Row gutter={10}>
          <Col className="mt-10">
            <label className="input-label required-input">
              Select a season
            </label>
            <Form.Item
              className="form-control mb-0"
              name="season"
              rules={[{ required: true, message: 'Season is required' }]}
            >
              <Radio.Group defaultValue={'summer'}>
                <Radio value={'summer'} className="mr-40 radio">
                  Summer
                </Radio>
                <Radio value={'fall'} className="mr-40 radio">
                  Fall
                </Radio>
                <Radio value={'winter'} className="mr-40 radio">
                  Winter
                </Radio>
                <Radio value={'spring'} className="mr-40 radio">
                  Spring
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row className="mt-10">
          <Col span={24}>
            <label className="input-label required-input">Year</label>
            <Form.Item
              name="year"
              rules={[{ required: true, message: 'Year is required' }]}
            >
              <DatePicker
                name="year"
                picker="year"
                className="selector w-full"
                disabledDate={current =>
                  current && current.year() < new Date().getFullYear()
                }
              />
            </Form.Item>
          </Col>
        </Row>

        {selectedOrganization && (
          <Row>
            <Col span={24}>
              <Location
                selectedOrganization={selectedOrganization}
                mapData={mapData}
                setMapModalData={setMapModalData}
                form={form}
              />
            </Col>
          </Row>
        )}

        <Row className="mt-10" gutter={20}>
          <Col span={12}>
            <label className="input-label required-input">Start date</label>
            <Form.Item
              name="startDate"
              rules={[
                { required: true, message: 'Start date is required' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const endDate = getFieldValue('endDate')
                    if (!value || !endDate || value.isBefore(endDate)) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      new Error('Start date must be less than End date'),
                    )
                  },
                }),
              ]}
            >
              <DatePicker
                name="year"
                picker="date"
                className="selector w-full"
                disabledDate={current =>
                  current && current.isBefore(new Date(), 'day')
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label className="input-label required-input">End date </label>
            <Form.Item
              name="endDate"
              rules={[
                { required: true, message: 'End date is required' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const startDate = getFieldValue('startDate')
                    if (!value || !startDate || value.isAfter(startDate)) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      new Error('End date must be greater than Start date'),
                    )
                  },
                }),
              ]}
            >
              <DatePicker
                name="year"
                picker="date"
                className="selector w-full"
                disabledDate={current =>
                  current && current.isBefore(new Date(), 'day')
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label className="input-label required-input">Sport</label>
            <Form.Item
              name="sportId"
              className="form-control allergies-field "
              rules={[{ required: true, message: 'Sport is required' }]}
            >
              <SportsSelect data={sports} form={form} setSports={setSports} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={18}>
            <label className="input-label">Add Cover</label>
            <UploadDocument
              documentIds={documentIds}
              setDocumentIds={setDocumentIds}
              multiple={true}
              docType="image/*"
              files={photos}
              uploadMultipleAtOnce={true}
            />
          </Col>
        </Row>

        <Row justify="space-between" className="mb-26 mt-40px">
          <Link to="/events">
            <Button className="cancel-button">Back to listing</Button>
          </Link>
          <Button
            className="stepper-button"
            disabled={createLeagueMutationLoading || updateLoading}
            htmlType="submit"
          >
            {createLeagueMutationLoading || updateLoading ? (
              <ButtonSpinner size={20} color="#000" />
            ) : (
              'Next'
            )}
          </Button>
        </Row>
      </Form>
    </div>
  )
}

export default Step1
