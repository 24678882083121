import React from 'react'
import CustomModal from 'components/global/CustomModal'
import { leagueEventStore } from 'state/event/leagueEvent'
import CustomModalFooter from 'components/global/CustomModalFooter'
import useFormSubmit from 'helper/Hooks/formSubmit'
import DivisionForm from './ModalContent/DivisionForm'

const AddDevision: React.FC = () => {
  const { form, handleSubmit } = useFormSubmit()
  const {
    addDevisionModal,
    setAddDevisonModal,
    isUpdatingDivision,
    setIsUpdatingDivision,
    setDivisionData,
  } = leagueEventStore(store => ({
    addDevisionModal: store.addDevisionModal,
    setAddDevisonModal: store.setAddDevisonModal,
    isUpdatingDivision: store.isUpdatingDivision,
    setIsUpdatingDivision: store.setIsUpdatingDivision,
    setDivisionData: store.setDivisionData,
  }))

  const closeModal = () => {
    setIsUpdatingDivision(false)
    setDivisionData(null)
    setAddDevisonModal(false)
  }

  return (
    <>
      <CustomModal
        openModal={addDevisionModal}
        isCloseModalCustom
        closeModal={closeModal}
        title={isUpdatingDivision ? 'Update division' : 'Add devision'}
        footer={
          <CustomModalFooter
            multibutton
            btnAlignment="justify-between"
            btnText={'Close,Save'}
            onSubmit={handleSubmit}
            closeModal={closeModal}
          />
        }
      >
        <DivisionForm form={form} />
      </CustomModal>
    </>
  )
}

export default AddDevision
