import React, { useEffect, useState } from 'react'
import { PencilSimpleLine } from '@phosphor-icons/react'
import { Table } from 'antd'
import { useGetEventCategories } from 'api/Listings/EventCategories'
import { EventCategoryListing } from 'types/listing/listings'
import Spinner from 'components/Spinner'
import moment from 'moment'
import { categoryStore } from 'state/listing/eventCategoryListing'

interface EventCategoriesTableProps {
  id: string
}

const EventCategoriesTable: React.FC<EventCategoriesTableProps> = ({ id }) => {
  const [tableData, setTableData] = useState([])
  const { setAddEventCategoryModal, setEventCategoryData } = categoryStore(
    store => ({
      addEventCategoryModal: store.addEventCategoryModal,
      setAddEventCategoryModal: store.setAddEventCategoryModal,
      setEventCategoryData: store.setEventCategoryData,
    }),
  )
  const { data, isLoading, isFetching, refetch } = useGetEventCategories()

  const handleEditClick = (data: EventCategoryListing) => {
    setEventCategoryData(data)
    setAddEventCategoryModal(true)
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => <p className="text-14 w-400">{text}</p>,
    },
    {
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
      render: (text: string) => (
        <div
          className="w-30px h-30px radius-rounded"
          style={{ backgroundColor: `#${text}` }}
        />
      ),
    },
    {
      title: 'Icon',
      dataIndex: 'icon',
      key: 'icon',
      render: (text: string) => (
        <img src={text} alt="icon" className="w-30px h-30px radius-rounded" />
      ),
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text: string) => (
        <p className="text-14 w-400">{moment(text).format('DD MMM, YYYY')}</p>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      render: (data: EventCategoryListing) => (
        <div className="flex align-center gap-20px">
          <PencilSimpleLine
            size={20}
            color="#000"
            className="pointer"
            onClick={() => handleEditClick(data)}
          />
        </div>
      ),
    },
  ]

  useEffect(() => {
    refetch()
  }, [id])

  useEffect(() => {
    if (data) {
      const enrichedTableData = data.map((category: EventCategoryListing) => {
        return {
          Key: category._id,
          name: category.name,
          color: category.color,
          updatedAt: category.updatedAt,
          icon: category.icon.post,
          actions: category,
        }
      })
      setTableData(enrichedTableData)
    }
  }, [data])

  return (
    <div>
      {isLoading || isFetching ? (
        <div className="w-full h-70vh flex align-center justify-center">
          <Spinner />
        </div>
      ) : (
        <Table
          className="colored_header_table"
          columns={columns}
          dataSource={tableData}
          pagination={false}
        />
      )}
    </div>
  )
}

export default EventCategoriesTable
