import { SkillListing } from 'types/listing/listings'
import { create } from 'zustand'

const skillCreationStore = (set: any) => ({
  addSkillModal: false,
  setAddSkillModal: (state: boolean) => set(() => ({ addSkillModal: state })),
  skillData: null,
  setSkillData: (state: SkillListing) => set(() => ({ skillData: state })),
})

export const skillCreateStore = create(skillCreationStore)
