import React from 'react'
import CustomModal from 'components/global/CustomModal'
import useFormSubmit from 'helper/Hooks/formSubmit'
import { leagueEventStore } from 'state/event/leagueEvent'
import CustomModalFooter from 'components/global/CustomModalFooter'
import AddGameConstraintForm from './ModalContent/AddGameConstraintForm'

const AddGameConstraint: React.FC = () => {
  const { form, handleSubmit } = useFormSubmit()
  const { addGameConstraintModal, setGameConstraintModal } = leagueEventStore(
    store => ({
      addGameConstraintModal: store.gameConstraintModal,
      setGameConstraintModal: store.setgameConstraintModal,
    }),
  )

  return (
    <>
      <CustomModal
        openModal={addGameConstraintModal}
        closeModal={setGameConstraintModal}
        title="Game Constraints"
        footer={
          <CustomModalFooter
            multibutton={false}
            btnAlignment="justify-between"
            btnText="Save"
            btnType="primary"
            onSubmit={handleSubmit}
          />
        }
      >
        <AddGameConstraintForm form={form} />
      </CustomModal>
    </>
  )
}

export default AddGameConstraint
