import React, { useEffect, useRef } from 'react'
import noMessage from 'assets/Images/no-messages.png'
import MessageBox from 'components/global/chat/MessageBox'
import SendInput from 'components/global/chat/SendInput'
import { Envelope, Info } from '@phosphor-icons/react'
import '../styles.scss'
import { useMessages } from 'helper/Hooks/chatMessages'
import { Person } from 'types/globalTypes'
import { eventShatStore } from 'state/event/eventChat'
import moment from 'moment'

interface MessageContainerProps {
  showReciepientContainer: boolean
  setShowReceipientContainer: React.Dispatch<React.SetStateAction<boolean>>
  activeChatRoomId: string
  me: Person
  participants: Person[]
}

const MessageContainer: React.FC<MessageContainerProps> = ({
  showReciepientContainer,
  setShowReceipientContainer,
  activeChatRoomId,
  me,
  participants,
}) => {
  const messages = useMessages(activeChatRoomId)
  const { setChatModalVisible, setSelectedParticipants } = eventShatStore(
    store => ({
      setChatModalVisible: store.setChatModalVisible,
      setSelectedParticipants: store.setSelectedParticipants,
    }),
  )

  const hanldeShowReceipientContainer = () => {
    setShowReceipientContainer(prevState => !prevState)
  }
  const hanldeNewChatClick = () => {
    setChatModalVisible(true)
    setSelectedParticipants([])
  }

  const messageRef = useRef(undefined)

  useEffect(() => {
    messageRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  if (!messages.length)
    return (
      <div className="w-full flex justify-center align-center h-full">
        <div className="w-400px flex flex-col gap-10px align-center justify-center">
          <img src={noMessage} alt="no-messages" width={120} height={120} />
          <h3 className="text-16 w-600">No messages</h3>
          <p className="text-center">
            Do you want send a SMS, chat message or email to the parents or
            mentors? Let’s do it now!
          </p>
          <button className="prim-btn" onClick={hanldeNewChatClick}>
            Create new chat
          </button>
        </div>
      </div>
    )

  return (
    <div className="relative h-80vh">
      <div className="flex justify-end align-center gap-16px mt-20px">
        <div className="px-10px py-10px bg-dark-gray flex justify-center align-center radius-8">
          <Envelope size={20} height={20} color="#000000" />
        </div>
        <div className="px-10px py-10px bg-dark-gray flex justify-center align-center radius-8">
          <Info size={20} height={20} color="#000000" />
        </div>
        <h3
          className="text-15 w-500 text-prim pointer"
          onClick={hanldeShowReceipientContainer}
        >
          {showReciepientContainer ? 'Hide' : 'Show'} details
        </h3>
      </div>

      <div className="height-chat-box mt-40px">
        {messages?.map((message, index) => {
          const sentAt = message?.time?.toDate()
          return (
            <div
              key={index}
              ref={index === messages?.length - 1 ? messageRef : null}
            >
              <MessageBox
                key={index}
                recepient={message.senderId === me._id ? 'sender' : 'receiver'}
                messageType={message.messageType}
                fileUrl={message.fileUrl}
                messageText={message.messageText}
                name={
                  participants.find(
                    participant => participant._id === message.senderId,
                  )?.firstName
                }
                time={moment(sentAt).format('hh: mm A')}
                avatar={
                  participants.find(
                    participant => participant._id === message.senderId,
                  )?.photo?.post
                }
              />
            </div>
          )
        })}
        <div ref={messageRef}></div>
      </div>

      <div
        className={`border-1px radius-12 py-12px px-15px bg-transparent`}
        style={{
          position: 'absolute',
          width: '100%',
          bottom: '12px',
        }}
      >
        <SendInput chatroomId={activeChatRoomId} participants={participants} />
      </div>
    </div>
  )
}

export default MessageContainer
