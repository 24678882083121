import { convertToLocal } from 'helper/utility'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Calendar from 'react-calendar'

interface SelectDatesCalendarProps {
  isLimited: boolean
  isRange?: boolean
  startDate?: string
  endDate?: string
  selectedDates: string[]
  setSelectedDates: React.Dispatch<React.SetStateAction<string[]>>
  occurrenceDates?: string[]
}

const SelectDatesCalendar: React.FC<SelectDatesCalendarProps> = ({
  isLimited,
  isRange,
  startDate,
  endDate,
  selectedDates,
  setSelectedDates,
  occurrenceDates,
}) => {
  const [availableDates, setAvailableDates] = useState<Date[]>([])

  const handleTileClick = (date: Date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD')
    if (!selectedDates?.includes(formattedDate)) {
      if (selectedDates) {
        setSelectedDates([...selectedDates, formattedDate])
      } else {
        setSelectedDates([formattedDate])
      }
    } else {
      setSelectedDates(
        selectedDates.filter(selectedDate => selectedDate !== formattedDate),
      )
    }
  }

  useEffect(() => {
    if (occurrenceDates) {
      const localFormatedDate = []
      occurrenceDates.forEach(date => {
        const utcDate = new Date(date)
        utcDate.setMinutes(utcDate.getMinutes() + utcDate.getTimezoneOffset())
        localFormatedDate.push(utcDate)
      })
      setAvailableDates(localFormatedDate)
    }

    if (isRange) {
      const localFormattedDate = []
      const start = new Date(startDate)
      const end = new Date(endDate)
      for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
        const LocalDate = new Date(convertToLocal(start))
        localFormattedDate.push(LocalDate)
      }
      setAvailableDates(localFormattedDate)
    }
  }, [occurrenceDates])

  return (
    <>
      {isLimited ? (
        <Calendar
          minDate={moment(convertToLocal(startDate), 'YYYY-MM-DD').toDate()}
          onClickDay={handleTileClick}
          tileClassName={({ date, view }) => {
            if (
              selectedDates?.find((x: any) => {
                return x === moment(date).format('YYYY-MM-DD')
              })
            ) {
              return 'selected'
            }
          }}
          maxDate={moment(convertToLocal(endDate), 'YYYY-MM-DD').toDate()}
          // tileDisabled={({ date }) =>
          //   !availableDates.some(
          //     availableDate =>
          //       availableDate.getDate() === date.getDate() &&
          //       availableDate.getMonth() === date.getMonth(),
          //   )
          // }
          tileDisabled={({ date }) => {
            const newDate = new Date()
            return !availableDates.some(
              availableDate =>
                availableDate.getTime() > newDate.getTime() &&
                availableDate.getDate() === date.getDate() &&
                availableDate.getMonth() === date.getMonth(),
            )
          }}
        />
      ) : (
        <Calendar
          minDate={new Date()}
          onClickDay={handleTileClick}
          tileClassName={({ date, view }) => {
            if (
              selectedDates?.find(
                (x: any) => x === moment(date).format('YYYY-MM-DD'),
              )
            ) {
              return 'selected'
            }
          }}
        />
      )}
    </>
  )
}

export default SelectDatesCalendar
