import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { axiosGet, axiosPatch, axiosPost } from 'Redux/axiosHelper'
import { BasicListingType } from 'types/listing/listings'

// get relation list
const getRelations = async () => {
  const response = await axiosGet(`v1/relation/`)
  return response.data
}
export const useGetRelations = () => {
  const relations = useQuery<BasicListingType[]>(
    ['all-relations-listing'],
    getRelations,
    {
      enabled: false,
    },
  )
  return relations
}

//add relation to list
const createRelation = async (payload: { name: string }) => {
  const response = await axiosPost(payload, `v1/relation/`)
  return response.data
}
export const useCreateRelation = () => {
  const queryClient = useQueryClient()
  return useMutation(createRelation, {
    onSuccess: (newRelation: BasicListingType) => {
      queryClient.setQueryData(
        ['all-relations-listing'],
        (oldData: BasicListingType[] | undefined) => {
          if (!oldData) return [newRelation]
          return [...oldData, newRelation]
        },
      )
    },
  })
}

// update relation
const updateRelation = async ({
  relationId,
  payload,
}: {
  relationId: string
  payload: { name: string }
}) => {
  const response = await axiosPatch(payload, `v1/relation/${relationId}`)
  return response.data
}

export const useUpdateRelation = () => {
  const queryClient = useQueryClient()
  return useMutation(updateRelation, {
    onSuccess: updateRelation => {
      queryClient.setQueryData(
        ['all-relations-listing'],
        (oldData: BasicListingType[] | undefined) => {
          if (!oldData) return [updateRelation]
          return oldData.map(relation =>
            relation._id === updateRelation._id ? updateRelation : relation,
          )
        },
      )
    },
  })
}
