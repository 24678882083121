import React, { useState } from 'react'
import { Layout, Steps, Button } from 'antd'
import Exitwithoutfinishing from '../../pages/Events/Views/Exitwithoutfinishing'
import './styles/sidebar.scss'
import { List } from '@phosphor-icons/react'
import { leagueEventStore } from 'state/event/leagueEvent'
import { useGetLeagueDivisions } from 'api/LeagueCreation/leagueDivision'

const { Sider } = Layout
const LeagueSidebar: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const { stepCounter, setStepCounter, leageueData } = leagueEventStore(
    store => ({
      stepCounter: store.leagueStepCounter,
      setStepCounter: store.setLeagueStepCounter,
      leageueData: store.leagueData,
    }),
  )
  const { Step } = Steps
  const [isModalVisibleEvent, setModalVisibleEvent] = useState(false)

  const enabled = false

  const steps = [
    {
      title: 'Step 1',
      description: (
        <div className="flex justify-between align-center">
          <b className="text-18 w-600">Settings</b>
          <button
            className={`progress-bar-btn ${
              leageueData && leageueData.statuses.settings
                ? 'active'
                : 'non-active disabled'
            }`}
            disabled={!leageueData?.statuses.settings}
            onClick={() => setStepCounter(0)}
          >
            {leageueData && leageueData.statuses.settings ? 'Review' : 'Start'}
          </button>
        </div>
      ),
    },
    {
      title: 'Step 2',
      description: (
        <div className="flex justify-between align-center">
          <b className="text-18 w-600">Rounds</b>
          <button
            className={`progress-bar-btn ${
              leageueData && leageueData.statuses.rounds
                ? 'active'
                : 'non-active disabled'
            }`}
            disabled={!leageueData?.statuses.rounds}
            onClick={() => setStepCounter(1)}
          >
            {leageueData && leageueData.statuses.rounds ? 'Review' : 'Start'}
          </button>
        </div>
      ),
    },
    {
      title: 'Step 3',
      description: (
        <div className="flex justify-between align-center">
          <b className="text-18 w-600">Divisions</b>
          <button
            className={`progress-bar-btn ${
              leageueData && leageueData.statuses.divisions
                ? 'active'
                : 'non-active disabled'
            }`}
            disabled={!leageueData?.statuses.divisions}
            onClick={() => setStepCounter(2)}
          >
            {leageueData && leageueData.statuses.divisions ? 'Review' : 'Start'}
          </button>
        </div>
      ),
    },
    {
      title: 'Step 4',
      description: (
        <div className="flex justify-between align-center">
          <b className="text-18 w-600">Time Slots</b>
          <button
            className={`progress-bar-btn ${
              leageueData && leageueData.statuses.timeSlots
                ? 'active'
                : 'non-active disabled'
            }`}
            disabled={!leageueData?.statuses.timeSlots}
            onClick={() => setStepCounter(3)}
          >
            {leageueData && leageueData.statuses.timeSlots ? 'Review' : 'Start'}
          </button>
        </div>
      ),
    },
    {
      title: 'Step 5',
      description: (
        <div className="flex justify-between align-center">
          <b className="text-18 w-600">League Configuration</b>
          <button
            className={`progress-bar-btn ${
              leageueData && leageueData.statuses.scheduleConstraints
                ? 'active'
                : 'non-active disabled'
            }`}
            disabled={!leageueData?.statuses.scheduleConstraints}
            onClick={() => setStepCounter(4)}
          >
            {leageueData && leageueData.statuses.scheduleConstraints
              ? 'Review'
              : 'Start'}
          </button>
        </div>
      ),
    },
    {
      title: 'Step 6',
      description: (
        <div className="flex justify-between align-center">
          <b className="text-18 w-600">Communication</b>
          <button
            className={`progress-bar-btn ${
              leageueData && leageueData.statuses.communication
                ? 'active'
                : 'non-active disabled'
            }`}
            disabled={!leageueData?.statuses.communication}
            onClick={() => setStepCounter(4)}
          >
            {leageueData && leageueData.statuses.communication
              ? 'Review'
              : 'Start'}
          </button>
        </div>
      ),
    },
  ]

  return (
    <div>
      <div
        className="event-list-icon"
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        <List size={30} color="#000" />
      </div>

      <Sider
        className={`side-bar create-event-side-bar  ${
          isSidebarOpen ? 'sidebar-open' : ''
        }`}
        width={'auto'}
        style={{ maxWidth: 'auto' }}
      >
        <div className="d-flex flex-column justify-between stepper-section-header pb-10">
          <div>
            <h2 className="mb-16 create-event-sidebar-heading">
              Create a league
            </h2>
            <span className="mb-20 complete-all-steps">
              Complete all following stept to create a league
            </span>
            <Steps direction="vertical" current={stepCounter}>
              {steps.map(item => (
                <Step
                  key={item.title}
                  className="stepper event-stepper event-step-bar"
                  title={item.title}
                  description={item.description}
                />
              ))}
            </Steps>
          </div>
          <div className="mt-30 save-progress-and-exit">
            <button
              className={`create-btn mb-30 ${enabled ? 'enabled' : 'disabled'}`}
              onClick={() => {
                setModalVisibleEvent(true)
              }}
            >
              Create League
            </button>
            <Button
              className="primary-transparent mb-30"
              style={{ width: '100%', height: '50px' }}
              onClick={() => {
                setModalVisibleEvent(true)
              }}
            >
              Save progress & exit
            </Button>
          </div>
        </div>
        {isModalVisibleEvent ? (
          <Exitwithoutfinishing
            isModalVisibleEvent={isModalVisibleEvent}
            setModalVisibleEvent={setModalVisibleEvent}
          />
        ) : (
          <></>
        )}
      </Sider>
    </div>
  )
}

export default LeagueSidebar
