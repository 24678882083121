import React, { useState } from 'react'
import { Button, Dropdown, Menu, Select, Space } from 'antd'
import { DownOutlined, SearchOutlined } from '@ant-design/icons'
import { useGetEventCategories } from 'api/Events'
import { CategoryType } from 'types/globalTypes'
import { GetAllEventsParams } from 'types/event/events'
import { EventStatus } from 'types/event/eventStep5'

const { Option } = Select

interface FiltersProps {
  params: GetAllEventsParams
  setParams: React.Dispatch<React.SetStateAction<GetAllEventsParams>>
}

const Filters: React.FC<FiltersProps> = ({ params, setParams }) => {
  const { data: categories } = useGetEventCategories()
  const [view, setView] = useState('cardview')
  const handleSearchChange = (e: any) => {
    setParams(prev => ({ ...prev, nameString: e.target.value }))
  }

  const onChangeCategory = (value: string) => {
    setParams(prev => ({ ...prev, categoryId: value ? value : undefined }))
  }
  const handleChangeStatus = (value: EventStatus) => {
    setParams(prev => ({ ...prev, status: value }))
  }

  const menu = (
    <Menu onClick={e => setView(e.key)}>
      <Menu.Item key="cardview">Card view</Menu.Item>
      <Menu.Item key="calendar">Calendar view</Menu.Item>
      <Menu.Item key="TableView">Table view</Menu.Item>
    </Menu>
  )

  return (
    <div style={{ display: 'flex' }}>
      <div
        className="right-search-block w-100 d-inline-block"
        style={{ maxWidth: '293px' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <div className={'search-input-box'} style={{ marginRight: '15px' }}>
            <input
              type="search"
              className="search-input-group"
              placeholder="Search by event name"
              onChange={handleSearchChange}
            />
            <SearchOutlined />
          </div>
        </div>
      </div>
      <Space size={20}>
        <Select
          className="custom-select-field black width-140"
          defaultValue="Category: All"
          placeholder="Categories"
          allowClear
          onChange={onChangeCategory}
        >
          <Option value="" style={{ width: 'auto' }}>
            Category: All
          </Option>
          {categories?.map((category: CategoryType) => {
            return (
              <option key={category._id} value={category._id}>
                {category.name}
              </option>
            )
          })}
        </Select>
        <Select
          className="custom-select-field black text-color-black width-140"
          defaultValue="Status: All"
          placeholder="Status"
          style={{ width: 'auto' }}
          allowClear
          onChange={handleChangeStatus}
        >
          <Option value="">status: All</Option>
          <Option value="draft">Draft</Option>
          <Option value="public">Public</Option>
          <Option value="following">Following</Option>
          <Option value="subscribed">Subscribed</Option>
          <Option value="private">Private</Option>
          <Option value="mentor_social">Mentor socail</Option>
          <Option value="archived">Archived </Option>
        </Select>
        <Button className="btn-item mr-15">More filters</Button>
      </Space>
      <div style={{ marginLeft: 'auto', marginRight: '1rem' }}>
        <Dropdown overlay={menu} trigger={['click']}>
          <Button className="nav-btn br-8">
            {view} <DownOutlined />
          </Button>
        </Dropdown>
      </div>
    </div>
  )
}

export default Filters
