import React from 'react'
import { listsName } from './constants'
import { CaretRight } from '@phosphor-icons/react'
import { Link } from 'react-router-dom'

const AllListings: React.FC = () => {
  return (
    <div>
      <h1 className="w-600 text-24">Manage Listings</h1>
      <div className="mt-20px">
        {listsName.map((item, index) => {
          return (
            <Link key={index} to={`/manage-listing/${item.route}`}>
              <div className="flex justify-between align-center gap-20px py-20px px-20px border-1px radius-12 mt-15px w-650px pointer">
                <div>
                  <h3 className="w-500 text-16">{item.name}</h3>
                  <p className="text-16 w-400 mt-5px text-gray">{`Manage ${item.name} listings`}</p>
                </div>
                <CaretRight size={20} color="#000" />
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default AllListings
