import IconsTextNavigation from 'components/global/IconsTextNavigation'
import React from 'react'
import { leagueEventStore } from 'state/event/leagueEvent'
import AddGameConstraint from '../Modals/Game/AddGameConstraint'
import { ConstaintStepStatusesResp } from 'types/league/league'

interface GamesConstraintsProps {
  status: ConstaintStepStatusesResp
}

const GamesConstraints: React.FC<GamesConstraintsProps> = ({ status }) => {
  const { addGameConstraintModal, setAddGameConstraintModal } =
    leagueEventStore(store => ({
      addGameConstraintModal: store.gameConstraintModal,
      setAddGameConstraintModal: store.setgameConstraintModal,
    }))

  return (
    <>
      <div onClick={() => setAddGameConstraintModal(true)}>
        <IconsTextNavigation
          iconName="ListNumbers"
          heading="Game Constraints"
          isCompleted={status.gameConstraints}
        />
      </div>
      {addGameConstraintModal && <AddGameConstraint />}
    </>
  )
}

export default GamesConstraints
