import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
} from 'antd'
import FileUploader from 'helper/FileUploader'
import GenderSelect from 'helper/GenderSelect'
import { validateAlphabets } from 'helper/utility'
import AllergyList from 'helper/AllergyList'
import {
  feetAndInchesToMeters,
  feetToMeters,
  gradeDropdown,
  inchesToMeters,
  kidFeetDropdown,
  kidInchesDropdown,
  metersToFeetAndInches,
} from './constants'
import { Allergy } from 'types/globalTypes'
import { CreateKidPayload, FamilyMemberKid } from 'types/family/kid'
import { kidStore } from 'state/family/kid'
import moment from 'moment'
import { useCreateKid, useUpdateKid } from 'api/family/kid'
import { myStore } from 'state/store'
import GradeInput from './GradeInput'

interface GeneralDetailsProps {
  form: FormInstance
  familyId: string
}
interface KidStoreStates {
  kidData: FamilyMemberKid
  setKidData: (state: FamilyMemberKid) => void
  setKidCounter: (state: number) => void
}

const GeneralDetails: React.FC<GeneralDetailsProps> = ({ form, familyId }) => {
  const { kidData, setKidData, setKidCounter }: KidStoreStates = kidStore(
    store => ({
      kidData: store.kidData,
      setKidData: store.setKidData,
      setKidCounter: store.setKidCounter,
    }),
  )
  const setModalBtnLoader = myStore(store => store.setModalBtnLoader)
  const { mutate: kidCreateMutation, isLoading: kidCreateLoading } =
    useCreateKid()
  const { mutate: kidUpdateMutation, isLoading: kidUpdateLoading } =
    useUpdateKid()

  const [avatar, setAvatar] = useState<string>('')
  const [allergies, setAllergies] = useState<Allergy[]>([])
  const [grade, setGrade] = useState<string>()
  const [feet, setFeet] = useState<number>()
  const [inches, setInches] = useState<number>()
  const [meter, setMeter] = useState<number>()
  const [weightInKg, setWeightInKg] = useState<number>()
  const [wightinPound, setWeightInPounds] = useState<number>()

  const formSchema = {
    photoId: kidData ? kidData?.photoId : undefined,
    firstName: kidData ? kidData.firstName : undefined,
    middleName: kidData ? kidData?.middleName : undefined,
    nickName: kidData ? kidData?.nickName : undefined,
    lastName: kidData ? kidData.lastName : undefined,
    gender: kidData ? kidData?.gender : undefined,
    dob: kidData && kidData?.dob ? moment(kidData.dob) : undefined,
    school: kidData && kidData?.school ? kidData.school : undefined,
    // grade: kidData && kidData?.grade ? kidData.grade : undefined,
    gradeId: kidData && kidData?.gradeId ? kidData.gradeId : undefined,
    weight: kidData && kidData?.weight ? kidData.weight : undefined,
    height: kidData && kidData?.height ? kidData.height : undefined,
    allergies: kidData ? kidData.allergies : [],
    medicalNotes:
      kidData && kidData?.medicalNotes ? kidData.medicalNotes : undefined,
    specialNeeds:
      kidData && kidData?.specialNeeds ? kidData.specialNeeds : undefined,
  }

  const handleWeightChange = value => {
    setWeightInPounds(value)

    const weightInKgs = value * 0.453592
    setWeightInKg(weightInKgs)
  }
  const handleFeetChange = value => {
    setFeet(value)
  }

  const handleInchesChange = value => {
    setInches(value)
  }

  const onFinish = (values: CreateKidPayload) => {
    const payload: CreateKidPayload = { ...values }
    payload.dob = values.dob
      ? moment(values.dob).format('YYYY-MM-DD')
      : undefined
    payload.weight = weightInKg
    payload.height = meter ? meter : undefined

    if (kidData?._id) {
      kidUpdateMutation(
        { kidId: kidData._id, payload },
        {
          onSuccess: data => {
            setKidData(data)
            setKidCounter(1)
          },
        },
      )
    } else {
      kidCreateMutation(
        { familyId, payload },
        {
          onSuccess: data => {
            setKidData(data)
            setKidCounter(1)
          },
        },
      )
    }
  }

  useEffect(() => {
    if (feet && inches) {
      setMeter(feetAndInchesToMeters(feet, inches))
    } else if (!feet) {
      setMeter(inchesToMeters(inches))
    } else if (!inches) {
      setMeter(feetToMeters(feet))
    }
  }, [feet, inches])

  useEffect(() => {
    if (kidData?.height) {
      const feetandInch = metersToFeetAndInches(kidData?.height)
      setFeet(feetandInch.feet)
      setInches(Number(feetandInch.inches.toFixed(0)))
    }
  }, [kidData])

  useEffect(() => {
    setModalBtnLoader(kidCreateLoading || kidUpdateLoading)
  }, [kidCreateLoading || kidUpdateLoading])

  return (
    <div className="mt-20px">
      <Form
        name="kid-creation-form"
        initialValues={formSchema}
        form={form}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
      >
        <Row gutter={24} align="middle">
          <Col span={4} className="mb-10px">
            <Avatar size={80} src={avatar ? avatar : ''}></Avatar>
          </Col>
          <Col span={18} className="">
            <Form.Item name="photoId">
              <FileUploader
                setAvatar={setAvatar}
                name="adult"
                form={form}
                fileId={
                  kidData && kidData?.photoId ? kidData.photoId : undefined
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} className="mt-10px">
          <Col span={12}>
            <label className="input-label required-input">FirstName</label>
            <Form.Item
              colon={false}
              name="firstName"
              rules={[
                { required: true, message: 'First Name required' },
                { validator: validateAlphabets },
              ]}
            >
              <Input placeholder="Enter First Name" required name="firstname" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label className="input-label">Middle Name</label>
            <Form.Item colon={false} name="middleName">
              <Input placeholder="Enter Middle Name" name="middleName" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <label className="input-label required-input">Last Name</label>
            <Form.Item
              colon={false}
              name="lastName"
              rules={[
                { required: true, message: 'Last Name required' },
                { validator: validateAlphabets },
              ]}
            >
              <Input placeholder="Enter Last Name" required name="lastName" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label className="input-label">Preferred Name</label>
            <Form.Item colon={false} name="nickName">
              <Input placeholder="Enter Preferred Name" name="nickName" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24} className="mt-10px">
            <label className="input-label">Gender</label>
            <Form.Item
              name="gender"
              rules={[{ required: true, message: 'Gender is required' }]}
            >
              <GenderSelect
                form={form}
                data={kidData && kidData.gender ? kidData.gender : undefined}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label className="input-label required-input">Date of Birth</label>
            <Form.Item
              name="dob"
              rules={[{ required: true, message: 'Date of birth required' }]}
            >
              <DatePicker name="dob" className="selector w-full" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label className="input-label ">School</label>
            <Form.Item name="school">
              <Input placeholder="example@gmail.com" name="scholl" />
            </Form.Item>
          </Col>
        </Row>

        {/* school grade height will come here  */}
        <Row>
          <Col span={24}>
            <label htmlFor="" className="input-label">
              Grade
            </label>
            <Form.Item name="gradeId" colon={false}>
              {/* <Select
                className="h-50px select-field"
                placeholder={'Grade'}
                onChange={(v, item) => {
                  setGrade(v)
                }}
              >
                {gradeDropdown.map((grade: any) => (
                  <Select.Option key={grade.key} value={grade.key}>
                    {grade.label}
                  </Select.Option>
                ))}
              </Select> */}
              <GradeInput
                form={form}
                gradeId={''}
                data={
                  kidData && kidData.gradeInfo
                    ? kidData.gradeInfo.name
                    : undefined
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} className="mt-10px">
          <Col span={12}>
            <label className="input-label">Weight (in lbs)</label>
            <Form.Item colon={false} name="weight">
              <Input
                placeholder="Weight"
                name="weight"
                className="custom-input-field"
                type="number"
                onChange={e => handleWeightChange(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label className="input-label">Height</label>
            <Form.Item name="height" colon={false}>
              <div className="combined-input-field flex justify-between">
                <div className="d-flex align-items-center">
                  <Select
                    className=" combined-select"
                    value={feet}
                    onChange={handleFeetChange}
                    placeholder="ft"
                  >
                    {kidFeetDropdown.map(option => (
                      <Select.Option key={option.key} value={option.key}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                  <span className="separator">
                    {'\u00A0'}
                    {'feet'}
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <Select
                    className="custom-select-form combined-select"
                    value={inches}
                    onChange={handleInchesChange}
                    placeholder="in"
                  >
                    {kidInchesDropdown.map(option => (
                      <Select.Option key={option.key} value={option.key}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                  <span className="separator">
                    {'\u00A0'}
                    {'inches'}
                  </span>
                </div>
              </div>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label className="input-label ">Allergies</label>
            <Form.Item
              name="allergies"
              className="form-control allergies-field "
            >
              <AllergyList
                form={form}
                setAllergies={setAllergies}
                data={kidData && kidData.allergies ? kidData.allergies : []}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <label className="input-label">Medical Notes</label>
            <Form.Item name="medicalNotes">
              <Input
                placeholder="Ex.: Asthma, Lactose intolerant"
                name="medicalNotes"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <label className="input-label">Special needs</label>
            <Form.Item name="specialNeeds">
              <Input placeholder="Special needs" name="specialNeeds" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default GeneralDetails
