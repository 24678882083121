import React, { useEffect } from 'react'
import ReturnBreadCrumbs from 'components/breadCrumb/NewBreadCrumbs'
import { EventResp } from 'types/event/events'
import { CaretDown } from '@phosphor-icons/react'
import { eventShatStore } from 'state/event/eventChat'

interface ChatHeaderProps {
  eventData: EventResp
}

const ChatHeader: React.FC<ChatHeaderProps> = ({ eventData }) => {
  const { setChatModalVisible, setSelectedParticipants } = eventShatStore(
    store => ({
      setChatModalVisible: store.setChatModalVisible,
      setSelectedParticipants: store.setSelectedParticipants,
    }),
  )

  const hanldeNewChatClick = () => {
    setChatModalVisible(true)
    setSelectedParticipants([])
  }

  return (
    <>
      <div className="flex justify-between">
        <div>
          <ReturnBreadCrumbs
            links={[
              { name: 'Events', link: '/events' },
              {
                name: eventData.name,
                link: `/events-details/${eventData?._id}`,
              },
              {
                name: 'Contact atendees',
                link: `/event-chat/${eventData?._id}`,
              },
            ]}
          />
          <h3 className="mt-15px text-24 w-600">Contact atendees</h3>
        </div>

        <div className="flex align-center gap-20px">
          <button className="flex align-center justify-center border-none gap-16px bg-dark-gray px-20px h-40px radius-8">
            <span className="text-14 w-500">More</span>
            <CaretDown size={20} color="#000" />
          </button>
          <button
            className="flex align-center justify-center border-none gap-16px bg-blue px-20px h-40px radius-8 pointer"
            onClick={hanldeNewChatClick}
          >
            <span className="text-14 w-500 text-white">New Chat</span>
          </button>
        </div>
      </div>
    </>
  )
}

export default ChatHeader
