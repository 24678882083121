import React from 'react'
import CustomModal from 'components/global/CustomModal'
import CustomModalFooter from 'components/global/CustomModalFooter'
import { skillCreateStore } from 'state/listing/skillListing'
import SkillForm from './ModalContent/SkillForm'
import useFormSubmit from 'helper/Hooks/formSubmit'

const SkillModal: React.FC = () => {
  const { form, handleSubmit } = useFormSubmit()
  const { addSkillModal, setAddSkillModal, skillData } = skillCreateStore(
    store => ({
      addSkillModal: store.addSkillModal,
      setAddSkillModal: store.setAddSkillModal,
      skillData: store.skillData,
    }),
  )

  return (
    <>
      <CustomModal
        openModal={addSkillModal}
        closeModal={setAddSkillModal}
        title={skillData ? 'Update Skill' : 'Add new Skill'}
        footer={
          <CustomModalFooter
            multibutton={false}
            btnAlignment="justify-end"
            btnText={skillData ? 'Update' : 'Create'}
            btnType="primary"
            onSubmit={handleSubmit}
          />
        }
      >
        <SkillForm form={form} />
      </CustomModal>
    </>
  )
}

export default SkillModal
