import React from 'react'
import { Steps } from 'antd'
const { Step } = Steps

interface CounterStepProps {
  steps: { title: string }[]
  currentStep: number
}

const GlobalCounterStep: React.FC<CounterStepProps> = ({
  steps,
  currentStep,
}) => {
  return (
    <div className="border-btm pb-15px">
      <Steps current={currentStep}>
        {steps.map(item => (
          <Step key={item.title} className="step-styling" title={item.title} />
        ))}
      </Steps>
    </div>
  )
}

export default GlobalCounterStep
