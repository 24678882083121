import React from 'react'
import IconsTextNavigation from 'components/global/IconsTextNavigation'

const MandatoryInfo: React.FC = () => {
  return (
    <>
      <div>
        <IconsTextNavigation iconName="Info" heading="Mandatory Information" />
      </div>
    </>
  )
}

export default MandatoryInfo
