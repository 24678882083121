import React, { useState } from 'react'
import './styles/component-styles.scss'
import { DotsThree, Eye, EyeSlash, Pencil, Trash } from '@phosphor-icons/react'
import { DiscountResp } from 'types/event/eventStep4'
import { Dropdown } from 'antd'
import DiscountCode from '../Modals/Discount/DiscountCode'
import {
  useRemoveDiscountCode,
  useUpdateDiscountStatus,
} from 'api/EventCreation/CreationStep4'
import ButtonSpinner from 'components/global/ButtonSpinner'
import AlertModal from 'helper/AlertModal'
import ViewDiscount from '../Modals/Discount/ViewDiscount'

const Discounts: React.FC<any> = ({ data }) => {
  const [openDiscountModal, setOpenDiscountModal] = useState<boolean>(false)
  const [viewDiscount, setViewDiscount] = useState<boolean>(false)
  const [alertModal, setAlertModal] = useState<boolean>(false)
  const [selectedData, setSelectedData] = useState<DiscountResp>()
  const { mutate: updateCodeStatus, isLoading: updateCodeStatusLoading } =
    useUpdateDiscountStatus()
  const { mutate: deleteDiscountCode, isLoading: deleteDiscountLoading } =
    useRemoveDiscountCode()

  const alertModalData = {
    name: 'Delete',
    heading: 'Delete discount',
    message1: 'Are you sure you want to delete discount?',
  }

  const handleYesDelete = () => {
    deleteDiscountCode(
      { discountCodeId: selectedData._id },
      {
        onSuccess: () => setAlertModal(false),
        onError: () => setAlertModal(false),
      },
    )
  }

  const handleMenuClick = (
    operation: 'edit' | 'view' | 'visibility' | 'delete',
    clickedDiscount: DiscountResp,
  ) => {
    if (operation === 'edit') {
      setSelectedData(clickedDiscount)
      setOpenDiscountModal(true)
    } else if (operation === 'visibility') {
      setSelectedData(clickedDiscount)
      const payload = {
        status: clickedDiscount.status === 'active' ? 'disabled' : 'active',
      }
      updateCodeStatus({ payload, discountCodeId: clickedDiscount._id })
    } else if (operation === 'delete') {
      setSelectedData(clickedDiscount)
      setAlertModal(true)
    } else {
      setSelectedData(clickedDiscount)
      setViewDiscount(true)
    }
  }

  const manageOptions = (discountData: DiscountResp) => [
    {
      key: 'edit',
      label: (
        <div
          className="flex gap-16px align-center"
          onClick={() => handleMenuClick('edit', discountData)}
        >
          <Pencil size={20} color="#000" />
          <p className="text-14 w-500">Edit Code</p>
        </div>
      ),
    },
    {
      key: 'view',
      label: (
        <div
          className="flex gap-16px align-center"
          onClick={() => handleMenuClick('view', discountData)}
        >
          <Eye size={20} color="#000" />
          <p className="text-14 w-500">View Discount</p>
        </div>
      ),
    },
    {
      key: 'visibility',
      label: (
        <div
          className="flex gap-16px align-center"
          onClick={() => handleMenuClick('visibility', discountData)}
        >
          {discountData.status === 'active' ? (
            <EyeSlash size={20} color="#000" />
          ) : (
            <Eye size={20} color="#000" />
          )}
          <p className="text-14 w-500">
            Make {discountData.status === 'active' ? 'Disable' : 'Enable'}
          </p>
        </div>
      ),
    },
    {
      key: 'delete',
      label: (
        <div
          className="flex gap-16px align-center"
          onClick={() => handleMenuClick('delete', discountData)}
        >
          <Trash size={20} color="#ff6961" />
          <p className="text-14 w-500 danger-txt">Delete Discount</p>
        </div>
      ),
    },
  ]

  return (
    <>
      <div className="discount-cont mt-20px">
        <div className="discount-header flex gap-10px">
          <h3 className="text-13 w-600 heading">Discount</h3>
          <h3 className="text-13 w-600 heading">Code</h3>
          <h3 className="text-13 w-600 heading">Used</h3>
          <h3 className="text-13 w-600 heading">Limit</h3>
        </div>
        <div>
          {data.map((discount: DiscountResp) => {
            return (
              <>
                {selectedData?._id === discount._id &&
                updateCodeStatusLoading ? (
                  <div
                    className="w-full flex align-center justify-center"
                    style={{ height: '60px' }}
                  >
                    <ButtonSpinner size={20} color="#000" />
                  </div>
                ) : (
                  <div className="flex gap-10px discount-body">
                    <p className="body-content">{discount.code}</p>
                    <p className="body-content">{`${discount.discount}${
                      discount.type === 'percent' ? '%' : '$'
                    }`}</p>
                    <p className="body-content">{discount.userIds.length}</p>
                    <p className="body-content">
                      {discount?.totalLimit ? discount.totalLimit : 'no limit'}
                    </p>
                    <Dropdown
                      menu={{ items: manageOptions(discount) }}
                      placement="bottomRight"
                      arrow={false}
                      className={``}
                      overlayStyle={{ width: '300px' }}
                      trigger={['click']}
                    >
                      <DotsThree size={20} color="#000" className="pointer" />
                    </Dropdown>
                  </div>
                )}
              </>
            )
          })}
        </div>
      </div>

      {openDiscountModal && (
        <DiscountCode
          modalOpen={openDiscountModal}
          modalClose={setOpenDiscountModal}
          data={selectedData}
        />
      )}

      {alertModal && (
        <AlertModal
          isModalVisible={alertModal}
          setIsModalVisible={setAlertModal}
          data={alertModalData}
          onYesClick={handleYesDelete}
          loading={deleteDiscountLoading}
        />
      )}

      {viewDiscount && (
        <ViewDiscount
          openModal={viewDiscount}
          closeModal={setViewDiscount}
          id={selectedData._id}
        />
      )}
    </>
  )
}

export default Discounts
