import { create } from 'zustand'
import { BasicListingType } from 'types/listing/listings'

const highlightedElementCreationStore = (set: any) => ({
  addHighlightedElementModal: false,
  setAddHighlightedElementModal: (state: boolean) =>
    set(() => ({ addHighlightedElementModal: state })),
  highlightedElementData: null,
  setHighlightedElementData: (state: BasicListingType) =>
    set(() => ({ highlightedElementData: state })),
})

export const highlightedElementStore = create(highlightedElementCreationStore)
