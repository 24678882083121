import React, { useEffect, useState } from 'react'
import { Avatar, Col, Form, FormInstance, Input, Row } from 'antd'
import OrganizationInput from 'components/global/inputs/OrganizationInput/OrganizationInput'
import { OrganizationResp, Photo } from 'types/globalTypes'
import FileUploader from 'helper/FileUploader'
import UploadDocument from 'components/global/UploadDocument'
import { infoMessage } from 'api/global'
import { formValidationOnRequiredFields } from 'helper/utility'
import { CreateTeamPayload } from 'types/team/team'
import {
  useCreateTeamForOrganization,
  useUpdateTeamInOrganization,
} from 'api/Team/Team'
import { teamStore } from 'state/team/team'
import { myStore } from 'state/store'

interface CreateTeamFormProps {
  form: FormInstance
}

interface FormValuesTypes {
  name: string
  about: string
  photoId?: string
}

const CreateTeamForm: React.FC<CreateTeamFormProps> = ({ form }) => {
  const { setTeamModalVisible, teamData, isUpdatingTeam } = teamStore(
    store => ({
      setTeamModalVisible: store.setTeamModalVisible,
      teamData: store.teamData,
      isUpdatingTeam: store.isUpdatingTeam,
    }),
  )

  const setModalBtnLoader = myStore(store => store.setModalBtnLoader)
  const [selectedOrganization, setSelectedOrganization] =
    useState<OrganizationResp>(teamData ? teamData.organization : undefined)
  const [avatar, setAvatar] = useState<string>('')
  const [documentIds, setDocumentIds] = useState<string[]>(
    teamData ? [teamData.coverPhotoId] : [],
  )
  const [photos] = useState<Photo[]>(
    teamData && teamData.coverPhoto?.length ? [teamData.coverPhoto] : [],
  )

  const { mutate: createTeamMutation, isLoading: createTeamMutationLoading } =
    useCreateTeamForOrganization()
  const { mutate: updateTeamMutation, isLoading: updateTeamMutationLoading } =
    useUpdateTeamInOrganization()

  const formSchema = {
    photoId: teamData ? teamData.profilePhotoId : undefined,
    name: teamData ? teamData.name : undefined,
    about: teamData ? teamData.about : undefined,
  }

  const onFinish = (values: FormValuesTypes) => {
    if (!selectedOrganization) return infoMessage('Organization is required')
    if (!formValidationOnRequiredFields(values, ['name', 'about'])) return
    const payload: CreateTeamPayload = {
      ...values,
      coverPhotoId: documentIds[0],
      profilePhotoId: values.photoId,
    }

    if (isUpdatingTeam) {
      updateTeamMutation(
        { teamId: teamData._id, payload },
        {
          onSuccess: () => {
            setTeamModalVisible(false)
          },
        },
      )
    } else {
      createTeamMutation(
        { organizationId: selectedOrganization._id, payload },
        {
          onSuccess: () => {
            setTeamModalVisible(false)
          },
        },
      )
    }
  }

  useEffect(() => {
    setModalBtnLoader(createTeamMutationLoading || updateTeamMutationLoading)
  }, [createTeamMutationLoading || updateTeamMutationLoading])

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        initialValues={formSchema}
        onFinish={onFinish}
      >
        <Row gutter={24} align="middle">
          <Col span={4} className="mb-10px">
            <Avatar size={80} src={avatar ? avatar : ''}></Avatar>
          </Col>
          <Col span={18} className="">
            <Form.Item name="photoId">
              <FileUploader
                setAvatar={setAvatar}
                name="adult"
                form={form}
                fileId={teamData ? teamData.profilePhotoId : undefined}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mt-10px">
          <Col span={24}>
            <OrganizationInput
              selectedOrganization={selectedOrganization}
              setSelectedOrganization={setSelectedOrganization}
              isDisabled={teamData}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col>
            <label className="input-label required-input">Team name</label>
            <Form.Item colon={false} name="name">
              <Input placeholder="Enter team name" required name="firstname" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col>
            <label className="input-label required-input">About the team</label>
            <Form.Item colon={false} name="about">
              <Input.TextArea
                className="note-field"
                placeholder="Few words about the event"
                autoSize={{ minRows: 5, maxRows: 10 }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <label className="input-label">Cover image</label>
            <UploadDocument
              documentIds={documentIds}
              setDocumentIds={setDocumentIds}
              multiple={false}
              docType="image/*"
              files={photos}
            />
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default CreateTeamForm
