import React, { useEffect } from 'react'
import { Avatar, FormInstance, Select } from 'antd'
import { useGetLeagueSports } from 'api/Lists'
import Spinner from 'components/Spinner'
import { CategoryType } from 'types/globalTypes'
import { DefaultOptionType } from 'antd/lib/select'

interface SportsSelectProps {
  data: CategoryType
  form: FormInstance
  setSports: React.Dispatch<React.SetStateAction<CategoryType>>
}

const SportsSelect: React.FC<SportsSelectProps> = ({
  data,
  form,
  setSports,
}) => {
  const {
    data: sportsList,
    isLoading: sportsListLoading,
    isFetching: sportsListFetching,
    refetch: refetchSportsList,
  } = useGetLeagueSports()

  useEffect(() => {
    refetchSportsList()
  }, [])

  return (
    <div>
      <Select
        placeholder={'Select Sport'}
        defaultValue={data && data.name}
        onChange={(_, item: DefaultOptionType) => {
          form.setFieldsValue({
            // sport: {
            //   _id: item.key,
            //   name: item.value,
            // },
            sportId: item.key,
          })
          setSports({ _id: item.key, name: item.value as string })
        }}
        className="selector select-field"
      >
        {sportsListLoading || sportsListFetching ? (
          <div className="py-20px flex align-center justify-center">
            <Spinner />
          </div>
        ) : (
          sportsList.map((sport: CategoryType) => (
            <Select.Option key={sport._id} value={sport.name}>
              {<Avatar size={18} src={sport?.icon}></Avatar>} {sport?.name}
            </Select.Option>
          ))
        )}
      </Select>
    </div>
  )
}

export default SportsSelect
