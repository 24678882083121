import {
  collection,
  addDoc,
  doc,
  setDoc,
  where,
  serverTimestamp,
  onSnapshot,
  query,
  orderBy,
  updateDoc,
  Timestamp,
} from 'firebase/firestore'
import { db } from '../../Firebase'
import { v4 as uuidv4 } from 'uuid'
import { ChatMessageType, ChatRoomData, ChatRoomsType } from 'types/chat'
import { Person } from 'types/globalTypes'

export function getChatRoom(
  roomId: string,
  callback: (rooms: ChatRoomsType[]) => void,
) {
  const chatRoomRef = collection(db, 'ChatRooms')
  const roomQuery = query(
    chatRoomRef,
    where('contractId', '==', roomId),
    where('memberIds', 'array-contains', localStorage.getItem('adminId')),
    orderBy('updatedAt', 'desc'),
  )

  return onSnapshot(roomQuery, querySnapshot => {
    const rooms = querySnapshot.docs.map(doc => ({
      id: doc.id,
      data: doc.data() as ChatRoomData,
    }))
    callback(rooms)
  })
}

export async function createChatRoom(
  roomId: string,
  chatRoomFor: 'EVENTCHATS',
  title: string,
  memberIds: string[],
  message: ChatMessageType,
  members: Person[],
): Promise<string> {
  const chatDynamicId = uuidv4()
  const chatRoomRef = collection(db, 'ChatRooms')
  const newRoomRef = doc(chatRoomRef, chatDynamicId)
  const newRoomData: ChatRoomData = {
    chatRoomFor: chatRoomFor,
    contractId: roomId,
    createdBy: 'admin',
    createdAt: serverTimestamp() as Timestamp,
    updatedAt: serverTimestamp() as Timestamp,
    id: chatDynamicId,
    title,
    lastMessage: message,
    memberIds,
    members,
  }
  try {
    await setDoc(newRoomRef, newRoomData)
    return newRoomRef.id
  } catch (error) {
    return error
  }
}

export async function updateChatRoom(
  roomId: string,
  updatedData: Partial<ChatRoomData>,
): Promise<void> {
  try {
    const chatRoomRef = doc(db, 'ChatRooms', roomId)
    await updateDoc(chatRoomRef, {
      ...updatedData,
      updatedAt: serverTimestamp() as unknown as string,
    })
  } catch (error) {
    throw error
  }
}

async function sendMessage(
  roomId: string,
  senderId: string,
  recieverId: string,
  messageType: 'Image' | 'Video' | 'Voicenote' | 'Text' | 'File',
  text: string,
  fileUrl: string,
  thumbnailUrl: string,
  readByUsers: string[],
) {
  try {
    await addDoc(collection(db, 'Messages', roomId, 'AllMessages'), {
      fileUrl,
      messageText: text.trim(),
      messageType,
      recieverId,
      senderId,
      thumbnailUrl,
      readByUsers,
      time: serverTimestamp(),
    })
  } catch (error) {
    console.error(error)
  }
}

function getMessages(roomId: string, callback: (messages: any[]) => void) {
  const docRef = doc(db, 'Messages', roomId)
  const messagesCollectionRef = query(
    collection(docRef, 'AllMessages'),
    orderBy('time', 'asc'),
  )

  return onSnapshot(messagesCollectionRef, querySnapshot => {
    const messageArray = querySnapshot.docs.map(doc => ({
      ...doc.data(),
    }))

    callback(messageArray)
  })
}

export { sendMessage, getMessages }
