import { Empty, Pagination } from 'antd'
import { Row, Col } from 'antd'
import CustomCalender from '../../../../helper/Calendar/Eventscalendar/CustomCalender'
import EventCard from './SingleCard'
import Loader from '../../../../helper/Loader'
import usePagination from 'helper/usePagination'
import { CommonEventResp } from 'types/event/events'
import CommonEventCard from './CommonEventCard'

const CardView = ({ events, parms, setParms }: any) => {
  const [paginationInfo, onTableChange, PaginationNoTabel] = usePagination({
    totalCount: events?.count || 0,
    currentPage: parms.page,
    setParams: setParms,
  })
  return (
    <Row gutter={[12, 24]}>
      <>
        <Col xs={{ span: 12 }} xl={{ span: 16 }}>
          <>
            {events ? (
              !events.records.length ? (
                <div className="w-full h-50vh flex align-center justify-center">
                  <Empty />
                </div>
              ) : (
                events?.records?.map((events: CommonEventResp) => {
                  return (
                    <div className="border-btm pb-20px">
                      <CommonEventCard event={events} />
                    </div>
                  )
                })
              )
            ) : (
              <Loader />
            )}

            {events.records.length ? (
              <Row className="justify-center flex mt-40px">
                <Pagination
                  className="pb-20"
                  style={{ width: '100%', display: 'flex' }}
                  {...paginationInfo}
                  onChange={PaginationNoTabel}
                />
              </Row>
            ) : null}
          </>
        </Col>
        <Col xl={{ span: 8 }} xs={{ span: 12 }}>
          <Row justify="center" className="justify-end">
            <Col lg={21} span={24}>
              <CustomCalender />
            </Col>
          </Row>
        </Col>
      </>
    </Row>
  )
}

export default CardView
