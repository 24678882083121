import React, { useEffect, useState } from 'react'
import { CalendarX, MapPin, PencilSimple, Trash } from '@phosphor-icons/react'
import {
  useDeleteTeamLeagueConstraint,
  useGetLeagueTeamConstraintsById,
} from 'api/LeagueCreation/leagueTeamConstraints'
import Spinner from 'components/Spinner'
import { Empty } from 'antd'
import { TeamConstraintType } from 'types/league/teamConstraints'
import moment from 'moment'
import AlertModal from 'helper/AlertModal'
import { leagueEventStore } from 'state/event/leagueEvent'

interface TeamConstraintListProps {
  leagueId: string
}

const TeamConstraintList: React.FC<TeamConstraintListProps> = ({
  leagueId,
}) => {
  const { setAddTeamConstraintModal, setTeamConstraintData } = leagueEventStore(
    store => ({
      setAddTeamConstraintModal: store.setAddTeamConstraintModal,
      setTeamConstraintData: store.setTeamConstraintData,
    }),
  )
  const {
    data: teamConstraints,
    isLoading: teamConstraintsLoading,
    isFetching: teamConstraintsFetching,
    refetch: refetchTeamConstraints,
  } = useGetLeagueTeamConstraintsById(leagueId)
  const [alertModal, setAlertModal] = useState<boolean>(false)
  const [constraint, setConstraint] = useState<TeamConstraintType>()
  const { mutate: deleteTeamConstraintMutation, isLoading: isDeleting } =
    useDeleteTeamLeagueConstraint()

  const onYesClick = () => {
    deleteTeamConstraintMutation(constraint._id, {
      onSuccess: () => {
        setAlertModal(false)
      },
    })
  }

  const handleDeleteConstraint = (constraint: TeamConstraintType) => {
    setConstraint(constraint)
    setAlertModal(true)
  }

  const handleEditConstraint = (constraint: TeamConstraintType) => {
    setTeamConstraintData(constraint)
    setAddTeamConstraintModal(true)
  }

  useEffect(() => {
    refetchTeamConstraints()
  }, [])

  return (
    <div className="mb-20px">
      <p className="text-gray">
        Block off times and (optionally) venues where certain teams should NOT
        be scheduled.
      </p>
      <>
        {teamConstraintsLoading || teamConstraintsFetching ? (
          <div className="flex align-center justify-center py-40px">
            <Spinner />
          </div>
        ) : (
          <div>
            {!teamConstraints.length ? (
              <div className="py-40px">
                <Empty />
              </div>
            ) : (
              teamConstraints.map((constraint: TeamConstraintType) => {
                return (
                  <div
                    key={constraint._id}
                    className="mt-15px bg-greyLight radius-12 py-20px px-20px flex align-start justify-between gap-30px"
                  >
                    <div>
                      <h2 className="text-16 w-600">{constraint.team.name}</h2>
                      <div className="flex align-center mt-3px gap-5px">
                        <MapPin size={18} color="#939598" />
                        <p className="text-14 w-500 text-gray">
                          {constraint.venue?.title}
                        </p>
                      </div>
                      <div className="flex align-center mt-3px gap-5px">
                        <CalendarX size={18} color="#939598" />
                        <div className="flex text-14 w-500 text-gray">
                          {constraint.days.map(day => (
                            <p className="text-14 w-500 text-gray">{day},</p>
                          ))}
                        </div>
                        <div className="flex">
                          <p className="text-14 w-500 text-gray">
                            {moment(constraint.dates[0]).format('MMM DD')}{' '}
                          </p>
                          <p className="text-14 w-500 text-gray">
                            -{' '}
                            {moment(
                              constraint.dates[constraint.dates.length - 1],
                            ).format('MMM DD')}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-10px  align-center">
                      <PencilSimple
                        size={22}
                        color="#000"
                        className="pointer"
                        onClick={() => handleEditConstraint(constraint)}
                      />
                      <Trash
                        size={22}
                        color="#ff6961"
                        className="pointer"
                        onClick={() => handleDeleteConstraint(constraint)}
                      />
                    </div>
                  </div>
                )
              })
            )}
          </div>
        )}
      </>

      {alertModal && (
        <AlertModal
          isModalVisible={alertModal}
          setIsModalVisible={setAlertModal}
          data={{
            name: 'Team Constraint',
            heading: `Delete the Team Constraint ${constraint?.team.name}`,
            message1: `Are you sure you want to delete the constraint?`,
          }}
          onYesClick={onYesClick}
          loading={isDeleting}
        />
      )}
    </div>
  )
}

export default TeamConstraintList
