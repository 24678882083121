import React from 'react'
import IconsTextNavigation from 'components/global/IconsTextNavigation'
import { eventCommonStore } from 'state/event/eventCommon'
import FaqModal from 'pages/Events/Views/Common/Modals/FaqModal'
import AddQuestion from 'pages/Events/Views/Common/Modals/AddQuestion'

const Faq: React.FC = () => {
  const { faqModalOpen, setFaqModalOpen, addQuestionModal, selectedQuestions } =
    eventCommonStore(store => ({
      faqModalOpen: store.faqModalOpen,
      setFaqModalOpen: store.setFaqModalOpen,
      addQuestionModal: store.addQuestionModal,
      selectedQuestions: store.selectedQuestions,
    }))
  return (
    <>
      <div onClick={() => setFaqModalOpen(true)}>
        <IconsTextNavigation
          iconName="Question"
          heading="FAQ"
          details={
            selectedQuestions ? selectedQuestions.length : 'No questions added'
          }
        />
      </div>

      {faqModalOpen && <FaqModal />}
      {addQuestionModal && (
        <AddQuestion
          referenceModalId={'67ca8696225e9a76e7b27870'}
          reference="league"
        />
      )}
    </>
  )
}

export default Faq
