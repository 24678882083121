import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { SkillList } from 'pages/JobRequest/types'
import { useEffect } from 'react'
import { axiosGet } from '../Redux/axiosHelper'
import { AgeGroups, CategoryType } from 'types/globalTypes'

export const getAllergyList = async () => {
  const response = await axiosGet('v1/lists/allergies')
  return response.data
}
export const getAccessLevel = async () => {
  const response = await axiosGet('v1/lists/access-levels')
  return response.data
}
export const getSkillsList = async () => {
  const response = await axiosGet('v1/lists/skills')
  return response.data
}
export const useSkillsList = () => {
  const SkillsList = async () => {
    const response = await axiosGet('v1/lists/skills')
    return response.data
  }
  const response: UseQueryResult<SkillList[], unknown> = useQuery(
    ['getSkillList'],
    () => SkillsList(),
  )

  return response
}

// get league sports
const getLeageueSports = async () => {
  const response = await axiosGet(`v1/lists/league-sports`)
  return response.data
}
export const useGetLeagueSports = () => {
  const leagueSports = useQuery<CategoryType[]>(
    ['league-sports-listing'],
    getLeageueSports,
    { enabled: false },
  )
  return leagueSports
}

//get age groups
const getAgeGroups = async () => {
  const response = await axiosGet(`v1/lists/age-groups`)
  return response.data
}

export const useGetAgeGroups = () => {
  const ageGroups = useQuery<AgeGroups[]>(
    ['age-groups-listing'],
    getAgeGroups,
    {
      enabled: false,
    },
  )
  return ageGroups
}
