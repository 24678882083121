import { create } from 'zustand'

const externalStoreState = (set: any) => ({
  // modals
  statusModalVisible: false,
  setStatusModalVisible: (state: boolean) =>
    set(() => ({ statusModalVisible: state })),
  externalStepCounter: 0,
  setExternalStepCounter: (state: number) =>
    set(() => ({ externalStepCounter: state })),

  // dataset
  externalData: null,
  setExternalData: (state: any) => set(() => ({ externalData: state })),
})

export const externalStore = create(externalStoreState)
