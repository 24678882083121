import moment from 'moment'
import facebook from 'assets/Images/facebook.svg'
import twitter from 'assets/Images/twitter.svg'
import linkedin from 'assets/Images/linkedin.svg'
import instagram from 'assets/Images/instagram.svg'

export const convertDateToUtc = (startDateTime: Date | string) => {
  const formatedDate = moment(startDateTime)
    .utc()
    .set({
      hour: moment(startDateTime, 'HH:mm').utc().hour(),
      minute: moment(startDateTime, 'HH:mm').utc().minute(),
    })
    .format()
  return formatedDate
}

export const weekDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

export const AlertModalData = (
  type: 'admission' | 'additional' | 'dropin' | 'discount',
  modalType: 'delete' | 'duplicate',
) => {
  return {
    name: 'Tickets',
    heading: `${
      modalType === 'delete' ? 'Delete' : 'Duplicate'
    } ${type}  Ticket!`,
    message1: `Are you sure you want to ${
      modalType === 'delete' ? 'Delete' : 'Duplicate'
    } this ${type} ticket?`,
  }
}

export const AlertModalGeneric = (name: string, heading: string) => {
  return {
    name: name,
    heading: `Delete ${heading}`,
    message1: `Are you sure you want to delete this ${name}?`,
  }
}

export const socialIcon = [
  { icon: facebook, url: '#' },
  { icon: twitter, url: '#' },
  { icon: linkedin, url: '#' },
  { icon: instagram, url: '#' },
]

export const getAdmissionTicketPriceSentence = (
  price: number,
  numberOfDays: number,
  days: string[] = [],
) => {
  if (price <= 0) return 'Free ticket pass'

  const daysCount = numberOfDays || days.length
  return `${daysCount} day${daysCount > 1 ? 's' : ''} for $${price}`
}

export const getAgeRangeWithGender = (
  minAge: number,
  maxAge: number,
  guestAllowedToVisit: 'boys' | 'girls' | 'all',
) => {
  const ageText =
    minAge && maxAge
      ? `${minAge} - ${maxAge}`
      : minAge
      ? `Only for ages ${minAge} and above`
      : maxAge
      ? `Only for ages below ${maxAge}`
      : ''

  const genderText =
    {
      boys: 'Male',
      girls: 'Female',
    }[guestAllowedToVisit] || 'For everyone'

  return ageText ? `${ageText}, ${genderText}` : genderText
}
