import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  EventCategoriesType,
  EventResp,
  OrganizationParams,
} from 'types/event/events'
import {
  OrgApiResponse,
  OrganizationAddress,
  LocationParams,
  ApprovedOrganizationResp,
  OrgVenuesParams,
  OrganizationVenues,
  Step1BodyTypes,
  VenuePayloadTypes,
} from 'types/organization'
import { axiosDelete, axiosGet, axiosPatch, axiosPost } from 'Redux/axiosHelper'
import { AmenityType, Address } from 'types/globalTypes'

// get event categories
export const getEventCategories = async () => {
  const response = await axiosGet(`v1/lists/event-categories`)
  return response.data
}
export const useGetEventCategories = () => {
  const eventCategories = useQuery<EventCategoriesType[]>(
    ['event-categories'],
    getEventCategories,
    {
      enabled: false,
    },
  )
  return eventCategories
}

// get orgnaizations
export const getOrganizations = async (params: OrganizationParams) => {
  const response = await axiosGet(`v1/admins/organizations`, params)
  return response.data
}

export const useGetOrganizations = (params: OrganizationParams) => {
  const organizations = useQuery<OrgApiResponse>(
    ['all-organizations'],
    () => getOrganizations(params),
    { enabled: false },
  )
  return organizations
}

// get organizations location's
export const getOrgLocations = async (organizationId: string) => {
  const response = await axiosGet(
    `v1/location/organization-locations/${organizationId}`,
  )
  return response.data
}

export const useGetOrgLocations = (organizationId: string) => {
  const orgLocations = useQuery<OrganizationAddress[]>(
    ['org-locations'],
    () => getOrgLocations(organizationId),
    { enabled: false },
  )
  return orgLocations
}

// get approved locations
export const getApprovedLocations = async (params: LocationParams) => {
  const response = await axiosGet(`v1/location/approved`, params)
  return response.data
}
export const useGetApprovedLocations = (params: LocationParams) => {
  const approvedLocations = useQuery<ApprovedOrganizationResp>(
    ['approved-locations'],
    () => getApprovedLocations(params),
    { enabled: false },
  )
  return approvedLocations
}

//post request add organization location
export const addOrgLocation = async ({
  payload,
  organizationId,
}: {
  payload: Address
  organizationId: string
}) => {
  const response = await axiosPost(
    payload,
    `v1/location/add-organization-location/${organizationId}`,
  )
  return response.data
}

export const useAddOrgLocation = () => {
  const queryClient = useQueryClient()
  return useMutation(addOrgLocation, {
    onSuccess: newVenue => {
      queryClient.invalidateQueries(['approved-locations'])
    },
  })
}

//get amenties list
export const getAmentiesList = async () => {
  const response = await axiosGet(`v1/lists/amenities`)
  return response.data
}

export const useGetAmenties = () => {
  const amentiesList = useQuery<AmenityType[]>(
    ['amenties-list'],
    getAmentiesList,
    { enabled: false },
  )
  return amentiesList
}

// get venues of the organization
export const getOrgVenues = async (params: OrgVenuesParams) => {
  const response = await axiosGet(`v1/event-venues/venues`, params)
  return response.data
}
export const useGetOrgVenues = (params: OrgVenuesParams) => {
  const availableVenues = useQuery<OrganizationVenues[]>(
    ['available-venues'],
    () => getOrgVenues(params),
    { enabled: false },
  )
  return availableVenues
}

// post request create venue
export const createVenue = async ({
  payload,
  orgId,
}: {
  payload: VenuePayloadTypes
  orgId: string
}) => {
  const response = await axiosPost(
    payload,
    `v1/event-venues/${orgId}/event-venue`,
  )
  return response.data
}

export const useCreateOrgVenue = () => {
  const queryClient = useQueryClient()
  return useMutation(createVenue, {
    onSuccess: newVenue => {
      queryClient.setQueryData(
        ['available-venues'],
        (oldData: OrganizationVenues[] | undefined) => {
          return oldData ? [...oldData, newVenue] : [newVenue]
        },
      )
    },
  })
}

// patch request update event venue
export const updateVenue = async ({
  payload,
  venueId,
}: {
  payload: VenuePayloadTypes
  venueId: string
}) => {
  const response = await axiosPatch(payload, `v1/event-venues/${venueId}`)
  return response.data
}

export const useUpdateVenue = () => {
  const queryClient = useQueryClient()
  return useMutation(updateVenue, {
    onSuccess: updatedVenue => {
      queryClient.setQueryData(
        ['available-venues'],
        (oldData: OrganizationVenues[] | undefined) => {
          if (!oldData) return [updatedVenue]

          return oldData.map(venue =>
            venue.id === updatedVenue.id ? updatedVenue : venue,
          )
        },
      )
    },
  })
}

// delete request delete event venue
export const deleteVenue = async ({ venueId }: { venueId: string }) => {
  const response = await axiosDelete(`v1/event-venues/${venueId}`)
  return response.data
}

export const useDeleteVenue = () => {
  const queryClient = useQueryClient()
  return useMutation(deleteVenue, {
    onSuccess: deletedVenue => {
      queryClient.setQueryData(
        ['available-venues'],
        (oldData: OrganizationVenues[] | undefined) => {
          if (!oldData) return []

          return oldData.filter(venue => venue.id !== deletedVenue.id)
        },
      )
    },
  })
}

// post request event step 1
export const createEventStep1 = async ({
  body,
  orgId,
}: {
  body: Step1BodyTypes
  orgId: string
}) => {
  const response = await axiosPost(
    body,
    `v1/organization-events/${orgId}/event-step1`,
    'Step 1 completed Successfully',
  )
  return response.data
}

export const useCreateEventStep1Mutation = () => {
  return useMutation(createEventStep1)
}

const editEventStep1 = async ({
  body,
  eventId,
}: {
  body: Step1BodyTypes
  eventId: string
}) => {
  const response = await axiosPatch(
    body,
    `v1/organization-events/${eventId}/event-step1`,
  )
  return response.data
}

export const useEditEventStep1 = () => {
  return useMutation(editEventStep1)
}

// get request event by id
export const getEventById = async (eventId: string) => {
  const response = await axiosGet(`v1/organization-events/open/${eventId}`)
  return response.data
}

export const useGetEventById = (eventId: string) => {
  const eventById = useQuery<EventResp>(
    ['event-detail-by-id'],
    () => getEventById(eventId),
    {
      enabled: false,
    },
  )
  return eventById
}
