import React, { useState } from 'react'
import { Modal } from 'antd'
import closeIcon from 'assets/Images/close.svg'
import { Steps } from 'antd'
import './style/styles.scss'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import { defaultActivitiesResp } from 'types/event/events'
import { creationEventStore } from 'state/event/eventCreationStore'

const { Step } = Steps
interface AddActivityProps {
  modalOpen: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  data?: defaultActivitiesResp
}

const AddActivity: React.FC<AddActivityProps> = ({
  modalOpen,
  setModalOpen,
}) => {
  const [current, setCurrent] = useState<number>(0)
  const data: defaultActivitiesResp = creationEventStore(
    store => store.customActivity,
  )
  const setCustomActivity = creationEventStore(store => store.setCustomActivity)

  const handleCancel = () => {
    setCustomActivity(undefined)
    setCurrent(0)
    setModalOpen(false)
  }

  const handleBack = () => {
    if (current === 0) {
      handleCancel()
    } else {
      setCurrent(prev => prev - 1)
    }
  }

  const handleNext = () => {
    if (current === 3) {
      setCurrent(0)
      setCustomActivity(undefined)
      handleCancel()
    } else {
      setCurrent(prev => prev + 1)
    }
  }

  const steps = [
    {
      title: 'Step 1',
    },
    {
      title: 'Step 2',
    },
    {
      title: 'Step 3',
    },
    {
      title: 'Step 4',
    },
  ]

  return (
    <>
      <div>
        <Modal
          open={modalOpen}
          onCancel={handleCancel}
          className="select-activityModal"
          footer={false}
        >
          <div className="flex-center pt-20px justify-between ">
            <h2 className="w-600 text-18">Select activity</h2>
            <img
              src={closeIcon}
              alt="close-button"
              onClick={handleCancel}
              className="close button pointer"
            />
          </div>

          <div className="add-activity-steps">
            <Steps current={current}>
              {steps.map(item => (
                <Step key={item.title} className="stepper" title={item.title} />
              ))}
            </Steps>
          </div>
          <div className="add-activity-forms scroller h-27rem overflow-auto-y  mt-15px">
            {current === 0 ? (
              <Step1
                handleBack={handleBack}
                handleNext={handleNext}
                data={data}
              />
            ) : current === 1 ? (
              <Step2
                handleBack={handleBack}
                handleNext={handleNext}
                data={data}
              />
            ) : current === 2 ? (
              <Step3 handleBack={handleBack} handleNext={handleNext} />
            ) : current === 3 ? (
              <Step4 handleBack={handleBack} handleNext={handleNext} />
            ) : null}
          </div>
        </Modal>
      </div>
    </>
  )
}

export default AddActivity
