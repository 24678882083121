import { create } from 'zustand'
import { ColorListing } from 'types/listing/listings'

const colorListingStore = (set: any) => ({
  addColorModal: false,
  setAddColorModal: (value: boolean) => set({ addColorModal: value }),
  colorListingData: null,
  setColorListingData: (value: ColorListing) =>
    set({ colorListingData: value }),
})

export const colorStore = create(colorListingStore)
