import CustomModal from 'components/global/CustomModal'
import CustomModalFooter from 'components/global/CustomModalFooter'
import React, { useEffect } from 'react'
import { kidStore } from 'state/family/kid'
import AdultSelect from './ModalContent/AdultSelect'
import { infoMessage } from 'api/global'
import { useGetKidById } from 'api/family/kid'
import { useParams } from 'react-router-dom'
import { myStore } from 'state/store'
import { useAddAdultToKid } from 'api/family/adultRole'

interface SelecteAdultProps {
  kidId: string
}

const SelectAdult: React.FC<SelecteAdultProps> = ({ kidId }) => {
  const {
    adultSelectModal,
    setAdultSelectModal,
    addedAdults,
    setAddedAdults,
    deletedAdults,
    setDeletedAdults,
    selectedAdults,
    setSelectedAdults,
  } = kidStore(store => ({
    adultSelectModal: store.adultSelectModal,
    setAdultSelectModal: store.setAdultSelectModal,
    addedAdults: store.addedAdults,
    setAddedAdults: store.setAddedAdults,
    deletedAdults: store.deletedAdults,
    setDeletedAdults: store.setDeletedAdults,
    selectedAdults: store.selectedAdults,
    setSelectedAdults: store.setSelectedAdults,
  }))
  const { data: kidData } = useGetKidById(kidId)
  const { familyId } = kidData
  const setModalBtnLoader = myStore(store => store.setModalBtnLoader)
  const { mutate: addAdultsToKidMutation, isLoading: addAdultMutationLoading } =
    useAddAdultToKid()
  const handleClose = () => {
    setAdultSelectModal(false)
  }
  const handleSubmit = () => {
    // if (!addedAdults.length && !deletedAdults.length) {
    //   return infoMessage(
    //     'To perform this action you must have to select or unselect atleast one adult',
    //   )
    // }
    if (!selectedAdults.length) {
      return infoMessage(
        'To perform this action you must have to select or unselect atleast one adult',
      )
    }

    addAdultsToKidMutation(
      {
        kidId,
        payload: {
          adultIds: selectedAdults,
        },
      },
      {
        onSuccess: () => {
          setSelectedAdults([])
          setAddedAdults([])
          setDeletedAdults([])
          setAdultSelectModal(false)
        },
        onError: () => {
          setAddedAdults([])
          setDeletedAdults([])
        },
      },
    )
  }

  useEffect(() => {
    setModalBtnLoader(addAdultMutationLoading)
  }, [addAdultMutationLoading])

  return (
    <>
      <CustomModal
        openModal={adultSelectModal}
        closeModal={setAdultSelectModal}
        title="Select adults"
        footer={
          <CustomModalFooter
            multibutton
            btnAlignment="justify-between"
            btnText="Close,Assign"
            closeModal={handleClose}
            onSubmit={handleSubmit}
          />
        }
      >
        <AdultSelect familyId={familyId} />
      </CustomModal>
    </>
  )
}

export default SelectAdult
