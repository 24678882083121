import { Col, Radio, RadioChangeEvent, Row } from 'antd'
import { useChangeExternalStatus } from 'api/External/External'
import CustomModal from 'components/global/CustomModal'
import CustomModalFooter from 'components/global/CustomModalFooter'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { externalStore } from 'state/event/external'
import { myStore } from 'state/store'
import { EventStatus } from 'types/event/eventStep5'

const StatusModal: React.FC = () => {
  const history = useHistory()
  const setModalBtnLoader = myStore(store => store.setModalBtnLoader)
  const { statusModalVisible, setStatusModalVisible, externalData } =
    externalStore(store => ({
      statusModalVisible: store.statusModalVisible,
      setStatusModalVisible: store.setStatusModalVisible,
      externalData: store.externalData,
    }))
  const [externalStatus, setExternalStatus] = useState<EventStatus>(
    externalData?.status || 'draft',
  )
  const { mutate: changeExternalStatus } = useChangeExternalStatus()

  const handleRadioChange = (e: RadioChangeEvent) => {
    setExternalStatus(e.target.value)
  }
  const handleSubmit = () => {
    setModalBtnLoader(true)
    changeExternalStatus(
      { payload: { status: externalStatus }, externalId: externalData._id },
      {
        onSuccess: () => {
          setModalBtnLoader(false)
          history.push(`/events`)
          setStatusModalVisible(false)
        },
      },
    )
  }
  return (
    <>
      <CustomModal
        openModal={statusModalVisible}
        closeModal={setStatusModalVisible}
        title="Status"
        footer={
          <CustomModalFooter
            multibutton={false}
            btnAlignment="justify-end"
            btnText="Create external"
            btnType="primary"
            onSubmit={handleSubmit}
          />
        }
      >
        <div>
          <Radio.Group
            defaultValue={externalStatus}
            onChange={handleRadioChange}
          >
            <Row>
              <Col className="d-flex justify-between align-items-start mt-20">
                <div>
                  <h2 className="text-16 w-600">Draft</h2>
                  <p className="text-14 w-400 text-gray mt-5px">
                    Only visible to organizers
                  </p>
                </div>
                <Radio className="radio mr-0" value={'draft'} />
              </Col>
            </Row>
            {externalData.organization.status === 'approved' && (
              <div>
                <Row>
                  <Col className="d-flex justify-between align-items-start mt-20">
                    <div>
                      <h2 className="text-16 w-600">Private</h2>
                      <p className="text-14 w-400 text-gray mt-5px">
                        Only visible by shared link
                      </p>
                    </div>
                    <Radio className="radio mr-0" value={'private'} />
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-between align-items-start mt-20">
                    <div>
                      <h2 className="text-16 w-600">Following</h2>
                      <p className="text-14 w-400 text-gray mt-5px">
                        Visible to all families following the organization
                      </p>
                    </div>
                    <Radio className="radio mr-0" value={'following'} />
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-between align-items-start mt-20">
                    <div>
                      <h2 className="text-16 w-600">Subscribed</h2>
                      <p className="text-14 w-400 text-gray mt-5px">
                        Visible to all families subscribed and approved the
                        organization
                      </p>
                    </div>
                    <Radio className="radio mr-0" value={'subscribed'} />
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-between align-items-start mt-20">
                    <div>
                      <h2 className="text-16 w-600">Mentor social</h2>
                      <p className="text-14 w-400 text-gray mt-5px">
                        Visible to Mentors on their separate app
                      </p>
                    </div>
                    <Radio className="radio mr-0" value={'mentor_social'} />
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-between align-items-start mt-20">
                    <div>
                      <h2 className="text-16 w-600">Public</h2>
                      <p className="text-14 w-400 text-gray mt-5px">
                        Visible to all families
                      </p>
                    </div>
                    <Radio className="radio mr-0" value={'public'} />
                  </Col>
                </Row>
              </div>
            )}
          </Radio.Group>
        </div>
      </CustomModal>
    </>
  )
}

export default StatusModal
