import React from 'react'
import { Checkbox, Col, Form, FormInstance, Input, Row } from 'antd'

interface BackToBackProps {
  form: FormInstance
  data: any | undefined // Replace `any` with a more specific type if available
}

const BackToBack: React.FC<BackToBackProps> = ({ form, data }) => {
  return (
    <Row className="mt-15px">
      <Col span={24}>
        <label className="input-label text-16">Max Back-to-back Games</label>
        <Form.Item
          name={['maxBackToBackGames', 'noOfGames']}
          rules={[{ required: true, message: 'Number of games is required' }]}
          className="mt-10px"
          style={{ width: '130px' }}
        >
          <Input
            type="number"
            placeholder="0"
            className="text-end radius-12px"
            onWheel={e => e.currentTarget.blur()}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <div className="d-flex align-items-center checkbox-container">
          <Form.Item
            name={['maxBackToBackGames', 'skipForPlayOff']}
            valuePropName="checked"
          >
            <Checkbox className="checkbox-style">
              <div className="w-600 text-14 mt-8px">Skip for play offs</div>
            </Checkbox>
          </Form.Item>
        </div>
      </Col>
    </Row>
  )
}

export default BackToBack
