import React from 'react'
import { Col, Form, FormInstance, Input, Row } from 'antd'
import { myStore } from 'state/store'
import { goalStore } from 'state/listing/GoalListing'
import { useCreateGoal, useUpdateGoal } from 'api/Listings/Goal'
import { useUpdateGrade } from 'api/Listings/Grades'

interface GoalFormProps {
  form: FormInstance
}

const GoalForm: React.FC<GoalFormProps> = ({ form }) => {
  const setModalBtnLoading = myStore(store => store.setModalBtnLoader)
  const { setAddGoalModal, goalData } = goalStore(store => ({
    setAddGoalModal: store.setAddGoalModal,
    goalData: store.goalData,
  }))
  const { mutate: createGoal } = useCreateGoal()
  const { mutate: updateGoal } = useUpdateGoal()

  const formSchema = {
    name: goalData ? goalData.name : undefined,
  }

  const onFinish = (values: { name: string }) => {
    setModalBtnLoading(true)

    if (goalData) {
      updateGoal(
        { goalId: goalData._id, payload: values },
        {
          onSuccess: () => {
            setModalBtnLoading(false)
            setAddGoalModal(false)
          },
          onError: () => {
            setModalBtnLoading(false)
          },
        },
      )
    } else {
      createGoal(values, {
        onSuccess: () => {
          setModalBtnLoading(false)
          setAddGoalModal(false)
        },
        onError: () => {
          setModalBtnLoading(false)
        },
      })
    }
  }
  return (
    <div>
      <Form
        name="grade-form"
        initialValues={formSchema}
        form={form}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
      >
        <Row className="mt-10px">
          <Col span={24}>
            <label className="input-label required-input">Name</label>
            <Form.Item
              colon={false}
              name="name"
              rules={[{ required: true, message: 'Name required' }]}
            >
              <Input placeholder="Enter Name" required name="name" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default GoalForm
