import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { axiosGet, axiosPatch, axiosPost } from 'Redux/axiosHelper'
import {
  ConstaintStepStatusesResp,
  ConstraintDates,
  LeagueStep1Payload,
  LeagueStep2Payload,
  LeagueStep4Payload,
  LeagueStep7Payload,
  TravelConstraintType,
} from 'types/league/league'

// create league step 1
const createLeague = async (payload: LeagueStep1Payload) => {
  const response = await axiosPost(payload, `v1/league/step-1`)
  return response.data
}

export const useCreateLeague = () => {
  const queryClient = useQueryClient()
  return useMutation(createLeague)
}

// edit league step 1
const updateLeague = async ({
  leagueId,
  payload,
}: {
  leagueId: string
  payload: LeagueStep1Payload
}) => {
  const response = await axiosPatch(payload, `v1/league/step-1/${leagueId}`)
  return response.data
}

export const useUpdateLeague = () => {
  const queryClient = useQueryClient()
  return useMutation(updateLeague)
}

// update league step 2
const leagueStep2 = async ({
  leagueId,
  payload,
}: {
  leagueId: string
  payload: LeagueStep2Payload
}) => {
  const response = await axiosPatch(payload, `v1/league/step-2/${leagueId}`)
  return response.data
}

export const useLeagueStep2 = () => {
  const queryClient = useQueryClient()
  return useMutation(leagueStep2)
}

// update league step 4 - time slots
const leagueStep4 = async ({
  payload,
  leagueId,
}: {
  payload: LeagueStep4Payload
  leagueId: string
}) => {
  const response = await axiosPatch(
    payload,
    `v1/league/step-4/time-slots/${leagueId}`,
  )
  return response.data
}

export const useLeagueStep4 = () => {
  const queryClient = useQueryClient()
  return useMutation(leagueStep4)
}

// update leageue step 7 game constraints
const leagueStep7 = async ({
  leagueId,
  payload,
}: {
  leagueId: string
  payload: LeagueStep7Payload
}) => {
  const response = await axiosPatch(
    payload,
    `v1/league/step-7/game-constraints/${leagueId}`,
  )
  return response.data
}
export const useLeagueStep7 = () => {
  const queryClient = useQueryClient()
  return useMutation(leagueStep7, {
    onSuccess: () => {
      queryClient.setQueryData(
        ['league-onstraints-status'],
        (oldData: ConstaintStepStatusesResp | undefined) => {
          if (!oldData)
            return {
              gameConstraints: true,
            }
          return {
            ...oldData,
            gameConstraints: true,
          }
        },
      )
    },
  })
}

// update league step 8 - travel constraints
const leagueStep8 = async ({
  leagueId,
  payload,
}: {
  leagueId: string
  payload: TravelConstraintType
}) => {
  const response = await axiosPatch(
    payload,
    `v1/league/step-8/travel-constraints/${leagueId}`,
  )
  return response.data
}
export const useLeagueStep8 = () => {
  const queryClient = useQueryClient()
  return useMutation(leagueStep8, {
    onSuccess: () => {
      queryClient.setQueryData(
        ['league-onstraints-status'],
        (oldData: ConstaintStepStatusesResp | undefined) => {
          if (!oldData)
            return {
              travelConstraints: true,
            }
          return {
            ...oldData,
            travelConstraints: true,
          }
        },
      )
    },
  })
}

// update league step 9 - dates
const leagueStep9 = async ({
  leagueId,
  payload,
}: {
  leagueId: string
  payload: ConstraintDates
}) => {
  const response = await axiosPatch(
    payload,
    `v1/league/step-9/dates/${leagueId}`,
  )
  return response.data
}

export const useLeagueStep9 = () => {
  const queryClient = useQueryClient()
  return useMutation(leagueStep9, {
    onSuccess: () => {
      queryClient.setQueryData(
        ['league-onstraints-status'],
        (oldData: ConstaintStepStatusesResp | undefined) => {
          if (!oldData)
            return {
              constraintDates: true,
            }
          return {
            ...oldData,
            constraintDates: true,
          }
        },
      )
    },
  })
}

// get league constraints status
const leagueConstraintsStatus = async (leagueId: string) => {
  const response = await axiosGet(`v1/league/${leagueId}/schedule-steps-status`)
  return response.data
}
export const useLeagueConstraintsStatus = (leagueId: string) => {
  return useQuery<ConstaintStepStatusesResp>(
    ['league-onstraints-status'],
    () => leagueConstraintsStatus(leagueId),
    { enabled: false },
  )
}
