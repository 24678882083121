import React from 'react'
import CustomModal from 'components/global/CustomModal'
import { leagueEventStore } from 'state/event/leagueEvent'
import CustomModalFooter from 'components/global/CustomModalFooter'
import useFormSubmit from 'helper/Hooks/formSubmit'
import AddItemContent from './ModalContent/AddItemContent'

const AddItemModal: React.FC = () => {
  const { form, handleSubmit } = useFormSubmit()
  const { addItemModal, setAddItemModal } = leagueEventStore(store => ({
    addItemModal: store.addItemModal,
    setAddItemModal: store.setAddItemModal,
  }))
  return (
    <>
      <CustomModal
        openModal={addItemModal}
        closeModal={setAddItemModal}
        title="Add item to bring"
        footer={
          <CustomModalFooter
            multibutton={false}
            btnText="Save"
            btnAlignment="justify-end"
            btnType="primary"
            onSubmit={handleSubmit}
          />
        }
      >
        <AddItemContent form={form} />
      </CustomModal>
    </>
  )
}

export default AddItemModal
