import React, { useState } from 'react'
import { Row, Col } from 'antd'
import SelectOrganizationModel from './SelectOrganizationModal'

interface Organization {
  name: string
  id: string
}
interface OrganizationInputTypes {
  selectedOrganization: Organization
  setSelectedOrganization: React.Dispatch<React.SetStateAction<Organization>>
  isDisabled?: boolean
}

const OrganizationInput: React.FC<OrganizationInputTypes> = ({
  selectedOrganization,
  setSelectedOrganization,
  isDisabled,
}) => {
  const [selectOrganizationModal, setSelectOrganizationModal] =
    useState<boolean>(false)
  return (
    <>
      <Row>
        <Col>
          <label className="input-label required-input">
            Select organization
          </label>
        </Col>
        <div
          onClick={() => {
            !isDisabled && setSelectOrganizationModal(true)
          }}
          className={`w-full border-1px radius-10 flex align-center px-12px pointer h-50px  mb-10px ${
            !selectedOrganization && 'text-gray'
          } ${isDisabled ? 'bg-grey disabled' : 'cursor-pointer'}`}
        >
          {selectedOrganization ? selectedOrganization?.name : 'Select'}
        </div>
      </Row>

      {selectOrganizationModal && (
        <SelectOrganizationModel
          selectOrganizationModal={selectOrganizationModal}
          setSelectOrganizationModal={setSelectOrganizationModal}
          setSelectedOrganization={setSelectedOrganization}
        />
      )}
    </>
  )
}

export default OrganizationInput
