import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { axiosGet, axiosPatch, axiosPost } from 'Redux/axiosHelper'
import { ColorListing, CreateColorPayload } from 'types/listing/listings'

const getColors = async () => {
  const response = await axiosGet(`v1/colors`)
  return response.data
}

export const useGetColors = () => {
  const colors = useQuery<ColorListing[]>(['all-colors-listing'], getColors, {
    enabled: false,
  })
  return colors
}

// create new color in listing
const createColor = async (payload: CreateColorPayload) => {
  const response = await axiosPost(payload, `v1/colors`)
  return response.data
}

export const useCreateColor = () => {
  const queryClient = useQueryClient()
  return useMutation(createColor, {
    onSuccess: data => {
      queryClient.setQueryData(
        ['all-colors-listing'],
        (oldData: ColorListing[] | undefined) => {
          if (!oldData) return [data]
          return [...oldData, data]
        },
      )
    },
  })
}

// update colro
const updateColor = async ({
  colorId,
  payload,
}: {
  colorId: string
  payload: CreateColorPayload
}) => {
  const response = await axiosPatch(payload, `v1/colors/${colorId}`)
  return response.data
}
export const useUpdateColor = () => {
  const queryClient = useQueryClient()
  return useMutation(updateColor, {
    onSuccess: updatedData => {
      queryClient.setQueryData(
        ['all-colors-listing'],
        (oldData: ColorListing[] | undefined) => {
          if (!oldData) return [updatedData]
          return oldData.map((color: ColorListing) =>
            color._id === updatedData._id ? updatedData : color,
          )
        },
      )
    },
  })
}
