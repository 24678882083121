import React, { useEffect, useState } from 'react'
import { Key, PencilSimpleLine, Trash } from '@phosphor-icons/react'
import { Table } from 'antd'
import Spinner from 'components/Spinner'
import { useGetListings } from 'api/Listings/Skill'
import { SkillListing } from 'types/listing/listings'
import { relationStore } from 'state/listing/relationListing'
import { skillCreateStore } from 'state/listing/skillListing'

interface ListingTableProps {
  id: string
}

const ListingTable: React.FC<ListingTableProps> = ({ id }) => {
  const { data, isLoading, isFetching, refetch } = useGetListings()
  const [tableData, setTableData] = useState([])
  const { setAddSkillModal, setSkillData } = skillCreateStore(store => ({
    setAddSkillModal: store.setAddSkillModal,
    setSkillData: store.setSkillData,
  }))

  const handleEditClick = (data: SkillListing) => {
    setSkillData(data)
    setAddSkillModal(true)
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => <p className="text-14 w-400">{text}</p>,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: (text: string) => <p className="text-14 w-400">{text}</p>,
    },

    {
      title: 'Icon',
      dataIndex: 'icon',
      key: 'icon',
      render: (text: string) => (
        <img src={text} alt="icon" className="w-30px h-30px radius-rounded" />
      ),
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (text: string) => <p className="text-14 w-400">${text}</p>,
    },
    {
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
      render: (text: string) => (
        <div
          className="w-30px h-30px radius-rounded"
          style={{ backgroundColor: `#${text}` }}
        />
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      render: (data: SkillListing) => (
        <div className="flex align-center gap-20px">
          <PencilSimpleLine
            size={20}
            color="#000"
            onClick={() => handleEditClick(data)}
          />
        </div>
      ),
    },
  ]

  useEffect(() => {
    refetch()
  }, [id])

  useEffect(() => {
    if (data) {
      const enrichedData = data.map((skill: SkillListing) => {
        return {
          Key: skill._id,
          name: skill.name,
          category: skill.category,
          icon: skill.icon.post,
          price: skill.price,
          color: skill.color,
          actions: skill,
        }
      })

      setTableData(enrichedData)
    }
  }, [data])

  return (
    <div>
      {isLoading || isFetching ? (
        <div className="w-full h-70vh flex align-center justify-center">
          <Spinner />
        </div>
      ) : (
        <Table
          className="colored_header_table"
          columns={columns}
          dataSource={tableData}
          pagination={false}
        />
      )}
    </div>
  )
}

export default ListingTable
