import { DivisonListingResp } from 'types/league/division'
import { OfficialConstraintResp } from 'types/league/officialConstraints'
import { TeamConstraintType } from 'types/league/teamConstraints'
import { create } from 'zustand'

interface SelectedItem {
  index: number
  item: string
}

const leagueEventStoreState = (set: any) => ({
  // counters and updates
  leagueStepCounter: 5,
  setLeagueStepCounter: (state: number) =>
    set(() => ({
      leagueStepCounter: state,
    })),
  isUpdatingDivision: false,
  setIsUpdatingDivision: (state: boolean) =>
    set(() => ({ isUpdatingDivision: state })),

  // datasets
  leagueData: null,
  setLeagueData: (state: any) =>
    set(() => ({
      leagueData: state,
    })),
  divisionData: null,
  setDivisionData: (state: DivisonListingResp) =>
    set(() => ({ divisionData: state })),
  timeSlots: null,
  setTimeSlots: (state: any) =>
    set(() => ({
      timeSlots: state,
    })),
  regions: [],
  setRegions: (state: string[]) => set(() => ({ regions: state })),

  // step 6 communication
  itemsToBring: [],
  setItemsToBring: (state: string[]) => set(() => ({ itemsToBring: state })),
  selectedItemToBring: undefined,
  setSelectedItemToBring: (state: undefined | SelectedItem) =>
    set(() => ({ selectedItemToBring: state })),
  // constraints
  teamConstraintData: null,
  setTeamConstraintData: (state: TeamConstraintType) =>
    set(() => ({ teamConstraintData: state })),
  divisionConstraintData: null,
  setDivisionConstraintData: (state: any) =>
    set(() => ({ divisionConstraintData: state })),
  officialData: null,
  setOfficialData: (state: OfficialConstraintResp) =>
    set(() => ({
      officialData: state,
    })),

  // modal states
  addDevisionModal: false,
  setAddDevisonModal: (state: boolean) =>
    set(() => ({ addDevisionModal: state })),
  addTimeModal: false,
  setAddTimeModal: (state: boolean) => set(() => ({ addTimeModal: state })),
  copyTimeModal: false,
  setCopyTimeModal: (state: boolean) => set(() => ({ copyTimeModal: state })),
  teamConstraintModal: false,
  setTeamConstraintModal: (state: boolean) =>
    set(() => ({ teamConstraintModal: state })),
  addTeamConstraintModal: false,
  setAddTeamConstraintModal: (state: boolean) =>
    set(() => ({ addTeamConstraintModal: state })),
  divisionConstraintModal: false,
  setDivisionConstraintModal: (state: boolean) =>
    set(() => ({ divisionConstraintModal: state })),
  addDivisionConstraintModal: false,
  setAddDivisionConstraintModal: (state: boolean) =>
    set(() => ({ addDivisionConstraintModal: state })),
  gameConstraintModal: false,
  setgameConstraintModal: (state: boolean) =>
    set(() => ({ gameConstraintModal: state })),
  travelConstraintModal: false,
  setTravelConstraintModal: (state: boolean) =>
    set(() => ({
      travelConstraintModal: state,
    })),
  officialModal: false,
  setOfficialModal: (state: boolean) =>
    set(() => ({
      officialModal: state,
    })),
  addOfficialModal: false,
  setAddOfficialModal: (state: boolean) =>
    set(() => ({
      addOfficialModal: state,
    })),
  datesConstraints: false,
  setDatesConstraints: (state: boolean) =>
    set(() => ({
      datesConstraints: state,
    })),
  addRegionModal: false,
  setAddRegionModal: (state: boolean) =>
    set(() => ({
      addRegionModal: state,
    })),
  divisionNameModal: false,
  setDivisionNameModal: (state: boolean) =>
    set(() => ({
      divisionNameModal: state,
    })),
  divisionPriceModal: false,
  setDivisionPriceModal: (state: boolean) =>
    set(() => ({
      divisionPriceModal: state,
    })),
  itemsToBringModal: false,
  setItemsToBringModal: (state: boolean) =>
    set(() => ({ itemsToBringModal: state })),
  addItemModal: false,
  setAddItemModal: (state: boolean) => set(() => ({ addItemModal: state })),
  // ohterstates
})

export const leagueEventStore = create(leagueEventStoreState)
