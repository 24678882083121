import React, { useEffect } from 'react'
import { Col, Form, FormInstance, Input, Row, Switch } from 'antd'
import { leagueEventStore } from 'state/event/leagueEvent'
import { useLeagueStep8 } from 'api/LeagueCreation/leagueCreation'
import { TravelConstraintType } from 'types/league/league'
import { myStore } from 'state/store'

interface AddTravelConstraintFormProps {
  form: FormInstance
}

const AddTravelConstraintForm: React.FC<AddTravelConstraintFormProps> = ({
  form,
}) => {
  const { leagueData, setLeagueData, setTravelConstraintModal } =
    leagueEventStore(store => ({
      leagueData: store.leagueData,
      setLeagueData: store.setLeagueData,
      setTravelConstraintModal: store.setTravelConstraintModal,
    }))
  const setModalBtnLoader = myStore(store => store.setModalBtnLoader)

  const {
    mutate: addTravelConstraintMutation,
    isLoading: isAddingTravelConstraint,
  } = useLeagueStep8()

  const formSchema = {
    travelConstraints: {
      betweenGames: {
        hours: leagueData.travelConstraints?.betweenGames
          ? leagueData.travelConstraints.betweenGames.hours
          : undefined,
        mins: leagueData.travelConstraints?.betweenGames
          ? leagueData.travelConstraints?.betweenGames.mins
          : undefined,
      },
      daily: {
        hours: leagueData.travelConstraints?.daily
          ? leagueData.travelConstraints.daily.hours
          : undefined,
        mins: leagueData.travelConstraints?.daily
          ? leagueData.travelConstraints.daily.mins
          : undefined,
      },
      forCoaches: leagueData.travelConstraints?.forCoaches
        ? leagueData.travelConstraints.forCoaches
        : false,
    },
  }

  const onFinish = (values: TravelConstraintType) => {
    addTravelConstraintMutation(
      { leagueId: leagueData._id, payload: values },
      {
        onSuccess: data => {
          setLeagueData({
            ...leagueData,
            travelConstraints: data.travelConstraints,
            statuses: data.statuses,
          })
          setTravelConstraintModal(false)
        },
      },
    )
  }

  useEffect(() => {
    setModalBtnLoader(isAddingTravelConstraint)
  }, [isAddingTravelConstraint, setModalBtnLoader])

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={formSchema}
      onFinish={onFinish}
    >
      <p className="text-gray">
        Enable this constraint to account for travel times. Games will be
        scheduled apart to allow teams to travel between venues.
      </p>

      <div className="flex align-center justify-between py-10px mt-15px">
        <label className="input-label text-16 mb-10px">Travel constraint</label>
        <Form.Item name="travelConstraint" valuePropName="checked">
          <Switch />
        </Form.Item>
      </div>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.travelConstraint !== currentValues.travelConstraint
        }
      >
        {({ getFieldValue }) =>
          getFieldValue('travelConstraint') && (
            <>
              <p className="text-gray mt-5px">
                Games will be scheduled apart to allow travel between venues.
              </p>

              <Row className="mt-20px">
                <Col>
                  <div className="flex gap-60px">
                    <span className="text-14 w-600 pt-10px pr-20px">
                      Max travel time between games:
                    </span>
                    <div className="flex align-center justify-between gap-16px">
                      {['hours', 'mins'].map(unit => (
                        <Form.Item
                          key={`max-${unit}`}
                          className="form-control mb-0"
                          style={{ maxWidth: '130px' }}
                        >
                          <div className="flex flex-col align-center gap-5px">
                            <Form.Item
                              name={['travelConstraints', 'betweenGames', unit]}
                              rules={[
                                {
                                  required: true,
                                  message: `Maximum ${unit} is required`,
                                },
                              ]}
                              noStyle
                            >
                              <Input
                                type="number"
                                placeholder="0"
                                className="text-end radius-12px"
                                onWheel={e => e.currentTarget.blur()}
                              />
                            </Form.Item>
                            <p className="input-label">
                              {unit.charAt(0).toUpperCase() + unit.slice(1)}
                            </p>
                          </div>
                        </Form.Item>
                      ))}
                    </div>
                  </div>

                  <div className="flex justify-between gap-60px mt-10px">
                    <span className="text-14 pt-15px w-600 pr-20px">
                      Max daily travel time:
                    </span>
                    <div
                      className="flex align-center justify-between gap-16px"
                      style={{ width: '240px' }}
                    >
                      {['hours', 'mins'].map(unit => (
                        <Form.Item
                          key={`max-daily-${unit}`}
                          className="form-control mb-0"
                          style={{ maxWidth: '130px' }}
                        >
                          <div className="flex flex-col align-center gap-5px">
                            <Form.Item
                              name={['travelConstraints', 'daily', unit]}
                              rules={[
                                {
                                  required: true,
                                  message: `Maximum ${unit} is required`,
                                },
                              ]}
                              noStyle
                            >
                              <Input
                                type="number"
                                placeholder="0"
                                className="text-end radius-12px"
                                onWheel={e => e.currentTarget.blur()}
                              />
                            </Form.Item>
                            <p className="input-label">
                              {unit.charAt(0).toUpperCase() + unit.slice(1)}
                            </p>
                          </div>
                        </Form.Item>
                      ))}
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="flex align-center justify-between py-10px mt-15px">
                <label className="input-label text-16 mb-10px">
                  Enable for coaches
                </label>
                <Form.Item name={['travelConstraints', 'forCoaches']}>
                  <Switch />
                </Form.Item>
              </div>

              <p className="text-gray mt-5px">
                These travel constraints will also apply to games with the same
                coach
              </p>
            </>
          )
        }
      </Form.Item>
    </Form>
  )
}

export default AddTravelConstraintForm
