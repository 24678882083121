import { Button } from 'antd'
import React from 'react'
import { teamStore } from 'state/team/team'

const TeamPageHeader: React.FC = () => {
  const { setTeamModalVisible, setTeamData, setIsUpdatingTeam } = teamStore(
    store => ({
      setTeamModalVisible: store.setTeamModalVisible,
      setTeamData: store.setTeamData,
      setIsUpdatingTeam: store.setIsUpdatingTeam,
    }),
  )

  const handleNewClick = () => {
    setTeamData(null)
    setIsUpdatingTeam(false)
    setTeamModalVisible(true)
  }

  return (
    <div className="flex align-center justify-between">
      <div>
        <h1 className="table-head mb-0">Teams</h1>
      </div>
      <div>
        <Button className={'btn-new primary br-8'} onClick={handleNewClick}>
          New Team
        </Button>
      </div>
    </div>
  )
}

export default TeamPageHeader
