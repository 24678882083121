import React, { useEffect, useState } from 'react'
import { MagnifyingGlass, PlusCircle, Spinner, X } from '@phosphor-icons/react'
import { Col, Row, Tabs } from 'antd'
import CustomSelectMenu from 'components/global/CustomSelectMenu'
import Attendees from './Attendees'
import { EventResp } from 'types/event/events'
import { useGetEventParticipants } from 'api/Events'
import { failureMessage } from 'api/global'
import { Person } from 'types/globalTypes'
import { eventShatStore } from 'state/event/eventChat'

interface RecepientInputProps {
  eventData: EventResp
  contactType: 'chat' | 'sms' | 'email'
}

const RecepientInput: React.FC<RecepientInputProps> = ({
  eventData,
  contactType,
}) => {
  const { TabPane } = Tabs
  const [open, setOpen] = useState<boolean>(false)
  const [activeKeyTab, setActiveKeyTab] = useState<string>('1')
  const [participants, setParticipants] = useState<Person[]>([])
  const {
    selectedParticipants,
    setSelectedParticipants,
    selectedParticipantsData,
    setSelectedParticipantsData,
  } = eventShatStore(store => ({
    selectedParticipants: store.selectedParticipants,
    setSelectedParticipants: store.setSelectedParticipants,
    selectedParticipantsData: store.selectedParticipantsData,
    setSelectedParticipantsData: store.setSelectedParticipantsData,
  }))

  const onChange = (key: string) => {
    setActiveKeyTab(key)
  }

  const {
    data: eventParticipantsData,
    isLoading: isEventParticipantsLoading,
    isFetching: isEventParticipantsFetching,
    refetch: refetchEventParticipants,
  } = useGetEventParticipants(eventData._id)

  const handleRemove = (id: string) => {
    setSelectedParticipants(selectedParticipants.filter(item => item !== id))
    setSelectedParticipantsData(
      selectedParticipantsData.filter(item => item._id !== id),
    )
  }

  const menuItems = [
    {
      key: 'search',
      label: (
        <>
          <div className="flex align-center gap-10px border-btm">
            <MagnifyingGlass size={20} color="#000" />
            <input type="text" className="w-full border-none" />
          </div>
          <div className="mt-10px">
            <Tabs defaultActiveKey={activeKeyTab} onChange={onChange}>
              <TabPane tab="Attendees" key="1">
                <>
                  {isEventParticipantsLoading || isEventParticipantsFetching ? (
                    <div className="py-40px">
                      <Spinner />
                    </div>
                  ) : (
                    <Attendees
                      participants={participants}
                      setOpen={setOpen}
                      contactType={contactType}
                    />
                  )}
                </>
              </TabPane>
              <TabPane tab="Guardians" key="2">
                <Attendees
                  participants={participants}
                  setOpen={setOpen}
                  contactType={contactType}
                />
              </TabPane>
              <TabPane tab="Mentors" key="3">
                <Attendees
                  participants={participants}
                  setOpen={setOpen}
                  contactType={contactType}
                />
              </TabPane>
            </Tabs>
          </div>
        </>
      ),
    },
  ]

  useEffect(() => {
    if (!eventData) {
      return failureMessage('Event not found')
    }
    refetchEventParticipants()
  }, [eventData])

  useEffect(() => {
    if (eventParticipantsData) {
      setParticipants([
        ...eventParticipantsData.kids.map(kid => ({ ...kid, role: 'kid' })),
        ...eventParticipantsData.guardians.map(guardian => ({
          ...guardian,
          role: 'guardian',
        })),
        ...eventParticipantsData.authorizedAdults.map(adult => ({
          ...adult,
          role: 'authorizedAdult',
        })),
      ])
    }
  }, [eventParticipantsData])

  return (
    <Row className="mt-10px">
      <Col>
        <label className="input-label">Recipients</label>
        <CustomSelectMenu open={open} setOpen={setOpen} menuItems={menuItems}>
          <div
            className="w-full flex align-center justify-between gap-20px mt-5px border-1px radius-12 px-15px h-50px"
            onClick={() => setOpen(true)}
          >
            <div className="w-90p">
              <span className="text-14 text-gray">
                {!selectedParticipants.length ? (
                  'Selct recipients'
                ) : (
                  <div className="flex align-center gap-10px overflow-hidden">
                    {selectedParticipants.map((item, index) => {
                      return (
                        <div
                          className="px-5px py-5px bg-grey radius-full flex align-center gap-10px"
                          key={index}
                        >
                          <span>
                            {
                              participants.find(
                                participant => participant._id === item,
                              )?.firstName
                            }
                          </span>
                          <X
                            size={14}
                            color="#000"
                            onClick={() => handleRemove(item)}
                          />
                        </div>
                      )
                    })}
                  </div>
                )}
              </span>
            </div>
            <PlusCircle size={20} color="#000" />
          </div>
        </CustomSelectMenu>
      </Col>
    </Row>
  )
}

export default RecepientInput
