import IconsTextNavigation from 'components/global/IconsTextNavigation'
import React from 'react'
import { leagueEventStore } from 'state/event/leagueEvent'
import ItemsToBringModal from '../Modals/ItemsToBring/ItemsToBringModal'
import AddItemModal from '../Modals/ItemsToBring/AddItem'

const ItemsToBring: React.FC = () => {
  const {
    itemsToBringModal,
    setItemsToBringModal,
    itemsToBring,
    addItemModal,
  } = leagueEventStore(store => ({
    itemsToBringModal: store.itemsToBringModal,
    setItemsToBringModal: store.setItemsToBringModal,
    itemsToBring: store.itemsToBring,
    addItemModal: store.addItemModal,
  }))

  return (
    <>
      <div onClick={() => setItemsToBringModal(true)}>
        <IconsTextNavigation
          iconName="ShoppingBagOpen"
          heading="Items to bring"
          details={itemsToBring.length > 0 ? itemsToBring.length : 'No items'}
        />
      </div>
      {itemsToBringModal && <ItemsToBringModal />}
      {addItemModal && <AddItemModal />}
    </>
  )
}

export default ItemsToBring
