import React, { useEffect } from 'react'
import { FormInstance, Select } from 'antd'
import Spinner from 'components/Spinner'
import { SkillListing } from 'types/listing/listings'
import { useGetLeagueSkills } from 'api/Listings/LeagueSkills'

interface SkillLevelProps {
  data: any
  form: FormInstance
  multiple: boolean
}

const SkillLevel: React.FC<SkillLevelProps> = ({ data, form, multiple }) => {
  const {
    data: skillListData,
    isLoading,
    isFetching,
    refetch,
  } = useGetLeagueSkills()

  useEffect(() => {
    refetch()
  }, [])

  return (
    <div>
      <Select
        placeholder={'Select Age'}
        defaultValue={
          multiple
            ? data?.map((item: { _id: string; name: string }) => item._id)
            : data?._id
        }
        onChange={selectedValues => {
          form.setFieldsValue({
            [data ? 'skillLevelId' : 'skillLevelIds']: selectedValues,
          })
        }}
        mode={multiple ? 'multiple' : undefined}
        className="h-50px select-field"
      >
        {isLoading || isFetching ? (
          <div className="py-20px flex align-center justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            {skillListData.map((skill: SkillListing) => (
              <Select.Option key={skill._id} value={skill._id}>
                {skill?.name}
              </Select.Option>
            ))}
          </>
        )}
      </Select>
    </div>
  )
}

export default SkillLevel
