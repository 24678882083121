import { create } from 'zustand'
import { BasicListingType } from 'types/listing/listings'

const goalCreationStore = (set: any) => ({
  addGoalModal: false,
  setAddGoalModal: (state: boolean) => set(() => ({ addGoalModal: state })),
  goalData: null,
  setGoalData: (state: BasicListingType) => set(() => ({ goalData: state })),
})

export const goalStore = create(goalCreationStore)
