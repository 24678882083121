import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { axiosGet, axiosPatch, axiosPost } from 'Redux/axiosHelper'
import { BasicListingType } from 'types/listing/listings'

const getHighlightedElem = async () => {
  const response = await axiosGet(`v1/highlightedElement/`)
  return response.data
}
export const useGetHighlightedElem = () => {
  const highlightedElem = useQuery<BasicListingType[]>(
    ['all-highlighted-elem-listings'],
    getHighlightedElem,
    {
      enabled: false,
    },
  )
  return highlightedElem
}

// create new highlighted element
const createHighlightedElem = async (payload: { name: string }) => {
  const response = await axiosPost(payload, `v1/highlightedElement`)
  return response.data
}

export const useCreateHighlightedElem = () => {
  const queryClient = useQueryClient()
  return useMutation(createHighlightedElem, {
    onSuccess: (newHighlightedElem: BasicListingType) => {
      queryClient.setQueryData(
        ['all-highlighted-elem-listings'],
        (oldData: BasicListingType[] | undefined) => {
          if (!oldData) return [newHighlightedElem]
          return [...oldData, newHighlightedElem]
        },
      )
    },
  })
}

// update highlighted element
const updateHighlightedElem = async ({
  highlightedElemId,
  payload,
}: {
  highlightedElemId: string
  payload: { name: string }
}) => {
  const response = await axiosPatch(
    payload,
    `v1/highlightedElement/${highlightedElemId}`,
  )
  return response.data
}

export const useUpdateHighlightedElem = () => {
  const queryClient = useQueryClient()
  return useMutation(updateHighlightedElem, {
    onSuccess: updateHighlightedElem => {
      queryClient.setQueryData(
        ['all-highlighted-elem-listings'],
        (oldData: BasicListingType[] | undefined) => {
          if (!oldData) return [updateHighlightedElem]
          return oldData.map(highlightedElem =>
            highlightedElem._id === updateHighlightedElem._id
              ? updateHighlightedElem
              : highlightedElem,
          )
        },
      )
    },
  })
}
