import React from 'react'
import { Col, Form, FormInstance, Input, Row } from 'antd'
import { leagueEventStore } from 'state/event/leagueEvent'

interface AddItemContentProps {
  form: FormInstance
}

const AddItemContent: React.FC<AddItemContentProps> = ({ form }) => {
  const {
    itemsToBring,
    setItemsToBring,
    setItemsToBringModal,
    setAddItemModal,
    selectedItemToBring,
  } = leagueEventStore(store => ({
    itemsToBring: store.itemsToBring,
    setItemsToBring: store.setItemsToBring,
    setItemsToBringModal: store.setItemsToBringModal,
    setAddItemModal: store.setAddItemModal,
    selectedItemToBring: store.selectedItemToBring,
  }))
  const formSchema = {
    item: selectedItemToBring ? selectedItemToBring.item : '',
  }

  const onFinish = (values: { item: string }) => {
    if (selectedItemToBring) {
      itemsToBring[selectedItemToBring.index] = values.item
      setItemsToBring(itemsToBring)
    } else {
      setItemsToBring([...itemsToBring, values.item])
    }
    setItemsToBringModal(true)
    setAddItemModal(false)
  }

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        initialValues={formSchema}
        onFinish={onFinish}
      >
        <Row gutter={10}>
          <Col>
            <label className="input-label required-input">Item’s name</label>
            <Form.Item
              className="form-control"
              name="item"
              rules={[{ required: true, message: 'Item is required' }]}
            >
              <Input
                placeholder="Enter item’s name"
                className="form-control-field"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default AddItemContent
