import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { axiosGet, axiosPost, axiosPatch } from 'Redux/axiosHelper'
import { SkillListing, SkillPayload } from 'types/listing/listings'

const getListings = async () => {
  const response = await axiosGet(`v1/skill`)
  return response.data
}
export const useGetListings = () => {
  const listings = useQuery<SkillListing[]>(
    [`all-skill-listings`],
    getListings,
    { enabled: false },
  )
  return listings
}

// create new skill
const addNewSkill = async (payload: SkillPayload) => {
  const response = await axiosPost(payload, `v1/skill`)
  return response.data
}

export const useAddNewSkill = () => {
  const queryClient = useQueryClient()
  return useMutation(addNewSkill, {
    onSuccess: data => {
      queryClient.setQueryData(
        [`all-skill-listings`],
        (oldData: SkillListing[] | undefined) => {
          if (!oldData) return [data]
          return [...oldData, data]
        },
      )
    },
  })
}

// update skill
const updateSkill = async ({
  skillId,
  payload,
}: {
  skillId: string
  payload: SkillPayload
}) => {
  const response = await axiosPatch(payload, `v1/skill/${skillId}`)
  return response.data
}

export const useUpdateSkill = () => {
  const queryClient = useQueryClient()
  return useMutation(updateSkill, {
    onSuccess: (updatedData: SkillListing) => {
      queryClient.setQueryData(
        [`all-skill-listings`],
        (oldData: SkillListing[] | undefined) => {
          if (!oldData) return [updatedData]
          return oldData.map((skill: SkillListing) =>
            skill._id === updatedData._id ? updatedData : skill,
          )
        },
      )
    },
  })
}
