import { Col, Row } from 'antd'
import { useAddOrgLocation } from 'api/EventCreation/EventCreation'
import MapModal from 'helper/Map/MapModal'
import LocationListingModal from 'pages/Events/Views/CreateEvent/Screens/GeneralDetails/GeneralDetailModal/LocationListingModal'
import React, { useEffect, useState } from 'react'

const Location = ({ selectedOrganization, mapData, setMapModalData, form }) => {
  console.log(selectedOrganization, 'selectedOrganization....')
  const [activeKey, setActiveKey] = useState<string>('1')
  const [locationModalVisible, setLocationModalVisible] =
    useState<boolean>(false)
  const [mapModalVisible, setMapModalVisible] = useState<boolean>(false)
  const [isFromPreviousLoc, setIsFromPreviousLoc] = useState<boolean>(false)
  const { mutate, isLoading } = useAddOrgLocation()

  const setFieldsValue = (data: any) => {
    if (data?.address || data?.country) {
      form.setFieldsValue({ location: data?.title })
    } else {
      form.setFieldsValue({ categories: data?.categories })
    }
  }

  useEffect(() => {
    if (!mapData?._id && mapData) {
      mutate(
        { payload: mapData, organizationId: selectedOrganization?._id },
        {
          onSuccess: result => {
            setLocationModalVisible(false)
          },
        },
      )
    }
  }, [mapData])

  return (
    <>
      <Row className="mt-10px">
        <Col>
          <label className="required-input  input-label">Location</label>
        </Col>
        <Col>
          <div
            className="w-full border-1px radius-10 flex align-center px-12px pointer h-50px"
            onClick={() => setLocationModalVisible(true)}
          >
            {mapData ? (
              <div className=" w-full flex justify-between align-center ">
                <p className="text-gray">{`${mapData.title}: ${mapData.apartment}, ${mapData.city}, ${mapData.country}`}</p>
              </div>
            ) : (
              <p className="text-gray">Select Location</p>
            )}
          </div>
        </Col>
      </Row>

      {locationModalVisible && (
        <LocationListingModal
          isModalVisible={locationModalVisible}
          setModalVisible={setLocationModalVisible}
          organizationId={selectedOrganization?.id}
          activeKeyTab={activeKey}
          setDefaultTabKey={setActiveKey}
          setMapModalVisible={setMapModalVisible}
          setMapModalData={setMapModalData}
          setFieldsValue={setFieldsValue}
          setIsFromPreviousLoc={setIsFromPreviousLoc}
        />
      )}

      {mapModalVisible && (
        <MapModal
          mapData={isFromPreviousLoc && mapData}
          setMapModalVisible={setMapModalVisible}
          setMapModalData={setMapModalData}
          setFieldsValue={setFieldsValue}
        />
      )}
    </>
  )
}

export default Location
