import React, { useEffect, useState } from 'react'
import { Col, Form, FormInstance, Input, Row, Select } from 'antd'
import ColorsInput from 'components/global/inputs/ColorsInput'
import UploadDocument from 'components/global/UploadDocument'
import { Photo } from 'types/globalTypes'
import { skillCreateStore } from 'state/listing/skillListing'
import { useAddNewSkill, useUpdateSkill } from 'api/Listings/Skill'
import { SkillPayload } from 'types/listing/listings'
import { myStore } from 'state/store'

interface SkillFormProps {
  form: FormInstance
}

const SkillForm: React.FC<SkillFormProps> = ({ form }) => {
  const { skillData, setAddSkilModal } = skillCreateStore(store => ({
    skillData: store.skillData,
    setAddSkilModal: store.setAddSkillModal,
  }))
  const setModalBtnLoading = myStore(store => store.setModalBtnLoader)
  const [documentIds, setDocumentIds] = useState<string[]>(
    skillData ? [skillData.iconId] : [],
  )
  const [photos] = useState<Photo[]>(skillData ? [skillData.icon] : [])
  const [error, setError] = useState<string>('')

  const { mutate: addSkillMutation } = useAddNewSkill()
  const { mutate: updateSkillMutation } = useUpdateSkill()

  const formSchema = {
    name: skillData ? skillData.name : undefined,
    category: skillData ? skillData.category : undefined,
    color: skillData ? skillData.color : undefined,
    price: skillData ? skillData.price : undefined,
  }

  const onFinish = (values: Omit<SkillPayload, 'iconId'>) => {
    if (documentIds.length === 0) {
      setError('Icons is required')
      return
    }
    const payload = {
      ...values,
      iconId: documentIds[0],
    }
    setModalBtnLoading(true)
    if (skillData) {
      updateSkillMutation(
        {
          skillId: skillData._id,
          payload,
        },
        {
          onSuccess: () => {
            setModalBtnLoading(false)
            setAddSkilModal(false)
          },
        },
      )
    } else {
      addSkillMutation(payload, {
        onSuccess: () => {
          setModalBtnLoading(false)
          setAddSkilModal(false)
        },
        onError: () => {
          setModalBtnLoading(false)
        },
      })
    }
  }

  useEffect(() => {
    if (documentIds.length) {
      setError('')
    }
  }, [documentIds])

  return (
    <div>
      <Form
        name="skill-form"
        initialValues={formSchema}
        form={form}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
      >
        <Row>
          <Col>
            <label className="custom-field-label required-input">
              Add Icon
            </label>
          </Col>
          <Col span={12}>
            <UploadDocument
              documentIds={documentIds}
              setDocumentIds={setDocumentIds}
              multiple={false}
              docType="image/*"
              files={photos}
            />
          </Col>
          {error && (
            <Col className="-mt-20px" span={24}>
              <p className="ant-form-item-explain-error">{error}</p>
            </Col>
          )}
        </Row>

        <Row className="mt-10px">
          <Col span={24}>
            <label className="input-label required-input">Name</label>
            <Form.Item
              colon={false}
              name="name"
              rules={[{ required: true, message: 'Name required' }]}
            >
              <Input placeholder="Enter Name" required name="name" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label className="input-label required-input">Category</label>
          </Col>
          <Form.Item
            className="w-full"
            colon={false}
            name="category"
            rules={[{ required: true, message: 'Category is required' }]}
          >
            <Col span={24}>
              <Select
                className="h-50px select-field"
                placeholder={'Select color'}
                defaultValue={skillData ? skillData.category : undefined}
                onChange={(v, item: { value: string; key: string }) => {
                  form.setFieldsValue({
                    category: item.value,
                  })
                }}
              >
                <Select.Option key={'educational'} value={'educational'}>
                  <p>Educational</p>
                </Select.Option>
                <Select.Option key={'sports'} value={'sports'}>
                  <p>Sports</p>
                </Select.Option>
                <Select.Option key={'other'} value={'other'}>
                  <p>Other</p>
                </Select.Option>
              </Select>
            </Col>
          </Form.Item>
        </Row>

        <Row gutter={24} className="mt-10px">
          <Col span={12}>
            <label className="input-label required-input">Price</label>
            <Form.Item
              colon={false}
              name="price"
              rules={[{ required: true, message: 'Price is required' }]}
            >
              <Input
                placeholder="Enter Price"
                type="number"
                required
                name="price"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <label className="input-label required-input">Color</label>
            <Form.Item
              colon={false}
              name="color"
              rules={[{ required: true, message: 'Color is required' }]}
            >
              <ColorsInput
                form={form}
                data={skillData ? skillData.color : undefined}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default SkillForm
