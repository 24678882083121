import React from 'react'
import GlobalCounterStep from 'components/global/CounterStep'
import { organizerStore } from 'state/organizer/organizer'
import Step1 from './Step1'
import Step2 from './Step2'
import { FormInstance } from 'antd'
import Step3 from './Step3'

interface CreateOrganizerContentProps {
  form: FormInstance
}

const CreateOrganizerContent: React.FC<CreateOrganizerContentProps> = ({
  form,
}) => {
  const { organizerCurrentStep, isUpdating } = organizerStore(store => ({
    organizerCurrentStep: store.organizerCurrentStep,
    isUpdating: store.isUpdating,
  }))
  const steps = [
    { title: 'General Details' },
    { title: 'Password' },
    { title: 'Assign to Organization' },
  ]
  return (
    <>
      {!isUpdating && (
        <GlobalCounterStep steps={steps} currentStep={organizerCurrentStep} />
      )}
      {organizerCurrentStep === 0 && <Step1 form={form} />}
      {organizerCurrentStep === 1 && <Step2 form={form} />}
      {organizerCurrentStep === 2 && <Step3 />}
    </>
  )
}

export default CreateOrganizerContent
