import React, { useCallback, useEffect, useState } from 'react'
import { Avatar, Col, Form, FormInstance, Input, Row } from 'antd'
import UploadDocument from 'components/global/UploadDocument'
import FileUploader from 'helper/FileUploader'
import { Photo } from 'types/globalTypes'
import ColorsInput from 'components/global/inputs/ColorsInput'
import {
  useCreateEventCategory,
  useUpdateEventCategory,
} from 'api/Listings/EventCategories'
import { myStore } from 'state/store'
import { categoryStore } from 'state/listing/eventCategoryListing'

interface EventCategoryFormProps {
  form: FormInstance
}

const EventCategoryForm: React.FC<EventCategoryFormProps> = ({ form }) => {
  const { setAddEventCategoryModal, categoryData } = categoryStore(store => ({
    categoryData: store.eventCategoryData,
    setAddEventCategoryModal: store.setAddEventCategoryModal,
  }))
  const [documentIds, setDocumentIds] = useState<string[]>(
    categoryData ? [categoryData.iconId] : [],
  )
  const [photos] = useState<Photo[]>(categoryData ? [categoryData.icon] : [])
  const [error, setError] = useState<string>('')
  const setModalBtnLoading = myStore(store => store.setModalBtnLoader)

  const { mutate: createEventCategory } = useCreateEventCategory()
  const { mutate: updateEventCategory } = useUpdateEventCategory()

  const formSchema = {
    name: categoryData ? categoryData.name : undefined,
    color: categoryData ? categoryData.color : undefined,
  }
  const onFinish = (values: { name: string; color: string }) => {
    if (documentIds.length === 0) {
      setError('Icons is required')
      return
    }
    const payload = {
      ...values,
      iconId: documentIds[0],
    }
    setModalBtnLoading(true)
    if (categoryData) {
      updateEventCategory(
        { eventCategoryId: categoryData._id, payload },
        {
          onSuccess: () => {
            setModalBtnLoading(false)
            setAddEventCategoryModal(false)
          },
          onError: () => {
            setModalBtnLoading(false)
          },
        },
      )
    } else {
      createEventCategory(payload, {
        onSuccess: () => {
          setModalBtnLoading(false)
          setAddEventCategoryModal(false)
        },
        onError: () => {
          setModalBtnLoading(false)
        },
      })
    }
  }

  useEffect(() => {
    if (documentIds.length) {
      setError('')
    }
  }, [documentIds])

  return (
    <div>
      <Form
        name="event-category-form"
        form={form}
        initialValues={formSchema}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
      >
        <Row>
          <Col>
            <label className="custom-field-label required-input">
              Add Icon
            </label>
          </Col>
          <Col span={12}>
            <UploadDocument
              documentIds={documentIds}
              setDocumentIds={setDocumentIds}
              multiple={true}
              docType="image/*"
              files={photos}
            />
          </Col>
          {error && (
            <Col className="-mt-20px" span={24}>
              <p className="ant-form-item-explain-error">{error}</p>
            </Col>
          )}
        </Row>
        <Row className="-mt-10px">
          <Col span={24}>
            <label className="input-label required-input">Name</label>
            <Form.Item
              colon={false}
              name="name"
              rules={[{ required: true, message: 'Name is required' }]}
            >
              <Input placeholder="Enter Name" required name="name" />
            </Form.Item>
          </Col>
        </Row>
        <Col span={24}>
          <label className="input-label required-input">Color</label>
          <Form.Item
            colon={false}
            name="color"
            rules={[{ required: true, message: 'Color is required' }]}
          >
            <ColorsInput
              form={form}
              data={categoryData ? categoryData.color : undefined}
            />
          </Form.Item>
        </Col>
      </Form>
    </div>
  )
}

export default EventCategoryForm
