import axios from 'axios'
import { axiosGet, axiosPatch, axiosPost, axiosPut } from '../Redux/axiosHelper'
import { BASE_URL, failureMessage, successMessage } from './global'
import { GetAllEventsParams, PaginatedEventsResp } from 'types/event/events'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import OrganizerDataType, {
  OrganizerCreatePayload,
  OrganizerListingResp,
  OrganizerUpdatePayload,
} from 'types/organizer'

export const updateOrganizerStatus = async (
  params: string,
  payload: string,
) => {
  const response = await axios.patch(
    `${BASE_URL}v1/organizers/${params}/status`,
    { status: payload },
  )
  response.status === 200
    ? successMessage('Status updated Successfully !')
    : failureMessage('Error while updating Status')
}
export const deleteOrganizer = async (id: string) => {
  const response = await axios.delete(`${BASE_URL}v1/organizers/${id}`)
  response.status === 200
    ? successMessage('Organizer Deleted Successfully !')
    : failureMessage('Error while Deleting Organizer')
}
export const confirmOrganizerImportData = async (payload: any) => {
  try {
    const response = await axios.put(`${BASE_URL}v1/imports/organizer`, payload)
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(`Unexpected status code: ${response.status}`)
    }
  } catch (error) {
    failureMessage(`${error.response.data.message}`)
    return null
  }
}
export const importOrganizerFile = async (payload: any) => {
  // const response = await axios.post(`${BASE_URL}v1/imports/organizer`, payload)
  // console.log(response)
  //  if(response.status === 200){
  //   return response.data
  //  }else{
  //   console.log(response?.data)
  //   failureMessage(`Error:${response.statusText}`)
  //   failureMessage(`Error:${response.status}`)

  try {
    const response = await axios.post(
      `${BASE_URL}v1/imports/organizer`,
      payload,
    )
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(`Unexpected status code: ${response.status}`)
    }
  } catch (error) {
    failureMessage(`${error.response.data.message}`)
    return null
  }
}
export const confirmEmail = async (organizerId: string, payload: string) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}v1/users/change-email/${organizerId}`,
      payload,
    )
    if (response.status === 200) {
      successMessage('Email change Successfully !')
      return response.data
    } else {
      failureMessage(response?.data?.message || 'An error occurred')
      return Promise.reject(response.data)
    }
  } catch (error) {
    if (error.response) {
      failureMessage(error.response.data.message || 'An error occurred')
    } else {
      failureMessage('An error occurred')
    }
  }
}
export const blockEmail = async (payload: string) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}v1/users/block-email`,
      payload,
    )
    if (response.status === 200) {
      successMessage('Email block Successfully !')
      return response.data
    } else {
      failureMessage(response?.data?.message || 'An error occurred')
      return Promise.reject(response.data)
    }
  } catch (error) {
    if (error.response) {
      failureMessage(error.response.data.message || 'An error occurred')
    } else {
      failureMessage('An error occurred')
    }
  }
}

// get all organizers
export const getOrganizers = async (parms: any) => {
  const response = await axiosGet('v1/admins/organizers', parms)
  return response.data
}

export const useGetOrganizers = (params: any) => {
  const organizerData = useQuery<any>(
    ['getOrganizers'],
    () => getOrganizers(params),
    { enabled: false },
  )
  return organizerData
}

// get organizer by id
const getOrganizerById = async (organizerId: string) => {
  const response = await axiosGet(`v1/organizers/${organizerId}`)
  return response.data
}
export const useGetOrganizerById = (id: string) => {
  const organizerById = useQuery<OrganizerDataType>(
    ['organizer-by-id'],
    () => getOrganizerById(id),
    { enabled: false },
  )
  return organizerById
}

// get organizer events
const getOrganizerEvents = async (
  organizerId: string,
  params: GetAllEventsParams,
) => {
  const response = await axiosGet(`v1/organizers/events/${organizerId}`, params)
  return response.data
}

export const useGetOrganizerEvents = (
  organizerId: string,
  params: GetAllEventsParams,
) => {
  const organizerEvents = useQuery<PaginatedEventsResp>(
    ['get-organizer-events'],
    () => (organizerId ? getOrganizerEvents(organizerId, params) : null),
    { enabled: false },
  )
  return organizerEvents
}

// get organizer organizations
const getOrganizerOrganizations = async (organizerId: string) => {
  const response = await axiosGet(`v1/organizers/${organizerId}/organizations`)
  return response.data
}
export const useGetOrganizerOrganizations = (organizerId: string) => {
  const organizerOrganizations = useQuery<any>(
    ['organizations-by-orgId'],
    () => (organizerId ? getOrganizerOrganizations(organizerId) : null),
    {
      enabled: false,
    },
  )
  return organizerOrganizations
}

// create organizer
const createOrganizer = async (payload: OrganizerCreatePayload) => {
  const response = await axiosPost(payload, `v1/admins/create-organizer`)
  return response.data
}

export const useCreateOrganizer = () => {
  const queryClient = useQueryClient()
  return useMutation(createOrganizer, {
    onSuccess: data => {
      queryClient.setQueryData(
        ['getOrganizers'],
        (oldData: OrganizerListingResp | undefined) => {
          if (!oldData) return
          return {
            ...oldData,
            records: [data, ...oldData.records],
            count: oldData.count + 1,
          }
        },
      )
    },
  })
}

// update organizer
const updateOrganizer = async ({
  organizerId,
  payload,
}: {
  organizerId: string
  payload: OrganizerUpdatePayload
}) => {
  const response = await axiosPatch(
    payload,
    `v1/admins/organizer/${organizerId}`,
  )
  return response.data
}

export const useUpdateOrganizer = () => {
  const queryClient = useQueryClient()
  return useMutation(updateOrganizer, {
    onSuccess: updatedData => {
      queryClient.setQueryData(['organizer-by-id'], () => {
        return { ...updatedData }
      })
      queryClient.setQueryData(
        ['getOrganizers'],
        (oldData: OrganizerListingResp | undefined) => {
          if (!oldData) return
          return {
            ...oldData,
            records: oldData.records.map(orgs =>
              orgs._id === updatedData._id ? updatedData : orgs,
            ),
            count: oldData.count + 1,
          }
        },
      )
    },
  })
}

// assign organizer to the organization
const assignToOrganization = async ({
  organizerId,
  payload,
}: {
  organizerId: string
  payload: {
    organizations: {
      organizationId: string
      designation: string
      accessLevel: string
    }[]
  }
}) => {
  const response = await axiosPut(
    payload,
    `v1/admins/organizer/${organizerId}/assign-organization`,
  )
  return response.data
}

export const useAssignToOrganization = () => {
  const queryClient = useQueryClient()
  return useMutation(assignToOrganization)
}

// get organization by email or name
const getOrganizationByEmailOrName = async (params: {
  searchString: string
}) => {
  const response = await axiosGet(`v1/organizers/search-organizer`, params)
  return response.data
}

export const useGetOrganizationByEmailOrName = (params: {
  searchString: string
}) => {
  const organizationByEmailOrName = useQuery<OrganizerDataType[]>(
    ['getOrganizationByEmailOrName'],
    () => getOrganizationByEmailOrName(params),
    { enabled: false },
  )
  return organizationByEmailOrName
}
