import { axiosDelete, axiosGet, axiosPatch, axiosPost } from 'Redux/axiosHelper'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  CreateTeamConstraintPayload,
  TeamConstraintType,
} from 'types/league/teamConstraints'
import { ConstaintStepStatusesResp } from 'types/league/league'

// get all team constraint of leagaueId
const getLeagueTeamConstraintsById = async (leagueId: string) => {
  const response = await axiosGet(
    `v1/league-team-constraints/step-5/${leagueId}`,
  )
  return response.data
}
export const useGetLeagueTeamConstraintsById = (leagueId: string) => {
  const teamConstraints = useQuery<TeamConstraintType[]>(
    ['league-team-constraints-by-id'],
    () => (leagueId ? getLeagueTeamConstraintsById(leagueId) : null),
    {
      enabled: false,
    },
  )
  return teamConstraints
}

// create new constraints
const createTeamLeagueConstraint = async ({
  leagueId,
  payload,
}: {
  leagueId: string
  payload: CreateTeamConstraintPayload
}) => {
  const response = await axiosPost(
    payload,
    `v1/league-team-constraints/step-5/${leagueId}`,
  )
  return response.data
}
export const useCreateTeamLeagueConstraint = () => {
  const queryClient = useQueryClient()
  return useMutation(createTeamLeagueConstraint, {
    onSuccess: newConstraint => {
      queryClient.setQueryData(
        ['league-team-constraints-by-id'],
        (oldData: TeamConstraintType[] | undefined) => {
          if (!oldData) return []
          return [newConstraint, ...oldData]
        },
      )
      queryClient.setQueryData(
        ['league-onstraints-status'],
        (oldData: ConstaintStepStatusesResp | undefined) => {
          if (!oldData)
            return {
              teamsConstraints: true,
            }
          return {
            ...oldData,
            teamsConstraints: true,
          }
        },
      )
    },
  })
}

// update team constraint
const updateTeamLeagueConstraint = async ({
  constraintId,
  payload,
}: {
  constraintId: string
  payload: CreateTeamConstraintPayload
}) => {
  const response = await axiosPatch(
    payload,
    `v1/league-team-constraints/step-5/${constraintId}`,
  )
  return response.data
}
export const useUpdateTeamLeagueConstraint = () => {
  const queryClient = useQueryClient()
  return useMutation(updateTeamLeagueConstraint, {
    onSuccess: updatedConstraint => {
      queryClient.setQueryData(
        ['league-team-constraints-by-id'],
        (oldData: TeamConstraintType[] | undefined) => {
          if (!oldData) return []
          return oldData.map(constraint =>
            constraint._id === updatedConstraint._id
              ? updatedConstraint
              : constraint,
          )
        },
      )
    },
  })
}

// delete team constraint
const deleteTeamLeagueConstraint = async (constraintId: string) => {
  const response = await axiosDelete(
    `v1/league-team-constraints/step-5/delete-by-id/${constraintId}`,
  )
  return response.data
}
export const useDeleteTeamLeagueConstraint = () => {
  const queryClient = useQueryClient()
  return useMutation(deleteTeamLeagueConstraint, {
    onSuccess: deletedTeam => {
      queryClient.setQueryData(
        ['league-team-constraints-by-id'],
        (oldData: TeamConstraintType[] | undefined) => {
          console.log(oldData, 'old data', deletedTeam)
          if (!oldData) return []
          return oldData.filter(team => team._id !== deletedTeam._id)
        },
      )
      queryClient.setQueryData(
        ['league-onstraints-status'],
        (oldData: ConstaintStepStatusesResp | undefined) => {
          if (!oldData)
            return {
              teamsConstraints: false,
            }
          const constraintsQuery = queryClient
            .getQueryCache()
            .find(['league-team-constraints-by-id'])
          if (
            Array.isArray(constraintsQuery?.state?.data) &&
            !constraintsQuery.state.data.length
          ) {
            return {
              ...oldData,
              teamsConstraints: false,
            }
          }
        },
      )
    },
  })
}
