import CustomModal from 'components/global/CustomModal'
import CustomModalFooter from 'components/global/CustomModalFooter'
import useFormSubmit from 'helper/Hooks/formSubmit'
import React from 'react'
import { ageGroupStore } from 'state/listing/ageGroupListing'
import AgeForm from './ModalContent/AgeForm'

const AgeGroupModal: React.FC = () => {
  const { form, handleSubmit } = useFormSubmit()
  const { addAgeGroupModal, setAddAgeGroupModal, ageGroupData } = ageGroupStore(
    store => ({
      addAgeGroupModal: store.addAgeGroupModal,
      setAddAgeGroupModal: store.setAddAgeGroupModal,
      ageGroupData: store.ageGroupData,
    }),
  )
  return (
    <>
      <CustomModal
        openModal={addAgeGroupModal}
        closeModal={setAddAgeGroupModal}
        title={ageGroupData ? 'Edit grade' : 'Add new grade'}
        footer={
          <CustomModalFooter
            multibutton={false}
            btnAlignment="justify-end"
            btnText={ageGroupData ? 'Update' : 'Create'}
            btnType="primary"
            onSubmit={handleSubmit}
          />
        }
      >
        <AgeForm form={form} />
      </CustomModal>
    </>
  )
}

export default AgeGroupModal
