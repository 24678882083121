import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Row } from 'antd'
import TimeSlots from './InputComps/TimeSlots'
import { Address } from 'types/globalTypes'
import LocationListingModal from 'pages/Events/Views/CreateEvent/Screens/GeneralDetails/GeneralDetailModal/LocationListingModal'
import { leagueEventStore } from 'state/event/leagueEvent'
import MapModal from 'helper/Map/MapModal'
import { useAddOrgLocation } from 'api/EventCreation/EventCreation'
import { LeagueStep4Payload } from 'types/league/league'
import { useLeagueStep4 } from 'api/LeagueCreation/leagueCreation'
import ButtonSpinner from 'components/global/ButtonSpinner'
import { infoMessage } from 'api/global'

interface LeagueStep3Props {
  handleNext: () => void
  handleBack: () => void
}

const Step4: React.FC<LeagueStep3Props> = ({ handleNext, handleBack }) => {
  const [form] = Form.useForm()
  const { leagueData, setLeagueData, timeSlots } = leagueEventStore(store => ({
    leagueData: store.leagueData,
    setLeagueData: store.setLeagueData,
    timeSlots: store.timeSlots,
  }))
  const [mapData, setMapModalData] = useState<Address | null>(
    leagueData ? leagueData.timeSlots.venue : null,
  )
  const [locationModalVisible, setLocationModalVisible] =
    useState<boolean>(false)
  const [activeKey, setActiveKey] = useState<string>('1')
  const [mapModalVisible, setMapModalVisible] = useState<boolean>(false)
  const [isFromPreviousLoc, setIsFromPreviousLoc] = useState<boolean>(false)
  const { mutate, isLoading } = useAddOrgLocation()
  const { mutate: step4Mutation, isLoading: step4MutationLoading } =
    useLeagueStep4()

  const formSchema = {
    minsBetweenGames: leagueData
      ? leagueData.timeSlots.minsBetweenGames
      : undefined,
  }

  const setFieldsValue = (leagueData: any) => {
    if (leagueData.timeSlots?.venue) {
      form.setFieldsValue({ location: leagueData.timeSlots.venue.title })
    }
  }

  const onFinish = (values: { minsBetweenGames: number }) => {
    if (!mapData._id) return infoMessage('Venue is required!')
    const payload: LeagueStep4Payload = {
      timeSlots: {
        ...timeSlots,
        ...values,
        venueId: mapData._id,
      },
    }

    step4Mutation(
      { payload, leagueId: leagueData._id },
      {
        onSuccess: data => {
          setLeagueData(data)
          handleNext()
        },
      },
    )
  }

  useEffect(() => {
    if (!mapData?._id && mapData) {
      mutate(
        { payload: mapData, organizationId: leagueData.organizationId },
        {
          onSuccess: result => {
            setMapModalData(result)
            setLocationModalVisible(false)
          },
        },
      )
    }
  }, [mapData])

  return (
    <>
      <div>
        <h2 className="text-24 w-600">Time Slots</h2>

        <Form
          form={form}
          initialValues={formSchema}
          layout="vertical"
          onFinish={onFinish}
        >
          <div
            className="overflow-auto-y scroller"
            style={{ height: `calc(100vh - 400px)` }}
          >
            <TimeSlots />
            <Row gutter={10}>
              <Col className="mt-15px">
                <label className="input-label required-input mb-10px">
                  Minutes between games
                </label>

                <Form.Item
                  name="minsBetweenGames"
                  className="form-control mb-0"
                  rules={[
                    {
                      required: true,
                      message: 'Minutes between games is required',
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="0 Mins"
                    className="text-end radius-12px text-left"
                    onWheel={e => e.currentTarget.blur()}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row className="mt-10px">
              <Col>
                <label className="required-input  input-label">Location</label>
              </Col>
              <Col>
                <div
                  className="w-full border-1px radius-10 flex align-center px-12px pointer h-50px"
                  onClick={() => setLocationModalVisible(true)}
                >
                  {mapData ? (
                    <div className=" w-full flex justify-between align-center ">
                      <p className="text-gray">{`${mapData.title}: ${mapData.apartment}, ${mapData.city}, ${mapData.country}`}</p>
                    </div>
                  ) : (
                    <p className="text-gray">Select Location</p>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <Row justify="space-between" className="mb-26 mt-40px">
            <Button className="cancel-button" onClick={handleBack}>
              Back
            </Button>
            <Button
              className="stepper-button"
              htmlType="submit"
              disabled={step4MutationLoading}
            >
              {step4MutationLoading ? (
                <ButtonSpinner color="#000" size={20} />
              ) : (
                'Next'
              )}
            </Button>
          </Row>
        </Form>
      </div>

      {locationModalVisible && (
        <LocationListingModal
          isModalVisible={locationModalVisible}
          setModalVisible={setLocationModalVisible}
          organizationId={leagueData.organizationId}
          activeKeyTab={activeKey}
          setDefaultTabKey={setActiveKey}
          setMapModalVisible={setMapModalVisible}
          setMapModalData={setMapModalData}
          setFieldsValue={setFieldsValue}
          setIsFromPreviousLoc={setIsFromPreviousLoc}
        />
      )}
      {mapModalVisible && (
        <MapModal
          mapData={isFromPreviousLoc && mapData}
          setMapModalVisible={setMapModalVisible}
          setMapModalData={setMapModalData}
          setFieldsValue={setFieldsValue}
        />
      )}
    </>
  )
}

export default Step4
