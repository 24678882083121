import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { axiosDelete, axiosGet, axiosPatch, axiosPost } from 'Redux/axiosHelper'
import { EventResp, GetEventParams } from 'types/event/events'
import {
  CreateAdultPayload,
  FamilyMemberAdult,
  FindAdultParams,
  FindAdultResp,
  UpdateAdultPayload,
} from 'types/family/adult'
import { FamilyFullResp } from 'types/family/family'
import { MediaPaginatedResp } from 'types/media'

// find adult by email
const findAdultByEmail = async (params: FindAdultParams) => {
  const response = await axiosGet(`v1/authorized-adults/adult-by-email`, params)
  return response.data
}

export const useFindAdultByEmail = (params: FindAdultParams) => {
  const adultByEmail = useQuery<FindAdultResp>(
    ['find-adult-by-email', params],
    () => (params ? findAdultByEmail(params) : null),
  )
  return adultByEmail
}

// create adult
const createAdult = async ({
  familyId,
  payload,
}: {
  familyId: string
  payload: CreateAdultPayload
}) => {
  const response = await axiosPost(
    payload,
    `v1/authorized-adults/${familyId}/create-authorized-adult`,
  )
  return response.data
}

export const useCreateAdult = () => {
  const queryClient = useQueryClient()
  return useMutation(createAdult, {
    onSuccess: (newAdult: FamilyMemberAdult) => {
      queryClient.setQueryData(
        ['getFamilyData'],
        (oldData: FamilyFullResp | undefined) => {
          return oldData.adults?.length > 0
            ? {
                ...oldData,
                adults: [...oldData.adults, newAdult],
              }
            : {
                ...oldData,
                adults: [newAdult],
              }
        },
      )
    },
  })
}

// update adult
const updateAdult = async ({
  adultId,
  payload,
}: {
  adultId: string
  payload: UpdateAdultPayload
}) => {
  const response = await axiosPatch(payload, `v1/authorized-adults/${adultId}`)
  return response.data
}

export const useUpdateAdult = () => {
  const queryClient = useQueryClient()
  return useMutation(updateAdult, {
    onSuccess: (updatedAdult: FamilyMemberAdult) => {
      queryClient.setQueryData(
        ['getFamilyData'],
        (oldData: FamilyFullResp | undefined) => {
          if (!oldData) return
          if (oldData.adults.length === 0)
            return { ...oldData, adults: [updatedAdult] }

          return {
            ...oldData,
            adults: oldData.adults.map((adult: FamilyMemberAdult) =>
              adult._id === updatedAdult._id ? updatedAdult : adult,
            ),
          }
        },
      )
      queryClient.setQueryData(['adult-by-id'], () => {
        return { ...updatedAdult }
      })
    },
  })
}

// delete adult
const deleteAdult = async (adultId: string) => {
  const response = await axiosDelete(`v1/authorized-adults/${adultId}`)
  return response.data
}

export const useDeleteAdult = () => {
  const queryClient = useQueryClient()
  return useMutation(deleteAdult, {
    onSuccess: (deletedAdult: FamilyMemberAdult) => {
      queryClient.setQueryData(
        ['getFamilyData'],
        (oldData: FamilyFullResp | undefined) => {
          if (oldData.adults.length === 0) return []
          return {
            ...oldData,
            adults: oldData.adults.filter(
              (adult: FamilyMemberAdult) => adult._id !== deletedAdult._id,
            ),
          }
        },
      )
    },
  })
}

// get adults of the family
const getAdultsByFamilyId = async (familyId: string) => {
  const response = await axiosGet(`v1/authorized-adults/${familyId}/adults`)
  return response.data
}

export const useGetAdultsByFamilyId = (familyId: string) => {
  const familyAdults = useQuery<any>(
    ['adults-by-familyid'],
    () => (familyId ? getAdultsByFamilyId(familyId) : null),
    { enabled: false },
  )
  return familyAdults
}

// get adult by id
const getAdultById = async (adultId: string) => {
  const response = await axiosGet(`v1/authorized-adults/${adultId}`)
  return response.data
}

export const useGetAdultById = (adultId: string) => {
  const adultById = useQuery<FamilyMemberAdult>(
    ['adult-by-id'],
    () => (adultId ? getAdultById(adultId) : null),
    { enabled: false },
  )
  return adultById
}

// get adults event by id
const getAdultEventsById = async (adultId: string, params: GetEventParams) => {
  const response = await axiosGet(
    `v1/authorized-adults/${adultId}/events`,
    params,
  )
  return response.data
}

export const useGetAdultEvnetsById = (
  adultId: string,
  params: GetEventParams,
) => {
  const adultEvents = useQuery<EventResp>(
    ['events-by-adultId'],
    () => (adultId ? getAdultEventsById(adultId, params) : null),
    { enabled: false },
  )
  return adultEvents
}

//get adult media
const getAdultMediaById = async (adultId: string, params: any) => {
  const response = await axiosGet(
    `v1/authorized-adults/${adultId}/media`,
    params,
  )
  return response.data
}

export const useGetAdultMediaById = (adultId: string, params: any) => {
  const adultMedia = useQuery<MediaPaginatedResp>(
    ['adult-media-by-id'],
    () => (adultId ? getAdultMediaById(adultId, params) : null),
    { enabled: false },
  )
  return adultMedia
}
