import { OrganizationResp } from 'types/globalTypes'
import { create } from 'zustand'

const organizationCreationStore = (set: any) => ({
  // modals
  organizationModalVisible: false,
  setOrganizationModalVisible: (state: boolean) =>
    set(() => ({ organizationModalVisible: state })),

  //   data sets
  organizationData: null,
  setOrganizationData: (state: OrganizationResp) =>
    set(() => ({ organizationData: state })),

  // couters and other states
  organizationCurrentStep: 0,
  setOrganizationCurrentStep: (state: number) =>
    set(() => ({ organizationCurrentStep: state })),
  isUpdating: false,
  setIsUpdating: (state: boolean) => set(() => ({ isUpdating: state })),
})

export const organizationStore = create(organizationCreationStore)
