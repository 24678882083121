import React, { useEffect, useState } from 'react'
import { Avatar, Col, Form, FormInstance, Input, Row } from 'antd'
import FileUploader from 'helper/FileUploader'
import { organizationStore } from 'state/organization/organization'
import youtube from 'assets/Images/youtube.svg'
import tiktok from 'assets/Images/tiktok.svg'
import instagram from 'assets/Images/instagram.svg'
import snapIcon from 'assets/Images/snapIcon.svg'
import facebookIcon from 'assets/Images/facebook.svg'
import twitter from 'assets/Images/twitter.svg'
import { useUpdateOrganizationById } from 'api/Organizations'
import { myStore } from 'state/store'

const { TextArea } = Input

interface Step1Props {
  form: FormInstance
}

const Step2: React.FC<Step1Props> = ({ form }) => {
  const {
    mutate: organizationUpdatingMutation,
    isLoading: organizationUpdateMutationLoading,
  } = useUpdateOrganizationById()
  const {
    organizationData,
    setOrganizationData,
    setOrganizationCurrentStep,
    isUpdating,
    setIsUpdating,
    setOrganizationModalVisible,
  } = organizationStore(store => ({
    organizationData: store.organizationData,
    setOrganizationData: store.setOrganizationData,
    setOrganizationCurrentStep: store.setOrganizationCurrentStep,
    isUpdating: store.isUpdating,
    setIsUpdating: store.setIsUpdating,
    setOrganizationModalVisible: store.setOrganizationModalVisible,
  }))
  const setModalBtnLoader = myStore(store => store.setModalBtnLoader)
  const [avatar, setAvatar] = useState<string>('')

  const formSchema = {
    photoId: organizationData ? organizationData.photoId : undefined,
    website: organizationData ? organizationData.website : undefined,
    about: organizationData ? organizationData.about : undefined,
    facebook: organizationData ? organizationData.social?.facebook : undefined,
    twitter: organizationData ? organizationData.social?.twitter : undefined,
    insta: organizationData ? organizationData.social?.insta : undefined,
    youtube: organizationData ? organizationData.social?.youtube : undefined,
    snapchat: organizationData ? organizationData.social?.snapchat : undefined,
    tiktok: organizationData ? organizationData.social?.tiktok : undefined,
    linkedIn: organizationData ? organizationData.social?.linkedIn : undefined,
  }

  const onFinsish = values => {
    const social = {
      facebook: values.facebook,
      twitter: values.twitter,
      instagram: values.insta,
      youtube: values.youtube,
      snapchat: values.snapchat,
      tiktok: values.tiktok,
    }
    const payload = {
      ...organizationData,
      ...values,
      social,
    }
    delete payload.facebook
    delete payload.twitter
    delete payload.insta
    delete payload.youtube
    delete payload.snapchat
    delete payload.tiktok

    if (isUpdating) {
      organizationUpdatingMutation(
        {
          payload: { ...payload, ownerId: organizationData?.ownerId },
          organizationId: organizationData?._id,
        },
        {
          onSuccess: () => {
            setIsUpdating(false)
            setOrganizationData(null)
            setOrganizationCurrentStep(0)
            setOrganizationModalVisible(false)
          },
        },
      )
    } else {
      setOrganizationData(payload)
      setOrganizationCurrentStep(2)
    }
  }

  useEffect(() => {
    setModalBtnLoader(organizationUpdateMutationLoading)
  }, [organizationUpdateMutationLoading])

  return (
    <div className="mt-20px">
      <Form
        name="organization-creation-form"
        initialValues={formSchema}
        form={form}
        onFinish={onFinsish}
        layout="vertical"
        autoComplete="off"
      >
        <Row gutter={24} align="middle">
          <Col span={4} className="mb-10px">
            <Avatar size={80} src={avatar ? avatar : ''}></Avatar>
          </Col>
          <Col span={18} className="">
            <Form.Item name="photoId">
              <FileUploader
                setAvatar={setAvatar}
                name="adult"
                form={form}
                fileId={
                  organizationData && organizationData?.photoId
                    ? organizationData.photoId
                    : undefined
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} className="mt-10px">
          <Col span={24}>
            <label className="input-label">Website</label>
            <Form.Item colon={false} name="website">
              <Input placeholder="www.bamrec.com" name="website" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} className="mt-10px">
          <Col span={24}>
            <label className="input-label">About organization </label>
            <Form.Item colon={false} name="about">
              <TextArea
                className="w-100"
                rows={5}
                placeholder="Some information about this organization goes here"
              />
            </Form.Item>
          </Col>
        </Row>

        <label className="input-label text-16">Socials</label>
        <Row className="align-items-center mb-16">
          <Col span={2}>
            <div>
              <img alt="img" src={facebookIcon}></img>
            </div>
          </Col>
          <Col span={22}>
            <Form.Item name="facebook" className="mb-0">
              <Input placeholder="Facebook profile URL" name="facebook" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="align-items-center mb-16">
          <Col span={2}>
            <div>
              <img alt="img" src={twitter}></img>
            </div>
          </Col>
          <Col span={22}>
            <Form.Item name="twitter" className="mb-0">
              <Input placeholder="Twitter profile URL" name="twitter" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="align-items-center mb-16">
          <Col span={2}>
            <div>
              <img alt="img" src={instagram}></img>
            </div>
          </Col>
          <Col span={22}>
            <Form.Item name="insta" className="mb-0">
              <Input placeholder="instagram profile URL" name="instagram" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="align-items-center mb-16">
          <Col span={2}>
            <div>
              <img alt="img" src={youtube}></img>
            </div>
          </Col>
          <Col span={22}>
            <Form.Item name="youtube" className="mb-0">
              <Input placeholder="youtube profile URL" name="youtube" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="align-items-center mb-16">
          <Col span={2}>
            <div>
              <img alt="img" src={snapIcon}></img>
            </div>
          </Col>
          <Col span={22}>
            <Form.Item name="snapchat" className="mb-0">
              <Input placeholder="snapIcon profile URL" name="snapIcon" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="align-items-center mb-16">
          <Col span={2}>
            <div>
              <img alt="img" src={tiktok}></img>
            </div>
          </Col>
          <Col span={22}>
            <Form.Item name="tiktok" className="mb-0">
              <Input placeholder="tiktok profile URL" name="tiktok" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Step2
