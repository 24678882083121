import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { axiosGet, axiosPost } from 'Redux/axiosHelper'
import { SendEmailPayload, SendSmsPayload } from 'types/chat'

// get emails by attendee ids
const getEmailsByAttendeeIds = async (payload: { attendeesIds: string[] }) => {
  const response = await axiosPost(payload, `v1/contact/get-emails`)
  return response.data
}

export const useGetEmailsByAttendeeIds = () => {
  return useMutation(getEmailsByAttendeeIds)
}

const sendEmailToAttendees = async (payload: SendEmailPayload) => {
  const response = await axiosPost(payload, `v1/contact/email-attendees`)
  return response.data
}

export const useSendEmailToAttendees = () => {
  return useMutation(sendEmailToAttendees)
}

export const getAttendeesPhoneById = async (payload: {
  attendeesIds: string[]
}) => {
  const response = await axiosPost(payload, `v1/contact/get-phone-numbers`)
  return response.data
}

export const useGetAttendeesPhoneById = () => {
  return useMutation(getAttendeesPhoneById)
}

const sendSmsToAttendees = async ({
  payload,
  isScheduled,
}: {
  payload: SendSmsPayload
  isScheduled?: boolean
}) => {
  const response = await axiosPost(payload, `v1/contact/sms-attendees`)
  return response.data
}
export const useSendSmsToAttendees = () => {
  const queryClient = useQueryClient()
  return useMutation(sendSmsToAttendees, {
    onSuccess: (data, variables) => {
      if (!variables.isScheduled) return
      queryClient.setQueryData(
        ['scheduled-messages'],
        (oldData: any[] | undefined) => {
          if (!oldData) return [data]
          return [...oldData, data]
        },
      )
    },
  })
}

const getScheduledMessages = async (schedulerId: string) => {
  const response = await axiosGet(`v1/contact/scheduled-sms/${schedulerId}`)
  return response.data
}
export const useGetScheduledMessages = (schedulerId: string) => {
  return useQuery(
    ['scheduled-messages'],
    () => (schedulerId ? getScheduledMessages(schedulerId) : null),
    {
      enabled: false,
    },
  )
}
